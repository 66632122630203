<template>
  <div>

    <div class="d-flex align-center pa-3 c-input-wrapper br-8">
      <c-input :value.sync="tokenAddress" placeholder="0x...">
      </c-input>

      <v-progress-circular
      v-if="loadingToken"
      indeterminate
      width="2"
      class="mr-2"
      size="24"
      color="primary"
      ></v-progress-circular>
    </div>

    <v-slide-y-transition>
      <div v-if="tokenHydrated.address" outlined class="mt-1 br-8" style="overflow: hidden;">
        <div class="d-flex align-center textFaint--text midground pa-4">
          <coin-icon :address="tokenHydrated.address" class="mr-2"></coin-icon>
          {{ tokenHydrated.symbol }} | {{ tokenHydrated.name }}
          <v-spacer></v-spacer>

          <v-btn icon color="textFaint" @click="tokenAddress = ''">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </div>
    </v-slide-y-transition>

  </div>
</template>

<script>
import { ethers } from 'ethers'
import ERC20PAGER from '@/smart-contracts/erc20/pager'

export default {

  props: {
    address: {
      default: ''
    }
  },

  data: () => ({
    tokenAddress: '',
    loadingToken: false,
    tokenHydrated: {}
  }),

  watch: {
    address (nv) {
      this.tokenAddress = nv
    },
    tokenAddress (nv) {
      this.loadTokenInfo()
    }
  },

  methods: {
    async loadTokenInfo () {
      this.tokenHydrated = {}
      try {
        ethers.utils.getAddress(this.tokenAddress)
      } catch (e) {
        this.$emit('on-update', this.tokenHydrated)
        return
      }
      this.loadingToken = true
      ERC20PAGER.getERC20(this.tokenAddress)
        .then(token => {
          if (token.name === 'Unknown' && token.symbol === 'Unknown' && token.totalSupply === '0') {
            return
          }
          this.tokenHydrated = token
        })
        .catch(e => {
        })
        .then(() => {
          this.loadingToken = false
          this.$emit('on-update', this.tokenHydrated)
        })
    },
  },

  created () {
    this.tokenAddress = this.address
  }

}
</script>
