<template>
  <div>
    <div class="ma-4 mb-8 font-weight-bold text-center">
      All of our tokens are equipped with a new, hard-coded AntiBot system. You can turn these features on/off below.
    </div>
    <v-flex class="br-8 justify-center" :class="[{'pa-6': !$vuetify.breakpoint.xs}]">
      <div :class="[{'px-3': $vuetify.breakpoint.xs}, {'mx-8 px-6': !$vuetify.breakpoint.xs}]">
        <div class="d-flex align-center">
          <v-checkbox v-model="values.antiBotSettings.isActive" />
          AntiBot
          <v-tooltip bottom max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ma-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Initial Max balance - Allows you to set how many tokens can one person hold in their wallet. Available for up to the first 48 hours after market initialization.
              <br>
              <br>
              Max Wallet increment - Set the increment with which the Max Balance function will rise.
              Eg. if you have the Max Balance at 1500 tokens and increment set to 5 tokens, then with every block the Max Balance will rise by 5 tokens, until the Ending date.
              <br>
              <br>
              Ending date - set how many hours after market initialization should this feature be enabled (up to 48 hours).
              </span>
          </v-tooltip>
        </div>
<!--        <div v-if="antibotMaxBalanceEnabled" class="d-flex">-->
<!--          <div class="d-flex justify-end inputcolor pa-2 r-outlined">-->
<!--            <c-input :value.sync="antibotMaxBalance" placeholder="Max." />-->
<!--          </div>-->
<!--        </div>-->
        <max-balance-settings v-if="values.antiBotSettings.isActive" :show-title="false" :showEnabled="false" :show-increment="false" :symbol="tokenSymbol"
          @on-change="handleAntiBotSettingsChange"
          :initial-values="values.antiBotSettings"
        />
        <div class="d-flex align-center">
          <v-checkbox v-model="values.maxBalanceAfterBuyActive" />
          Max Balance
          <v-tooltip bottom max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ma-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Set the maximum amount of tokens that one wallet can obtain via a market buy.</span>
          </v-tooltip>
        </div>
        <v-row class="align-center" v-if="values.maxBalanceAfterBuyActive">
          <v-col cols="6">
            <div class="d-flex align-center pa-2">
              Max Balance
            </div>
          </v-col>
          <v-col cols="6">
            <div class="d-flex align-end inputcolor pa-2 r-outlined">
              <c-input
                :value.sync="values.maxBalanceAfterBuy"
                type="number"
                min="0"
              />
              {{tokenSymbol}}
            </div>
          </v-col>
        </v-row>
        <div class="d-flex align-center">
          <v-checkbox v-model="values.swapWhitelistingSettings.isActive" />
          Swap Whitelisting
          <v-tooltip bottom max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ma-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Limit the amount of people allowed to trade the token right after market initialization (up to 48 hours). During this time, only whitelisted wallets will be allowed to buy the token. You’ll be able to submit the whitelist in the token Admin Panel at a later date.
            <br>
            <br>
            Keep in mind that presale wallets will still be able to sell their allocations!</span>
          </v-tooltip>
        </div>
        <div class="d-flex pt-0">
          <whitelist-settings class="mb-2" v-if="values.swapWhitelistingSettings.isActive" :show-title="false" :showEnabled="false" :showWhitelistEnabled= "false" 
          @on-change="handleSwapWhitelistingSettingsChange"
          :initial-values="values.swapWhitelistingSettings"
          />
        </div>
      </div>
    </v-flex>
  </div>
</template>
<script>
import MaxBalanceSettings from '@/components/antibot/max-balance-settings';
import WhitelistSettings from '@/components/antibot/whitelist-settings';

export default {
  components: {
    WhitelistSettings,
    MaxBalanceSettings,
  },
  props: {
    initialValues: {
      type: Object
    },
    symbol: {
      type: String
    }
  },
  data: ({ initialValues, symbol }) => ({
    values: initialValues,
    tokenSymbol: symbol
  }),
  methods: {
    onChange (values) {
      this.$emit('on-change', values);
    },
    handleAntiBotSettingsChange (values) {
      this.values.antiBotSettings = values;
    },
    handleSwapWhitelistingSettingsChange (values) {
      this.values.swapWhitelistingSettings = values;
    }
  },
  watch: {
    values: {
      handler (newValue, _) {
        this.onChange(newValue);
      },
      deep: true
    }
  }
}
</script>