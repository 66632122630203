<template>
  <div class="pa-4 v-card br-8">
    <div>
      Admins
    </div>
    <div v-for="admin of admins" :key="admin" class="d-flex align-center">
      <v-icon class="mr-1">mdi-account-circle</v-icon>
      <div class="caption">
        {{ admin }}
      </div>
      <v-spacer></v-spacer>
      <v-btn icon @click="removeAdmin(admin)" class="lgrad-red" small rounded>
        <v-icon color="white" small>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import EcosystemWhitelistContract from '@/smart-contracts/ecosystem-whitelist/ecosystem-whitelist'
import TxWrapper from '@/web3/tx-wrapper-2';

export default {
  props: {
    token_address: null
  },

  data: () => ({
    num_admins: 0,
    admins: [],
  }),

  computed: {
  },

  methods: {
    async refresh () {
      this.tokens = []
      this.num_admins = await EcosystemWhitelistContract.getNumAdminsForToken(this.token_address)
      var admins = await EcosystemWhitelistContract.getPagedAdmins(this.token_address, 0, this.num_admins)
      this.admins = admins
    },
    async removeAdmin (admin) {
      TxWrapper.doTransaction(EcosystemWhitelistContract.removeTokenAdminFromWhitelist, [this.token_address, admin], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(response => {
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
  },

  created () {
    this.refresh()
  }
}
</script>