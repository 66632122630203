<template>
  <div>
    <div class="v-card">

      <div class="font-weight-bold d-flex align-center pa-4">
        White list 
        <v-spacer></v-spacer>
        <span class="textFaint--text font-weight-regular">{{ whitelistLength }} accounts</span>
      </div>

      <div class="px-4">
        <div class="caption textFaint--text text-center">
          Check if an address is whitelisted
        </div>
        <div class="mt-2 d-flex align-center px-4 py-2 br-c inputcolor">
          <c-input :value.sync="checkUserAddress" placeholder="0x...">
          </c-input>
          <v-btn outlined small rounded @click="checkAddress">
            Check
          </v-btn>
        </div>
        <div class="text-center">
          <div v-if="checkResponse === true" class="primary--text">
            This address is whitelisted
          </div>
          <div v-else-if="checkResponse === false" class="pink--text">
            This address is not whitelisted
          </div>
          <div v-else class="pink--text">
            {{ checkResponse }}
          </div>
        </div>
      </div>

      <div class="mt-3 px-4 textFaint--text font-weight-bold caption d-flex">
        <span>
          User
        </span>
        <v-spacer></v-spacer>
        <span>
          Contribution
        </span>
      </div>
      <div class="px-4 pb-4 caption">
        <div v-for="w_item of whitelist" :key="w_item.address" :class="['d-flex', w_item.base_deposited !== '0' ? 'primary--text' : '']">
          {{ w_item.address }} 
          <v-spacer></v-spacer>
          <span v-if="w_item.base_deposited !== '0'">
            {{ $root.formatAmount(w_item.base_deposited, presaleInfo.base_token.decimals) }} {{ baseTokenSymbol }}
          </span>
        </div>
      </div>

      <!-- PAGINATION -->
      <div v-if="pageCount > 1" class="d-flex align-center background justify-center py-2">
        <v-btn :disabled="page === 0" @click="firstPage" text icon color="text">
          <v-icon>mdi-page-first</v-icon>
        </v-btn>
        <v-btn @click="previousPage" :disabled="page <= 0" text>
          Previous
        </v-btn>
        <div class="px-2 textFaint--text">
          {{ page + 1 }} / {{ pageCount }}
        </div>
        <v-btn :disabled="!nextPageExists" @click="nextPage" text>
          Next
        </v-btn>
        <v-btn icon :disabled="!nextPageExists" @click="lastPage" text color="text">
          <v-icon>mdi-page-last</v-icon>
        </v-btn>
      </div>
      <!-- PAGINATION -->

    </div>
  </div>
</template>

<script>
import PresaleContract from '@/smart-contracts/presales/v6/presale-contract'
import { ethers } from 'ethers'

export default {
  props: {
    address: {
      type: String
    }
  },

  data: () => ({
    whitelistLength: 0,
    whitelist: [],
    page: 0,
    rowsPerPage: 10,
    checkResponse: '',
    checkUserAddress: '',
    presaleInfo: {
      base_token: {
        symbol: '?'
      }
    },
  }),

  computed: {
    nextPageExists () {
      return (this.page + 1) * this.rowsPerPage < this.whitelistLength
    },
    pageCount () {
      return Math.ceil(this.whitelistLength / this.rowsPerPage)
    },
    baseTokenSymbol () {
      return this.presaleInfo.presale_in_eth ? this.$store.state.nativeGasTokenSymbol : this.presaleInfo.base_token.symbol.toUpperCase()
    },
  },

  methods: {
    firstPage () {
      if (this.page === 0) {
        return
      }
      this.page = 0
      this.refreshPage()
    },
    lastPage () {
      if (this.page === this.pageCount - 1) {
        return
      }
      this.page = this.pageCount - 1
      this.refreshPage()
    },
    nextPage () {
      this.page++
      this.refreshPage()
    },
    previousPage () {
      this.page--
      this.refreshPage()
    },
    async refreshPage () {
      var whitelistLength = await PresaleContract.whitelistLength(this.address)
      whitelistLength = Number(whitelistLength)
      /* var whitelist = []
      var endItem = (this.page + 1) * this.rowsPerPage
      endItem = endItem > whitelistLength ? whitelistLength : endItem
      var startOffset = this.page * this.rowsPerPage
      for (var i = startOffset; i < endItem; i++) {
        var address = await PresaleContract.getWhitelistedUserAtIndex(this.address, i)
        whitelist.push(address)
      }
      this.whitelist = whitelist */
      this.whitelistLength = whitelistLength

      this.whitelist = await PresaleContract.getPagedWhitelist(this.address, this.page * this.rowsPerPage, this.rowsPerPage)
    },
    async checkAddress () {
      var address = this.checkUserAddress
      try {
        ethers.utils.getAddress(address)
      } catch (e) {
        this.checkResponse = 'Invalid address'
        return
      }
      // var condensed = address.slice(0, 6) + '...' + address.slice(address.length - 4)
      var status = await PresaleContract.getUserWhitelistStatus(this.address, this.checkUserAddress)
      this.checkResponse = status
    },
    async loadPresaleInfo () {
      var response = await PresaleContract.getInfo(this.address)
      this.presaleInfo = response.presale_info
      await this.refreshPage()
    }
  },

  created () {
    this.loadPresaleInfo()
  }
}
</script>