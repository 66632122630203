<template>
  <div>
    <div class="ma-4 mb-8 font-weight-bold text-center">
      Our Tax Token comes with optional preset taxes. These can be adjusted later. 
    </div>
    <v-flex class="br-8 justify-center" :class="[{'pa-6': !$vuetify.breakpoint.xs}]">
      <div :class="[{'px-3': $vuetify.breakpoint.xs}, {'mx-8 px-6': !$vuetify.breakpoint.xs}]">
        <div class="d-flex align-center">
          <v-checkbox type="radio" v-model="preset" value="high"/>
          High Tax
          <v-tooltip right max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Buy tax: 12% 
              <br>
              Sell tax: 12%</span>
          </v-tooltip>
        </div>
        <div class="d-flex align-center">
          <v-checkbox type="radio" v-model="preset" value="mid" />
          Medium Tax
          <v-tooltip right max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Buy tax: 5% 
              <br>
              Sell tax: 6%</span>
          </v-tooltip>
        </div>
        <div class="d-flex align-center">
          <v-checkbox type="radio" v-model="preset" value="low" />
          Low Tax
          <v-tooltip right max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Buy tax: 3% 
              <br>
              Sell tax: 3%</span>
          </v-tooltip>
        </div>
      </div>
    </v-flex>
  </div>
</template>
<script>

export default {
  components: {
  },
  props: {
    initialValues: {
      type: Object
    },
    currentPreset: {
      type: String
    }
  },
  data: ({ initialValues }) => ({
    values: initialValues,
    preset: ''
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
  },
  methods: {
    onChange () {
      if (this.preset === null) { 
        this.values.taxSettings = {
          transactionTax: false,
          buyBackTax: false,
          holderTax: false,
          lpTax: false,
          canBlacklist: false,
          canMint: false,
          canPause: false,
          maxBalanceAfterBuy: false
        }
        this.values.lockedSettings = {
          transactionTax: false,
          buyBackTax: false,
          holderTax: true,
          lpTax: false,
          canBlacklist: false,
          canMint: false,
          canPause: false,
          maxBalanceAfterBuy: false
        }
        this.values.fees = {
          transactionTax: {
            buy: 0,
            sell: 0
          },
          buyBackTax: 0,
          holderTax: 0,
          lpTax: 0
        }
        this.values.buybackTaxThreshold = 0;
        this.values.lpTaxThreshold = 0;
        this.values.transactionTaxAddress = '';
      }
      if (this.preset === 'high') {
        this.values.taxSettings = {
          transactionTax: true,
          buyBackTax: true,
          holderTax: true,
          lpTax: true,
          canBlacklist: false,
          canMint: false,
          canPause: false,
          maxBalanceAfterBuy: false
        }
        this.values.lockedSettings = {
          transactionTax: false,
          buyBackTax: false,
          holderTax: true,
          lpTax: false,
          canBlacklist: true,
          canMint: true,
          canPause: true,
          maxBalanceAfterBuy: false
        }
        this.values.fees = {
          transactionTax: {
            buy: 9,
            sell: 2
          },
          buyBackTax: 4,
          holderTax: 3,
          lpTax: 3
        }
        this.values.buybackTaxThreshold = 5;
        this.values.lpTaxThreshold = 5;
        this.values.transactionTaxAddress = this.sEthers.coinbase;
      }
      if (this.preset === 'mid') {
        this.values.taxSettings = {
          transactionTax: true,
          buyBackTax: false,
          holderTax: true,
          lpTax: true,
          canBlacklist: false,
          canMint: false,
          canPause: false,
          maxBalanceAfterBuy: false
        }
        this.values.lockedSettings = {
          transactionTax: false,
          buyBackTax: false,
          holderTax: true,
          lpTax: false,
          canBlacklist: true,
          canMint: true,
          canPause: true,
          maxBalanceAfterBuy: false
        }
        this.values.fees = {
          transactionTax: {
            buy: 3,
            sell: 2
          },
          buyBackTax: 0,
          holderTax: 2,
          lpTax: 2
        }
        this.values.buybackTaxThreshold = 0;
        this.values.lpTaxThreshold = 5;
        this.values.transactionTaxAddress = this.sEthers.coinbase;
      }
      if (this.preset === 'low') {
        this.values.taxSettings = {
          transactionTax: true,
          buyBackTax: false,
          holderTax: true,
          lpTax: true,
          canBlacklist: false,
          canMint: false,
          canPause: false,
          maxBalanceAfterBuy: false
        }
        this.values.lockedSettings = {
          transactionTax: false,
          buyBackTax: false,
          holderTax: true,
          lpTax: false,
          canBlacklist: true,
          canMint: true,
          canPause: true,
          maxBalanceAfterBuy: false
        }
        this.values.fees = {
          transactionTax: {
            buy: 2,
            sell: 1
          },
          buyBackTax: 0,
          holderTax: 1,
          lpTax: 1
        }
        this.values.buybackTaxThreshold = 0;
        this.values.lpTaxThreshold = 0;
        this.values.transactionTaxAddress = this.sEthers.coinbase;
      }
      this.$emit('on-change', this.values);
      this.$emit('on-preset-change', this.preset);
    },
  },
  mounted () {
    this.preset = this.currentPreset;      
  },
  watch: {
    preset: {
      handler (newValue, _) {
        this.onChange();
      },
      deep: true
    }
  }
}
</script>