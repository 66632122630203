import {store} from '@/store/index'
import { ethers } from "ethers"

var weth_abis = [
  {"constant":false,"inputs":[{"name":"wad","type":"uint256"}],"name":"withdraw","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"},
  {"constant":false,"inputs":[],"name":"deposit","outputs":[],"payable":true,"stateMutability":"payable","type":"function"}
]

const Self = {
  deposit: async (_wethAddress, _amount) => {
    var sEthers = store.state.ethers
    var Weth = new ethers.Contract(_wethAddress, weth_abis, sEthers.signer)
    var res = Weth.deposit({value: _amount})
    return res
  },
  withdraw: async (_wethAddress, _amount) => {
    var sEthers = store.state.ethers
    var Weth = new ethers.Contract(_wethAddress, weth_abis, sEthers.signer)
    var res = Weth.withdraw(_amount)
    return res
  },
}

export default Self