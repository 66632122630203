import {store} from '@/store/index';
import { ethers } from "ethers";
import MINTERABI from '@/smart-contracts/minter-v2/minter-v2-abis';

// Example constructor object

// let taxSettings = {
//   transactionTax: true,
//   buyBackTax: true,
//   holderTax: true,
//   lpTax: true,
//   canBlacklist: true,
//   canMint: true,
//   canPause: true
// }

// let lockedSettings = {
//   transactionTax: false,
//   buyBackTax: false,
//   holderTax: true,
//   lpTax: false,
//   canBlacklist: false,
//   canMint: false,
//   canPause: false
// }

// let fees = {
//   transactionTax: {
//       buy: 500,
//       sell: 500
//   },
//   buyBackTax: 500,
//   holderTax: 500,
//   lpTax: 500
// }

// customTaxes = [
//   {
//       name: "Marketing Tax",
//       fee: {
//           buy: 100,
//           sell: 100
//       },
//       wallet: txWallet.address
//   }
// ]

// constructorParams = {
//   name_: "JeeTay",
//   symbol_: "JT",
//   decimals_: 18, 
//   creator_: owner.address,
//   tTotal_: 18000000,
//   _maxTax: 3000,
//   _settings: taxSettings,
//   _lockedSettings: lockedSettings,
//   _fees: fees,
//   _transactionTaxWallet: owner.address,
//   _customTaxes: customTaxes,
//   _taxHelperIndex: 0,
//   admin_: owner.address, 
//   recoveryAdmin_: txWallet.address,
//   timelockPeriod_: 100000,
//   lossless_: lossless,
//   isLossless_ : true
// }

const Self = {
  // See above for an example constructorParams
  createToken: async (constructorParams, _ethFee) => {
    var sEthers = store.state.ethers;
    const MintGenerator = new ethers.Contract(MINTERABI.mint_generator01_address(), MINTERABI.mint_generator01_abi, sEthers.signer);
    var res = await MintGenerator.createToken(constructorParams, { value: _ethFee, gasLimit: 12487794 });
    return res;
  }   
}

export default Self