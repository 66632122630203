import {store} from '@/store/index';
import { ethers } from "ethers";
import STAKE_POOL_ABI from './abis'
import ERC20 from '@/smart-contracts/erc20/pager'
import PROVIDERS from '@/web3/providers'

const Self = {
  getPoolInfo: async (_poolAddress, network = null) => {
    var provider = network ? PROVIDERS[network] : store.state.ethers.provider
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, provider)
    var res = await StakePool.getPoolInfo()
    var boost_options = res[2]
    boost_options = boost_options.map(item => {
      return {
        time_period: item[0].toNumber(),
        boost_percentage: item[1].toNumber()
      }
    })
    var stake_mod_fee_token = {}
    try {
      stake_mod_fee_token = await ERC20.getERC20(res[4][2], network)
    } catch (e) {}
    var staking_token = {}
    try {
      staking_token = await ERC20.getERC20(res[0][1], network)
    } catch (e) {}
    return {
      creator: res[0][0],
      staking_token: staking_token,
      min_staking_period: res[0][2].toNumber(),
      min_stake_amount: res[0][3].toString(),
      max_stake_amount: res[0][4].toString(),
      unlock_tokens: Boolean(res[0][5]),
      num_stakers: res[0][6].toString(),
      num_reward_pools: res[0][7].toString(),
      max_reward_subscriptions: res[0][8].toString(),
      reward_creator: res[0][9],

      shares_total: res[1][0].toString(),
      share_weight_total: res[1][1].toString(),

      boost_options: boost_options,

      uncl_fee_address: res[3][0],
      uncl_token_address: res[3][1],
      uncl_boost_max_percentage: res[3][2].toString(),
      uncl_boost_max_amount: res[3][3].toString(),

      stake_mod_fee_allowed: Boolean(res[4][0]),
      stake_mod_fee_amount: res[4][1].toString(),
      stake_mod_fee_token: stake_mod_fee_token,
      stake_mod_fee_address: res[4][3]
    }
  },
  stakeTokens: (_poolAddress, _stakeOwner, _stake_amount, _lock_period, _boost_time_period, _boost_percentage, _acceptedStakeModFee, _acceptedStakeModFeeToken) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var stakeFeeTokenIsGasToken = _acceptedStakeModFeeToken === ethers.constants.AddressZero
    var msgValue = stakeFeeTokenIsGasToken ? _acceptedStakeModFee : '0'
    var res = StakePool.stakeTokens(_stakeOwner, _stake_amount, _lock_period, _boost_time_period, _boost_percentage, _acceptedStakeModFee, _acceptedStakeModFeeToken, {value: msgValue})
    return res
  },
  boostWeightWithUNCL: (_poolAddress, _unclAmount) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.boostWeightWithUNCL(_unclAmount)
    return res
  },
  adminDrainToken: (_poolAddress, _token, _amount) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminDrainToken(_token, _amount)
    return res
  },
  adminSetRewardCreator: (_poolAddress, _creator) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetRewardCreator(_creator)
    return res
  },
  adminSetMinStakingPeriod: (_poolAddress, _lock_period) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetMinStakingPeriod(_lock_period)
    return res
  },
  adminSetMinMaxStakeAmount: (_poolAddress, _minStakeAmount, _maxStakeAmount) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetMinMaxStakeAmount(_minStakeAmount, _maxStakeAmount)
    return res
  },
  adminSetUnlockTokens: (_poolAddress, _unlockTokens) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetUnlockTokens(_unlockTokens)
    return res
  },
  adminSetStakeModificationFee: (_poolAddress, _amount, _feeToken, _feeAddress) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.adminSetStakeModificationFee(_amount, _feeToken, _feeAddress)
    return res
  },
  transferPoolOwnership: (_poolAddress, _newOwner) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.transferPoolOwnership(_newOwner)
    return res
  },
  withdrawTokens: (_poolAddress, _withdraw_amount, _acceptedStakeModFee, _acceptedStakeModFeeToken) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var stakeFeeTokenIsGasToken = _acceptedStakeModFeeToken === ethers.constants.AddressZero
    var msgValue = stakeFeeTokenIsGasToken ? _acceptedStakeModFee : '0'
    var res = StakePool.withdrawTokens(_withdraw_amount, {value: msgValue})
    return res
  },
  harvestMultiple: (_poolAddress, _rewardPools) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.harvestMultiple(_rewardPools)
    return res
  },
  subscribe: (_poolAddress, _rewardPools) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.subscribe(_rewardPools)
    return res
  },
  getUserInfo: async (_poolAddress, _user) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.provider)
    var res = await StakePool.getUserInfo(_user)
    var stake_mod_fee_token = {}
    try {
      stake_mod_fee_token = await ERC20.getERC20(res[9])
    } catch (e) {}
    return {
      user_address: res[0],
      shares: res[1].toString(),
      tokens_staking: res[2].toString(),
      share_weight: res[3].toString(),
      unlock_date: res[4].toNumber(),
      boost_additional_time: res[5].toNumber(),
      time_boost_percentage: res[6].toString(),
      uncl_boost_percentage: res[7].toString(),
      subscriptions_length: res[8].toNumber(),
      accepted_stake_fee_token: stake_mod_fee_token,
      accepted_stake_fee_amount: res[10].toString()
    }
  },
  sharesToTokens: async (_poolAddress, _numShares) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.provider)
    var res = await StakePool.sharesToTokens(_numShares)
    return res.toString()
  },
  tokensToShares: async (_poolAddress, _numTokens) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.provider)
    var res = await StakePool.tokensToShares(_numTokens)
    return res.toString()
  },

  // UNCX ADMIN FUNCTIONS
  setStakeModFeeState: (_poolAddress, _state) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.setStakeModFeeState(_state)
    return res
  },
  overrideUNCLBoostInfo: (_poolAddress, _boostToken, _boostFeeAddress, _boostMaxPercent, _boostMaxAmount) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(_poolAddress, STAKE_POOL_ABI.stake_pool_abi, sEthers.signer)
    var res = StakePool.overrideUNCLBoostInfo(_boostToken, _boostFeeAddress, _boostMaxPercent, _boostMaxAmount)
    return res
  },
}

export default Self