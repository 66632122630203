<template>
  <div>

    <div v-if="firstLoad || userHasPools" class="d-flex align-center textFaint--text midground caption px-4 py-1">
      <div class="">
        <v-icon color="textFaint" class="px-2">mdi-pulse</v-icon>
        {{ $settings.CHAINS[network].name }}
      </div>

      <v-spacer></v-spacer>
      <v-fade-transition appear mode="out-in">
        <v-progress-circular
        v-if="loading"
        indeterminate
        size="36"
        width="2"
        color="textFaint">
          <img 
          src="@/assets/img/UNCX_fill.svg" 
          height="120px"
          class="greyscale"
          width="28px">
        </v-progress-circular>
        <v-btn v-else @click="refresh" icon color="textFaint">
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
      </v-fade-transition>
    </div>
    <v-expand-transition appear>
      <div v-if="userHasPools" class="">

        <template v-if="Number(normal.num_pools) > 0">
          <div v-if="false" class="px-4 py-2 caption d-flex align-center textFaint--text">
            Your Stake Pools ({{ normal.num_pools }})
          </div>

          <v-row dense class="ma-0 pt-1">
            <v-col v-for="pool of normal.pools" :key="pool.spool_address" cols="12" md="6">
              <farm-row :item="pool" @click.native="$root.chainLink(`/farm/${pool.spool_address}`, network)">
              </farm-row>
            </v-col>
          </v-row>
        </template>

        <template v-if="Number(oracle.num_pools) > 0">
          <div class="px-4 py-2 caption d-flex align-center textFaint--text">
            Your Oracle Pools ({{ oracle.num_pools }})
          </div>

          <div>
            <user-farm-row v-for="pool of oracle.pools" :key="pool.address" :pool_address="pool.address" :pool_info="pool.pool_info" mode="oracle" @click.native="$root.chainLink(`/farm/${pool.address}`, network)"></user-farm-row>
          </div>
        </template>
      </div>
    </v-expand-transition>

  </div>
</template>

<script>
import StakeFactoryPagerContract from '@/smart-contracts/farms-v2/normal/stake-factory-paginator'
// import StakePoolContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool'

import OracleFactoryPagerContract from '@/smart-contracts/farms-v2/oracle/stake-factory-paginator'
import OraclePoolContract from '@/smart-contracts/farms-v2/oracle/stake-pool/v2/stake-pool'

import UserFarmRow from './user-farm-row'
import axios from "axios";
import FarmRow from '@/views/farms/list-page/row'

export default {

  props: {
    network: null
  },

  components: {
    UserFarmRow,
    FarmRow
  },

  data: () => ({
    normal: {
      num_pools: '0',
      pools: []
    },
    oracle: {
      num_pools: '0',
      pools: []
    },

    firstLoad: true,
    loading: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    userHasPools () {
      return Number(this.normal.num_pools) > 0 || Number(this.oracle.num_pools) > 0
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    }
  },

  methods: {
    /*
    async refresh () {
      this.loading = true
      this.num_pools = await StakeFactoryPagerContract.userActivePoolsLength(this.sEthers.coinbase)
      var pools = await StakeFactoryPagerContract.getUserActivePools(this.sEthers.coinbase, 0, this.num_pools)
      pools.forEach(async (pool) => {
        var pool_info = await StakePoolContract.getPoolInfo(pool.pool_address)
        var stake_token_hydrated = await ERC20.getERC20(pool_info.staking_token)
        pool_info.staking_token = stake_token_hydrated
        this.pools.push({
          address: pool.pool_address,
          pool_info: pool_info
        })
      })
      this.loading = false
    }, */

    async refresh () {
      this.loading = true
      await this.getNormalPools()
      if (this.$store.state.allowGanache) {
        await this.getOraclePools()
      }
      this.firstLoad = false
      this.loading = false
    },
    async getNormalPools () {
      // this.normal.pools = []
      /* var num_pools = await StakeFactoryPagerContract.getNumPoolsForAdmin(this.sEthers.coinbase, this.network)
      var pools = await StakeFactoryPagerContract.getStakePoolsForAdmin(this.sEthers.coinbase, 0, num_pools, this.network)
      var poolArray = []
      for (var pool of pools) {
        var pool_info = await StakePoolContract.getPoolInfo(pool.pool_address, this.network)
        poolArray.push({
          address: pool.pool_address,
          pool_info: pool_info
        })
      }
      this.normal.pools = poolArray
      this.normal.num_pools = num_pools */

      var num_pools = await StakeFactoryPagerContract.getNumPoolsForAdmin(this.sEthers.coinbase, this.network)
      var pools = await StakeFactoryPagerContract.getStakePoolsForAdmin(this.sEthers.coinbase, 0, num_pools, this.network)
      pools = pools.map(item => item.pool_address)
      var poolArray = []
      try {
        var response = await axios.post(
          `${this.$root.$servers.FARMS[this.$root.getLegacyChainKey(this.network)].server}/farms/get-multiple`,
          {farms: pools}
        )
        poolArray = response.data
      } catch (e) {}
      this.normal.pools = poolArray
      this.normal.num_pools = num_pools
    },
    async getOraclePools () {
      // this.oracle.pools = []
      this.oracle.num_pools = await OracleFactoryPagerContract.getNumPoolsForAdmin(this.sEthers.coinbase, this.network)
      var pools = await OracleFactoryPagerContract.getStakePoolsForAdmin(this.sEthers.coinbase, 0, this.oracle.num_pools, this.network)
      var poolArray = []
      await pools.forEach(async (pool) => {
        var pool_info = await OraclePoolContract.getPoolInfo(pool.pool_address, this.network)
        this.oracle.pools.push({
          address: pool.pool_address,
          pool_info: pool_info
        })
      })
      this.oracle.pools = poolArray
    }
  },

  created () {
    this.refresh()
  }
}
</script>