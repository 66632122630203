import Vue from 'vue'
import Vuex from 'vuex'
import themes from '@/plugins/themes.js'
import ConnectProvider from '@/web3/connect-provider'
import SETTINGS from '@/store/settings'
import EndpointViem from '../web3/endpointsViem'
import { ethers } from "ethers"

Vue.use(Vuex)

// SETTINGS - CHANGE ALL
const NETWORK = 'Mainnet' // Hardcoded (wont change) // options: Ganache, Mainnet, Kovan, BSC_MAINNET, XDAI
const EXCHANGE = 'Uniswap V2' // Uniswap, Pancakeswap, Honeyswap

export const store = new Vuex.Store({
  state: {
    allowGanache: false, // set to true to allow Ganache test suite
    theme: 'theme1',
    darkMode: true,
    showAllStatsMode: false,
    blockNumber: -1,
    blockNumz: {
      Mainnet: -1,
      BSC_MAINNET: -1,
      Goerli: -1,
      xDai: -1,
      Matic: -1,
      DogeChain: -1,
    },
    intervals: {
      short: -1,
      minute: -1
    },
    // use user settings to remember a users search filters for this session
    userSettings: {
      kycPopupApproved: false, // Set this to true once user has clicked the high risk KYC popup once
      decentralisedPresaleSafetyAlertApproved: false, // Set this to true once user has clicked the presale safety warning once
      chartProvider: 'Dext', // 'Geckoterimal', 'Dexscreener', 'Dext
    },
    blocksPerSecond: SETTINGS.CHAINS[NETWORK].blockTime,
    requiredNetworkId: -1,
    requiredNetwork: NETWORK,
    requiredNetworkDisplayName: SETTINGS.CHAINS[NETWORK].name,
    exchange: EXCHANGE,
    ammPrefix: `/amm/${SETTINGS.AMMS[EXCHANGE].shortname}`,
    chainPrefix: `/chain/${SETTINGS.CHAIN_URL_NAME[NETWORK]}`,
    etherscan_url: SETTINGS.CHAINS[NETWORK].explorerURL,
    explorer_name: SETTINGS.CHAINS[NETWORK].explorerName,
    graphExplorer: SETTINGS.AMMS[EXCHANGE].graphExplorer,
    exchangeTokenLink: SETTINGS.AMMS[EXCHANGE].ammTokenLink,
    exchangePairLink: SETTINGS.AMMS[EXCHANGE].ammPairLink,
    nativeGasTokenSymbol: SETTINGS.CHAINS[NETWORK].gasToken.symbol,
    wrongNetwork: false,

    superUserMode: false,
    superUserDrawerOpen: false, // toggle the super user nav drawer

    adminIds: ['21', '25', '1446'],
    
    user: {
      username: null,
      jwtToken: null,
      jwtObject: {},
      watchlist: {},
      watchlistKeys: [],
      watchlistLastUpdated: null
    },
    ethers: {
      coinbase: null,
      coinbase_condensed: null,
      providerName: null, // values: Metamask, Coinbase
      provider: null,
      signer: null,
      network: null,
      watcher: null, // watcher
      walletConnectProvider: null,
      walletLinkProvider: null,
    },
    pendingTx: {
      pending: [], // [txhash, txhash]
      tx: {}
    },
  },
  mutations: {
    setSuperUserMode (state, mode) {
      state.superUserMode = mode
    },
    setSuperUserDrawerState (state, openDrawer) {
      state.superUserDrawerOpen = openDrawer
    },
    setWrongNetwork (state, flag) {
      state.wrongNetwork = flag
      state.ethers.watcher = state.ethers.coinbase + state.requiredNetwork + state.wrongNetwork
    },
    setShowAllStatsMode (state, flag) {
      state.showAllStatsMode = flag
    },
    switchExchange (state, exchange) {
      state.exchange = exchange
      var network = SETTINGS.AMMS[exchange].chain
      Vue.prototype.$axios.defaults.baseURL = SETTINGS.AMMS[exchange].server

      state.requiredNetwork = network
      state.requiredNetworkDisplayName = SETTINGS.CHAINS[network].name
      state.blocksPerSecond = SETTINGS.CHAINS[network].blockTime
      state.etherscan_url = SETTINGS.CHAINS[network].explorerURL
      state.explorer_name = SETTINGS.CHAINS[network].explorerName
      state.graphExplorer = SETTINGS.AMMS[exchange].graphExplorer
      state.exchangeTokenLink = SETTINGS.AMMS[exchange].ammTokenLink
      state.exchangePairLink = SETTINGS.AMMS[exchange].ammPairLink
      state.nativeGasTokenSymbol = SETTINGS.CHAINS[network].gasToken.symbol
      state.ammPrefix = `/amm/${SETTINGS.AMMS[exchange].shortname}`
      state.chainPrefix = `/chain/${SETTINGS.CHAIN_URL_NAME[network]}`
      /* if (state.ethers.coinbase) { // this leads to the annoying popup
        ConnectProvider.disconnect()
        ConnectProvider.connectViewOnlyProvider()
      } */

      // ConnectProvider.disconnect()
      ConnectProvider.connectViewOnlyProvider()

      state.user.watchlistKeys = Object.keys((state.user.watchlist[state.requiredNetwork] || {})[state.exchange] || {})
    },
    switchChain (state, network) {
      state.requiredNetwork = network
      state.exchange = null
      state.requiredNetworkDisplayName = SETTINGS.CHAINS[network].name
      state.blocksPerSecond = SETTINGS.CHAINS[network].blockTime
      state.etherscan_url = SETTINGS.CHAINS[network].explorerURL
      state.explorer_name = SETTINGS.CHAINS[network].explorerName
      state.nativeGasTokenSymbol = SETTINGS.CHAINS[network].gasToken.symbol
      state.chainPrefix = `/chain/${SETTINGS.CHAIN_URL_NAME[network]}`
      /* if (state.ethers.coinbase) {
        ConnectProvider.disconnect()
        ConnectProvider.connectViewOnlyProvider()
      } */
      // Just disconnect the wallet, better UX experience, otherwise re-enable above block
      // ConnectProvider.disconnect()
      ConnectProvider.connectViewOnlyProvider()
    },
    updateInterval (state, intervalKey) {
      state.intervals[intervalKey]++
    },
    updateEthers (state, payload) {
      state.ethers = Object.assign(state.ethers, payload)
      state.ethers.watcher = state.ethers.coinbase + state.requiredNetwork + state.wrongNetwork
      if ((state.ethers.coinbase || '').length === 42) {
        state.ethers.coinbase_condensed = state.ethers.coinbase.slice(0, 6) + '...' + state.ethers.coinbase.slice(state.ethers.coinbase.length - 4)
      }

      // set chain id
      // console.log('payload', payload)
      // var network = await payload.provider.getNetwork()
      // console.log('chainId', network.chainId)
      // state.ethers.network = network.chainId
    },
    async updateWalletNetwork (state, provider) {
      var network = await provider.getNetwork()
      state.ethers.network = network.chainId
      if (state.ethers.network === SETTINGS.CHAINS[state.requiredNetwork].chainId) {
        state.ethers.provider = provider
      }
    },
    async updateWalletNetworkWalletConnect (state, chainId) {
      state.ethers.network = chainId
      if (state.ethers.network === SETTINGS.CHAINS[state.requiredNetwork].chainId) {
        var provider = new ethers.providers.JsonRpcProvider(EndpointViem[chainId])
        state.ethers.provider = provider
      }
    },
    login (state, loginObject) {
      // JWT
      const base64Url = loginObject.token.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const keyObj = JSON.parse(window.atob(base64))
      state.user.jwtObject = keyObj
      state.user.username = keyObj.username
      state.user.permissions = keyObj.permissions
      state.user.jwtToken = loginObject.token
      localStorage.setItem('token', loginObject.token)
      Vue.prototype.$userAxios.defaults.headers.common.Authorization = `Bearer ${loginObject.token}`
      Vue.prototype.$axios.defaults.headers.common.Authorization = `Bearer ${loginObject.token}`
      // Watchlist copied from 'updateWatchlist'
      state.user.watchlistLastUpdated = Date.now()
      state.user.watchlist = loginObject.watchlist
      state.user.watchlistKeys = Object.keys((loginObject.watchlist[state.requiredNetwork] || {})[state.exchange] || {})
    },
    logout (state) {
      state.user.username = null
      state.user.jwtToken = null
      state.user.jwtObject = {}
      Vue.prototype.$userAxios.defaults.headers.common.Authorization = ``
      Vue.prototype.$axios.defaults.headers.common.Authorization = ``
      localStorage.removeItem('token')
    },
    updateWatchlist (state, watchlist) {
      state.user.watchlistLastUpdated = Date.now()
      state.user.watchlist = watchlist
      state.user.watchlistKeys = Object.keys((watchlist[state.requiredNetwork] || {})[state.exchange] || {})
    },
    setTheme (state, {themeName, vuetify}) {
      state.theme = themeName
      state.darkMode = themes[themeName].darkMode
      vuetify.theme.setTheme('light', themes[themeName].light)
      localStorage.setItem('theme', themeName)
    },
    setBlockNumber (state, blockNumber) {
      state.blockNumber = blockNumber
    },
    setBlockNum (state, { blockNumber, network }) {   
      state.blockNumz[network] = blockNumber
      if (network === state.requiredNetwork) {
        state.blockNumber = blockNumber
      }
    },
    editUserSettings (state, { setting, value }) {
      state.userSettings[setting] = value
    },
    addPendingTx (state, { txhash, requiredNetwork }) {
      var txkey = txhash + requiredNetwork
      state.pendingTx.pending.push(txkey)

      var item = {
        created: Date.now(),
        txhash: txhash,
        network: requiredNetwork,
        state: 'pending'
      }
      Vue.set(state.pendingTx.tx, txkey, item)
    },
    completePendingTx (state, { txhash, requiredNetwork }) {
      var txkey = txhash + requiredNetwork
      state.pendingTx.pending = state.pendingTx.pending.filter(item => item !== txkey)
      state.pendingTx.tx[txkey].state = 'completed'
    },
    failPendingTx (state, { txhash, requiredNetwork }) {
      var txkey = txhash + requiredNetwork
      state.pendingTx.pending = state.pendingTx.pending.filter(item => item !== txkey)
      if (state.pendingTx.tx[txkey]) {
        state.pendingTx.tx[txkey].state = 'failed'
      }
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
  }
})
