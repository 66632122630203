<template>
  <v-dialog v-model="dialog" max-width="450" content-class="br-20">
    <v-card class="br-20">
      <div>
        <v-row class="pa-4 ma-0 font-weight-medium background align-center">
          <div>

            <div v-if="!sEthers.coinbase">
              Connect your wallet
            </div>
            <div v-else>
              Your wallet
            </div>
          </div>
          <v-spacer></v-spacer>

          <v-btn v-if="false" @click="test">
            TEST
          </v-btn>
          
          <v-btn v-if="true" @click="close" icon color="text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <!-- HARDHAT -->
        <div v-if="$store.state.superUserMode"  class="v-card ma-2 pa-2 br-20 text-center">
          <div :class="['mt-1 mb-5 d-flex align-center pa-3 r-outlined br-20 mx-2', {'foreground': $vuetify.breakpoint.xs}, {'inputcolor': !$vuetify.breakpoint.xs}]">
            <c-input :value.sync="impersonate" placeholder="Impersonate address..." class="">
            </c-input>

            <v-icon v-if="impersonate === ''">
              mdi-magnify
            </v-icon>
            <v-icon v-else @click="impersonate = ''">
              mdi-close
            </v-icon>

          </div>
          <v-btn @click="impersonateAccount" rounded small color="primary">
            Impersonate
          </v-btn>
        </div>
        <!-- END HARDHAT -->

        <div v-if="wrongNetwork" class="pa-4 lgrad-red text-center white--text br-20 ma-2">
          <div class="">
            Wrong Network
          </div>
          <v-btn @click="$root.$dialog.chainSwitch.open($store.state.requiredNetwork)" block rounded color="white red--text">
            Switch your wallet to {{ ($settings.CHAINS[$store.state.requiredNetwork] || {}).name }}
          </v-btn>
        </div>

        <div class="text-end px-3 py-1">
          <v-btn text rounded @click="main_tab = 'wallet'" :color="main_tab === 'wallet' ? 'text' : 'textFaint'">
            Wallet
          </v-btn>
          <v-btn text rounded @click="main_tab = 'txns'" :color="main_tab === 'txns' ? 'text' : 'textFaint'">
            Txns
            <v-progress-circular
            v-if="pendingTx.length > 0"
            indeterminate
            width="2"
            size="24"
            color="primary"
            class="ml-1"
            >
              <span class="caption">
                {{ pendingTx.length }}
              </span>
            </v-progress-circular>
          </v-btn>
        </div>

        <div v-if="main_tab === 'wallet'">
          <div v-if="sEthers.coinbase" class="pa-3">
            <v-card outlined class="br-8 pa-3">
              <div class="d-flex align-center">
                <img 
                v-if="false"
                src="@/assets/img/UNCX_fill.svg"
                width="50px"
                class="">
                <v-icon size="50" class="lgrad-green br-c" color="white">mdi-account-circle</v-icon>
                <div class="caption pl-2 textFaint--text">
                  <div>
                    Your wallet
                  </div>
                  <div>
                    <copy-address :address="sEthers.coinbase" color="primary"></copy-address>
                  </div>
                </div>
              </div>
              <!-- <v-icon size="50" color="textFaint">mdi-account-circle</v-icon> -->
              <div class="mt-4" style="flex: 1;">
                <div class="d-flex align-center">
                  {{ $root.formatAmount(ethBalance, $settings.CHAINS[$store.state.requiredNetwork].gasToken.decimals) }}
                  <v-spacer></v-spacer>
                  <span>
                    {{ $settings.CHAINS[$store.state.requiredNetwork].gasToken.symbol }}
                  </span>
                  <img 
                  :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
                  height="20px"
                  width="20px"
                  class="br-20 ml-2">
                </div>
                <div class="d-flex textFaint--text align-center">
                  {{ $root.formatAmount(wethBalance, $settings.CHAINS[$store.state.requiredNetwork].wrappedGasToken.decimals) }}
                  <v-spacer></v-spacer>
                  <copy-address :address="$settings.CHAINS[$store.state.requiredNetwork].wrappedGasToken.address" color="text" class="br-c">
                    <v-icon small color="primary">mdi-content-copy</v-icon>
                  </copy-address>
                  <span>
                    {{ $settings.CHAINS[$store.state.requiredNetwork].wrappedGasToken.symbol }}
                  </span>
                  <img 
                  :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
                  height="20px"
                  width="20px"
                  class="br-20 ml-2">
                </div>
                <div class="text-end">
                  <v-btn text color="primary" style="margin-right: -16px;" @click="$root.$dialog.wethConvertorDialog.open()">
                    Convert {{ $settings.CHAINS[$store.state.requiredNetwork].gasToken.symbol }}/{{ $settings.CHAINS[$store.state.requiredNetwork].wrappedGasToken.symbol }}
                  </v-btn>
                </div>
              </div>
            </v-card>

            <v-btn color="" class="mt-2" outlined block x-large @click="disconnect" >
              Disconnect
              <v-icon small class="ml-1">mdi-exit-to-app</v-icon>
            </v-btn>

          </div>

          <template v-if="!sEthers.coinbase">
            <div class="text-center mb-4 mt-4">
              <v-btn text rounded @click="tab = 0" :color="tab === 0 ? 'text' : 'textFaint'">
                Desktop
              </v-btn>
              <v-btn text rounded @click="tab = 1" :color="tab === 1 ? 'text' : 'textFaint'">
                Mobile
              </v-btn>
            </div>

            <div class="caption textFaint--text pa-4 d-flex align-center">
              <v-icon small color="textFaint" class="mr-2">mdi-star-outline</v-icon>
              UNCX works best with Metamask on all chains.
            </div>

            <!-- Main content -->
            <div>

              <div v-if="tab === 0">

                <div 
                @click="connectToMetamask()"
                class="c-list border-b pa-4 d-flex align-center">
                  <img 
                  src="@/assets/img/metamask.svg" 
                  width="30px"
                  class="mr-3">
                  <div class="font-weight-medium d-flex align-center">
                    Metamask
                    <v-icon small color="text" class="ml-1">mdi-star-outline</v-icon>
                  </div>
                </div>

                <div
                v-if="$store.state.requiredNetwork === 'Mainnet'"
                @click="connectToWalletLink" 
                class="c-list border-b pa-4 d-flex align-center">
                  <img 
                  src="@/assets/img/coinbase-wallet.svg" 
                  width="30px"
                  class="mr-3">
                  <div class="font-weight-bold">
                    Coinbase Wallet
                  </div>
                </div>
                
                <div
                @click="connectWalletConnect" 
                class="c-list pa-4 d-flex align-center">
                  <img 
                  src="@/assets/img/wallet-connect.svg" 
                  width="30px"
                  class="mr-3">
                  <div>
                    <div class="font-weight-medium">
                      Wallet Connect
                    </div>
                    <div class="caption textFaint--text">
                      Metamask... + 30 more
                    </div>
                  </div>
                </div>
                
              </div>

              <div v-if="tab === 1">

                <div 
                @click="connectToMetamask()"
                class="c-list border-b pa-4 d-flex align-center">
                  <img 
                  src="@/assets/img/metamask.svg" 
                  width="30px"
                  class="mr-3">
                  <div>
                    <div class="font-weight-medium d-flex align-center">
                      In-Wallet Browser
                      <v-icon small color="text" class="ml-1">mdi-star-outline</v-icon>
                    </div>
                    <div v-if="$vuetify.breakpoint.smAndDown" class="caption textFaint--text">
                      Use this option if you are using an in-wallet browser such as Metamask browser, or TrustWallet browser.
                    </div>
                  </div>
                </div>
                
                <div
                @click="connectWalletConnect" 
                class="c-list pa-4 d-flex align-center">
                  <img 
                  src="@/assets/img/wallet-connect.svg" 
                  width="30px"
                  class="mr-3">
                  <div>
                    <div class="font-weight-medium">
                      Wallet Connect
                    </div>
                    <div class="caption textFaint--text">
                      Metamask... + 30 more
                    </div>
                  </div>
                </div>
                
              </div>

            </div>
            <!-- END MAIN CONTENT -->

          </template>
        </div>

        <div v-if="main_tab === 'txns'">
          <tx-list></tx-list>
        </div>

        <div class="pa-2 caption ma-0 font-weight-medium background d-flex">
          <div>
            Version 1.23
          </div>
          <v-spacer />
          <div :class="['ml-2 caption text-end unselectable', $store.state.showAllStatsMode ? 'primary--text' : 'textFaint--text']" @click="clickBlockText">
            block {{ blockNumber }}
          </div>
        </div>

        <div v-if="$store.state.allowGanache && $store.state.requiredNetwork === 'Ganache'" class="text-center py-2">
          <v-btn @click="increaseBlock" outlined rounded>
            Increase Block (Ganache)
          </v-btn>
        </div>

      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// import { ethers } from "ethers";
// import SmartContract from '@/views/liquidity/components/lock-liquidity/smart-contract'
import ConnectProvider from './connect-provider'
// import UniswapFactory from '@/smart-contracts/uniswap/uniswap-factory-contract'
import { ethers } from 'ethers'
import TxList from './tx-list'
import axios from "axios"
import ERC20 from '@/smart-contracts/erc20'

export default {
  components: {
    TxList
  },
  data: () => ({
    tab: 0,
    main_tab: 'wallet', // wallet, txns
    dialog: false,
    resolve: null,
    reject: null,
    ethBalance: '0',
    wethBalance: '0',
    impersonate: '',
    blockTextClickCounter: []
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    pendingTx () {
      return this.$store.state.pendingTx.pending
    },
    wrongNetwork () {
      return this.$store.state.wrongNetwork
    },
    blockNumber () {
      return this.$store.state.blockNumber
    },
    addressCondensed () {
      var address = this.sEthers.coinbase
      if (!address) {
        return ''
      }
      return address.slice(0, 6) + '...' + address.slice(address.length - 4)
    }
  },

  watch: {
    sEthersWatcher () {
      this.getEthBalance()
    },
    blockNumber () {
      if (this.dialog) {
        this.getEthBalance()
      }
    }
  },

  methods: {
    clickBlockText () {
      var currentTime = Math.round(Date.now())
      if (this.blockTextClickCounter.length >= 6) {
        this.blockTextClickCounter.shift()
        var diff = currentTime - this.blockTextClickCounter[0]
        if (diff < 1000) {
          this.$store.commit('setShowAllStatsMode', !this.$store.state.showAllStatsMode)
          this.blockTextClickCounter = []
        }
      }
      this.blockTextClickCounter.push(currentTime)
    },
    async impersonateAccount () {
      var signer = await this.sEthers.provider.getSigner(this.impersonate)
      this.$store.commit('updateEthers', {
        coinbase: ethers.utils.getAddress(this.impersonate),
        signer: signer,
        // network: StaticMap.networkHexToInt[window.ethereum.chainId]
      })
    },
    async test () {
      var data = {
        chainId: 1,
        txHash: '0x80b10b9960bde12aeeaae0498e6abae79618b106dff65bab2483e9fb2094eb1a',
        from: '0x692C7972cd975EF122F3D0FAb5718f8A167075c6'
      }
      await axios.post(`${this.$root.$servers.TXNS}/tx/new`, data)
    },
    async getEthBalance () {
      if (!this.sEthers.coinbase) {
        return
      }
      var ethBalance = await this.sEthers.provider.getBalance(this.sEthers.coinbase)
      this.ethBalance = ethBalance

      var wethBalance = await ERC20.getBalance(this.sEthers.coinbase, this.$settings.CHAINS[this.$store.state.requiredNetwork].wrappedGasToken.address)
      this.wethBalance = wethBalance
    },
    open () {
      this.tab = this.$vuetify.breakpoint.xs ? 1 : 0
      this.main_tab = 'wallet'
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close () {
      this.dialog = false
      this.reject()
    },
    closeAndResolve () {
      this.dialog = false
      this.resolve()
    },
    requireConnectionThen (callback) {
      if (!this.sEthers.coinbase) {
        this.open()
          .then(() => {
            callback()
          })
          .catch(e => {})
      } else {
        callback()
      }
    },
    async connectToWalletLink () {
      await ConnectProvider.connectToWalletLink()
      this.closeAndResolve()
    },
    async connectWalletConnect () {
      await ConnectProvider.connectWalletConnect()
      this.closeAndResolve()
    },
    async connectToMetamask () {
      await ConnectProvider.connectToMetamask()
      this.closeAndResolve()
    },
    disconnect () {
      ConnectProvider.disconnect()
    },
    increaseBlock () {
      this.sEthers.signer.sendTransaction({
        to: '0xAA3d85aD9D128DFECb55424085754F6dFa643eb1', 
        value: ethers.utils.parseEther("0.00001")
      })
    }
  }
}
</script>