<template>
  <!--
    TODO:
      - move to components dir
      - fix address label vertical text alignment
  -->
  <v-dialog v-model="isOpen" max-width="450" content-class="br-20">
    <v-card class="br-20">
      <div>
        <v-row class="pa-4 ma-0 font-weight-medium align-center">
          <div style="text-align: center;">
            {{ title }}
          </div>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="px-8 py-6 ma-0 font-weight-medium align-center background">
          <div style="text-align: center;">{{ warningText }}</div>
        </v-row>
        <v-row class="px-8 pb-6 ma-0 font-weight-medium align-center background">
          <div style="color: red; text-align: center;">{{ alertText }}</div>
        </v-row>
        <v-row class="pa-4 ma-0 font-weight-medium align-center">
          <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">
            <div style="justify-content: flex-start; width: 48%;">
              <v-btn
                rounded block depressed large
                color="secondary"
                dark
                @click="close"
              >
                Cancel
              </v-btn>
            </div>
            <div style="justify-content: flex-end; width: 48%;">
              <v-btn
                rounded block depressed large
                color="warning"
                @click="closeAndResolve"
              >
                Confirm
              </v-btn>
            </div>
          </div>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: 'Warning'
    },
    warningText: {
      type: String
    },
    alertText: {
      type: String
    },
    onConfirm: {
      type: Function
    }
  },
  components: {},
  data: () => ({
    isOpen: false,
    resolve: null,
    reject: null,
  }),
  computed: {},
  watch: {},
  methods: {
    open () {
      this.isOpen = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    close () {
      this.isOpen = false;
      this.reject();
    },
    closeAndResolve () {
      this.isOpen = false;
      this.$emit('on-confirm', this.addresses)
      this.resolve();
    },
  }
}
</script>