<template>
  <v-container :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`" :class="[{'pa-0 midground mobile-page': $vuetify.breakpoint.xs}]">

    <template v-if="$vuetify.breakpoint.mdAndUp">
      
      <div class="d-flex align-center">

        <div v-if="false" class="text-end">
          <token-chip address="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" exchange="Safemoonswap V1" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png"></token-chip>
          <token-chip address="0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" exchange="Uniswap V2" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"></token-chip>
          <token-chip address="0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599" exchange="Uniswap V2" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png"></token-chip>
        </div>

        <!-- <v-spacer></v-spacer> -->

        <a class="v-card c-list mb-2 br-20 pa-2 pr-6 mx-2 d-flex align-center deadlink" href="https://defillama.com/protocol/uncx-network" target="_blank">
          <img 
          :src="$root.$icons.defi_llama" 
          height="60px"
          width="60px">
          <div class="pl-4">
            <div v-if="defi_lama_tvl > 0" >
              <div class="title">
                ${{ $root.formatDollars(defi_lama_tvl) }} TVL
              </div>
              <div class="caption d-flex textFaint--text">
                View on Defi Llama
                <v-spacer></v-spacer>
              </div>
            </div>
            <div v-else class="caption d-flex textFaint--text">
              View UNCX on Defi Llama
            </div>
          </div>
        </a>

        <!-- <router-link 
        to="/services/lock-liquidity"
        v-slot="{ navigate }">
          <div class="v-card c-list mb-2 br-20 pa-2 pr-6 mx-2 d-flex align-center" @click="navigate">
            <img 
            src="@/assets/img/icons/padlock.svg" 
            height="60px"
            width="60px"
            class="br-c">

            <div class="pl-4">
              <div class="title">
                Liquidity Locker
              </div>
              <div class="caption textFaint--text">
                Lock liquidity tokens
              </div>
            </div>
          </div>
        </router-link> -->

        <v-spacer></v-spacer>

        <div class="pa-4 br-20" style="background: #18191C;">
          <!-- TrustBox widget - Micro Review Count -->
          <div ref="desktopTrustbox" class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="64c2658d4c50091222eaed45" data-style-height="24px" data-style-width="100%" data-theme="dark" data-min-review-count="0" data-style-alignment="center">
            <a href="https://www.trustpilot.com/review/uncx.network" target="_blank" rel="noopener">Trustpilot</a>
          </div>
          <!-- End TrustBox widget -->
        </div>

      </div>

      <!-- <router-link 
      to="/amm/yodeswap/pairs">
        <img src="https://i.imgur.com/9RLOkou.gif" width="100%" class="br-8"/>
      </router-link> -->

      <v-row class="ma-0 pa-0">

        <v-col cols="12">
          <services-card class="v-card mb-4"></services-card>
        </v-col>

        <v-col v-for="(card, index) of promotedContent" :key="index" cols="6">
          <div class="v-card overflow-hidden br-20" style="height: 100%;">
            <div style="position: relative;">
              <v-img
              height="140px"
              max-width="100%"
              position="center center"
              :src="card.image_url"
              >
              </v-img>
              <coin-icon 
              v-if="card.icon_url"
              :url="card.icon_url" 
              :size="80"
              class="foreground pa-05"
              style="position: absolute; left: 2px; bottom: -30px;"
              ></coin-icon>
              <div v-if="card.subtitle" class="text-center" style="position: absolute; bottom: -16px;width: 100%;">
                <span class="textFaint--text foreground py-1 px-4 br-20">
                  {{ card.subtitle }}
                </span>
              </div>
            </div>
            <div class="text-center pt-4 pb-1">
              <div v-if="card.title" class="title mt-4">
                {{ card.title }}
              </div>
            </div>
            <div v-if="card.description" class="textFaint--text pb-3 px-3" style="white-space: pre-line;">
              {{ card.description }}
            </div>
            <div class="d-flex border-t align-center justify-center py-3 px-4">
              <v-btn v-if="card.button_text && card.button_url.slice(0, 1) === '/'" rounded color="textFaint" class="foreground--text font-weight-bold" :to="card.button_url">
                <span class="text-uppercase">{{ card.button_text }}</span>
              </v-btn>
              <v-btn v-else-if="card.button_text" color="textFaint" class="foreground--text font-weight-bold" :href="card.button_url">
                <span class="text-uppercase">{{ card.button_text }}</span>
              </v-btn>
              <v-btn v-if="card.twitter_url" icon color="textFaint" class="ml-2" :href="card.twitter_url" target="_blank">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn v-if="card.website_url" icon color="textFaint" class="ml-2" :href="card.website_url" target="_blank">
                <v-icon>mdi-web</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="false" class="ma-0 pa-0">
        <v-col cols="6">

          <!-- <upcoming-presales class="mb-6"></upcoming-presales> -->
          <!-- PROMOTED CONTENT -->
          <v-carousel 
          v-if="promotedContent.length > 0 && false"
          :show-arrows="promotedContent.length > 1"
          hide-delimiter-background
          height="440px"
          :hide-delimiters="true"
          class="carousel-arrow-fix">
            <v-carousel-item v-for="(card, index) of promotedContent" :key="index">
              <div class="v-card overflow-hidden br-20">
                <div style="position: relative;">
                  <v-img
                  height="140px"
                  max-width="100%"
                  position="center center"
                  :src="card.image_url"
                  >
                  </v-img>
                  <coin-icon 
                  v-if="card.icon_url"
                  :url="card.icon_url" 
                  :size="80"
                  class="foreground pa-05"
                  style="position: absolute; left: 2px; bottom: -30px;"
                  ></coin-icon>
                  <div v-if="card.subtitle" class="text-center" style="position: absolute; bottom: -16px;width: 100%;">
                    <span class="textFaint--text foreground py-1 px-4 br-20">
                      {{ card.subtitle }}
                    </span>
                  </div>
                </div>
                <div class="text-center pt-4 pb-1">
                  <div v-if="card.title" class="title mt-4">
                    {{ card.title }}
                  </div>
                </div>
                <div v-if="card.description" class="textFaint--text pb-3 px-3" style="white-space: pre-line;">
                  {{ card.description }}
                </div>
                <div class="d-flex border-t align-center justify-center py-3 px-4">
                  <v-btn v-if="card.button_text && card.button_url.slice(0, 1) === '/'" rounded color="textFaint" class="foreground--text font-weight-bold" :to="card.button_url">
                    <span class="text-uppercase">{{ card.button_text }}</span>
                  </v-btn>
                  <v-btn v-else-if="card.button_text" color="textFaint" class="foreground--text font-weight-bold" :href="card.button_url">
                    <span class="text-uppercase">{{ card.button_text }}</span>
                  </v-btn>
                  <v-btn v-if="card.twitter_url" icon color="textFaint" class="ml-2" :href="card.twitter_url" target="_blank">
                    <v-icon>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn v-if="card.website_url" icon color="textFaint" class="ml-2" :href="card.website_url" target="_blank">
                    <v-icon>mdi-web</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
          <!-- <recent-presales class="mb-6"></recent-presales> -->

        </v-col>
        <v-col cols="6">
          
        </v-col>
      </v-row>

      <!-- INCUBATIONS -->
      <div v-if="numPresales > 0" class="br-20 mb-4">
        <template>
          <div class="title pa-4">
            Upcoming Incubated Launches
          </div>
        </template>

        <v-row dense class="pa-2 ma-0">
          <template v-for="exchange of Object.keys(presales)">
            <template v-if="presales[exchange].length > 0">
              <v-col cols="12" md="6" v-for="presale of presales[exchange]" :key="presale.presale_contract" class="py-0">
                <presale-row
                @click.native="goToPresale(presale)"
                :key="presale.presale_contract"
                :exchange="presale.exchange"
                :presale="presale"
                >
                </presale-row>
              </v-col>
            </template>
          </template>
        </v-row>
      </div>
      <!-- INCUBATIONS -->

      <!-- <div class="br-8 mb-1">
        <presale-timeline exchange="Pancakeswap V2" sorting="start_block" :hide_flagged="true"></presale-timeline>
        <presale-timeline exchange="Trader Joe V1" sorting="start_block" :hide_flagged="true"></presale-timeline>
        <presale-timeline exchange="Uniswap V2" sorting="start_block" :hide_flagged="true"></presale-timeline>
        <presale-timeline exchange="Quickswap V1" sorting="start_block" :hide_flagged="true"></presale-timeline>
      </div> -->

      <v-row v-if="false" dense class="ma-0">
        <v-col cols="12" md="6">

          <v-slide-x-transition appear>
            <div :class="['mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'v-card br-20': !$vuetify.breakpoint.xs}]">
              
              <template v-if="presalesLoading">
                <div class="text-center pa-8">
                  <v-progress-circular
                  indeterminate
                  size="80"
                  width="3"
                  color="textFaint">
                    <v-icon color="textFaint" size="50">mdi-lock-outline</v-icon>
                  </v-progress-circular>
                </div>
              </template>
              <v-scroll-y-transition>
                <div v-if="!presalesLoading">

                  <div class="pa-4 d-flex align-center mb-1 mb-6 midground">
                    <v-icon color="text" size="22" class="mr-2">mdi-lock-outline</v-icon>
                    New Liquidity Locks (>100k)
                  </div>

                  <template v-for="exchange of Object.keys(locks)">
                    <div :key="`${exchange}-lock`" class="">
                      <div v-if="false" class="pr-4 border-b d-flex align-center text-center py-2">
                        <v-btn color="text" text rounded @click="goToLocks(exchange)">
                          <img 
                          :src="$settings.AMMS[exchange].icon" 
                          height="24px"
                          width="24px"
                          class="mr-2 br-20">
                          {{ exchange }}
                          <v-icon small>
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <div class="text-end">
                          <div class="text--text font-weight-medium">
                          ${{ tvalHumanFormat(tval[exchange].tval) }} Locked
                          </div>
                          <div class="textFaint--text caption">
                          in {{ tval[exchange].pair_count }} pairs
                          </div>
                        </div>
                      </div>
                      <lock-row
                      v-for="item of locks[exchange]" 
                      :key="item.address"
                      :exchange="item.exchange"
                      class="border-b"
                      :item="item">
                      </lock-row>
                    </div>
                  </template>

                </div>
              </v-scroll-y-transition>
              
            </div>
          </v-slide-x-transition>
        </v-col>
        <v-col cols="12" md="6">
          
          <!--
          <a href="https://uncx.network/community-campaign" class="deadlink" target="_blank">
            <div class="primary-pulse pa-1 br-20 mb-3">
              <div class="border primary white--text br-20 pa-4 c-list">
                <div class="title text-center font-weight-bold">
                  Community Campaign now LIVE
                </div>
                <div class="text-center">
                  Earn USDC by completing educational tasks.
                </div>
                <div class="text-center caption font-italic">
                  Click here to find out more!
                </div>
              </div>
            </div>
          </a>
          -->

          <div v-if="false" :class="['mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'v-card br-20': !$vuetify.breakpoint.xs}]">

            <div class="pa-4 d-flex align-center mb-1 mb-6 background">
              <v-icon color="text" size="22" class="mr-2">mdi-lock-outline</v-icon>
              New Token Locks
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center">
                <img 
                :src="$settings.CHAINS['Mainnet'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Mainnet
              </div>
              <global-tokens chain="Mainnet"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center">
                <img 
                :src="$settings.CHAINS['BSC_MAINNET'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Binance smart chain
              </div>
              <global-tokens chain="BSC_MAINNET"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center">
                <img 
                :src="$settings.CHAINS['xDai'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                xDai
              </div>
              <global-tokens chain="xDai"></global-tokens>
            </div>

            <div class="mt-2 br-20">
              <div class="pa-3 d-flex align-center">
                <img 
                :src="$settings.CHAINS['Matic'].icon" 
                height="24px"
                width="24px"
                class="mr-3 br-20">
                Matic
              </div>
              <global-tokens chain="Matic"></global-tokens>
            </div>

          </div>

        </v-col>
      </v-row>
    </template>

    <div v-else :class="['mb-16']">

      <!--
      <a href="https://uncx.network/community-campaign" class="deadlink" target="_blank">
        <div class="primary-pulse pa-1 mx-2 br-20 mb-3">
          <div class="border primary white--text br-20 pa-4 c-list">
            <div class="title text-center font-weight-bold">
              Community Campaign now LIVE
            </div>
            <div class="text-center">
              Earn USDC by completing educational tasks.
            </div>
            <div class="text-center caption font-italic">
              Click here to find out more!
            </div>
          </div>
        </div>
      </a>
      -->

      <div v-if="false" class="text-center mt-2">
        <token-chip address="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" exchange="Safemoonswap V1" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png"></token-chip>
        <token-chip address="0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" exchange="Uniswap V2" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png"></token-chip>
        <token-chip address="0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599" exchange="Uniswap V2" icon="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png"></token-chip>
      </div>

      <a class="v-card c-list mt-2 br-20 pa-2 mx-2 d-flex align-center deadlink" href="https://defillama.com/protocol/uncx-network" target="_blank">
        <img 
        :src="$root.$icons.defi_llama" 
        height="60px"
        width="60px">
        <div class="pl-4">
          <div v-if="defi_lama_tvl > 0" >
            <div class="title">
              ${{ $root.formatDollars(defi_lama_tvl) }} TVL
            </div>
            <div class="caption d-flex textFaint--text">
              View on Defi Llama
              <v-spacer></v-spacer>
            </div>
          </div>
          <div v-else class="caption d-flex textFaint--text">
            View UNCX on Defi Llama
          </div>
        </div>
      </a>

      <!-- PROMOTED CONTENT -->
      <v-carousel 
      v-if="promotedContent.length > 0 && false"
      :show-arrows="promotedContent.length > 1" 
      hide-delimiter-background
      height="440px"
      class="px-2 mt-4 carousel-arrow-fix"
      :hide-delimiters="true">
        <v-carousel-item v-for="(card, index) of promotedContent" :key="index">
          <div class="v-card overflow-hidden br-20">
            <div style="position: relative;">
              <v-img
              height="140px"
              max-width="100%"
              position="center center"
              :src="card.image_url"
              >
              </v-img>
              <coin-icon 
              v-if="card.icon_url"
              :url="card.icon_url" 
              :size="80"
              class="foreground pa-05"
              style="position: absolute; left: 2px; bottom: -30px;"
              ></coin-icon>
              <div v-if="card.subtitle" class="text-center" style="position: absolute; bottom: -16px;width: 100%;">
                <span class="textFaint--text foreground py-1 px-4 br-20">
                  {{ card.subtitle }}
                </span>
              </div>
            </div>
            <div class="text-center pt-4 pb-1">
              <div v-if="card.title" class="title mt-4">
                {{ card.title }}
              </div>
            </div>
            <div v-if="card.description" class="textFaint--text pb-3 px-3" style="white-space: pre-line;">
              {{ card.description }}
            </div>
            <div class="d-flex border-t align-center justify-center py-3 px-4">
              <v-btn v-if="card.button_text && card.button_url.slice(0, 1) === '/'" rounded color="textFaint" class="foreground--text font-weight-bold" :to="card.button_url">
                <span class="text-uppercase">{{ card.button_text }}</span>
              </v-btn>
              <v-btn v-else-if="card.button_text" color="textFaint" class="foreground--text font-weight-bold" :href="card.button_url">
                <span class="text-uppercase">{{ card.button_text }}</span>
              </v-btn>
              <v-btn v-if="card.twitter_url" icon color="textFaint" class="ml-2" :href="card.twitter_url" target="_blank">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn v-if="card.website_url" icon color="textFaint" class="ml-2" :href="card.website_url" target="_blank">
                <v-icon>mdi-web</v-icon>
              </v-btn>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
      <!-- <router-link 
      to="/amm/yodeswap/pairs">
        <img src="https://i.imgur.com/9RLOkou.gif" width="100%" class=""/>
      </router-link> -->

      <!-- <gaming-launches class="my-6 mx-2"></gaming-launches> -->
      <!-- <upcoming-presales class="mb-6 mx-2"></upcoming-presales>
      <recent-presales class="mb-6 mx-2"></recent-presales> -->

      <!-- INCUBATIONS -->

      <div class="pa-4 br-20 mt-3" style="background: #18191C;">
        <!-- TrustBox widget - Micro Review Count -->
        <div ref="mobileTrustbox" class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="64c2658d4c50091222eaed45" data-style-height="24px" data-style-width="100%" data-theme="dark" data-min-review-count="0" data-style-alignment="center">
          <a href="https://www.trustpilot.com/review/uncx.network" target="_blank" rel="noopener">Trustpilot</a>
        </div>
        <!-- End TrustBox widget -->
      </div>

      <v-row class="ma-0 pa-0">

        <v-col cols="12">
          <services-card class="v-card mb-4"></services-card>
        </v-col>

        <v-col v-for="(card, index) of promotedContent" :key="index" cols="12">
          <div class="v-card overflow-hidden br-20" style="height: 100%;">
            <div style="position: relative;">
              <v-img
              height="140px"
              max-width="100%"
              position="center center"
              :src="card.image_url"
              >
              </v-img>
              <coin-icon 
              v-if="card.icon_url"
              :url="card.icon_url" 
              :size="80"
              class="foreground pa-05"
              style="position: absolute; left: 2px; bottom: -30px;"
              ></coin-icon>
              <div v-if="card.subtitle" class="text-center" style="position: absolute; bottom: -16px;width: 100%;">
                <span class="textFaint--text foreground py-1 px-4 br-20">
                  {{ card.subtitle }}
                </span>
              </div>
            </div>
            <div class="text-center pt-4 pb-1">
              <div v-if="card.title" class="title mt-4">
                {{ card.title }}
              </div>
            </div>
            <div v-if="card.description" class="textFaint--text pb-3 px-3" style="white-space: pre-line;">
              {{ card.description }}
            </div>
            <div class="d-flex border-t align-center justify-center py-3 px-4">
              <v-btn v-if="card.button_text && card.button_url.slice(0, 1) === '/'" rounded color="textFaint" class="foreground--text font-weight-bold" :to="card.button_url">
                <span class="text-uppercase">{{ card.button_text }}</span>
              </v-btn>
              <v-btn v-else-if="card.button_text" color="textFaint" class="foreground--text font-weight-bold" :href="card.button_url">
                <span class="text-uppercase">{{ card.button_text }}</span>
              </v-btn>
              <v-btn v-if="card.twitter_url" icon color="textFaint" class="ml-2" :href="card.twitter_url" target="_blank">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn v-if="card.website_url" icon color="textFaint" class="ml-2" :href="card.website_url" target="_blank">
                <v-icon>mdi-web</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        </v-row>
      
      <div v-if="numPresales > 0" class="br-20 mb-4">
        <template>
          <div class="title px-4 pt-4">
            Upcoming Incubated Launches
          </div>
        </template>

        <v-row dense class="pa-2 ma-0">
          <template v-for="exchange of Object.keys(presales)">
            <template v-if="presales[exchange].length > 0">
              <v-col cols="12" md="6" v-for="presale of presales[exchange]" :key="presale.presale_contract" class="py-0">
                <presale-row
                @click.native="goToPresale(presale)"
                :key="presale.presale_contract"
                :exchange="presale.exchange"
                :presale="presale"
                >
                </presale-row>
              </v-col>
            </template>
          </template>
        </v-row>
      </div>
      <!-- INCUBATIONS -->

      <!-- <div class="br-8 mb-1">
        <presale-timeline exchange="Pancakeswap V2" sorting="start_block" :hide_flagged="true"></presale-timeline>
        <presale-timeline exchange="Trader Joe V1" sorting="start_block" :hide_flagged="true"></presale-timeline>
        <presale-timeline exchange="Uniswap V2" sorting="start_block" :hide_flagged="true"></presale-timeline>
        <presale-timeline exchange="Quickswap V1" sorting="start_block" :hide_flagged="true"></presale-timeline>
      </div> -->

      <template v-if="false">
        <template v-if="presalesLoading">
          <div class="text-center">
            <v-progress-circular
            indeterminate
            size="80"
            width="3"
            color="textFaint">
              <v-icon color="textFaint" size="50">mdi-lock-outline</v-icon>
            </v-progress-circular>
          </div>
        </template>
        <v-scroll-y-transition>
          <div v-if="!presalesLoading">

            <div class="text-center mt-10">
              <img 
              src="@/assets/img/icons/padlock.svg" 
              height="40px"
              width="40px"
              class="greyscale">
              <div class="text-center textFaint--text mb-4">
                New Liquidity Locks (>100k)
              </div>
            </div>

            <template v-for="exchange of Object.keys(locks)">
              <div :key="`${exchange}-lock`" class="">
                <div v-if="false" class="pr-4 border-b d-flex align-center text-center py-2">
                  <v-btn color="text" text rounded @click="goToLocks(exchange)">
                    <img 
                    :src="$settings.AMMS[exchange].icon" 
                    height="24px"
                    width="24px"
                    class="mr-2 br-20">
                    {{ exchange }}
                    <v-icon small>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <div class="text-end">
                    <div class="text--text font-weight-medium">
                    ${{ tvalHumanFormat(tval[exchange].tval) }} Locked
                    </div>
                    <div class="textFaint--text caption">
                    in {{ tval[exchange].pair_count }} pairs
                    </div>
                  </div>
                </div>
                <lock-row
                v-for="item of locks[exchange]" 
                :key="item.address"
                :exchange="item.exchange"
                class="border-b"
                :item="item">
                </lock-row>
              </div>
            </template>

            <!-- TOKEN LOCKS -->
            <div v-if="false">

              <div class="text-center mt-10">
                <img 
                src="@/assets/img/icons/padlock.svg" 
                height="40px"
                width="40px"
                class="greyscale">
                <div class="text-center textFaint--text mb-4">
                  New Token Locks
                </div>
              </div>

              <div class="mt-2 br-20">
                <div class="pa-3 d-flex align-center justify-center">
                  <img 
                  :src="$settings.CHAINS['Mainnet'].icon" 
                  height="24px"
                  width="24px"
                  class="mr-3 br-20">
                  Mainnet
                </div>
                <global-tokens chain="Mainnet"></global-tokens>
              </div>

              <div class="mt-2 br-20">
                <div class="pa-3 d-flex align-center justify-center">
                  <img 
                  :src="$settings.CHAINS['BSC_MAINNET'].icon" 
                  height="24px"
                  width="24px"
                  class="mr-3 br-20">
                  Binance smart chain
                </div>
                <global-tokens chain="BSC_MAINNET"></global-tokens>
              </div>

              <div class="mt-2 br-20">
                <div class="pa-3 d-flex align-center justify-center">
                  <img 
                  :src="$settings.CHAINS['xDai'].icon" 
                  height="24px"
                  width="24px"
                  class="mr-3 br-20">
                  xDai
                </div>
                <global-tokens chain="xDai"></global-tokens>
              </div>

              <div class="mt-2 br-20">
                <div class="pa-3 d-flex align-center justify-center">
                  <img 
                  :src="$settings.CHAINS['Matic'].icon" 
                  height="24px"
                  width="24px"
                  class="mr-3 br-20">
                  Matic
                </div>
                <global-tokens chain="Matic"></global-tokens>
              </div>
              
            </div>
            <!-- TOKEN LOCKS -->

          </div>
        </v-scroll-y-transition>
      </template>

    </div>
    
  </v-container>
</template>

<script>
import SETTINGS from '@/store/settings'
import axios from 'axios'
import LockRow from '@/views/pairs/row'
import PresaleRow from '@/views/presale/presales/row-switcher'
import GlobalTokens from '@/views/locker/erc20/global-token/GlobalTokens'
// import BuyTokens from './buy-uncx-uncl'
// import IloCard from './ilo-card'
import ServicesCard from './services'
import TokenChip from '@/views/tokens/token-chip'
// import GamingLaunches from './gaming-launches'
// import UpcomingPresales from './upcoming-presales'
// import RecentPresales from './recent-presales'

export default {

  components: {
    PresaleRow,
    LockRow,
    GlobalTokens,
    // BuyTokens,
    ServicesCard,
    TokenChip,
    // UpcomingPresales,
    // RecentPresales
  },

  data: () => ({
    presales: {
      'Uniswap V2': [],
      'Honeyswap V1': [],
      'Pancakeswap V1': [],
      'Pancakeswap V2': [],
      'Julswap V1': [],
      'Sushiswap V1': [],
      'Quickswap V1': [],
    },
    locks: {
      'Uniswap V2': [],
      'Pancakeswap V2': [],
      'BabyDogeSwap V1': [],
      'Pancakeswap V1': [],
      'Julswap V1': [],
      'Sushiswap V1': [],
      'Quickswap V1': [],
      'Honeyswap V1': [],
      'Sushiswap - Arbitrum': [],
      'Camelot': [],
    },
    tval: {
      'Uniswap V2': '',
      'Honeyswap V1': '',
      'Pancakeswap V1': '',
      'Pancakeswap V2': '',
      'Julswap V1': '',
      'Sushiswap V1': '',
      'Quickswap V1': '',
    },
    defi_lama_tvl: 0,
    presalesLoading: true,
    locksLoading: true,
    mobileTab: 0,

    promotedContent: []
  }),

  computed: {
    numPresales () {
      var length = Object.keys(this.presales).reduce((a, exchange) => { return a + this.presales[exchange].length }, 0)
      return length
    },
    tvalLocked () {
      var tval = Object.keys(this.tval).reduce((a, exchange) => { return a + (parseInt(this.tval[exchange].tval) || 0) }, 0)
      return tval
    }
  },

  methods: {
    tvalHumanFormat (tval) {
      var amount = parseInt(tval)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
    },
    goToPresale (presale) {
      // this.$store.commit('switchExchange', presale.exchange)
      this.$root.ammLinkTwo(`/ilo/${presale.presale_contract}`, presale.exchange)
    },
    goToPresales (exchange) {
      this.$root.ammLinkTwo(`/ilos`, exchange)
    },
    goToLocks (exchange) {
      this.$root.ammLinkTwo(`/pairs`, exchange)
    },
    async loadToken (address, exchange) {
      var presale_response = await axios.get(`${SETTINGS.AMMS[exchange].server}/presales/info/${address}`)
      var presale = presale_response.data
      var token_response = await axios.get(`${SETTINGS.AMMS[exchange].server}/erc20/token/${presale.s_token}`)
      var token = token_response.data
      token.exchange = exchange
      token.presale_contract = address
      this.tokens.push(token)
    },
    async getPresales (exchange) {
      var page = 0
      var filters = {
        sort: 'start_block',
        sortAscending: true,
        search: '',
        hide_flagged: false,
        hot_only: true
      }
      var response = await axios.post(`${SETTINGS.AMMS[exchange].server}/presales/search`, {filters: filters, page: page, stage: 0})
      var presales = response.data.rows
      presales.map(item => {
        item.exchange = exchange
      })
      this.presales[exchange] = presales
    },
    async getAll () {
      await Promise.allSettled([
        this.getNewLocks('Uniswap V2'),
        this.getNewLocks('Pancakeswap V2'),
        this.getNewLocks('BabyDogeSwap V1'),
        this.getNewLocks('Sushiswap V1'),
        this.getNewLocks('Quickswap V1'),
        this.getNewLocks('Camelot'),
        this.getNewLocks('Sushiswap - Arbitrum'),
      ])
    },
    async getNewLocks (exchange) {
      var page = 0
      var filters = {
        rowsPerPage: 10,
        sort: 'most_recent_lock',
        sortAscending: false,
        search: ''
      }
      var response = await axios.post(`${SETTINGS.AMMS[exchange].server}/uniswap/search`, {filters: filters, page: page}, {timeout: 4000})
      var locks = response.data.rows
      locks.map(item => {
        item.exchange = exchange
      })
      locks = locks.filter(item => item.locked_usd > 100000)
      this.locks[exchange] = locks
    },
    async getTVAL () {
      // OLD
      // var response = await axios.get(`${SETTINGS.AMMS[exchange].server}/pol/lock-stats`)
      // this.tval[exchange] = response.data

      // New
      var response = await axios.get(`https://api.llama.fi/tvl/uncx-network`)
      this.defi_lama_tvl = response.data
    },
    async getPromotedContent () {
      this.$userAxios.get(`/admin/get-promoted-content`)
        .then(response => {
          this.promotedContent = response.data
        })
        .catch(console.error)
    },
  },

  created () {
    // this.loadToken('0x67A6EaBB1fB634cdFe5636688961556260C48629', 'Pancakeswap')
    this.getTVAL()
    this.getPromotedContent()
    this.getAll()
      .catch(e => {})
      .then(() => {
        this.presalesLoading = false
      })
  },

  mounted () {
    this.$nextTick(() => {
      try {
        window.Trustpilot.loadFromElement(this.$refs.desktopTrustbox)
      } catch (e) {
        // console.log(e)
      }
      try {
        console.log(this.$refs.mobileTrustbox)
        window.Trustpilot.loadFromElement(this.$refs.mobileTrustbox)
      } catch (e) {
        // console.log(e)
      }
    })
  }
}
</script>