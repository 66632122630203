<template>
  <v-container style="max-width: 1200px;">
    
    <v-slide-y-transition appear>
      <div :class="['mb-16', {'': $vuetify.breakpoint.xs}, {'': !$vuetify.breakpoint.xs}]">
        
        <div class="py-6 title text-center textFaint--text">
          Our services
        </div>

        <services-card />
        
      </div>
    </v-slide-y-transition>
    
  </v-container>
</template>

<script>
import ServicesCard from '../trending/services'

export default {

  components: {
    ServicesCard,
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },

  created () {
  }
}
</script>