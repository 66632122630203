const Self = {
  coinstats: 'https://is1-ssl.mzstatic.com/image/thumb/Purple124/v4/83/c2/38/83c2387f-95f3-4c61-12ad-696e5abebb74/AppIcon-0-2x-4-0-85-220.png/1200x630bb.png',
  dext: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xfB7B4564402E5500dB5bB6d63Ae671302777C75a/logo.png',
  poocoin: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xB27ADAfFB9fEa1801459a1a81B17218288c097cc/logo.png',
  bogged: 'https://charts.bogged.finance/img/logo.3645a631.png',
  // farm_image: 'https://img.wallpapersafari.com/desktop/800/450/8/12/XPH4Ru.jpg',
  // farm_image: 'https://i.imgur.com/VfGubmV.png',
  farm_image: 'https://i.imgur.com/kxRRNBU.png',
  defi_llama: 'https://raw.githubusercontent.com/Unicrypt/media/bb5e32ddc118d3d81db45e8ae6e72b42f2d46493/logo/partners/defillama-green.svg',
  gecko_terminal: 'https://raw.githubusercontent.com/Unicrypt/media/2fa59dc262802384e7d0038a4d8b82211c856321/logo/partners/geckoterminal.svg',
  dexscreener: 'https://dexscreener.com/img/apple-touch-icon.png',
  // farm_image: 'https://img.freepik.com/free-vector/smart-ecological-farming-cartoon-illustration_33099-2028.jpg?t=st=1649971728~exp=1649972328~hmac=258bc1f0116c773ed88a8c448834d151b9f294717f274d6e8b97abdf327cacf1&w=996',
}

module.exports = Self