<template>
  
  <span v-if="!loading">
    <div 
    v-if="mode === 'normal'"
    class="caption c-list pa-4 d-flex align-center border-b">

      <coin-icon :address="pool_info.staking_token.address" class="mr-2" :size="38"></coin-icon>
      {{ pool_info.staking_token.symbol }}
      <span class="px-2 midground br-c ml-2">
        {{ $root.condenseAddress(pool_address) }}
      </span>
      <v-spacer></v-spacer>
      <span class="px-2 d-flex align-center textFaint--text font-weight-medium">
        <v-icon small class="mr-1" color="textFaint">mdi-account-group</v-icon>
        {{ pool_info.num_stakers }}
      </span>
      <v-icon color="textFaint">mdi-chevron-right</v-icon>
    </div>

    <div 
    v-else
    class="caption c-list pa-4 d-flex align-center">
    <v-icon size="36" class="mr-2">mdi-cube-outline</v-icon>
      Oracle pool
      <v-spacer></v-spacer>
      <span class="px-2 d-flex align-center textFaint--text font-weight-medium">
        <v-icon small class="mr-1" color="textFaint">mdi-account-group</v-icon>
        {{ pool_info.num_stakers }}
      </span>
      <v-icon color="textFaint">mdi-chevron-right</v-icon>
    </div>
  </span>

</template>

<script>
export default {

  props: {
    pool_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    mode: {
      type: String,
      default: 'normal'
    },
    pool_address: {
      type: String,
      default: ''
    }
  },

  components: {
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },

  created () {
  }
}
</script>