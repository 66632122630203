<template>
  <v-dialog v-model="dialog" max-width="450" content-class="br-20">
    <v-card class="br-20">
      <div>
        <v-row class="pa-4 ma-0 font-weight-medium background align-center">
          <div>
            Convert {{ $settings.CHAINS[this.$store.state.requiredNetwork].gasToken.symbol }}
          </div>
          <v-spacer></v-spacer>
          
          <v-btn @click="close" icon color="text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>

        <div v-if="sEthers.coinbase" class="pa-3">
          <div>
            <div class="d-flex align-center textFaint--text caption mb-2">
              Convert from
              <v-spacer></v-spacer>
              <v-btn text rounded small color="textFaint" class="text-decoration-underline font-weight-regular" @click="getBalances">
                Refresh balances
              </v-btn>
            </div>
            <div class="d-flex align-center">
              <div class="midground flex br-8 d-flex align-center py-2 px-3">
                <c-input :value.sync="amountConvertHuman" @updateWhileFocussed="onConvertAmountChanged" placeholder="0.0" class="font-weight-bold" />
                <v-btn small text rounded color="textFaint" @click="setMax">
                  MAX
                </v-btn>
              </div>
              <div class="px-2 d-flex align-center">
                {{ fromSymbol }}
                <img 
                :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
                height="20px"
                width="20px"
                class="br-20 ml-2">
              </div>
            </div>
            <div v-if="eInsufficientBalance" class="pl-3 caption red--text">
              Insufficient balance
            </div>
            <div class="caption pl-3 textFaint--text">
              <template v-if="convertFrom === 'gas'">
                Balance: {{ $root.formatAmount(ethBalance, $settings.CHAINS[$store.state.requiredNetwork].gasToken.decimals) }}
              </template>
              <template v-else>
                Balance: {{ $root.formatAmount(wethBalance, $settings.CHAINS[$store.state.requiredNetwork].wrappedGasToken.decimals) }}
              </template>
            </div>

            <div class="d-flex align-center py-4">
              <div class="outline flex" style="height: 1px;" />
              <v-btn outlined icon x-large @click="swapPairs">
                <v-icon x-large color="textFaint">mdi-swap-vertical</v-icon>
              </v-btn>
              <div class="outline" style="height: 1px;width: 16px;" />
            </div>

            <div class="textFaint--text caption">
              Convert to
            </div>
            <div class="d-flex align-center">
              <div class="flex br-8 d-flex align-center font-weight-bold py-2 px-3">
                {{ amountConvertHuman }}
              </div>
              <div class="px-2 d-flex align-center">
                {{ toSymbol }}
                <img 
                :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
                height="20px"
                width="20px"
                class="br-20 ml-2">
              </div>
            </div>
            <div class="caption pl-3 mb-10 textFaint--text">
              <template v-if="convertFrom === 'wrapped'">
                Balance: {{ $root.formatAmount(ethBalance, $settings.CHAINS[$store.state.requiredNetwork].gasToken.decimals) }}
              </template>
              <template v-else>
                Balance: {{ $root.formatAmount(wethBalance, $settings.CHAINS[$store.state.requiredNetwork].wrappedGasToken.decimals) }}
              </template>
            </div>

            <div v-if="successfulyConvertedFrom" class="text-center pa-4 primary--text">
              Your {{ successfulyConvertedFrom }} has been converted!
            </div>

            <div>
              <v-btn x-large outlined block color="text" @click="convert" :loading="convertLoading" :disabled="eInsufficientBalance || amountConvert === '0'">
                Convert to {{ toSymbol }}
              </v-btn>
            </div>
          </div>
        </div>

        <template v-if="!sEthers.coinbase">
          Connect your wallet
        </template>

      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// import { ethers } from 'ethers'
import ERC20 from '@/smart-contracts/erc20'
import WETH from '@/smart-contracts/weth'
import TxWrapper from '@/web3/tx-wrapper-2';
import { ethers } from 'ethers'

export default {
  components: {
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    ethBalance: '0',
    wethBalance: '0',

    amountConvert: '0',
    amountConvertHuman: '0',
    convertFrom: 'gas', // gas || wrapped

    convertLoading: false,
    successfulyConvertedFrom: false
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    fromSymbol () {
      if (this.convertFrom === 'gas') {
        return this.$settings.CHAINS[this.$store.state.requiredNetwork].gasToken.symbol
      }
      return this.$settings.CHAINS[this.$store.state.requiredNetwork].wrappedGasToken.symbol
    },
    toSymbol () {
      if (this.convertFrom === 'gas') {
        return this.$settings.CHAINS[this.$store.state.requiredNetwork].wrappedGasToken.symbol
      }
      return this.$settings.CHAINS[this.$store.state.requiredNetwork].gasToken.symbol
    },
    eInsufficientBalance () {
      if (this.convertFrom === 'gas') {
        return ethers.BigNumber.from(this.amountConvert).gt(this.ethBalance)
      } else {
        return ethers.BigNumber.from(this.amountConvert).gt(this.wethBalance)
      }
    }
  },

  watch: {
    sEthersWatcher () {
      this.getBalances()
    },
  },

  methods: {
    swapPairs () {
      this.convertFrom = this.convertFrom === 'gas' ? 'wrapped' : 'gas'
    },
    setMax () {
      if (this.convertFrom === 'gas') {
        this.amountConvert = this.ethBalance
        this.amountConvertHuman = ethers.utils.formatUnits(this.ethBalance, this.$settings.CHAINS[this.$store.state.requiredNetwork].gasToken.decimals)
      } else {
        this.amountConvert = this.wethBalance
        this.amountConvertHuman = ethers.utils.formatUnits(this.wethBalance, this.$settings.CHAINS[this.$store.state.requiredNetwork].wrappedGasToken.decimals)
      }
    },
    onConvertAmountChanged (_val) {
      this.successfulyConvertedFrom = false
      var decimals = this.convertFrom === 'gas' ? this.$settings.CHAINS[this.$store.state.requiredNetwork].gasToken.decimals : this.$settings.CHAINS[this.$store.state.requiredNetwork].wrappedGasToken.decimals
      try {
        this.amountConvert = ethers.utils.parseUnits(_val, decimals).toString()
      } catch (e) {
        this.amountConvert = '0'
      }
    },
    async getBalances () {
      if (!this.sEthers.coinbase) {
        return
      }
      var ethBalance = await this.sEthers.provider.getBalance(this.sEthers.coinbase)
      this.ethBalance = ethBalance

      var wethBalance = await ERC20.getBalance(this.sEthers.coinbase, this.$settings.CHAINS[this.$store.state.requiredNetwork].wrappedGasToken.address)
      this.wethBalance = wethBalance
    },
    async convert () {
      this.convertLoading = true
      var method = this.convertFrom === 'gas' ? WETH.deposit : WETH.withdraw
      TxWrapper.doTransaction(method,
        [this.$settings.CHAINS[this.$store.state.requiredNetwork].wrappedGasToken.address, this.amountConvert], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch)
        .then(response => {
          this.getBalances()
          this.successfulyConvertedFrom = this.fromSymbol
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.convertLoading = false
        })
    },
    open () {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close () {
      this.dialog = false
      this.reject()
    },
    closeAndResolve () {
      this.dialog = false
      this.resolve()
    },
  }
}
</script>