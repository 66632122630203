<template>
  <span>
    <v-btn
    v-if="wrongNetwork"
    @click="connectWallet"
    small
    color="red"
    :class="['font-weight-medium white--text pl-0']"
    rounded depressed
    >
      <!-- <v-icon color="red">mdi-pulse</v-icon> -->
      <div class="white br-c pa-05 mr-2" style="height: 24px;">
        <img 
        :src="currentChainIcon" 
        height="20px"
        width="20px"
        class="br-20">
        <img 
        :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
        height="20px"
        width="20px"
        style="margin-left: -4px;"
        class="br-20">
      </div>
      Wrong network
      <!--
        <div class="caption ml-1">
        Switch your wallet to {{ $store.state.requiredNetwork }}
      </div>
      -->
    </v-btn>

    <v-btn
    v-else
    @click="connectWallet"
    class="midground"
    rounded depressed
    >   
      <img 
      :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
      height="20px"
      width="20px"
      class="br-20">
      <span class="ml-1"/>
      <span v-if="!sEthers.coinbase">Connect</span>
      <span v-else>{{ $root.condenseAddress(sEthers.coinbase) }}</span>

      <v-progress-circular
      v-if="pendingTx.length > 0"
      indeterminate
      width="2"
      size="24"
      color="primary"
      class="ml-2"
      >
        <span class="caption">
          {{ pendingTxLengthHuman }}
        </span>
      </v-progress-circular>
    </v-btn>
  </span>
</template>

<script>

export default {
  data: () => ({
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    pendingTx () {
      return this.$store.state.pendingTx.pending
    },
    pendingTxLengthHuman () {
      if (this.pendingTx.length > 9) {
        return '>9'
      }
      return this.pendingTx.length
    },
    wrongNetwork () {
      return this.$store.state.wrongNetwork
    },
    currentChainIcon () {
      var currentChainKey = null
      for (var key of Object.keys(this.$settings.CHAINS)) {
        if (this.$settings.CHAINS[key].chainId === this.sEthers.network) {
          currentChainKey = key
          break
        }
      }
      return (this.$settings.CHAINS[currentChainKey] || {}).icon
    },
  },

  watch: {
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .catch(e => {})
    },
  }
}
</script>