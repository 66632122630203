<template>
  <v-container style="max-width: 600px;">

    <page-v1-amms v-if="$settings.AMMS[$store.state.exchange].liqLockerVersion === 1"></page-v1-amms>

    <liq-locker-v2 v-else></liq-locker-v2>

  </v-container>
</template>

<script>
import PageV1Amms from './PageV1amms'
import LiqLockerV2 from '@/views/liqlockerv2/Page'

export default {
  components: {
    PageV1Amms,
    LiqLockerV2
  },

  data: () => ({
  }),

  methods: {
  }
}
</script>