<template>
  <div class="v-card pa-4 mb-2">
    <div>
      STAKE FACTORY ({{ num_pools }})
      <v-btn icon small @click="refresh" color="textFaint" rounded>
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>

    <div>
      <div v-for="pool of pools" :key="pool.address" @click="$root.chainLink(`/farm/${pool.address}`)">
        {{ pool.id }} - {{ pool.address }}
      </div>
    </div>
  </div>
</template>

<script>
import StakeFactoryContract from '@/smart-contracts/farms-v2/normal/stake-factory'

export default {

  components: {
  },

  data: () => ({
    num_pools: '0',
    pools: []
  }),

  computed: {
  },

  methods: {
    async refresh () {
      this.num_pools = await StakeFactoryContract.stakePoolsLength()
      this.pools = []
      for (var i = 0; i < this.num_pools; i++) {
        var pool_address = await StakeFactoryContract.stakePoolAtIndex(i)
        this.pools.push({
          id: i,
          address: pool_address
        })
      }
    }
  },

  created () {
    this.refresh()
  }
}
</script>