<template>
  <div>
    <div class="d-flex">
      Total Buy / Sell tax
      <v-spacer />
      <span class="font-weight-medium">
        {{ totalBuyFee }}% / {{ totalSellFee }}%
      </span>
    </div>

    <div class="d-flex">
      Mintable
      <v-spacer />
      <span class="d-flex align-center">
        <span class="mr-2">
          {{ tokenSettings.taxSettings.canMint ? 'On' : 'Off' }}
        </span>
        <v-tooltip v-if="tokenSettings.isLocked.canMint" left max-width="24em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
            v-bind="attrs"
            v-on="on"
            color="text" small>mdi-lock</v-icon>
          </template>
          <span>This setting is locked and cannot be changed</span>
        </v-tooltip>
        <v-tooltip v-else left max-width="24em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
            v-bind="attrs"
            v-on="on"
            color="textFaint" small>mdi-shield-alert-outline</v-icon>
          </template>
          <span>This setting is not locked and can be changed</span>
        </v-tooltip>
      </span>
    </div>
    <div class="d-flex">
      Pausable
      <v-spacer />
      <span class="d-flex align-center">
        <span class="mr-2">
          {{ tokenSettings.taxSettings.canPause ? 'On' : 'Off' }}
        </span>
        <v-tooltip v-if="tokenSettings.isLocked.canPause" left max-width="24em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
            v-bind="attrs"
            v-on="on"
            color="text" small>mdi-lock</v-icon>
          </template>
          <span>This setting is locked and cannot be changed</span>
        </v-tooltip>
        <v-tooltip v-else left max-width="24em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
            v-bind="attrs"
            v-on="on"
            color="textFaint" small>mdi-shield-alert-outline</v-icon>
          </template>
          <span>This setting is not locked and can be changed</span>
        </v-tooltip>
      </span>
    </div>
    <div class="d-flex">
      Blacklist
      <v-spacer />
      <span class="d-flex align-center">
        <span class="mr-2">
          {{ tokenSettings.taxSettings.canBlacklist ? 'On' : 'Off' }}
        </span>
        <v-tooltip v-if="tokenSettings.isLocked.canBlacklist" left max-width="24em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
            v-bind="attrs"
            v-on="on"
            color="text" small>mdi-lock</v-icon>
          </template>
          <span>This setting is locked and cannot be changed</span>
        </v-tooltip>
        <v-tooltip v-else left max-width="24em">
          <template v-slot:activator="{ on, attrs }">
            <v-icon 
            v-bind="attrs"
            v-on="on"
            color="textFaint" small>mdi-shield-alert-outline</v-icon>
          </template>
          <span>This setting is not locked and can be changed</span>
        </v-tooltip>
      </span>
    </div>

    <div class="d-flex">
      AntiBot
      <v-spacer />
      <span>
        {{ tokenSettings.antiBotSettings.isActive ? 'On' : 'Off' }}
      </span>
    </div>
  </div>
</template>

<script>
import axiosQL from '@/js/axios-graphql-interceptor';
import { ethers } from 'ethers'
import SETTINGS from '@/store/settings'

export default {
  props: {
    address: { // the tax tokens address
      type: String
    }
  },

  data: () => ({
    tokenSettings: {
      taxSettings: {},
      isLocked: {},
      fees: {
        transactionTax: {}
      },
      customTaxes: [],
      antiBotSettings: {}
    }
  }),

  computed: {
    totalBuyFee () {
      let total = 0;

      // add transaction tax to total
      if (this.tokenSettings.taxSettings.isTransactionTaxOn) {
        total += Number.parseInt(this.tokenSettings.fees.transactionTax.buy);
      }

      // add holder tax to total
      if (this.tokenSettings.taxSettings.isHolderTaxOn) {
        total += Number.parseInt(this.tokenSettings.fees.holderTax);
      }

      // add custom taxes to total
      total += Number.parseInt(
        this.tokenSettings.customTaxes
          .map(tax => tax.fee.buy)
          .reduce((partialSum, a) => partialSum + a, 0)
      );
      return total / 100;
    },
    totalSellFee () {
      let total = 0;

      // add transaction tax to total
      if (this.tokenSettings.taxSettings.isTransactionTaxOn) {
        total += Number.parseInt(this.tokenSettings.fees.transactionTax.sell);
      }

      // add buyback tax to total
      if (this.tokenSettings.taxSettings.isBuyBackTaxOn) {
        total += Number.parseInt(this.tokenSettings.fees.buyBackTax);
      }

      // add holder tax to total
      if (this.tokenSettings.taxSettings.isHolderTaxOn) {
        total += Number.parseInt(this.tokenSettings.fees.holderTax);
      }

      // add lp tax to total
      if (this.tokenSettings.taxSettings.isLpTaxOn) {
        total += Number.parseInt(this.tokenSettings.fees.lpTax);
      }

      // add custom taxes to total
      total += Number.parseInt(
        this.tokenSettings.customTaxes
          .map(tax => tax.fee.sell)
          .reduce((partialSum, a) => partialSum + a, 0)
      );

      return total / 100;
    },
  },

  watch: {
    address (nv) {
      this.fetchTaxTokenSettings()
    }
  },

  methods: {
    async fetchTaxTokenSettings () {
      if (!ethers.utils.isAddress(this.address)) {
        return
      }
      var data = {
        query: `
          {
            taxToken(id: "${this.address.toLowerCase()}") {
              id
              name
              symbol
              decimals
              totalSupply
              isLosslessOn
              losslessAdmin {
                id
              }
              losslessRecoveryAdmin {
                id
              }
              lossless
              taxSettings {
                isTransactionTaxOn
                isBuyBackTaxOn
                isHolderTaxOn
                isLpTaxOn
                canBlacklist
                canMint
                canPause
                isMaxBalanceAfterBuyOn
              }
              isLocked {
                isTransactionTaxOn
                isBuyBackTaxOn
                isHolderTaxOn
                isLpTaxOn
                canBlacklist
                canMint
                canPause
                isMaxBalanceAfterBuyOn
              }
              fees {
                transactionTax {
                  buy
                  sell
                }
                buyBackTax
                holderTax
                lpTax
              }
              customTaxLength
              customTaxes {
                id
                name
                fee {
                  buy
                  sell
                }
                wallet {
                  id
                }
              }
              transactionTaxWallet {
                id
              }
              taxHelperIndex
              pairAddress
              lpTokens
              buyBackWallet {
                id
              }
              lpWallet {
                id
              }
              excluded {
                id
              }
              maxBalanceAfterBuy
              antiBotSettings {
                startBlock
                endDate
                increment
                initialMaxHold
                isActive
              }
              swapWhitelistingSettings {
                endDate
                isActive
              }
              owner {
                id
              }
              isPaused
              isTaxed
              buyBackWalletThreshold
              lpWalletThreshold
              blacklist {
    					  id
    					}
              swapWhitelist {
                id
              }
    					maxBalanceWhitelist {
    					  id
    					}
              marketInitTimestamp
            }
          }
        `
      }

      var network = this.$store.state.requiredNetwork;
      var graphExplorer = SETTINGS.CHAINS[network].graphExplorerMinterV2;

      // axiosRetry(axiosQL, { retries: 3 });

      var response = await axiosQL.post(graphExplorer, data);

      console.log(response)

      if (response.data.data.taxToken !== null) {
        this.tokenSettings = response.data.data.taxToken;
      }
    },
  },

  created () {
    this.fetchTaxTokenSettings()
  }
}
</script>
