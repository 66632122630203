<template>
  <div class="d-flex flex-column" style="height: 100%; position: relative;">
    <div style="display:flex;flex: 1;">
      <iframe v-if="chartProvider === 'Geckoterminal'" height="100%" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" :src="`https://www.geckoterminal.com/${chainSlug}/pools/${address}?embed=1&info=0&swaps=0`" frameborder="0" allow="clipboard-write" allowfullscreen></iframe>
      <iframe v-else-if="chartProvider === 'Dexscreener'" width="100%" height="100%" :src="`https://dexscreener.com/${chainSlug}/${address}?embed=1&trades=0&info=0`" title="Dexscreener" style="border:none;"></iframe>
      <iframe v-else-if="chartProvider === 'Dext'" id="dextools-widget"
      title="DEXTools Trading Chart"
      style="border:none;"
      width="100%"
      height="100%" :src="`https://www.dextools.io/widgets/en/${chainSlug}/pe-light/${address}?theme=dark&chartType=${dext_number}&chartResolution=30&drawingToolbars=false`"></iframe>
    </div>
    <div style="position: absolute; bottom: 4px;right:8px;">
      <!-- <c-input :value.sync="dext_number" placeholder="dext number">
      </c-input> -->
      <v-menu
        offset-y
        content-class="br-20"
        transition="fade-transition"
        :open-on-hover="!$vuetify.breakpoint.xs"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              depressed
              rounded class="pa-1"
              color="#151617"
              v-bind="attrs"
              v-on="on"
            >
              <img 
              :src="chartProviderIcon" 
              height="20px"
              width="20px"
              class="br-20">
              <v-icon x-small color="white">mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list class="background br-20 ma-0 pa-0">
            <v-list-item @click="setChartProvider('Dext')">
              <v-list-item-title class="d-flex align-center caption">
                <img 
                :src="$root.$icons.dext" 
                height="24px"
                width="24px"
                class="br-20">
                <span class="ml-2">
                  Dext
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="setChartProvider('Geckoterminal')">
              <v-list-item-title class="d-flex align-center caption">
                <img 
                :src="$root.$icons.gecko_terminal" 
                height="24px"
                width="24px"
                class="br-20">
                <span class="ml-2">
                  Coingecko
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="setChartProvider('Dexscreener')">
              <v-list-item-title class="d-flex align-center caption">
                <img 
                :src="$root.$icons.dexscreener" 
                height="24px"
                width="24px"
                class="br-20">
                <span class="ml-2">
                  Dexscreener
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    db_info: {
      type: Object,
      default: () => ({
        presale_contract: null
      })
    },
    address: null,
    network: null
  },

  data: () => ({
    dext_number: 1
  }),

  computed: {
    chartProvider () {
      return this.$store.state.userSettings.chartProvider
    },
    chartProviderIcon () {
      var map = {
        'Geckoterminal': this.$root.$icons.gecko_terminal,
        'Dexscreener': this.$root.$icons.dexscreener,
        'Dext': this.$root.$icons.dext,
      }
      return map[this.chartProvider]
    },
    chainSlug () {
      var map = {
        'Geckoterminal': {
          'Mainnet': 'eth',
          'BSC_MAINNET': 'bsc',
          'AVAX': 'avax',
          'DogeChain': 'dogechain',
          'Matic': 'polygon_pos',
          'ArbitrumOne': 'arbitrum',
          'Base': 'base',
        },
        'Dexscreener': {
          'Mainnet': 'ethereum',
          'BSC_MAINNET': 'bsc',
          'AVAX': 'avalanche',
          'DogeChain': 'dogechain',
          'Matic': 'polygon',
          'ArbitrumOne': 'arbitrum',
          'Base': 'base',
        },
        'Dext': {
          'Mainnet': 'ether',
          'BSC_MAINNET': 'bnb',
          'AVAX': 'avalanche',
          'DogeChain': 'dogechain',
          'Matic': 'polygon',
          'ArbitrumOne': 'arbitrum',
          'Base': 'base'
        }
      }
      return map[this.chartProvider][this.network]
    }
  },

  methods: {
    setChartProvider (chartProvider) {
      this.$store.commit('editUserSettings', {
        setting: 'chartProvider',
        value: chartProvider
      })
    }
  },

  created () {
  }
}
</script>