<template>
  <v-container :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`" :class="['mt-1 v-card midground pa-0 mb-16', {'pa-0': $vuetify.breakpoint.xs}, {'br-20': !$vuetify.breakpoint.xs}]">

    <!--
    <div v-if="!sEthers.coinbase" class="py-3 px-2">
      <v-btn color="primary" class="title" block depressed large rounded @click="connectWallet">
        <v-icon class="mr-1">mdi-exit-to-app</v-icon>
        Connect your wallet
      </v-btn>
    </div>
    -->

    <div :class="['foreground mx-auto', {'px-4 pt-4': $vuetify.breakpoint.xs}, {'px-8 pt-4': !$vuetify.breakpoint.xs}]">
      
      <div class="d-flex align-center">

        <div v-if="!$vuetify.breakpoint.xs && false" class="ml-2 mr-3 title font-weight-bold">
          LAUNCHPAD
        </div>

        <div v-if="true" :class="['my-1 d-flex flex align-center background px-3 py-1 r-outlined br-c']">
          <c-input :value.sync="filters.search" placeholder="Search...">
          </c-input>

          <v-progress-circular
          v-if="showSearchLoader"
          indeterminate
          width="2"
          class="mr-2"
          size="24"
          color="primary"
          ></v-progress-circular>

          <v-icon v-if="filters.search === ''">
            mdi-magnify
          </v-icon>
          <v-icon v-else @click="filters.search = ''">
            mdi-close
          </v-icon>
        </div>

        <v-btn to="/services/launchpad" :class="['white--text', {'ml-2': $vuetify.breakpoint.xs}, {'ml-3': !$vuetify.breakpoint.xs}]" small depressed rounded color="navcolor" style="border: 1.5px solid;">
          Create Launch
        </v-btn>
      </div>

      <v-bottom-navigation
        v-if="!filters.search"
        color="navcolor"
        grow
        class="align-center transparent br-c"
        style="box-shadow: none;"
        :value="tab"
      >

        <v-btn @click="tab = 0">
          <span class="font-weight-medium">Upcoming</span>
          <v-icon>mdi-clock-outline</v-icon>
        </v-btn>

        <v-btn @click="tab = 1">
          <span class="font-weight-medium">Live</span>
          <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
        </v-btn>

        <v-btn @click="tab = 2">
          <span class="font-weight-medium">Success</span>
          <v-icon>mdi-check-outline</v-icon>
        </v-btn>

        <v-btn @click="tab = 3">
          <span class="font-weight-medium">Failed</span>
          <v-icon>mdi-close-outline</v-icon>
        </v-btn>

      </v-bottom-navigation>

    </div>

    <div>

      <div class="midground mt-1" style="height: 3px;">

      </div>

      <div class="px-5 py-3 foreground d-flex textFaint--text align-center">
        {{ presaleCount }} presale{{ presaleCount === 1 ? '' : 's'}}

        <v-spacer></v-spacer>

        <v-btn v-if="$store.state.superUserMode && $store.state.adminIds.includes($store.state.user.jwtObject.user_id)" color="textFaint" small rounded outlined @click="filters.show_hidden = !filters.show_hidden">
          <v-icon v-if="filters.show_hidden" size="16" class="mr-2" color="text">mdi-checkbox-blank-circle</v-icon>
          <v-icon v-else size="16" class="mr-2" color="text">mdi-checkbox-blank-circle-outline</v-icon>
          Show hidden
        </v-btn>

        <div>
          <v-menu
          offset-y
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                small
                rounded outlined color="" class="textFaint--text"
                v-bind="attrs"
                v-on="on"
                style="min-width: 74px;"
              >
                <div v-if="false" class="mr-1">
                  Sort
                </div>
                <div v-if="filters.sort === 'uncl_participants'" class="d-flex align-center">
                  <img 
                  src="@/assets/img/UNCL.svg" 
                  height="16px"
                  width="16px"
                  class="mr-2">
                  UNCL
                </div>
                <div v-else-if="filters.sort === 'start_block'">
                  Start block
                </div>
                <div v-else-if="filters.sort === 'end_block'">
                  End block
                </div>
                <div v-else-if="filters.sort === 'liquidity_lock'">
                  Lock %
                </div>
                <div v-else-if="filters.sort === 'profit'">
                  profit
                </div>
                <div v-else-if="filters.sort === 'id'">
                  latest
                </div>
                <v-icon small color="" :class="[{'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list style="min-width: 200px;" class="background">
              <div class="pa-4 caption font-italic">
                Sort by
              </div>
              <v-list-item @click="sortList('uncl_participants')">
                <v-list-item-title>
                  UNCL participation
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="sortList('profit')">
                <v-list-item-title>
                  Profit
                </v-list-item-title>
                <v-icon v-if="filters.sort === 'profit'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
              </v-list-item>
              <v-list-item @click="sortList('start_block')">
                <v-list-item-title>
                  Start date
                </v-list-item-title>
                <v-icon v-if="filters.sort === 'start_block'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
              </v-list-item>
              <v-list-item @click="sortList('id')">
                <v-list-item-title>
                  Newest
                </v-list-item-title>
                <v-icon v-if="filters.sort === 'id'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
              </v-list-item>
              <!--
              <v-list-item @click="sortList('end_block')">
                <v-list-item-title>
                  End date
                </v-list-item-title>
                <v-icon v-if="filters.sort === 'end_block'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
              </v-list-item>
              <v-list-item @click="sortList('liquidity_lock')">
                <v-list-item-title>
                  Liquidity lock %
                </v-list-item-title>
                <v-icon v-if="filters.sort === 'liquidity_lock'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
              </v-list-item>
              -->
            </v-list>
          </v-menu>
        </div>

        <v-btn color="textFaint" small rounded outlined @click="toggleKYCFilter">
          <img 
          v-if="filters.hide_flagged"
          src="@/assets/img/flags/magnify.svg" 
          height="16px"
          class="mr-2"
          width="16px">
          <v-icon v-else size="16" class="mr-2" color="text">mdi-checkbox-blank-circle-outline</v-icon>
          KYC only
        </v-btn>
        <v-menu offset-y open-on-hover transition="scale-transition" max-width="300px">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop
              small
              v-bind="attrs"
              v-on="on"
              color="textFaint"
              class="ml-1"
            >
              mdi-help-circle-outline
            </v-icon>
          </template>
          <div class="pa-3 foreground caption">
            Only show presales where the team is known. We strongly reccommend leaving this filter ON.
            New anonymous teams should not be trusted.
          </div>
        </v-menu>
      </div>

      <presale-timeline v-if="tab === 0 && false" :exchange="$store.state.exchange" :sorting="filters.sort" :hide_flagged="filters.hide_flagged"></presale-timeline>

      <!-- PRESALES -->
      <div v-if="items.length === 0 && loading" class="text-center pb-4">
        <v-progress-circular
        indeterminate
        size="60"
        width="2"
        color="#aaa">
          <img 
          src="@/assets/img/UNCX_fill.svg" 
          height="50px"
          class="greyscale"
          width="50px">
        </v-progress-circular>
      </div>

      <div v-else>
        <!--
        <v-row dense
        v-if="items.length > 0"
        class="ma-0 px-2">
          <v-col cols="12" md="6">
            <presale-row
            v-for="presale of items.slice(0, rowsPerPage / 2)"
            @click.native="$root.ammLink(`/ilo/${presale.presale_contract}`)"
            :key="presale.presale_contract"
            :presale="presale"
            >
            </presale-row>
          </v-col>
          <v-col cols="12" md="6">
            <presale-row
            v-for="presale of items.slice(rowsPerPage / 2)"
            @click.native="$root.ammLink(`/ilo/${presale.presale_contract}`)"
            :key="presale.presale_contract"
            :presale="presale"
            >
            </presale-row>
          </v-col>
        </v-row>
        -->

        <v-row dense
        v-if="items.length > 0"
        class="ma-0 px-2 pt-2">
          <v-col v-for="presale of items" :key="presale.presale_contract"
          cols="12" md="6" class="py-0">
            <presale-row
            @click.native="$root.ammLink(`/ilo/${presale.presale_contract}`)"
            :presale="presale"
            :exchange="$store.state.exchange"
            >
            </presale-row>
          </v-col>
        </v-row>

        <!-- NO ITEMS -->
        <div v-if="items.length === 0" class="textFaint--text">
          <div v-if="filters.search.length > 0" class="d-flex align-center br-20 pa-4">
            No presales match your search terms
          </div>
          <div v-else>
            <div v-if="filters.hide_flagged" class="d-flex align-center pa-4">
              <img 
              src="@/assets/img/flags/magnify.svg" 
              height="40px"
              width="40px">
              <div class="ml-3">
                No KYC'd {{ $store.state.exchange }} presales here, disabling the KYC filters will show Anon team presales.
              </div>
            </div>
            <div v-else class="d-flex align-center br-20 pa-4">
              No presales here
            </div>
          </div>
        </div>
        <!-- NO ITEMS -->

        <!-- PAGINATION -->
        <div v-if="pageCount > 1" class="d-flex align-center foreground justify-center py-2">
          <v-btn :disabled="page === 0" @click="firstPage" text icon color="text">
            <v-icon>mdi-page-first</v-icon>
          </v-btn>
          <v-btn @click="previousPage" :disabled="page <= 0" text>
            Previous
          </v-btn>
          <div class="px-2 textFaint--text">
            {{ page + 1 }} / {{ pageCount }}
          </div>
          <v-btn :disabled="!nextPageExists" @click="nextPage" text>
            Next
          </v-btn>
          <v-btn icon :disabled="!nextPageExists" @click="lastPage" text color="text">
            <v-icon>mdi-page-last</v-icon>
          </v-btn>
        </div>
        <!-- PAGINATION -->
      </div>
      <!-- PRESALES -->
    </div>

    <kyc-dialog ref="kycDialog" @on-enable-ape-mode="enableApeMode"></kyc-dialog>

  </v-container>
</template>

<script>
import PresaleRow from './row-switcher'
import _ from 'lodash'
import PresaleTimeline from './timeline'
import KycDialog from '../KYCDialog.vue'

export default {
  name: 'Presales',
  components: {
    PresaleRow,
    PresaleTimeline,
    KycDialog
  },

  data: () => ({
    tab: 0,
    presales: [],
    items: [],
    page: 0,
    filters: {
      // sort: 'start_block',
      sort: 'uncl_participants',
      sortAscending: true,
      search: '',
      hide_flagged: false,
      show_hidden: false,
    },
    nextPageExists: false,
    showSearchLoader: false,
    loading: false,
    presaleCount: 0, // for your search term or tab
    rowsPerPage: 6
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    pageCount () {
      return Math.ceil(this.presaleCount / this.rowsPerPage)
    }
  },

  watch: {
    tab (nv) {
      this.page = 0
      this.items = []
      if (nv === 2) {
        this.filters.sort = 'profit'
        this.filters.sortAscending = false
      }
      this.normalLoad()
    },
    'filters.search' (nv) {
      this.showSearchLoader = true
      this.page = 0
      this.normalLoad()
    },
    'filters.hide_flagged' (nv) {
      this.showSearchLoader = true
      this.page = 0
      this.normalLoad()
    },
    'filters.show_hidden' (nv) {
      this.showSearchLoader = true
      this.page = 0
      this.normalLoad()
    }
  },

  methods: {
    toggleKYCFilter () {
      this.filters.hide_flagged = !this.filters.hide_flagged
      /* 
      if (this.filters.hide_flagged) {
        if (!this.$store.state.userSettings.kycPopupApproved) {
          this.$refs.kycDialog.open()
        } else {
          this.filters.hide_flagged = false
        }
      } else {
        this.filters.hide_flagged = true
      } */
    },
    enableApeMode () {
      this.filters.hide_flagged = false
      this.$store.commit('editUserSettings', {
        setting: 'kycPopupApproved',
        value: true
      })
    },
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    /*
    nextPage: _.debounce(function () {
      this.page++
      this.APIFetch()
        .then(response => {
          var rows = response.data.rows
          this.items.push(...rows)
          this.presaleCount = response.data.count
          // this.nextPageExists = rows.length === 2
          var endOfPage = (this.page + 1) * 2
          this.nextPageExists = endOfPage < response.data.count
        })
    }, 500, {trailing: true}), */

    sortList (sorting) {
      if (this.filters.sort === sorting) {
        this.filters.sortAscending = !this.filters.sortAscending
      } else {
        this.filters.sort = sorting
        this.filters.sortAscending = false
      }
      this.page = 0
      this.normalLoad()
    },

    firstPage () {
      if (this.page === 0) {
        return
      }
      this.page = 0
      this.normalLoad()
    },
    lastPage () {
      if (this.page === this.pageCount - 1) {
        return
      }
      this.page = this.pageCount - 1
      this.normalLoad()
    },
    nextPage () {
      if (this.page + 1 >= this.pageCount) {
        return
      }
      this.page++
      this.normalLoad()
    },

    previousPage () {
      if (this.page <= 0) {
        return
      }
      this.page--
      this.normalLoad()
    },

    normalLoad: _.debounce(function () {
      this.APIFetch()
        .then(response => {
          var rows = response.data.rows
          this.items = rows
          this.presaleCount = Number(response.data.count)
          // this.nextPageExists = rows.length === 2
          var endOfPage = (this.page + 1) * this.rowsPerPage
          this.nextPageExists = endOfPage < response.data.count
        })
    }, 500, {leading: true}),

    APIFetch () {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.$axios.post(`/presales/search`, {filters: this.filters, page: this.page, rows_per_page: this.rowsPerPage, stage: this.tab})
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
          .then(() => {
            this.loading = false
            this.showSearchLoader = false
          })
      })
    },
  },

  activated () {
    this.normalLoad()
  },

  created () {
    // this.refresh()
    // this.normalLoad()
  }
}
</script>