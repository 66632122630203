import { 
  mainnet,
  goerli,
  sepolia,
  bsc,
  bscTestnet,
  gnosis,
  polygon,
  avalanche,
  dogechain,
  arbitrum
} from 'viem/chains'

import Endpoints from './endpoints'

const EndpointsViem = {
  "1": Endpoints.Mainnet,
  "5": Endpoints.Goerli,
  "11155111": Endpoints.Sepolia,
  "56": Endpoints.BSC_MAINNET,
  "97": Endpoints.BSC_TESTNET,
  "100": Endpoints.xDai,
  "137": Endpoints.Matic,
  "43114": Endpoints.AVAX,
  "2000": '',
  "42161": Endpoints.ArbitrumOne,
  '8453': Endpoints.Base,
  
  "1337": "http://127.0.0.1:8545",
}

export default EndpointsViem