<template>
  <v-container style="max-width: 600px;">

    <v-card class="mb-2 br-20">
      <v-btn x-large text :color="tab === 0 ? 'orange' : ''" @click="tab = 0">
        New Lock
      </v-btn>
      <v-btn x-large text :color="tab === 1 ? 'orange' : ''" @click="tab = 1">
        {{ $store.state.exchange === 'Pancakeswap V1' ? 'Migrate / Withdraw' : 'Edit / Withdraw' }}
      </v-btn>
      <!--
      <v-btn x-large text :color="tab === 2 ? 'primary' : ''" @click="tab = 2">
        Info
      </v-btn>
      -->
    </v-card>

    <keep-alive>
      <lock-page v-if="tab === 0"></lock-page>
      <withdraw-page v-if="tab === 1"></withdraw-page>
      <info-page v-if="tab === 2"></info-page>
    </keep-alive>

  </v-container>
</template>

<script>
import LockPage from './LockPage'
import WithdrawPage from './withdraw/WithdrawPage'
import InfoPage from './info-page.vue'

export default {
  components: {
    LockPage,
    WithdrawPage,
    InfoPage
  },

  data: () => ({
    tab: 0
  }),

  methods: {
  }
}
</script>