<template>
  <div :class="['mb-16']">

    <v-expand-transition mode="out-in">
      <div v-if="showInfoTip">
        <div class="v-card pa-4 ma-1 br-8 textFaint--text d-flex align-center">
          <v-icon class="mr-2" color="textFaint">mdi-information-outline</v-icon>
          <div class="flex">
            You can easily access all your farms from the 
            <router-link to="/account" class="deadlink navcolor--text">
              Account page
            </router-link>
            under the 'farms' tab
          </div>
          <v-btn icon color="textFaint" @click="showInfoTip = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </div>
    </v-expand-transition>
    
    <v-expand-transition mode="out-in">
      <div v-if="showStakingTip">
        <div class="py-4 mx-2 font-weight-medium mt-3 br-20 textFaint--text d-flex align-center">
          <div class="flex">
            Earn UNCX fees with
            <router-link to="/uncx-staking" class="deadlink primary--text">
              UNCX staking
            </router-link>
          </div>
          <v-btn v-if="false" icon color="textFaint" @click="showStakingTip = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </div>
    </v-expand-transition>

    <!--
    <div v-for="(item, i) of items" :key="item.spool_address" class="caption v-card ma-1">
      <div>
        {{ i + 1 }}
      </div>
      {{ item.meta.rewards }}
    </div>
    -->

    <div class="display-1 font-weight-bold pt-4 px-3 pb-4">
      FARMS & STAKING
    </div>

    <div class="pl-3 pr-5 d-flex align-center">
      <chain-select :chains="allowedChains" :value.sync="selected_chain" @on-update="switchServer"></chain-select>
      <v-spacer></v-spacer>
      <template v-if="!['kovan'].includes(selectedChain)">
        <span class="mr-1 text--text">
          TVL 
          ${{ $root.formatDollars(TVL_MAP[selectedChain]) }}
        </span>
        <span class="text--text">
        / ${{ $root.formatDollars(TVL) }}
        </span>
      </template>
      <template v-else>
        <span>
          TVL 
          ${{ $root.formatDollars(TVL_MAP[selectedChain]) }}
        </span>
      </template>
    </div>

    <div class="px-4 pt-2 d-flex align-center justify-end">

      <span class="textFaint--text">
        {{ rowCount }} pools
      </span>

      <v-spacer></v-spacer>

      <v-fade-transition appear mode="out-in">
        <v-progress-circular
        v-if="loading"
        indeterminate
        size="36"
        width="2"
        color="textFaint">
          <img 
          src="@/assets/img/UNCX_fill.svg" 
          height="120px"
          class="greyscale"
          width="28px">
        </v-progress-circular>
        <v-btn v-else @click="normalLoad" icon color="textFaint">
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
      </v-fade-transition>

      <v-menu
      v-if="false"
      offset-y
      open-on-hover
      transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            icon
            :color="advancedMode ? 'primary' : 'textFaint'" class=""
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small color="">mdi-eye</v-icon>
          </v-btn>
        </template>

        <v-list style="min-width: 200px;" class="background">
          <div class="pa-4 caption font-italic">
            Advanced mode
          </div>
          <v-list-item @click="advancedMode = !advancedMode">
            <v-list-item-title>
              Show advanced info
            </v-list-item-title>
            <v-icon small :color="advancedMode ? 'primary' : 'textFaint'" class="ml-2">mdi-check-circle</v-icon>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
      offset-y
      open-on-hover
      transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            small
            rounded depressed color="foreground" class=""
            v-bind="attrs"
            v-on="on"
            style="min-width: 74px;"
          >
            <div class="mr-1 textFaint--text">
              Sort
            </div>
            <div v-if="filters.sort === 'id'">
              latest
            </div>
            <div v-else-if="filters.sort === 'num_stakers'">
              num stakers
            </div>
            <div v-else-if="filters.sort === 'apy'">
              APY
            </div>
            <div v-else-if="filters.sort === 'tvl'">
              TVL
            </div>
            <v-icon small color="" :class="[{'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list style="min-width: 200px;" class="background">
          <div class="pa-4 caption font-italic">
            Sort by
          </div>
          <v-list-item @click="sortList('id')">
            <v-list-item-title>
              Latest
            </v-list-item-title>
            <v-icon v-if="filters.sort === 'id'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
          </v-list-item>
          <v-list-item @click="sortList('apy')">
            <v-list-item-title>
              APY
            </v-list-item-title>
            <v-icon v-if="filters.sort === 'apy'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
          </v-list-item>
          <v-list-item @click="sortList('tvl')">
            <v-list-item-title>
              TVL
            </v-list-item-title>
            <v-icon v-if="filters.sort === 'tvl'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
          </v-list-item>
          <v-list-item @click="sortList('num_stakers')">
            <v-list-item-title>
              Number of stakers
            </v-list-item-title>
            <v-icon v-if="filters.sort === 'num_stakers'" color="" :class="['ml-1', {'rotate-180': filters.sortAscending}]">mdi-chevron-down</v-icon>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn color="foreground" class="primary--text" rounded depressed small @click="$root.chainLink(`/farms/create`)">
        Create
      </v-btn>

    </div>
    
    <!--
    <item-row v-for="item of items" :key="item.id" :item="item">
    </item-row>
    -->

    <!--
    <div style="overflow: auto;">
      <table class="c-table" style="width: 100%">
        <tr class="caption textFaint--text">
          <th>
            Stake
          </th>
          <th>
            Stakers
          </th>
          <th v-if="advancedMode">
            Rwrd
          </th>
          <th v-if="advancedMode">
            Id
          </th>
        </tr>
        <item-row v-for="item of items" :key="item.id" :item="item" :advancedMode="advancedMode">
        </item-row>
      </table>
    </div>
    -->

    <v-row class="ma-0 pt-1">
      <v-col v-for="item of items" :key="item.spool_address" cols="12" md="4">
        <item-row :item="item" :advancedMode="advancedMode" @click.native="$root.chainLink(`/farm/${item.spool_address}`)">
        </item-row>
      </v-col>
    </v-row>

    <!-- PAGINATION -->
    <div v-if="pageCount > 1" class="d-flex align-center py-2 justify-center">
      <v-btn :disabled="page === 0" @click="firstPage" text icon color="text">
        <v-icon small>mdi-page-first</v-icon>
      </v-btn>
      <v-btn @click="previousPage" :disabled="page <= 0" text icon color="text">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <div class="px-2 textFaint--text">
        {{ page + 1 }} / {{ pageCount }}
      </div>
      <v-btn icon :disabled="!nextPageExists" @click="nextPage" text color="text">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn icon :disabled="!nextPageExists" @click="lastPage" text color="text">
        <v-icon small>mdi-page-last</v-icon>
      </v-btn>
    </div>
    <!-- PAGINATION -->

  </div>
</template>

<script>
import axios from "axios";
import ItemRow from './row'
import ChainSelect from '@/components/ui/chain-select'

export default {

  components: {
    ItemRow,
    ChainSelect
  },

  data: () => ({
    showStakingTip: true,
    showInfoTip: false,
    allowedChains: [],
    selected_chain: 'Mainnet',

    items: [],
    page: 0,
    filters: {
      sort: 'tvl',
      sortAscending: false,
    },
    rowsPerPage: 12,

    rowCount: 0,
    nextPageExists: false,

    TVL: 0,
    TVL_MAP: {
      'eth': 0,
      'bsc': 0,
      'avax': 0,
    },

    advancedMode: false,

    loading: false
  }),

  computed: {
    selectedChain () {
      return this.$root.getLegacyChainKey(this.$store.state.requiredNetwork)
    },
    pageCount () {
      return Math.ceil(this.rowCount / this.rowsPerPage)
    }
  },

  watch: {
    selectedChain () {
      this.page = 0
      this.normalLoad()
    }
  },

  methods: {
    switchServer (chain) {
      this.$store.commit('switchChain', chain)
    },
    nextPage () {
      if (this.page + 1 >= this.pageCount) {
        return
      }
      this.showSearchLoader = true
      this.page++
      this.normalLoad()
    },

    firstPage () {
      if (this.page === 0) {
        return
      }
      this.showSearchLoader = true
      this.page = 0
      this.normalLoad()
    },

    lastPage () {
      if (this.page === this.pageCount - 1) {
        return
      }
      this.showSearchLoader = true
      this.page = this.pageCount - 1
      this.normalLoad()
    },

    previousPage () {
      if (this.page <= 0) {
        return
      }
      this.showSearchLoader = true
      this.page--
      this.normalLoad()
    },
    sortList (sorting) {
      this.showSearchLoader = true
      if (this.filters.sort === sorting) {
        this.filters.sortAscending = !this.filters.sortAscending
      } else {
        this.filters.sort = sorting
        this.filters.sortAscending = false
      }
      this.page = 0
      this.normalLoad()
    },
    async getTVL (chain) {
      try {
        var response = await axios.get(
          `${this.$root.$servers.FARMS[chain].server}/farms/tvl`)
        this.TVL_MAP[chain] = response.data
      } catch (e) {
      }
    },
    async getTVLAll () {
      await Promise.all([
        this.getTVL('eth'),
        this.getTVL('bsc'),
        this.getTVL('avax')
      ])
      var mainnets = Object.keys(this.TVL_MAP).filter(chain => !['kovan'].includes(chain))
      var tvl = 0
      for (var chain of mainnets) {
        tvl += this.TVL_MAP[chain]
      }
      this.TVL = tvl
    },
    async normalLoad () {
      this.loading = true
      this.getTVL(this.selectedChain)
      try {
        var response = await axios.post(
          `${this.$root.$servers.FARMS[this.selectedChain].server}/farms/search`,
          {filters: this.filters, page: this.page, rows_per_page: this.rowsPerPage},
        )
        var rows = response.data.rows
        this.items = rows
        this.rowCount = Number(response.data.count)
        // this.nextPageExists = rows.length === 2
        var endOfPage = (this.page + 1) * this.rowsPerPage
        this.nextPageExists = endOfPage < response.data.count
      } catch (e) {
        this.items = 0
        this.rowCount = 0
      }
      this.loading = false
    }
  },

  created () {
    this.selected_chain = this.$store.state.requiredNetwork
    this.allowedChains = ['Mainnet', 'BSC_MAINNET', 'AVAX', 'Sepolia']
    if (this.$store.state.allowGanache) {
      this.allowedChains.push('Ganache')
    }
    this.normalLoad()
    this.getTVLAll()
  }
}
</script>