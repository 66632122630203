<template>
  <v-container :style="`max-width: ${$vuetify.breakpoint.xs ? '600px': '1100px'};`" :class="[{'pa-0 foreground mobile-page pb-16': $vuetify.breakpoint.xs}]">
    <div class="text-center flex pa-2">
      <v-row dense class="ma-0 justify-center">
        <v-col cols="12" md="9">
          <div class="d-flex flex-row" style="justify-content: center;">
            <div class="text-center d-flex pa-2">
              <v-btn large rounded outlined @click="$root.$dialog.chainSwitcher.open('taxToken')" class="foreground title">
                <img
                :src="$settings.CHAINS[$store.state.requiredNetwork].icon"
                height="24px"
                width="24px"
                class="mr-3 br-20">
                {{ $settings.CHAIN_DISPLAY_NAME[$store.state.requiredNetwork] }}
                <v-icon small color="">mdi-chevron-down</v-icon>
              </v-btn>
            </div>

            <div v-for="amm of amms" :key="amm" class="v-card d-flex align-center title c-list pr-4 pl-4 br-20 mb-2" @click="selectExchange(amm)" style="height: 43.99px; margin-top: 8px;">
            <img 
            :src="$settings.AMMS[amm].icon" 
            height="24px"
            width="24px"
            class="mr-3 br-20">
            <div>
              <div class="title">
                {{ $settings.AMMS[amm].displayname }}
              </div>
            </div>
          </div>
            
            <!-- <div class="text-center flex pa-2">
              <v-btn large rounded outlined @click="showMinterVersionDialog" class="foreground title">
                Exchange
                <v-icon small color="">mdi-chevron-down</v-icon>
              </v-btn>
            </div> -->
          </div>
          <div v-if="!sEthers.coinbase" class="pa-2 v-card br-20 px-8 py-8">
            <v-btn large rounded outlined color="textFaint" @click="connectWallet" style="border: 1.5px solid;">
              <div class="">
                Connect your wallet to continue
              </div>
            </v-btn>
          </div>
          <v-slide-x-transition v-else appear>
            <token-create />
          </v-slide-x-transition>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import TokenCreate from '@/views/minter-v2/token-create';

export default {
  components: {
    TokenCreate
  },

  data: () => ({
    showForm: false,
    tokenName: '',
    tokenSymbol: '',
    tokenDecimal: '18',
    tokenTotalSuply: '',
    createLoading: false,
    fees: {
      flat_fee: '0',
      ts_fee: '0'
    },
    formError: false,
    formErrorMessage: '',
    minterVersion: 'ENMT'
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    amms () {
      // !important
      // if adding here, add to chain-switcher-dialogue
      // you can set the default exchange there as well
      
      if (this.$store.state.requiredNetwork === 'BSC_MAINNET') {
        return ['Pancakeswap V2']
      };
      if (this.$store.state.requiredNetwork === 'Mainnet') {
        return ['Uniswap V2']
      };
      if (this.$store.state.requiredNetwork === 'Matic') {
        return ['Quickswap V1']
      };
      if (this.$store.state.requiredNetwork === 'BSC_TESTNET') {
        return ['Pancakeswap V2 - BSC TESTNET']
      };
      if (this.$store.state.requiredNetwork === 'Goerli') {
        return ['Uniswap V2 - Goerli']
      };
      if (this.$store.state.requiredNetwork === 'ArbitrumOne') {
        return ['Camelot'];
      }
      return [];
    }
  },
  watch: {
    sEthersWatcher () {
      this.refresh()
    },
  },
  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    async refresh () {
    }
  },
  selectExchange (exchange) {
    this.$store.commit('switchExchange', exchange)
  },
  created () {
    this.refresh();
  }
}
</script>