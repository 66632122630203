import {store} from '@/store/index';
import { ethers } from "ethers";
import ABI from './abis'
import PROVIDERS from '@/web3/providers'

const Self = {
  userActivePoolsLength: async (_user, network = null) => {
    var provider = network ? PROVIDERS[network] : store.state.ethers.provider
    const StakeFactory = new ethers.Contract(ABI.factory_pager_address(network), ABI.factory_pager_abi, provider)
    var res = await StakeFactory.userActivePoolsLength(_user)
    return res.toString()
  },
  getUserActivePools: async (_user, _start, _count, network = null) => {
    var provider = network ? PROVIDERS[network] : store.state.ethers.provider
    const StakeFactory = new ethers.Contract(ABI.factory_pager_address(network), ABI.factory_pager_abi, provider)
    var res = await StakeFactory.getUserActivePools(_user, _start, _count)
    return res.map(item => ({
      pool_address: item[0],
      contract_version: item[1].toString()
    }))
  },
  getNumPoolsForAdmin: async (_user, network = null) => {
    var provider = network ? PROVIDERS[network] : store.state.ethers.provider
    const StakeFactory = new ethers.Contract(ABI.factory_pager_address(network), ABI.factory_pager_abi, provider)
    var res = await StakeFactory.getNumPoolsForAdmin(_user)
    return res.toString()
  },
  getStakePoolsForAdmin: async (_user, _start, _count, network = null) => {
    var provider = network ? PROVIDERS[network] : store.state.ethers.provider
    const StakeFactory = new ethers.Contract(ABI.factory_pager_address(network), ABI.factory_pager_abi, provider)
    var res = await StakeFactory.getStakePoolsForAdmin(_user, _start, _count)
    return res.map(item => ({
      pool_address: item[0],
      contract_version: item[1].toString()
    }))
  }
}

export default Self