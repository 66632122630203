<template>
  <div class="pa-1 br-20 gradient-border-v2">

    <!-- NOT CONNECTED -->
    <div v-if="!sEthers.coinbase" class="v-card br-20 text-center pa-6">
      <div>
        <v-icon size="40" color="textFaint">mdi-account-circle</v-icon>
        <div class="textFaint--text caption pt-2">
          Connect your wallet to view your tokens
        </div>
      </div>
      <div class="pt-2">
        <v-btn
        large
        block
        outlined
        color="navcolor"
        class="white--text"
        rounded
        @click="connectWallet"
        >
          CONNECT
        </v-btn>
      </div>
    </div>
    <!-- NOT CONNECTED -->

    <div v-else class="v-card br-20">
      <div class="">
        <div class="pa-4 border-b text-center">
          Your UNCX Minter tokens
        </div>
        <div class="d-flex flex-row">
          <div class="align-center d-flex flex pa-2">
            <v-btn large rounded text @click="$root.$dialog.chainSwitcher.open('taxToken')" class="foreground title border">
              <img
              :src="$settings.CHAINS[$store.state.requiredNetwork].icon"
              height="24px"
              width="24px"
              class="mr-3 br-20">
              {{ $settings.CHAIN_DISPLAY_NAME[$store.state.requiredNetwork] }}
              <v-icon small color="">mdi-chevron-down</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div class="d-flex align-center px-4">
              <v-spacer></v-spacer>
              <v-btn v-if="!loading" @click="refresh" icon color="textFaint">
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
              <div v-else style="height: 36px; width: 36px;" class="d-flex align-center justify-center">
                <v-progress-circular
                  indeterminate
                  size="14"
                  width="2"
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="enmtTokens.length === 0 && taxTokens.length === 0 && !loading" class="pa-8">
          No tokens found for your address. If you are sure you minted tokens on this chain
          try waiting a few seconds and then refresh.
        </div>

        <template v-if="enmtTokens.length > 0">
          <div class="caption pa-2 text-center">
            Your ENMT tokens ({{ enmtTokens.length }})
          </div>
          <div style="overflow-y: hidden;" class="unselectable py-4 background u-scrollbar d-flex mb-8">
            <div 
            v-for="token of enmtTokens" 
            :key="token.id"
            @click="$root.tokenPage.open(token.id, $store.state.requiredNetwork)"
            class="v-card c-list br-c mr-1 d-flex align-center pa-2" style="min-width: 180px;overflow:hidden;">
              <coin-icon :address="token.id" class="mr-1" />
              <div>
                <div class="caption">
                  {{ token.symbol }}
                </div>
                <div class="caption">
                  {{ $root.formatAmount(token.totalSupply, token.decimals) }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <v-fade-transition mode="out-in">
          <div class="">
            <div class="">
              <div v-if="firstLoad" class="text-center py-4">
                <v-progress-circular
                  indeterminate
                  size="60"
                  width="2"
                  color="#aaa"
                >
                  <img
                    src="@/assets/img/UNCX_fill.svg"
                    height="50px"
                    class="greyscale"
                    width="50px"
                    alt="unicrypt"
                  >
                </v-progress-circular>
              </div>
              <v-slide-y-transition appear>
                <div v-if="!firstLoad">
                  <template v-if="taxTokens.length > 0">
                    <div class="caption px-4 text-center">
                      Your Tax Tokens ({{ taxTokens.length }})
                    </div>
                    <div class="caption px-4 text-center textFaint--text">
                      Select a tax token for more details.
                    </div>
                    <div style="display: flex; justify-content: space-between; margin: 8px;" class="background">
                      <v-btn 
                        v-if="$vuetify.breakpoint.xs"
                        @click="goPrevious"
                        text
                        :disabled="currentPage - 1 === 0"
                        class="align-self-center"
                        style="height: 46px; min-width: auto; padding: inherit;"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        @click="goPrevious"
                        text
                        :disabled="currentPage - 1 === 0"
                        class="align-self-center"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                      <div
                        v-for="token of taxTokens.slice((currentPage - 1) * 5, currentPage * 5)"
                        :key="token.id"
                        :class="[`d-flex align-center pa-3 c-list ${token}`]"
                        style="flex-direction: column;"
                        @click="selectToken(token.id)"
                      >
                        <coin-icon :address="token.id" />
                        <span :class="`${selectedTokenAddress === token.id ? 'primary--text': ''} caption text-truncate`" style="max-width: 8ch;">
                          {{ token.symbol }}
                        </span>
                      </div>
                      <v-btn 
                        v-if="$vuetify.breakpoint.xs"
                        @click="goNext" 
                        text 
                        :disabled="currentPage * 5 > taxTokens.length" 
                        class="align-self-center"
                        style="height: 46px; min-width: auto; padding: inherit;"
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                      <v-btn 
                        v-else
                        @click="goNext" 
                        text 
                        :disabled="currentPage * 5 > taxTokens.length" 
                        class="align-self-center"
                      >
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </div>
              </v-slide-y-transition>
              <div v-if="selectedTokenIndex !== null && taxTokens[selectedTokenIndex]" class="d-flex align-center m-4 flex-column">
                <div class="pa-4 text-center text-h5" style="width: 100%;">
                  {{ taxTokens[selectedTokenIndex].symbol }} Token
                </div>
                <div class="px-4 text-center">
                  <copy-address :address="tokenTaxValues.id" color="textFaint"></copy-address>
                  <v-btn small color="textFaint" text :href="`${$settings.ETHERSCAN_URL[$store.state.requiredNetwork]}/address/${tokenTaxValues.id}`" target="_blank">
                    {{ $settings.CHAINS[$store.state.requiredNetwork].explorerName }}
                    <v-icon x-small>mdi-arrow-top-right</v-icon>
                  </v-btn>
                </div>

                <v-flex class="d-flex pa-4" style="width: 100%;">
                  <v-col cols="2">
                    <div class="mb-2 mt-2 font-weight-bold">
                      Owner
                    </div>
                  </v-col>
                  <v-col cols="7">
                    <div class="d-flex align-center inputcolor pa-2 r-outlined">
                      <c-input
                        :value.sync="ownershipAddress"
                        title="Ownership"
                        placeholder="Address..."
                        disabled="true" 
                      />
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                        rounded block depressed 
                        color="primary"
                        class="br-20 gradient-button-v1"
                        @click="renounceOwnership(false)"
                      >
                      Renounce
                    </v-btn>
                  </v-col>
                </v-flex>
                <v-flex class="d-flex pb-4" style="width: 75%;">
                  <v-row class="d-flex justify-space-around pa-4">
                    <div style="justify-content: flex-end; width: 48%;">
                      <v-btn
                          rounded block depressed 
                          color="primary"
                          class="br-20 gradient-button-v1"
                          @click="showMintDialog"
                          :disabled="!tokenTaxValues.taxSettings.canMint"
                        >
                        Mint
                      </v-btn>
                    </div>
                    <div style="justify-content: flex-end; width: 48%;">
                      <v-btn
                          rounded block depressed 
                          color="primary"
                          class="br-20 gradient-button-v1"
                          @click="showBurnDialog"
                        >
                        Burn
                      </v-btn>
                    </div>
                  </v-row>
                </v-flex>
                <v-flex class="mb-15 mt-2 d-flex" style="width: 30%;">
                    <v-row>
                      <div style="justify-content: flex-end; width: 100%;">
                        <v-btn
                            v-if="tokenTaxValues.isPaused"
                            rounded block depressed 
                            color="primary"
                            class="br-20 gradient-button-v1"
                            @click="pause"
                            :disabled="!tokenTaxValues.taxSettings.canPause"
                          >
                          Resume
                        </v-btn>
                        <v-btn
                            v-if="!tokenTaxValues.isPaused"
                            rounded block depressed 
                            color="primary"
                            class="br-20 gradient-button-v1"
                            @click="pause"
                            :disabled="!tokenTaxValues.taxSettings.canPause"
                          >
                          Pause
                        </v-btn>
                      </div>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-card v-if="tokenTaxValues.isPaused" class="pa-4 br-20">
                          Pause Status:
                          <br>
                          &nbsp;
                          <span
                            class="d-inline-block"
                            style="height: 10px; width: 10px; border-radius: 50%; background-color: red;"
                          />
                          Paused
                        </v-card>
                        <v-card v-else class="pa-4 br-20">
                          Pause Status:  
                          <br>
                          &nbsp;
                          <span
                            class="d-inline-block"
                            style="height: 10px; width: 10px; border-radius: 50%; background-color: green;"
                          />
                          Trading
                        </v-card>
                      </v-col>
                    </v-row>
                </v-flex>
                <v-slide-y-transition appear>
                  <div style="width: 100%;" >
                    <v-slide-group
                      class="mb-2 foreground textFaint--text font-weight-medium gradient-border-v2"
                      style="width: 100%;"
                      show-arrows
                    >
                      <v-slide-item :class="['v-card', {'mt-1': tab === 'TAXES'}, {'mb-1': tab === 'ANTIBOT'}]" style="margin-right: 3px!important;">
                        <div
                          @click="tab = 'TAXES'"
                          :class="['py-3 px-6 c-list', {'primary--text': tab === 'TAXES'}]"
                          style="width: 100%;"
                        >
                          <div class="text-center">
                            TAXES
                          </div>
                        </div>
                      </v-slide-item>
                      <v-slide-item :class="['v-card', {'mt-1': tab === 'ANTIBOT'}, {'mb-1': tab === 'TAXES'}]" style="margin-left: 3px!important;">
                        <div
                          @click="tab = 'ANTIBOT'"
                          :class="['py-3 px-6 c-list', {'primary--text': tab === 'ANTIBOT'}]"
                          style="width: 100%;"
                        >
                          <div class="text-center">
                            ANTIBOT
                          </div>
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </div>
                </v-slide-y-transition>
                <v-flex v-if="tab === 'TAXES'" class="d-flex pa-4 flex-column">
                  <token-tax-form
                    ref="tokenTaxFormComponent"
                    :initial-values="tokenTaxValues"
                    :isAdmin="true"
                    :key="selectedTokenAddress"
                    @on-change="handleTaxFormChange"
                  />
                  <v-card outlined class="background d-flex align-center pa-5 ma-3 br-4 flex-column">
                    <v-row>
                      <div class="text-center pa-4 font-weight-medium mb-0" style="width: 100%;">
                        Wallet Max Balance
                        <v-tooltip top max-width="24em">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              small
                              v-bind="attrs"
                              v-on="on"
                              class="ml-1"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>Sets a maximum balance for all accounts excluding those whitelisted.</span>
                        </v-tooltip>
                      </div>
                      <v-col cols="6">
                        <div class="d-flex align-center inputcolor pa-2 mb-3 r-outlined">
                          <c-input
                            :value.sync="tokenTaxValues.maxBalanceAfterBuy"
                            title="Initial Max Balance"
                          />
                          {{tokenTaxValues.symbol}}
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="d-flex align-center pa-2 align-center">
                          <v-btn
                            rounded block depressed
                            color="primary"
                            @click="showAntibotWhitelistDialog"
                            class="align-self-center pa-0 br-20 gradient-button-v1"
                            :disabled="isLockedDisabled.maxBalanceAfterBuyButton"
                          >
                            Whitelist Addresses
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="6" class="pt-0">
                        <div class="d-flex flex-row justify-space-between align-center font-weight-medium" style="padding: 8px; margin-right: 8px;">
                          On/Off
                          <v-switch
                            v-model="tokenTaxValues.taxSettings.maxBalanceAfterBuy"
                            color="primary"
                            class="ma-0"
                            hide-details
                            style="align-self: center;"
                            :disabled="isLockedDisabled.maxBalanceAfterBuy"
                          />
                        </div>
                      </v-col>
                      <v-col cols="6" class="pt-0">
                        <div class="d-flex flex-row justify-space-between align-center font-weight-medium" style="padding: 8px; margin-right: 8px;">
                          Lock
                          <v-switch
                            v-model="tokenTaxValues.lockedSettings.maxBalanceAfterBuy"
                            color="red"
                            class="ma-0"
                            hide-details
                            style="align-self: center;"
                            :disabled="isLockedDisabled.maxBalanceAfterBuy"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="background d-flex align-center pa-5 ma-3 br-4 flex-column">
                    <v-row>
                      <div class="text-center pa-4 font-weight-medium mb-0" style="width: 100%;">
                        Blacklisting
                        <v-tooltip top max-width="24em">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="primary"
                                small
                                v-bind="attrs"
                                v-on="on"
                                class="mb-2 ml-1"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>Updates blacklists if active.</span>
                        </v-tooltip>
                      </div>
                      <v-col cols="12">
                        <div class="d-flex align-center pa-2 align-center">
                          <v-btn
                              rounded block depressed
                              color="primary"
                              @click="showBlacklistDialog"
                              class="align-self-center pa-0 br-20 gradient-button-v1"
                              :disabled="isLockedDisabled.canBlacklistButton"
                          >
                            Blacklist Addresses
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-card outlined class="background d-flex align-center pa-5 ma-3 br-4 flex-column">
                    <v-row>
                      <div class="text-center pa-4 font-weight-medium mb-0" style="width: 100%;">
                        Taxed LP Tokens
                        <v-tooltip top max-width="24em">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="primary"
                                small
                                v-bind="attrs"
                                v-on="on"
                                class="mb-2 ml-1"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>Approved LP Tokens in which you would like to tax buys/sells.</span>
                        </v-tooltip>
                      </div>
                      <v-col cols="12">
                        <div class="d-flex align-center pa-2 align-center">
                          <v-btn
                              rounded block depressed
                              color="primary"
                              @click="showLPTokensDialog"
                              class="align-self-center pa-0 br-20 gradient-button-v1"
                          >
                            LP Tokens
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-flex>
                <v-flex v-if="tab === 'ANTIBOT' && antiBotCheck" class="d-flex pa-4 flex-column">
                  <div class="text-center pa-4 text-h6 mb-0">
                    AntiBot Features
                  </div>
                  <div class="mb-8 py-1 px-16">
                    Our unique AntiBot features allow you to control your presale chart for the first 48 hours after market initalization. Feel free to set up a maximum holder balance, how it increases over time and whitelist specific wallets to be able to trade - like your own presale contributors!
                  </div>
                  <v-card outlined class="background d-flex align-center pa-5 ma-3 br-4 flex-column">
                    <max-balance-settings :key="selectedTokenAddress" :initial-values="tokenTaxValues.antiBotSettings" :symbol="tokenTaxValues.symbol"/>
                    <div class="border-t pb-6" style="width: 100%; margin-top: 40px;" />
                    <whitelist-settings 
                      :initial-values="tokenTaxValues.swapWhitelistingSettings"
                      :symbol="tokenTaxValues.symbol"
                      :showWhitelistEnabled="true"
                      :key="selectedTokenAddress"
                      @open-whitelist="showWhitelistDialog"
                    />
                  </v-card>
                </v-flex>
                <v-flex v-if="tab === 'ANTIBOT' && !antiBotCheck" class="d-flex pa-4 flex-column">
                  <div class="text-center pa-4 text-h6 mb-0">
                    AntiBot Features
                  </div>
                  <div class="mb-8 py-1 px-16">
                    Our unique AntiBot features allow you to control your presale chart for the first 48 hours after market initalization. Feel free to set up a maximum holder balance, how it increases over time and whitelist specific wallets to be able to trade - like your own presale contributors!
                  </div>

                  <div class="mb-8 py-1 px-16 justify-center red br-20">
                    It has been more than 48 hours after market initialization, meaning AntiBot features are no longer available.
                  </div>
                  <v-card outlined class="background d-flex align-center pa-5 ma-3 br-4 flex-column">
                    <max-balance-settings :key="selectedTokenAddress" :initial-values="tokenTaxValues.antiBotSettings" :symbol="tokenTaxValues.symbol"/>
                    <div class="border-t pb-6" style="width: 100%; margin-top: 40px;" />
                    <whitelist-settings 
                      :initial-values="tokenTaxValues.swapWhitelistingSettings"
                      :symbol="tokenTaxValues.symbol"
                      :showWhitelistEnabled="true"
                      :key="selectedTokenAddress"
                      @open-whitelist="showWhitelistDialog"
                    />
                  </v-card>
                </v-flex>
                <v-row v-if="!$vuetify.breakpoint.xs" class="pa-4 ma-0 font-weight-medium align-center">
                  <div style="display: flex; flex-direction: row; justify-content: space-between; width: 450px;">
                    <div style="justify-content: flex-start; width: 48%;">
                      <v-btn
                        rounded block depressed large
                        color="secondary"
                        dark
                        @click="cancel"
                      >
                        Cancel
                      </v-btn>
                    </div>
                    <div v-if="tab === 'TAXES'" style="justify-content: flex-end; width: 48%;">
                      <v-btn
                        rounded block depressed large
                        color="primary"
                        class="br-20 gradient-button-v1"
                        @click="adminMulticall"
                        :loading="createLoading"
                      >
                        Confirm Admin Changes
                      </v-btn>
                    </div>
                    <div v-if="tab === 'ANTIBOT'" style="justify-content: flex-end; width: 48%;">
                      <v-btn
                        rounded block depressed large
                        color="primary"
                        class="br-20 gradient-button-v1"
                        @click="antiBotMulticall"
                        :disabled="!antiBotCheck"
                        :loading="antibotTxInProcess"
                      > 
                        Confirm AntiBot Changes
                      </v-btn>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.xs" class="pa-4 ma-0 font-weight-medium align-center">
                  <div style="">
                    <div v-if="tab === 'TAXES'" style="justify-content: flex-end;">
                      <v-btn
                        rounded block depressed large
                        color="primary"
                        class="br-20 gradient-button-v1"
                        @click="adminMulticall"
                        :loading="createLoading"
                      >
                        Confirm Admin Changes
                      </v-btn>
                    </div>
                    <div v-if="tab === 'ANTIBOT'" style="justify-content: flex-end;">
                      <v-btn
                        rounded block depressed large
                        color="primary"
                        class="br-20 gradient-button-v1"
                        @click="antiBotMulticall"
                        :disabled="!antiBotCheck"
                        :loading="antibotTxInProcess"
                      > 
                        Confirm AntiBot Changes
                      </v-btn>
                    </div>
                    <br>
                    <div style="justify-content: flex-start;">
                      <v-btn
                        rounded block depressed large
                        color="secondary"
                        dark
                        @click="cancel"
                      >
                        Cancel
                      </v-btn>
                    </div>
                  </div>
                </v-row>
              </div>
            </div>
          </div>
        </v-fade-transition>
      </div>
    </div>
    <warning-modal
      ref="renounceWarningDialog"
      warningText="Renouncing ownership is not reversible."
      alertText="Are you sure you want to continue?"
      @on-confirm="renounceOwnership(true)"
    />
    <address-modal
      ref="whitelistDialog"
      title="Whitelist Addresses"
      label="Whitelist Addresses"
      custom="minter-v2"
      :inputData="tokenTaxValues.swapWhitelist"
      @on-single-add="addSingleSwapWhitelist"
      @on-single-remove="removeSingleSwapWhitelist"
      @on-bulk-add="bulkAddSwapWhitelist"
      @on-bulk-remove="bulkRemoveSwapWhitelist"
    />
    <address-modal
      ref="antibotWhitelistDialog"
      title="Whitelist Addresses"
      label="Whitelist Addresses"
      custom="minter-v2"
      :inputData="tokenTaxValues.maxBalanceWhitelist"
      @on-single-add="addSingleMaxBalanceWhitelist"
      @on-single-remove="removeSingleMaxBalanceWhitelist"
      @on-bulk-add="bulkAddMaxBalanceWhitelist"
      @on-bulk-remove="bulkRemoveMaxBalanceWhitelist"
    />
    <address-modal
      ref="blacklistDialog"
      title="Blacklist Addresses"
      label="Blacklist Addresses"
      custom="minter-v2"
      :inputData="tokenTaxValues.blacklist"
      @on-single-add="addSingleBlacklist"
      @on-single-remove="removeSingleBlacklist"
      @on-bulk-add="bulkAddBlacklist"
      @on-bulk-remove="bulkRemoveBlacklist"
    />
    <address-modal
      ref="lpTokens"
      title="LP Tokens"
      label="LP Tokens"
      custom="lpTokens"
      :inputData="tokenTaxValues.lpTokens"
      @on-single-add="addSingleLPToken"
      @on-single-remove="removeSingleLPToken"
    />
    <mint-modal
      ref="mintDialog"
      title="Mint"
      :symbol="tokenTaxValues.symbol"
      @on-confirm="mint"
    />
    <mint-modal
      ref="burnDialog"
      title="Burn"
      :symbol="tokenTaxValues.symbol"
      @on-confirm="burn"
    />
  </div>
</template>

<script>
import TokenTaxForm from "@/views/minter-v2/token-tax-form";
import WarningModal from "@/components/dialogs/warning-modal";
import AddressModal from '@/components/dialogs/address-modal';
import MintModal from '@/components/dialogs/mint-modal';
import MaxBalanceSettings from '@/components/antibot/max-balance-settings';
import WhitelistSettings from '@/components/antibot/whitelist-settings';
import SETTINGS from '@/store/settings'
import axiosQL from '@/js/axios-graphql-interceptor';
import { ethers } from 'ethers';
import TxWrapper from '@/web3/tx-wrapper-2';
import TaxToken from '@/smart-contracts/minter-v2/tax-token';
// import axiosRetry from 'axios-retry';

export default {

  components: {
    TokenTaxForm,
    WarningModal,
    AddressModal,
    MintModal,
    WhitelistSettings,
    MaxBalanceSettings,
  },

  data: () => ({
    tab: 'TAXES',
    currentPage: 1,
    firstLoad: true,
    loading: true,
    createLoading: false,
    antibotTxInProcess: false,

    selectedTokenIndex: null,
    selectedTokenAddress: null,
    taxTokens: [],
    enmtTokens: [],

    ownershipAddress: '0x00000000000000000000000000000000000000000004',
    // TODO : set from selected token
    tokenTaxValues: {
      id: '',
      symbol: '',
      blacklist: [],
      swapWhitelist: [],
      feeWhitelist: [],
      maxBalanceWhitelist: [],
      lockedSettings: {
        maxBalanceAfterBuy: false,
        canBlacklist: false
      },
      taxSettings: {
        maxBalanceAfterBuy: false,
        canBlacklist: false
      },
      maxBalanceAfterBuy: 0
    },
    isLockedDisabled: {
      maxBalanceAfterBuy: false,
      maxBalanceAfterBuyButton: false,
      canBlacklistButton: false,
      check: false
    }
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    antiBotCheck () {
      if (this.tokenTaxValues.marketInitTimestamp === 0) {
        return true;
      }
      const timestamp = new Date(this.tokenTaxValues.marketInitTimestamp);
      timestamp.setDate(timestamp.getDate() + 2);
      if (Date.now() > timestamp) {
        return false;
      }
      return true;
    },
    network () {
      return this.$store.state.requiredNetwork;
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    },
    network: {
      handler (newData, oldData) {
        this.refresh();
      }
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
          this.switchNetwork()
        })
        .catch(e => {})
    },
    multicallUpdateToken () {

    },
    async syncTokens () {
      // for referrence 
      var userAddress = this.sEthers.coinbase.toLowerCase();
      this.taxTokens = [];
      this.enmtTokens = [];

      var data = {
        query: `
          {
            taxTokens(where: {owner: "${userAddress}"}) {
              id
              name
              symbol
              decimals
              totalSupply
              isLosslessOn
              losslessAdmin {
                id
              }
              losslessRecoveryAdmin {
                id
              }
              lossless
              taxSettings {
                isTransactionTaxOn
                isBuyBackTaxOn
                isHolderTaxOn
                isLpTaxOn
                canBlacklist
                canMint
                canPause
                isMaxBalanceAfterBuyOn
              }
              isLocked {
                isTransactionTaxOn
                isBuyBackTaxOn
                isHolderTaxOn
                isLpTaxOn
                canBlacklist
                canMint
                canPause
                isMaxBalanceAfterBuyOn
              }
              fees {
                transactionTax {
                  buy
                  sell
                }
                buyBackTax
                holderTax
                lpTax
              }
              customTaxLength
              customTaxes {
                id
                name
                fee {
                  buy
                  sell
                }
                wallet {
                  id
                }
              }
              transactionTaxWallet {
                id
              }
              taxHelperIndex
              pairAddress
              lpTokens
              buyBackWallet {
                id
              }
              lpWallet {
                id
              }
              excluded {
                id
              }
              maxBalanceAfterBuy
              antiBotSettings {
                startBlock
                endDate
                increment
                initialMaxHold
                isActive
              }
              swapWhitelistingSettings {
                endDate
                isActive
              }
              owner {
                id
              }
              isPaused
              isTaxed
              buyBackWalletThreshold
              lpWalletThreshold
              blacklist {
    					  id
    					}
    					taxWhitelist {
                id
              }
              swapWhitelist {
                id
              }
    					maxBalanceWhitelist {
    					  id
    					}
              marketInitTimestamp
            }
            enmtTokens(where: {owner: "${userAddress}"}) {
              id
              name
              symbol
              decimals
              totalSupply
            }
          }
        `
      }

      var network = this.$store.state.requiredNetwork;
      var graphExplorer = SETTINGS.CHAINS[network].graphExplorerMinterV2;

      // axiosRetry(axiosQL, { retries: 3 });

      var response = await axiosQL.post(graphExplorer, data, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.data.data.taxTokens !== null) {
        this.taxTokens = response.data.data.taxTokens;
      }
      if (response.data.data.enmtTokens !== null) {
        this.enmtTokens = response.data.data.enmtTokens;
      }
    },
    async refresh () {
      this.loading = true;
      // this.syncTokens();
      try {
        await this.syncTokens();
        // this.selectToken(0);
        this.firstLoad = false;
        this.loading = false;
      } catch (e) {
        this.firstLoad = false;
        this.loading = false;
      }
    },
    selectToken (address) {
      // this.token.values = {};
      const gasTokenDecimals = SETTINGS.CHAINS[this.network].gasToken.decimals;
      this.selectedTokenAddress = address
      this.selectedTokenIndex = this.taxTokens.findIndex((i) => i.id === address);
      const token = this.taxTokens[this.selectedTokenIndex];
      const _customTaxes = [...token.customTaxes];
      if (_customTaxes.length > 0) {
        var index = 0;
        _customTaxes.forEach((i) => {
          i.fee.buy /= 100;
          i.fee.sell /= 100;
          if (token.customTaxes[index].wallet.id) {
            i.wallet = token.customTaxes[index].wallet.id;
          } else {
            i.wallet = token.customTaxes[index].wallet;
          }
          index++;
        })
      }
      this.ownershipAddress = token.owner.id;
      this.tokenTaxValues = {
        id: token.id,
        decimals: token.decimals,
        symbol: token.symbol,
        transactionTaxAddress: token.transactionTaxWallet.id,
        buybackTaxThreshold: ethers.utils.formatUnits(token.buyBackWalletThreshold, gasTokenDecimals),
        lpTaxThreshold: ethers.utils.formatUnits(token.lpWalletThreshold, gasTokenDecimals),

        losslessEnabled: token.isLosslessOn,
        losslessAdminAddress: token.losslessAdmin.id,
        losslessRecoveryAddress: token.losslessRecoveryAdmin.id,
        customTaxes: _customTaxes,
        taxSettings: {
          // ...token.taxSettings,
          transactionTax: token.taxSettings.isTransactionTaxOn,
          buyBackTax: token.taxSettings.isBuyBackTaxOn,
          holderTax: token.taxSettings.isHolderTaxOn,
          lpTax: token.taxSettings.isLpTaxOn,
          canBlacklist: token.taxSettings.canBlacklist,
          canMint: token.taxSettings.canMint,
          canPause: token.taxSettings.canPause,
          maxBalanceAfterBuy: token.taxSettings.isMaxBalanceAfterBuyOn
        },
        lockedSettings: {
          // ...token.isLocked,
          transactionTax: token.isLocked.isTransactionTaxOn,
          buyBackTax: token.isLocked.isBuyBackTaxOn,
          holderTax: token.isLocked.isHolderTaxOn,
          lpTax: token.isLocked.isLpTaxOn,
          canBlacklist: token.isLocked.canBlacklist,
          canMint: token.isLocked.canMint,
          canPause: token.isLocked.canPause,
          maxBalanceAfterBuy: token.isLocked.isMaxBalanceAfterBuyOn

        },
        fees: {
          // ...token.fees,
          transactionTax: {
            buy: token.fees.transactionTax.buy / 100,
            sell: token.fees.transactionTax.sell / 100
          },
          buyBackTax: token.fees.buyBackTax / 100,
          holderTax: token.fees.holderTax / 100,
          lpTax: token.fees.lpTax / 100
        },
        antiBotSettings: {
          startBlock: token.antiBotSettings.startBlock,
          endDate: token.antiBotSettings.endDate,
          increment: ethers.utils.formatUnits(token.antiBotSettings.increment, token.decimals),
          initialMaxHold: ethers.utils.formatUnits(token.antiBotSettings.initialMaxHold, token.decimals),
          isActive: token.antiBotSettings.isActive
        },
        swapWhitelistingSettings: token.swapWhitelistingSettings,
        excluded: token.excluded,
        blacklist: token.blacklist,
        swapWhitelist: token.swapWhitelist,
        feeWhitelist: token.taxWhitelist,
        maxBalanceWhitelist: token.maxBalanceWhitelist,
        maxBalanceAfterBuy: ethers.utils.formatUnits(token.maxBalanceAfterBuy, token.decimals),
        lpTokens: token.lpTokens,
        marketInitTimestamp: token.marketInitTimestamp * 1000,
        isPaused: token.isPaused
      };
      
      this.$emit('on-select-token', this.tokenTaxValues);
      this.updateLocks();
      console.log(this.tokenTaxValues.feeWhitelist);
    },
    handleTaxFormChange (values) {
      this.tokenTaxValues = values;
    },
    goPrevious () {
      this.currentPage--;
    },
    goNext () {
      this.currentPage++;
    },
    renounceOwnership (confirmed = false) {
      if (!confirmed) {
        this.renounceWarningDialog.open();
      } else {
        TxWrapper.doTransaction(
          TaxToken.renounceOwnership,
          [this.taxTokens[this.selectedTokenIndex].id],
          this.$store.state.requiredNetwork,
          this.$root.$dialog.chainSwitch,
          this.$root.$dialog.confirmTx2
        ).then(response => {
          this.refresh();
          this.$emit('hard-refresh');
        }).catch(e => {
          this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message)
        });
      }
    },
    showWhitelistDialog () {
      this.whitelistDialog.open();
    },
    showBlacklistDialog () {
      this.blacklistDialog.open();
    },
    showAntibotWhitelistDialog () {
      this.antibotWhitelistDialog.open();
    },
    showMintDialog () {
      this.mintDialog.open();
    },
    showBurnDialog () {
      this.burnDialog.open();
    },
    showLPTokensDialog () {
      this.lpTokens.open();
    },
    async mint (amount) {
      const num = ethers.utils.parseUnits(amount, this.tokenTaxValues.decimals);
      TxWrapper.doTransaction(
        TaxToken.mint,
        [this.tokenTaxValues.id, num],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
      })
    },
    async burn (amount) {
      const num = ethers.utils.parseUnits(amount, this.tokenTaxValues.decimals);
      TxWrapper.doTransaction(
        TaxToken.burn,
        [this.tokenTaxValues.id, num],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
      })
    },
    async pause () {
      TxWrapper.doTransaction(
        TaxToken.togglePause,
        [this.tokenTaxValues.id],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then((data) => {
        this.createLoading = false;
        if (data.receipt.status === 1) {
          this.tokenTaxValues.isPaused = !this.tokenTaxValues.isPaused;
        }
      })
    },
    async addSingleSwapWhitelist (address) {
      TxWrapper.doTransaction(
        TaxToken.addSwapWhitelistedAddress,
        [this.tokenTaxValues.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async removeSingleSwapWhitelist (address) {
      TxWrapper.doTransaction(
        TaxToken.removeSwapWhitelistedAddress,
        [this.tokenTaxValues.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async bulkAddSwapWhitelist (addresses) {
      TxWrapper.doTransaction(
        TaxToken.updateSwapWhitelistBatch,
        [this.tokenTaxValues.id, addresses, true],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async bulkRemoveSwapWhitelist (addresses) {
      TxWrapper.doTransaction(
        TaxToken.updateSwapWhitelistBatch,
        [this.tokenTaxValues.id, addresses, false],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async addSingleBlacklist (address) {
      TxWrapper.doTransaction(
        TaxToken.addBlacklistedAddress,
        [this.tokenTaxValues.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async removeSingleBlacklist (address) {
      TxWrapper.doTransaction(
        TaxToken.removeBlacklistedAddress,
        [this.tokenTaxValues.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async bulkAddBlacklist (addresses) {
      TxWrapper.doTransaction(
        TaxToken.updateBlacklistBatch,
        [this.tokenTaxValues.id, addresses, true],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async bulkRemoveBlacklist (addresses) {
      TxWrapper.doTransaction(
        TaxToken.updateBlacklistBatch,
        [this.tokenTaxValues.id, addresses, false],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async addSingleMaxBalanceWhitelist (address) {
      TxWrapper.doTransaction(
        TaxToken.addMaxBalanceWhitelistedAddress,
        [this.tokenTaxValues.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async removeSingleMaxBalanceWhitelist (address) {
      TxWrapper.doTransaction(
        TaxToken.removeMaxBalanceWhitelistedAddress,
        [this.tokenTaxValues.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async bulkAddMaxBalanceWhitelist (addresses) {
      TxWrapper.doTransaction(
        TaxToken.updateMaxBalanceWhitelistBatch,
        [this.tokenTaxValues.id, addresses, true],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async bulkRemoveMaxBalanceWhitelist (addresses) {
      TxWrapper.doTransaction(
        TaxToken.updateMaxBalanceWhitelistBatch,
        [this.tokenTaxValues.id, addresses, false],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async addSingleLPToken (address) {
      TxWrapper.doTransaction(
        TaxToken.addLPToken,
        [this.tokenTaxValues.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async removeSingleLPToken (address) {
      TxWrapper.doTransaction(
        TaxToken.removeLPToken,
        [this.tokenTaxValues.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async adminMulticall () {
      const gasTokenDecimals = SETTINGS.CHAINS[this.network].gasToken.decimals
      const _customTaxes = [];
      this.tokenTaxValues.customTaxes.forEach((i) => {
        const _tax = {
          name: i.name,
          fee: {
            buy: i.fee.buy * 100,
            sell: i.fee.sell * 100
          },
          wallet: i.wallet
        }
        _customTaxes.push(_tax);
      })

      // console.log("Custom Taxes");
      // console.log(_customTaxes);

      const _tokenFees = {
        transactionTax: {
          buy: this.tokenTaxValues.fees.transactionTax.buy * 100,
          sell: this.tokenTaxValues.fees.transactionTax.sell * 100,
        },
        buyBackTax: this.tokenTaxValues.fees.buyBackTax * 100,
        holderTax: this.tokenTaxValues.fees.holderTax * 100,
        lpTax: this.tokenTaxValues.fees.lpTax * 100
      }

      const multicallParams = {
        _taxSettings: this.tokenTaxValues.taxSettings,
        _lockSettings: this.tokenTaxValues.lockedSettings,
        _customTaxes: _customTaxes,
        _fees: _tokenFees,
        _transactionTaxWallet: this.tokenTaxValues.transactionTaxAddress,
        _maxBalanceAfterBuy: ethers.utils.parseUnits(this.tokenTaxValues.maxBalanceAfterBuy, this.tokenTaxValues.decimals),
        _lpWalletThreshold: ethers.utils.parseUnits(this.tokenTaxValues.lpTaxThreshold, gasTokenDecimals),
        _buyBackWalletThreshold: ethers.utils.parseUnits(this.tokenTaxValues.buybackTaxThreshold, gasTokenDecimals)
      };

      console.log(multicallParams)
      TxWrapper.doTransaction(
        TaxToken.multicallAdminUpdate,
        [this.tokenTaxValues.id, multicallParams],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      )
        .then(() => {
          this.refresh()
        })
        .catch(e => {
          this.$root.$dialog.web3Error.open(e.message);
        })
        .finally(() => {
          this.createLoading = false;
        })
    },
    async antiBotMulticall () {
      const _antiBotSettings = {
        startBlock: this.tokenTaxValues.antiBotSettings.startBlock,
        endDate: this.tokenTaxValues.antiBotSettings.endDate,
        increment: ethers.utils.parseUnits(this.tokenTaxValues.antiBotSettings.increment, this.tokenTaxValues.decimals),
        initialMaxHold: ethers.utils.parseUnits(this.tokenTaxValues.antiBotSettings.initialMaxHold, this.tokenTaxValues.decimals),
        isActive: this.tokenTaxValues.antiBotSettings.isActive
      }
      const multicallParams = {
        _antiBotSettings: _antiBotSettings,
        _swapWhitelistingSettings: this.tokenTaxValues.swapWhitelistingSettings
      };

      this.antibotTxInProcess = true
      TxWrapper.doTransaction(
        TaxToken.multicallAntiBotUpdate,
        [this.tokenTaxValues.id, multicallParams],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      )
        .then (() => {
          this.refresh()
        })
        .catch(e => {
          this.$root.$dialog.web3Error.open(e.message);
        }).finally(() => {
          this.antibotTxInProcess = false;
        })
    },
    async syncLists () {
      var data = {
        query: `
          {
            taxTokens(where: {id: "${this.tokenTaxValues.id}"}) {
              id
              blacklist {
    					  id
    					}
              swapWhitelist {
                id
              }
              taxWhitelist {
                id
              }
    					maxBalanceWhitelist {
    					  id
    					}
              lpTokens
            }
          }
        `
      }

      var network = this.$store.state.requiredNetwork;
      var graphExplorer = SETTINGS.CHAINS[network].graphExplorerMinterV2;

      var response = await axiosQL.post(graphExplorer, data, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.data.data.taxTokens !== null) {
        this.tokenTaxValues.blacklist = response.data.data.taxTokens[0].blacklist;
        this.tokenTaxValues.swapWhitelist = response.data.data.taxTokens[0].swapWhitelist;
        this.tokenTaxValues.feeWhitelist = response.data.data.taxTokens[0].taxWhitelist;
        this.tokenTaxValues.maxBalanceWhitelist = response.data.data.taxTokens[0].maxBalanceWhitelist;
        this.tokenTaxValues.lpTokens = response.data.data.taxTokens[0].lpTokens;
      }
    },
    cancel () {
      this.syncTokens().then(() => {
        const address = this.tokenTaxValues.id;
        this.selectToken(address);
      })
    },
    updateLocks () {
      if (this.tokenTaxValues.lockedSettings.maxBalanceAfterBuy) {
        this.isLockedDisabled.maxBalanceAfterBuy = true;
      }
      if (this.tokenTaxValues.lockedSettings.maxBalanceAfterBuy && !this.tokenTaxValues.taxSettings.maxBalanceAfterBuy) {
        this.isLockedDisabled.maxBalanceAfterBuyButton = true;
      }
      if (this.tokenTaxValues.lockedSettings.canBlacklist && !this.tokenTaxValues.taxSettings.canBlacklist) {
        this.isLockedDisabled.canBlacklistButton = true;
      }
    },
  },
  created () {
    this.refresh();
  },
  beforeMount () {
    if (!this.isLockedDisabled.check) {
      this.updateLocks();
      this.isLockedDisabled.check = true;
    } 
  },
  mounted () {
    this.renounceWarningDialog = this.$refs.renounceWarningDialog;
    this.whitelistDialog = this.$refs.whitelistDialog;
    this.blacklistDialog = this.$refs.blacklistDialog;
    this.antibotWhitelistDialog = this.$refs.antibotWhitelistDialog;
    this.mintDialog = this.$refs.mintDialog;
    this.burnDialog = this.$refs.burnDialog;
    this.lpTokens = this.$refs.lpTokens;
  }
}
</script>