import {store} from '@/store/index';
import { ethers } from "ethers";
import GENERATOR_ABI from './abis'

const Self = {
  createStakePool: (_countryCode, _decimals, _ethCreationFee) => {
    var sEthers = store.state.ethers
    const StakePoolGenerator = new ethers.Contract(GENERATOR_ABI.stake_pool_generator(), GENERATOR_ABI.stake_pool_generator_abi, sEthers.signer)
    var res = StakePoolGenerator.createStakePool(_countryCode, _decimals, {value: _ethCreationFee})
    return res
  },
}

export default Self