<template>
  <span>

    <v-btn small icon color="textFaint" rounded :href="`https://www.geckoterminal.com/${chainSlug['Geckoterminal'][network]}/tokens/${token_address}`" target="_blank">
      <img 
      :src="$root.$icons.gecko_terminal" 
      height="20px"
      width="20px"
      class="mr-1">
    </v-btn>

    <v-btn v-if="dextChain" small icon color="textFaint" rounded :href="`https://www.dextools.io/app/${dextChain}/pair-explorer/${token_address}`" target="_blank">
      <img 
      :src="$root.$icons.dext" 
      height="20px"
      width="20px"
      class="mr-1">
    </v-btn>

    <!-- <coinstats-button :network="network" :token_address="token_address"></coinstats-button> -->

    <!-- <v-btn v-if="exchange === 'Pancakeswap V2'" small icon color="textFaint" rounded :href="`https://poocoin.app/tokens/${token_address}`" target="_blank">
      <img 
      :src="$root.$icons.poocoin" 
      height="20px"
      width="20px"
      class="mr-1">
    </v-btn> -->

    <!-- <v-btn v-if="network === 'BSC_MAINNET'" small icon color="textFaint" rounded :href="`https://charts.bogged.finance/?c=bsc&t=${token_address}`" target="_blank">
      <img 
      :src="$root.$icons.bogged" 
      height="20px"
      width="20px"
      class="mr-1">
    </v-btn> -->

  </span>
</template>

<script>
// import CoinstatsButton from '@/components/linkers/coinstats'

export default {
  props: {
    network: null,
    token_address: null,
    exchange: null
  },

  components: {
    // CoinstatsButton
  },

  data: () => ({
    chainSlug: {
      'Geckoterminal': {
        'Mainnet': 'eth',
        'BSC_MAINNET': 'bsc',
        'AVAX': 'avax',
        'DogeChain': 'dogechain',
        'Matic': 'polygon_pos',
        'ArbitrumOne': 'arbitrum',
      },
      'Dexscreener': {
        'Mainnet': 'ethereum',
        'BSC_MAINNET': 'bsc',
        'AVAX': 'avalanche',
        'DogeChain': 'dogechain',
        'Matic': 'polygon',
      }
    }
  }),

  computed: {
    dextChain () {
      if (this.network === 'Mainnet') {
        return 'ether'
      } else if (this.network === 'BSC_MAINNET') {
        return 'bsc'
      } else if (this.network === 'Matic') {
        return 'polygon'
      }
      return null
    },
  },

  methods: {
  },

  created () {
  }
}
</script>