<template>

  <div class="v-card br-20 pa-6">
    <div>
      Drain token
    </div>

    <div class="mb-2 mt-2 textFaint--text">
      Enter a token address for a token you wish to withdraw from the reward pool contract
      <span v-if="!reward_pool.allow_drain_primary">
        (<span class="orange--text">Except {{ reward_pool.reward_token.symbol }}</span> which is blocked from withdrawls)
      </span>
    </div>
    <load-token @on-update="updateToken"></load-token>

    <template v-if="tokenHydrated.address">
      <div class="pa-4 align-center flex-nowrap inputcolor r-outlined">

        <div class="caption text-end pt-2">
          Balance: {{ $root.formatAmount(contractBalance, tokenHydrated.decimals) }}
        </div>

        <div class="d-flex align-center">
          <c-input :value.sync="withdrawAmountHuman" placeholder="0.0" @updateWhileFocussed="onAmountChanged" class="pa-2 font-weight-bold">

          </c-input>

          <v-btn small rounded outlined @click.native="$root.tokenPage.open(tokenHydrated.address, $store.state.requiredNetwork)" style="min-width: 10px;">
            <span :class="['text-truncate']" :style="$vuetify.breakpoint.xs ? 'max-width: 14ch;' : 'max-width: 20ch;'">
              {{ tokenHydrated.symbol }}
            </span>
          </v-btn>

          <v-btn rounded small depressed color="lgrad-blue" @click="setMax" class="ml-1 white--text">
            MAX
          </v-btn>
        </div>

      </div>

      <v-btn rounded class="white--text" color="lgrad-blue" @click="withdraw">
        Withdraw
      </v-btn>
    </template>

  </div>

</template>

<script>
import { ethers } from 'ethers'
import ERC20BALANCE from '@/smart-contracts/erc20'
import TxWrapper from '@/web3/tx-wrapper-2';
import RewardPoolContract from '@/smart-contracts/farms-v2/normal/reward-pool/v1/reward-pool'

export default {

  props: {
    address: null,
    reward_pool: {
      type: Object,
      default: () => {
        return {
          reward_token: {}
        }
      }
    }
  },

  components: {
  },

  data: () => ({
    tokenHydrated: '',
    contractBalance: '0',
    withdrawAmount: '0',
    withdrawAmountHuman: '0',
  }),

  computed: {
  },

  watch: {
  },

  methods: {
    updateToken (token) {
      this.tokenHydrated = token;
      this.getContractBalance()
    },
    onAmountChanged (_val) {
      try {
        this.withdrawAmount = ethers.utils.parseUnits(_val, this.tokenHydrated.decimals).toString()
      } catch (e) {
        this.withdrawAmount = '0'
      }
    },
    setMax () {
      this.withdrawAmount = this.contractBalance
      this.withdrawAmountHuman = ethers.utils.formatUnits(this.withdrawAmount, this.tokenHydrated.decimals)
    },
    async getContractBalance () {
      this.contractBalance = await ERC20BALANCE.getBalance(this.address, this.tokenHydrated.address)
    },
    async withdraw () {
      TxWrapper.doTransaction(RewardPoolContract.drainToken,
        [this.address, this.tokenHydrated.address, this.withdrawAmount], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(response => {
          this.getContractBalance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
        })
    },
  },

  created () {
  }
}
</script>