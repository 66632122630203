import {store} from '@/store/index';
import { ethers } from "ethers";
import ABI from './abis'
import PROVIDERS from '@/web3/providers'

const Self = {
  getNumStakers: async (_stake_pool, network = null) => {
    var provider = network ? PROVIDERS[network] : store.state.ethers.provider
    const StakePool = new ethers.Contract(ABI.stake_pool_pager(network), ABI.stake_pool_pager_abi, provider)
    var res = await StakePool.getNumStakers(_stake_pool)
    return res.toNumber()
  },
  getStakers: async (_stake_pool, _start, _count, network = null) => {
    var provider = network ? PROVIDERS[network] : store.state.ethers.provider
    const StakePool = new ethers.Contract(ABI.stake_pool_pager(network), ABI.stake_pool_pager_abi, provider)
    var res = await StakePool.getStakers(_stake_pool, _start, _count)
    return res.map(res => ({
      user_address: res[0],
      share_weight: res[1].toString(),
      time_boost_percentage: res[2].toString(),
      uncl_boost_percentage: res[3].toString(),
      subscriptions_length: res[4].toNumber()
    }))
  },
  getNumRewardStakers: async (_stake_pool, network = null) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(ABI.stake_pool_pager(network), ABI.stake_pool_pager_abi, sEthers.provider)
    var res = await StakePool.getNumRewardStakers(_stake_pool)
    return res.toString()
  },
  getRewardStakers: async (_stake_pool, _start, _count, network = null) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(ABI.stake_pool_pager(network), ABI.stake_pool_pager_abi, sEthers.provider)
    var res = await StakePool.getRewardStakers(_stake_pool, _start, _count)
    return res.map(res => ({
      user_address: res[0],
      share_weight: res[1].toString(),
      reward_debt: res[2].toString(),
      unharvested_shares: res[3].toString(),
      pending_reward: res[4].toString()
    }))
  },
  getNumRewardPools: async (_stake_pool, network = null) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(ABI.stake_pool_pager(network), ABI.stake_pool_pager_abi, sEthers.provider)
    var res = await StakePool.getNumRewardPools(_stake_pool)
    return res.toString()
  },
  getRewardPools: async (_stake_pool, _user, _start, _count, network = null) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(ABI.stake_pool_pager(network), ABI.stake_pool_pager_abi, sEthers.provider)
    var res = await StakePool.getRewardPools(_stake_pool, _user, _start, _count)
    return res.map(item => ({
      pool_address: item[0],
      contract_version: item[1].toString(),
      user_share_weight: item[2].toString(),
      unharvested: item[3].toString(),
      farm_state: item[4].toNumber(),
    }))
  },
  getNumUserSubscriptions: async (_stake_pool, _user, network = null) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(ABI.stake_pool_pager(network), ABI.stake_pool_pager_abi, sEthers.provider)
    var res = await StakePool.getNumUserSubscriptions(_stake_pool, _user)
    return res.toString()
  },
  getUserSubscriptions: async (_stake_pool, _user, _start, _count, network = null) => {
    var sEthers = store.state.ethers
    const StakePool = new ethers.Contract(ABI.stake_pool_pager(network), ABI.stake_pool_pager_abi, sEthers.provider)
    var res = await StakePool.getUserSubscriptions(_stake_pool, _user, _start, _count)
    return res.map(item => ({
      pool_address: item[0],
      contract_version: item[1].toString()
    }))
  },
}

export default Self