import { render, staticRenderFns } from "./tax-token-success-dialog.vue?vue&type=template&id=97ae8b42&scoped=true&"
import script from "./tax-token-success-dialog.vue?vue&type=script&lang=js&"
export * from "./tax-token-success-dialog.vue?vue&type=script&lang=js&"
import style0 from "./tax-token-success-dialog.vue?vue&type=style&index=0&id=97ae8b42&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97ae8b42",
  null
  
)

export default component.exports