import {store} from '@/store/index';
import { ethers } from "ethers";
import ABI from './abis'

const Self = {
  addTokenAdminToWhitelist: (_token, _admin) => {
    var sEthers = store.state.ethers
    const EcosystemWhitelist = new ethers.Contract(ABI.ecosystem_whitelist(), ABI.ecosystem_whitelist_abi, sEthers.signer)
    var res = EcosystemWhitelist.addTokenAdminToWhitelist(_token, _admin)
    return res
  },
  removeTokenAdminFromWhitelist: (_token, _admin) => {
    var sEthers = store.state.ethers
    const EcosystemWhitelist = new ethers.Contract(ABI.ecosystem_whitelist(), ABI.ecosystem_whitelist_abi, sEthers.signer)
    var res = EcosystemWhitelist.removeTokenAdminFromWhitelist(_token, _admin)
    return res
  },
  chargeFee: async (_token, _user) => {
    var sEthers = store.state.ethers
    const EcosystemWhitelist = new ethers.Contract(ABI.ecosystem_whitelist(), ABI.ecosystem_whitelist_abi, sEthers.provider)
    var res = await EcosystemWhitelist.chargeFee(_token, _user)
    return Boolean(res)
  },
  getNumTokens: async () => {
    var sEthers = store.state.ethers
    const EcosystemWhitelist = new ethers.Contract(ABI.ecosystem_whitelist(), ABI.ecosystem_whitelist_abi, sEthers.provider)
    var res = await EcosystemWhitelist.getNumTokens()
    return res.toNumber()
  },
  getNumAdminsForToken: async (_token) => {
    var sEthers = store.state.ethers
    const EcosystemWhitelist = new ethers.Contract(ABI.ecosystem_whitelist(), ABI.ecosystem_whitelist_abi, sEthers.provider)
    var res = await EcosystemWhitelist.getNumAdminsForToken(_token)
    return res.toNumber()
  },
  getPagedTokens: async (_start, _count) => {
    var sEthers = store.state.ethers
    const EcosystemWhitelist = new ethers.Contract(ABI.ecosystem_whitelist(), ABI.ecosystem_whitelist_abi, sEthers.provider)
    var res = await EcosystemWhitelist.getPagedTokens(_start, _count)
    return res
  },
  getPagedAdmins: async (_token, _start, _count) => {
    var sEthers = store.state.ethers
    const EcosystemWhitelist = new ethers.Contract(ABI.ecosystem_whitelist(), ABI.ecosystem_whitelist_abi, sEthers.provider)
    var res = await EcosystemWhitelist.getPagedAdmins(_token, _start, _count)
    return res
  },
}

export default Self