<template>

  <div>
    <div v-if="firstLoad">
      <div class="text-center mt-6" style="height: 80vh;">
        <v-progress-circular
        indeterminate
        size="130"
        color="#aaa">
          <img 
          src="@/assets/img/UNCX_fill.svg" 
          height="120px"
          class="greyscale"
          width="120px">
        </v-progress-circular>
        <div class="pt-4 textFaint--text">
          Loading Stake Pool info
        </div>
      </div>
    </div>

    <template v-else>

      <div :class="[{'mt-2': !$vuetify.breakpoint.xs}]">

        <div :style="`background-image: url(${apiData.meta.media.banner ? apiData.meta.media.banner : $root.$icons.farm_image});background-repeat: no-repeat;background-size: cover;background-position: top;min-height: ${$vuetify.breakpoint.xs ? '160px' : '400px'};`" :class="['v-card', {'br-20': !$vuetify.breakpoint.xs}]">
        </div>

        <div class="d-flex align-center pb-3 px-4" style="position: absolute;margin-top: -40px;">
          <template v-if="!apiData.meta.lp_meta">
            <div class="pa-05 white br-c" style="width: 62px; height: 62px;">
              <coin-icon :address="apiData.stoken_address" :url="apiData.meta.media.icon" :size="58"></coin-icon>
            </div>
          </template>
          <div v-else class="d-flex align-center">
            <div class="pa-05 white br-c" style="width: 62px; height: 62px;">
              <coin-icon :address="apiData.meta.lp_meta.token0.address" :size="58"></coin-icon>
            </div>
            <div class="pa-05 white br-c" style="width: 52px; height: 52px;margin-left: -20px;">
              <coin-icon :address="apiData.meta.lp_meta.token1.address" :size="48"></coin-icon>
            </div>
          </div>
          <!-- <coin-icon :address="pool_info.staking_token.address" :size="64" class=""></coin-icon> -->
        </div>

        <div class="pa-2 d-flex justify-end align-center midground br-c" style="position: absolute;margin-top: -25px;right: 10px;">
          <v-btn icon small text @click="expandSettings = true" color="textFaint">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <v-btn small icon text color="textFaint" @click="refresh" class="ml-2">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>

        <div class="ml-5 flex pt-8">
          <span class="display-1 font-weight-bold hover-primary" @click.stop="$root.tokenPage.open(pool_info.staking_token.address, $store.state.requiredNetwork)">
            <template v-if="!apiData.meta.lp_meta">
              {{ apiData.stoken_symbol }}
            </template>
            <template v-else>
              {{ apiData.meta.lp_meta.token0.symbol }}
              -
              {{ apiData.meta.lp_meta.token1.symbol }}
            </template>
          </span>
        </div>

        <v-row class="ma-0 px-2" dense>
          <v-col cols="6" md="2">
            <div class="v-card br-8 pa-4" style="height: 100%;">
              <div class="caption primary--text font-weight-medium">
                APR
              </div>
              <div :class="['primary--text font-weight-bold title', {'title font-weight-bold': !$vuetify.breakpoint.xs}, {'': $vuetify.breakpoint.xs}]">
                {{ (apiData.apy || 0).toFixed(2) }}%
              </div>
            </div>
          </v-col>
          <v-col cols="6" md="2">
            <div class="v-card br-8 pa-4" style="height: 100%;">
              <div class="caption textFaint--text font-weight-medium">
                TVL
              </div>
              <div :class="['font-weight-bold textFaint--text title', {'title font-weight-bold': !$vuetify.breakpoint.xs}, {'': $vuetify.breakpoint.xs}]">
                ${{ $root.formatDollars(apiData.tvl) }}
              </div>
            </div>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.xs" cols="6" md="3">
            <div class="v-card br-8 pa-4" style="height: 100%;">
              <div class="caption textFaint--text font-weight-medium">
                STAKING
              </div>
              <div :class="['font-weight-medium d-flex align-center textFaint--text', {'title font-weight-bold': !$vuetify.breakpoint.xs}, {'': $vuetify.breakpoint.xs}]">
                <div class="text-truncate mr-1" style="max-width: 10ch;">
                  {{ $root.formatAmount(stakePoolBalance, pool_info.staking_token.decimals) }}
                </div>
                <span>
                  {{ pool_info.staking_token.symbol }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="6" md="2">
            <div class="v-card br-8 pa-4" style="height: 100%;">
              <div class="caption textFaint--text font-weight-medium">
                STAKERS
              </div>
              <div :class="['textFaint--text d-flex align-center title font-weight-bold', {'title font-weight-bold': !$vuetify.breakpoint.xs}, {'': $vuetify.breakpoint.xs}]">
                {{ pool_info.num_stakers }}
                <v-spacer></v-spacer>
                <v-btn small icon @click="expandStakerSheet = true" class="background">
                  <v-icon small color="textFaint" class="ml-1">mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="6" md="3">
            <div class="v-card br-8 pa-4" style="height: 100%;">
              <div class="caption textFaint--text font-weight-medium">
                LIMITS
              </div>
              <div :class="['textFaint--text', {'title': !$vuetify.breakpoint.xs}, {'': $vuetify.breakpoint.xs}]">
                <span class="text-end pr-1">
                {{ $root.formatAmount(pool_info.min_stake_amount, pool_info.staking_token.decimals) }}
                </span>
                -
                <span class="text-start pl-1">
                  <template v-if="maxStakeAmountIsMaxUint">
                    <v-icon color="textFaint">mdi-infinity</v-icon>
                  </template>
                  <template v-else>
                    {{ $root.formatAmount(pool_info.max_stake_amount, pool_info.staking_token.decimals) }}
                  </template>
                </span>
              </div>
            </div>
          </v-col>
        </v-row>
        
        <div v-if="false" class="px-4 d-flex align-center text-uppercase pt-4">
          <div>
            <div class="textFaint--text">
              Stake Pool
            </div>
            <div class="display-1 font-weight-medium">
              {{ pool_info.staking_token.name }}
              <v-btn color="foreground" class="px-2 py-5" rounded depressed @click.native="$root.tokenPage.open(pool_info.staking_token.address, $store.state.requiredNetwork)" style="min-width: 10px;max-height: unset;">
                <coin-icon :address="pool_info.staking_token.address" :size="24" class="mr-1"></coin-icon>
                <v-icon small>mdi-chevron-down</v-icon>
              </v-btn>
            </div>
          </div>
          <v-spacer></v-spacer>
        </div>

        <v-tabs v-if="false" center-active show-arrows color="textFaint" background-color="midground" class="br-0">
          <v-tab>
            STAKERS
          </v-tab>
          <v-tab v-if="false">
            INFO
          </v-tab>
          <v-tab>
            SETTINGS
          </v-tab>

          <v-tab-item>
            <div>
              <staker-view :stake_pool_address="address" :tokenHydrated="pool_info.staking_token" :pool_info="pool_info"></staker-view>
            </div>
          </v-tab-item>

          <v-tab-item v-if="false">
            <div>

              <div>
                {{ pool_info }}
              </div>

              <div class="mt-4">
                User info: {{ user_info }}
              </div>
            </div>
          </v-tab-item>

          <v-tab-item>
            <div>
              <settings-panel :address="address" :pool_info="pool_info" @hard-refresh="refresh" @api-refresh="fetchAPI"></settings-panel>
            </div>
          </v-tab-item>
        </v-tabs>
        
        <div v-if="!$vuetify.breakpoint.xs && false" :class="['mt-4 br-20', {'v-card': !$store.state.darkMode}]">

          <div class="d-flex align-center py-2">
            <coin-icon :address="pool_info.staking_token.address" :size="60" class="mx-2"></coin-icon>
            <v-row dense class="ma-0">
              <v-col cols="3">
                <div class="caption textFaint--text">
                  STAKING
                </div>
                <div class="">
                  {{ $root.formatAmount(stakePoolBalance, pool_info.staking_token.decimals) }}
                  {{ pool_info.staking_token.symbol }}
                </div>
              </v-col>
              <v-col cols="3">
                <div class="caption textFaint--text">
                  STAKERS
                </div>
                <div class="">
                  {{ pool_info.num_stakers }}
                </div>
              </v-col>
              <v-col cols="3">
                <div class="caption textFaint--text">
                  <v-menu offset-y open-on-hover transition="scale-transition" max-width="300px">
                    <template v-slot:activator="{ on, attrs }">
                      <div 
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex align-center">
                        SHARE WEIGHT
                        <v-icon
                        small
                        color="textFaint"
                        class="ml-1"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </div>
                    </template>
                    <div class="pa-3 foreground caption">
                      Share weight = SharesStaking * (TimeBoost + BurnBoost)
                    </div>
                  </v-menu>
                </div>
                <div class="">
                  {{ $root.formatAmount(pool_info.share_weight_total, pool_info.staking_token.decimals) }}
                </div>
              </v-col>
              <v-col cols="3">
                <div class="caption textFaint--text">
                  CONTRACT
                </div>
                <div class="">
                  <copy-address :address="address" color="text"></copy-address>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="pt-1 midground">

          </div>
          <!-- ACCOUNT -->
          <div v-if="sEthers.coinbase" class="d-flex align-center py-2">
            <v-icon size="60" class="px-2" color="textFaint">mdi-account-circle</v-icon>
            <v-row dense class="ma-0 d-flex align-center">
              <v-col cols="3">
                <div class="caption textFaint--text">
                  YOUR STAKE
                </div>
                <div class="">
                  {{ $root.formatAmount(user_info.tokens_staking, pool_info.staking_token.decimals) }}
                  {{ pool_info.staking_token.symbol }}
                </div>
              </v-col>
              <v-col cols="3">
                <div class="caption textFaint--text">
                  YOUR SHARE
                </div>
                <div class="">
                  {{ poolSharePercent }}%
                </div>
              </v-col>
              <v-col cols="3">
                <div class="caption textFaint--text">
                  <v-menu offset-y open-on-hover transition="scale-transition" max-width="300px">
                    <template v-slot:activator="{ on, attrs }">
                      <div 
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex align-center">
                        YOUR WEIGHT
                        <v-icon
                        small
                        color="textFaint"
                        class="ml-1"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </div>
                    </template>
                    <div class="pa-3 foreground caption">
                      Your Share weight = SharesStaking * (TimeBoost + BurnBoost)
                    </div>
                  </v-menu>
                </div>
                <div class="">
                  {{ $root.formatAmount(user_info.share_weight, pool_info.staking_token.decimals) }}
                </div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex align-center">
                  <v-progress-linear height="36" :value="UnclBoostProgress"
                  color="lgrad-blue"
                  background-color=""
                  style="width: 36px;"
                  class="font-weight-bold br-c">
                    <v-icon size="30" color="white">mdi-flash-circle</v-icon>
                  </v-progress-linear>
                  <v-progress-linear height="36" :value="timeBoostProgress"
                  color="lgrad-green"
                  background-color=""
                  style="width: 36px;"
                  class="font-weight-bold br-c">
                    <v-icon size="30" color="white">mdi-flash-circle</v-icon>
                  </v-progress-linear>
                  <div class="pl-2">
                    {{ totalUserBoost }}%
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="$vuetify.breakpoint.xs && false" class="px-1 pt-6">
          <div class="px-4 mt-3 d-flex textFaint--text">
            Total Staking
            <v-spacer></v-spacer>
            Total Stakers
          </div>
          <div class="v-card d-flex align-center pa-4 br-20">
            <!-- v-if="rpool_user_info.unharvested_rewards !== '0'" -->
            <coin-icon :address="pool_info.staking_token.address" :size="30" class="mr-2"></coin-icon>
            <div class="">
              <div class="text--text">
                {{ $root.formatAmount(stakePoolBalance, pool_info.staking_token.decimals) }}
                <span>
                  {{ pool_info.staking_token.symbol }}
                </span>
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class="text--text">
              {{ pool_info.num_stakers }}
            </div>
            <v-icon size="30" color="textFaint" class="ml-2">mdi-account-group-outline</v-icon>
          </div>
        </div>

        <!-- USER -->
        <div v-if="$vuetify.breakpoint.xs && sEthers.coinbase && false" class="mt-2 px-1">
          <div class="px-4 mt-3 d-flex textFaint--text">
            Your info
          </div>
          <div class="d-flex v-card br-20 align-center px-3 py-2">
            <v-icon size="30" class="mr-2" color="textFaint">mdi-account-circle</v-icon>

            <div class="flex">
              <div class="d-flex align-center text--text">
                {{ $root.formatAmount(user_info.tokens_staking, pool_info.staking_token.decimals) }}
                <span class="ml-1">
                  {{ pool_info.staking_token.symbol }}
                </span>
                <v-spacer></v-spacer>
                <div class="d-flex textFaint--text caption align-center font-weight-bold">
                  <v-progress-linear height="24" :value="timeBoostProgress"
                  color="lgrad-green"
                  background-color=""
                  style="width: 24px;"
                  class="font-weight-bold br-c">
                    <v-icon size="20" color="white">mdi-flash-circle</v-icon>
                  </v-progress-linear>
                  <v-progress-linear height="24" :value="UnclBoostProgress"
                  color="lgrad-blue"
                  background-color=""
                  style="width: 24px;"
                  class="font-weight-bold br-c">
                    <v-icon size="20" color="white">mdi-flash-circle</v-icon>
                  </v-progress-linear>
                </div>
              </div>
              <div class="d-flex align-center">
                <div class="caption textFaint--text">
                  {{ poolSharePercent }}% of pool
                </div>
                <v-spacer></v-spacer>
                <div v-if="false" class="ml-6 textFaint--text caption">
                  {{ $root.formatAmount(user_info.share_weight, pool_info.staking_token.decimals) }} SW
                </div>
                <div class="textFaint--text caption">
                  <span class="pr-1">
                    {{ user_info.time_boost_percentage / 100 }}%
                  </span>
                  +
                  <span class="px-1">
                    {{ user_info.uncl_boost_percentage / 100 }}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- USER -->

        <v-row dense class="ma-0 mt-6">
          <v-col cols="12" md="7">

            <!-- NEW -->
            <div v-if="false" class="text-center v-card pa-4 br-20 mt-10" style="overflow: unset;">
              <div class="textFaint--text d-flex align-center">
                Contract
                <v-spacer></v-spacer>
                <copy-address :address="address" color="text" class="midground br-c"></copy-address>
              </div>
            </div>
            <!-- NEW -->
            
            <div :class="['', {'mb-10' : $vuetify.breakpoint.xs}]">

              <reward-list :stake_pool_address="address" :user_info="user_info" :pool_info="pool_info" :reward_meta="apiData.meta.rewards" @on-sub-event="getUserInfo" ref="rewardList"></reward-list>
                  
            </div>
          </v-col>
          <v-col cols="12" md="5">
            
            <user-panel 
            :address="address" 
            :user_info="user_info" 
            :pool_info="pool_info" 
            :stake_token_hydrated="pool_info.staking_token"
            @hard-refresh="refresh"></user-panel>

          </v-col>
        </v-row>

      </div>

    </template>

    <v-dialog v-model="expandStakerSheet" :fullscreen="$vuetify.breakpoint.xs" scrollable :content-class="!$vuetify.breakpoint.xs ? 'br-20' : ''" width="1000px">
        <v-card :class="['midground', {'br-20': !$vuetify.breakpoint.xs}]">
          <div class="d-flex border-b pa-4">
            <div class="title">
              Stakers
            </div>
            <v-spacer></v-spacer>
            <v-btn color="textFaint" icon @click="expandStakerSheet = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pa-0 foreground">
            <staker-view :stake_pool_address="address" :tokenHydrated="pool_info.staking_token" :pool_info="pool_info"></staker-view>
          </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="expandSettings" :fullscreen="$vuetify.breakpoint.xs" scrollable :content-class="!$vuetify.breakpoint.xs ? 'br-20' : ''" width="600px">
        <v-card :class="['midground', {'br-20': !$vuetify.breakpoint.xs}]">
          <div class="d-flex align-center border-b pa-4">
            <div class="title">
              Settings
            </div>
            <v-spacer></v-spacer>
            <v-btn large color="textFaint" icon @click="expandSettings = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pa-0 foreground">
            <settings-panel v-if="expandSettings" :address="address" :pool_info="pool_info" :apiData="apiData" @hard-refresh="refresh" @api-refresh="fetchAPI" @close-settings="onRewardPoolCreated"></settings-panel>
          </v-card-text>
        </v-card>
    </v-dialog>
    <!--
    <v-overlay
    absolute
    color="background"
    :opacity="0.9"
    :value="!sEthers.coinbase"
    >
      <v-btn
        x-large
        color="primary"
        @click="connectWallet"
      >
        Connect your wallet
      </v-btn>
    </v-overlay>
    -->

  </div>
</template>

<script>
import { ethers } from 'ethers'
import StakePoolContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool'
import ERC20BALANCE from '@/smart-contracts/erc20'
import StakerView from './staker-view'
import SettingsPanel from './settings'
import RewardList from '@/views/farms/rewards/ui/list'
import UserPanel from './user-panel'
import axios from "axios";

export default {

  props: {
    address: null
  },

  components: {
    StakerView,
    SettingsPanel,
    RewardList,
    UserPanel
  },

  data: () => ({
    pool_info: {
      uncl_boost_max_percentage: '0',
      boost_options: [],
      share_weight_total: '0'
    },

    apiData: {
      meta: {
        rewards: [],
        media: {}
      },
      apy: 0
    },

    user_info: {
      user_address: null,
      shares: '0',
      tokens_staking: '0',
      share_weight: '0',
      unlock_date: '0',
      boost_additional_time: '0',
      time_boost_percentage: '0',
      uncl_boost_percentage: '0'
    },

    stakePoolBalance: '0',
    expandStakerSheet: false,
    expandSettings: false,

    firstLoad: true
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    maxStakeAmountIsMaxUint () {
      return ethers.constants.MaxUint256.toString() === this.pool_info.max_stake_amount
    },
    totalUserBoost () {
      var combined = ethers.BigNumber.from(this.user_info.uncl_boost_percentage).add(this.user_info.time_boost_percentage)
      return combined.toNumber() / 100
    },
    poolSharePercent () {
      if (this.pool_info.share_weight_total === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.user_info.share_weight).mul(10000).div(this.pool_info.share_weight_total)
      return percent.toNumber() / 100
      // return 0
    },
    UnclBoostProgress () {
      if (this.pool_info.uncl_boost_max_percentage === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.user_info.uncl_boost_percentage).mul(1000).div(this.pool_info.uncl_boost_max_percentage)
      return percent.toNumber() / 10
      // return 0
    },
    timeBoostProgress () {
      var maxBoost = this.pool_info.boost_options.reduce((a, item) => {
        if (item.boost_percentage > a) {
          a = item.boost_percentage
        }
        return a
      }, 0)
      if (maxBoost === 0) {
        return 0
      }
      var percent = ethers.BigNumber.from(this.user_info.time_boost_percentage).mul(1000).div(maxBoost)
      return percent.toNumber() / 10
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    onRewardPoolCreated () {
      this.expandSettings = false
      try {
        this.$refs.rewardList.refresh()
      } catch (e) {}
    },
    async refresh () {
      try {
        this.pool_info = await StakePoolContract.getPoolInfo(this.address, this.$store.state.requiredNetwork)
        this.firstLoad = false
        await this.getStakePoolBalance()
        await this.getUserInfo()
      } catch (e) {}
    },
    async getUserInfo () {
      this.user_info = await StakePoolContract.getUserInfo(this.address, this.sEthers.coinbase, this.$store.state.requiredNetwork)
    },
    async getStakePoolBalance () {
      this.stakePoolBalance = await ERC20BALANCE.getBalance(this.address, this.pool_info.staking_token.address, this.$store.state.requiredNetwork)
    },
    async fetchAPI () {
      try {
        var response = await axios.get(
          `${this.$root.$servers.FARMS[this.$root.getLegacyChainKey(this.$store.state.requiredNetwork)].server}/farms/farm/${this.address}`
        )
        var item = response.data
        var rewardMap = {}
        for (var reward of item.meta.rewards) {
          rewardMap[reward.reward_pool_address] = reward
        }
        item.meta.rewards = rewardMap
        this.apiData = item
      } catch (e) {
      }
    }
  },

  created () {
    this.refresh()
    this.fetchAPI()
  }
}
</script>