import {
  mainnet,
  goerli,
  sepolia,
  bsc,
  bscTestnet,
  gnosis,
  polygon,
  avalanche,
  dogechain,
  arbitrum,
  base
} from 'viem/chains'

const Endpoints = {

  /* Mainnet: mainnet.rpcUrls.default.http[0],
  Goerli: goerli.rpcUrls.default.http[0],
  Sepolia: sepolia.rpcUrls.default.http[0],
  BSC_MAINNET: bsc.rpcUrls.default.http[0],
  BSC_TESTNET: bscTestnet.rpcUrls.default.http[0],
  xDai: gnosis.rpcUrls.default.http[0],
  Matic: polygon.rpcUrls.default.http[0],
  AVAX: avalanche.rpcUrls.default.http[0],
  DogeChain: dogechain.rpcUrls.default.http[0],
  ArbitrumOne: arbitrum.rpcUrls.default.http[0],
  Base: base.rpcUrls.default.http[0],
  Ganache: "http://127.0.0.1:8545", */

  Mainnet: 'https://boldest-multi-emerald.quiknode.pro/b48ca0d767b97c4c1410860b708a795a3956cba9/',
  Goerli: '',
  Sepolia: 'https://smart-fluent-shard.ethereum-sepolia.quiknode.pro/082b0b0fdfc255d46e05be7e84a3195cb2a45e10/',
  BSC_MAINNET: 'https://thrumming-bold-choice.bsc.quiknode.pro/3163a2d5b1aa2da6521f59ca8aace76ac2530815/',
  BSC_TESTNET: '',
  xDai: 'https://radial-icy-asphalt.xdai.quiknode.pro/95a7c105f1b91bbd8cb79dd2565f911cba24f402/',
  Matic: 'https://thrilling-convincing-pine.matic.quiknode.pro/ead59d74b84904625e48e956defed34ac51d99d9/',
  AVAX: 'https://silent-flashy-tab.avalanche-mainnet.quiknode.pro/fb6b2f8967ac231f37bbf41d80525fe9e56d3a26/ext/bc/C/rpc/',
  DogeChain: dogechain.rpcUrls.default.http[0],
  ArbitrumOne: 'https://proud-floral-bush.arbitrum-mainnet.quiknode.pro/120f1ce4547668069196fa6eee20c624a72a00cf/',
  Base: 'https://solemn-sly-sunset.base-mainnet.quiknode.pro/f8a6abcf367a433e752b7d6be9b63b003d94a9ef/',
  Ganache: "http://127.0.0.1:8545",
}

export default Endpoints