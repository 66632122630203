<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="450" content-class="br-20">
    <v-card class="foreground br-20">
      <div class="d-flex align-center title px-4 py-3 border-b background" style="font-size: 1rem;">
        <div style="width: 24px;">

        </div>
        <v-spacer></v-spacer>
        Switch Network
        <v-spacer></v-spacer>
        <v-btn icon color="text" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text class="pa-0">

        <div v-for="chain of chains" class="d-flex align-center c-list pa-4 border-b" :key="chain" @click="selectChain(chain)">
          <img 
          :src="$settings.CHAINS[chain].icon" 
          height="30px"
          width="30px"
          class="br-20">
          <span class="mr-3"/>
          <div>
            <div class="title">
              {{ $settings.CHAINS[chain].name }}
            </div>
          </div>
        </div>

        <div class="textFaint--text py-2 background text-center">
          Testnets
        </div>

        <div v-for="testnet of testnets" class="d-flex align-center c-list pa-4 border-b" :key="testnet" @click="selectChain(testnet)">
          <svg width="30px" style="stroke:#aaaaaa;" class="mr-3 draw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.5 332.3" stroke-miterlimit="10"><path d="M166.2 217.7l83-49.1-83-137.6"/><path d="M166.2 31L83.3 168.6l82.9 49.1V131zm0 270.2l83-116.8-83 49"/><path d="M166.2 301.2v-67.8l-82.9-49zm83-132.6l-83-37.6m0 0l-82.9 37.6"/></svg>
          <div>
            <div class="title">
              {{ $settings.CHAINS[testnet].name }}
            </div>
          </div>
        </div>

        <!-- <div class="d-flex align-center c-list pa-4 border-b" @click="selectChain('Sepolia')">
          <svg width="30px" style="stroke:#aaaaaa;" class="mr-3 draw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.5 332.3" stroke-miterlimit="10"><path d="M166.2 217.7l83-49.1-83-137.6"/><path d="M166.2 31L83.3 168.6l82.9 49.1V131zm0 270.2l83-116.8-83 49"/><path d="M166.2 301.2v-67.8l-82.9-49zm83-132.6l-83-37.6m0 0l-82.9 37.6"/></svg>
          <div>
            <div class="title textFaint--text">
              Sepolia
            </div>
          </div>
        </div>

        <div class="d-flex align-center c-list pa-4 border-b" @click="selectChain('Goerli')">
          <svg width="30px" style="stroke:#aaaaaa;" class="mr-3 draw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.5 332.3" stroke-miterlimit="10"><path d="M166.2 217.7l83-49.1-83-137.6"/><path d="M166.2 31L83.3 168.6l82.9 49.1V131zm0 270.2l83-116.8-83 49"/><path d="M166.2 301.2v-67.8l-82.9-49zm83-132.6l-83-37.6m0 0l-82.9 37.6"/></svg>
          <div>
            <div class="title textFaint--text">
              Goerli
            </div>
          </div>
        </div> -->

        <div v-if="$store.state.allowGanache && type === 'default'" class="d-flex align-center c-list pa-4 border-b" @click="selectChain('Ganache')">
          <svg width="30px" style="stroke:#aaaaaa;" class="mr-3 draw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.5 332.3" stroke-miterlimit="10"><path d="M166.2 217.7l83-49.1-83-137.6"/><path d="M166.2 31L83.3 168.6l82.9 49.1V131zm0 270.2l83-116.8-83 49"/><path d="M166.2 301.2v-67.8l-82.9-49zm83-132.6l-83-37.6m0 0l-82.9 37.6"/></svg>
          <div>
            <div class="title textFaint--text">
              Ganache
            </div>
          </div>
        </div>

        <!--
        <div class="d-flex align-center c-list pa-4" @click="selectChain('Hardhat')">
          <img 
          :src="$settings.CHAINS['Hardhat'].icon" 
          height="40px"
          width="40px"
          class="mr-3 br-20 greyscale">
          <div>
            <div class="title textFaint--text">
              Hardhat
            </div>
          </div>
        </div>
        -->

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    chains: ['Mainnet', 'ArbitrumOne', 'BSC_MAINNET', 'Base', 'AVAX', 'xDai', 'Matic', 'DogeChain'],
    testnets: ['Goerli', 'Sepolia'],
    type: 'default'
  }),
  computed: {
    amms () {
      if (this.$store.state.requiredNetwork === 'BSC_MAINNET') {
        return ['Pancakeswap V2']
      };
      if (this.$store.state.requiredNetwork === 'Mainnet') {
        return ['Uniswap V2']
      };
      if (this.$store.state.requiredNetwork === 'Matic') {
        return ['Quickswap V1']
      };
      if (this.$store.state.requiredNetwork === 'BSC_TESTNET') {
        return ['Pancakeswap V2 - BSC TESTNET']
      };
      if (this.$store.state.requiredNetwork === 'Goerli') {
        return ['Uniswap V2 - Goerli']
      };
      if (this.$store.state.requiredNetwork === 'ArbitrumOne') {
        return ['Camelot'];
      }
      return [];
    }
  },
  methods: {
    open (type = 'default') {
      if (type === 'taxToken') {
        this.type = type;
        this.chains = ['Mainnet', 'BSC_MAINNET', 'Matic', 'ArbitrumOne']
        this.testnets = ['Goerli']
      } else if (type === 'vesting') {
        this.type = type;
        this.chains = ['Mainnet', 'BSC_MAINNET', 'Base', 'Matic', 'ArbitrumOne', 'AVAX']
        this.testnets = ['Goerli', 'Sepolia']
      } else if (type === 'enmt') {
        this.type = type;
        this.chains = ['Mainnet', 'BSC_MAINNET', 'Matic', 'ArbitrumOne', 'AVAX']
        this.testnets = ['Goerli', 'Sepolia']
      }
      this.dialog = true
    },
    close () {
      this.dialog = false
    },
    selectChain (requiredChain) {
      this.$store.commit('switchChain', requiredChain)
      if (this.type === 'taxToken') {
        this.$store.commit('switchExchange', this.amms[0]);
      }
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
.draw-icon {
  fill:transparent;
  stroke-width:5px;

  path{
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 1.5s linear forwards;
    // animation: dash 5s cubic-bezier(.645,.045,.355,1);
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
</style>