<template>
  <div>
    <div :class="[{'v-card br-20': !$vuetify.breakpoint.xs}, {'v-card': $vuetify.breakpoint.xs}]">
      <div class="">
        <v-slide-group
          class="mb-2 foreground textFaint--text font-weight-medium justify-space-between flex"
          show-arrows
        >
          <v-slide-item>
            <div
              @click="tab = 'status'"
              :class="['py-3 px-6 c-list', {'primary--text': tab === 'status'}]"
            >
              <div class="text-center">
                Status Tracker
              </div>
            </div>
          </v-slide-item>
          <v-slide-item>
            <div
              @click="tab = 'admin_input'"
              :class="['py-3 px-6 c-list', {'primary--text': tab.startsWith('admin_')}]"
            >
              <div class="text-center">
                Admin
              </div>
            </div>
          </v-slide-item>
        </v-slide-group>
        <v-fade-transition mode="out-in">
          <div class="">
            <div class="">
              <div v-if="firstLoad" class="text-center py-4">
                <v-progress-circular
                  indeterminate
                  size="60"
                  width="2"
                  color="#aaa"
                >
                  <img
                    src="@/assets/img/UNCX_fill.svg"
                    height="50px"
                    class="greyscale"
                    width="50px"
                    alt="unicrypt"
                  >
                </v-progress-circular>
              </div>
              <div class="d-flex flex-row">
                <div class="text-center flex pa-2">
                    <v-btn large rounded outlined @click="$root.$dialog.chainSwitcher.open()" class="foreground title">
                      <img
                      :src="$settings.CHAINS[$store.state.requiredNetwork].icon"
                      height="24px"
                      width="24px"
                      class="mr-3 br-20">
                      {{ $settings.CHAIN_DISPLAY_NAME[$store.state.requiredNetwork] }}
                      <v-icon small color="">mdi-chevron-down</v-icon>
                    </v-btn>
                  </div>
                </div>
              <div class="d-flex align-center m-4 flex-column">
                <div v-if="tab === 'admin_input' && this.$store.state.requiredNetwork !== 'Goerli'" style="width: 100%;">
                  <!-- NOT CONNECTED -->
                  <div v-if="!sEthers.coinbase" class="text-center pa-6">
                    <div>
                      <v-icon size="40" color="textFaint">mdi-account-circle</v-icon>
                      <div class="textFaint--text caption pt-2">
                        Connect your wallet to view your farms
                      </div>
                    </div>
                    <div class="pt-2">
                      <v-btn
                      large
                      block
                      outlined
                      color="navcolor"
                      class="white--text"
                      rounded
                      @click="connectWallet"
                      >
                        CONNECT
                      </v-btn>
                    </div>
                  </div>
                  <!-- NOT CONNECTED -->
                  <v-col v-else cols="12" class="mb-3">
                    <div class="mb-2 font-weight-bold">
                      Token Contract
                    </div>
                    <div class="d-flex align-center inputcolor pa-2 r-outlined">
                      <c-input
                        :value.sync="loadedContractAddress"
                        placeholder="Address"
                      />
                    </div>
                  </v-col>
                  <div
                    class="flex flex-row justify-space-between pa-3"
                    style="width: 100%;"
                  >
                    <v-btn
                      large
                      block
                      rounded
                      outlined
                      @click="loadTokenAdmin"
                      color="primary"
                      style="border: 1.5px solid;"
                    >
                      Submit
                    </v-btn>
                  </div>
                </div>
                <div v-if="tab === 'admin_input' && this.$store.state.requiredNetwork === 'Goerli'" style="width: 100%;">
                  <v-col cols="12" class="mb-3">
                    <div class="mb-2 text-center">
                      Lossless not supported on Goerli.
                    </div>
                  </v-col>
                </div>
                <div v-if="tab === 'admin_page'" style="width: 100%;">
                  <v-col cols="12">
                    <div class="font-weight-bold text-center">
                      {{adminToken.name}} ( {{adminToken.symbol}})
                    </div>
                    <v-flex class="flex-row">
                      <div class="text-center grey--text">
                        {{adminToken.id}}
                        <!-- &nbsp;
                        <button @click="0">
                          <v-icon color="textFaint" small>
                            mdi-open-in-new
                          </v-icon>
                        </button> -->
                      </div>
                    </v-flex>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-card v-if="adminToken.isLosslessOn" class="pa-4 br-20">
                      Current Status:
                      &nbsp;
                      <span
                        class="d-inline-block"
                        style="height: 10px; width: 10px; border-radius: 50%; background-color: green;"
                      />
                      On
                    </v-card>
                    <v-card v-else class="pa-4 br-20">
                      Current Status:
                      &nbsp;
                      <span
                        class="d-inline-block"
                        style="height: 10px; width: 10px; border-radius: 50%; background-color: red;"
                      />
                      Off
                    </v-card>
                  </v-col>
                  <v-col v-if="activeButton === 'LOCKED'" cols="12" class="d-flex justify-center px-4">
                    Lossless turn off: {{turnOffDate}}
                  </v-col>
                  <v-col cols="12" class="d-flex justify-space-between px-4">
                    <v-col cols="4">
                      <v-btn
                        large
                        rounded
                        outlined
                        :disabled="activeButton !== 'PROPOSE_OFF'"
                        @click="proposeTurnOff()"
                        color="primary"
                        style="border: 1.5px solid; width: 100%;"
                      >
                        Propose Turn Off
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        large
                        rounded
                        outlined
                        :disabled="activeButton !== 'OFF'"
                        @click="turnOff()"
                        color="primary"
                        style="border: 1.5px solid; width: 100%;"
                      >
                        Turn Off
                      </v-btn>
                    </v-col>
                    <v-col cols="4">
                      <v-btn
                        large
                        rounded
                        outlined
                        :disabled="activeButton !== 'ON'"
                        @click="turnOn()"
                        color="primary"
                        style="border: 1.5px solid; width: 100%;"
                      >
                        Turn On
                      </v-btn>
                    </v-col>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="d-flex flex-row justify-space-between px-4">
                      <v-col cols="4" class="d-flex ma-auto justify-center">
                        <button @click="activeHelpCard = activeHelpCard === 'PROPOSE_OFF' ? null : 'PROPOSE_OFF'">
                          <v-icon color="textFaint">
                            mdi-help-circle-outline
                          </v-icon>
                        </button>
                      </v-col>
                      <v-col cols="4" class="d-flex ma-auto justify-center">
                        <button @click="activeHelpCard = activeHelpCard === 'OFF' ? null : 'OFF'">
                          <v-icon color="textFaint">
                            mdi-help-circle-outline
                          </v-icon>
                        </button>
                      </v-col>
                      <v-col cols="4" class="d-flex ma-auto justify-center">
                        <button @click="activeHelpCard = activeHelpCard === 'ON' ? null : 'ON'">
                          <v-icon color="textFaint">
                            mdi-help-circle-outline
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                    <v-card class="pa-6 br-20 mb-3 mt-2" v-if="activeHelpCard === 'PROPOSE_OFF'">
                      <div class="font-weight-light align-self-center">
                        In order to turn Lossless off, you first need to propose it. Think of it as a ‘cooldown’ time - it gives your holders time to learn about the proposal. The proposal lasts for 7 days - after that time, you will be able to turn it off.
                      </div>
                      <div class="font-weight-medium align-self-center pt-4 text-decoration-underline">
                        <a href="https://docs.lossless.io/protocol/technical-reference/hack-mitigation-protocol/overview" class="deadlink" target="_blank">
                          For full walk-through and guide please visit our docs.
                        </a>
                      </div>
                    </v-card>
                    <v-card class="pa-6 br-20 mb-3 mt-2" v-if="activeHelpCard === 'OFF'">
                      Immediately turns off Lossless functionalities.
                    </v-card>
                    <v-card class="pa-6 br-20 mb-3 mt-2" v-if="activeHelpCard === 'ON'">
                      Immediately turns on Lossless functionalities.
                    </v-card>
                  </v-col>
                  <!-- TODO : token address with etherscan link -->
                  <v-col cols="12">
                    <v-card br="20" class="pa-4">
                      <div class="mb-0" style="display: flex; justify-content: space-between;">
                        <div class="font-weight-bold align-self-center">
                          Transfer Recovery Admin Privileges
                        </div>
                      </div>
                      <v-container>
                        <v-row>
                          <v-col cols="3" class="align-self-center">
                            <div class="font-weight-medium align-self-center">
                              Address:
                            </div>
                          </v-col>
                          <v-col>
                            <div class="d-flex align-center inputcolor pa-2 r-outlined" style="width: 100%;">
                              <c-input
                                :value.sync="losslessTransferAddress"
                                placeholder="Address"
                              />
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3" class="align-self-center">
                            <div class="font-weight-medium align-self-center">
                              Set Passphrase
                            </div>
                          </v-col>
                          <v-col>
                            <div class="d-flex align-center inputcolor pa-2 r-outlined" style="width: 100%;">
                              <c-input
                                :value.sync="losslessTransferPassphrase"
                                placeholder="Passphrase"
                              />
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex justify-space-between">
                          <v-col cols="5">
                            <v-btn
                              large
                              rounded
                              outlined
                              @click="this.tab = 'status'"
                              color="primary"
                              style="border: 1.5px solid; width: 100%;"
                            >
                              Cancel
                            </v-btn>
                          </v-col>
                          <v-col cols="5">
                            <v-btn
                              large
                              rounded
                              outlined
                              @click="transferRecoveryAdmin()"
                              color="primary"
                              style="border: 1.5px solid; width: 100%;"
                            >
                              Confirm
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </div>
                <div v-if="tab === 'admin_denied'" style="width: 100%;">
                  <v-col cols="12" class="mb-3">
                    <div class="mb-2 font-weight-bold text-center">
                      You do not have permission to view the lossless admin page for this token.
                    </div>
                  </v-col>
                  <div class="flex flex-row justify-space-between pa-3" style="width: 100%;">
                    <v-btn large block rounded outlined @click="tab = 'admin_input'" color="primary" style="border: 1.5px solid;">
                      Back
                    </v-btn>
                  </div>
                </div>
                <div v-if="tab === 'admin_transfer'" style="width: 100%;">
                  <v-col cols="12" class="mb-3">
                    <div class="mb-2 font-weight-bold text-center pb-4">
                      Recovery Admin Transfer
                    </div>
                    <div class="mb-2">
                      Enter passphrase to accept privileges
                    </div>
                    <div class="d-flex align-center inputcolor pa-2 r-outlined">
                      <c-input
                        :value.sync="passphrase"
                        placeholder="Passphrase"
                      />
                    </div>
                  </v-col>
                  <div
                    class="flex flex-row justify-space-between pa-3"
                    style="width: 100%;"
                  >
                    <v-btn
                      large
                      block
                      rounded
                      outlined
                      @click="acceptAdminRecovery()"
                      color="primary"
                      style="border: 1.5px solid;"
                    >
                      Submit
                    </v-btn>
                  </div>
                </div>
                <div v-if="tab === 'status' && this.$store.state.requiredNetwork !== 'Goerli'" style="width: 100%;">
                  <v-col cols="12" class="mb-3">
                    <div class="mb-2 text-center">
                      Search for a token
                    </div>
                    <div class="d-flex align-center inputcolor pa-2 r-outlined">
                      <c-input
                        :value.sync="searchToken"
                        placeholder="Address"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" class="mb-3">
                    <v-btn
                      large
                      rounded
                      outlined
                      @click="loadTokenStatus"
                      color="primary"
                      style="border: 1.5px solid;"
                      class="d-flex justify-center ma-auto"
                    >
                      Submit
                    </v-btn>
                  </v-col>
                  <v-card br="20" class="pa-4">
                    <v-container v-if="losslessTrackedToken.status !== ''">
                      <v-row>
                        <v-col cols="6" class="align-self-center">
                          <div class="font-weight-bold text-center">
                            Name
                          </div>
                        </v-col>
                        <v-col cols="6" class="align-self-center">
                          <div class="font-weight-bold text-center">
                            Status
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="align-self-center">
                          <div class="text-center">
                            {{ losslessTrackedToken.name }} ({{losslessTrackedToken.symbol}})
                          </div>
                        </v-col>
                        <v-col v-if="losslessTrackedToken.isLosslessOn && !losslessTrackedToken.isLosslessTurnOffProposed" cols="6" class="align-self-center">
                          <div class="text-center">
                            <span
                              class="d-inline-block"
                              style="height: 10px; width: 10px; border-radius: 50%; background-color: green;"
                            />
                            {{ losslessTrackedToken.status }}
                          </div>
                        </v-col>
                        <v-col v-if="!losslessTrackedToken.isLosslessOn" cols="6" class="align-self-center">
                          <div class="text-center">
                            <span
                              class="d-inline-block"
                              style="height: 10px; width: 10px; border-radius: 50%; background-color: red;"
                            />
                            {{ losslessTrackedToken.status }}
                          </div>
                        </v-col>
                        <v-col v-if="losslessTrackedToken.isLosslessTurnOffProposed" cols="6" class="align-self-center">
                          <div class="text-center">
                            <span
                              class="d-inline-block"
                              style="height: 10px; width: 10px; border-radius: 50%; background-color: yellow;"
                            />
                            {{ losslessTrackedToken.status }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="losslessTrackedToken.isLosslessTurnOffProposed" class="justify-center red br-20"> 
                      Proposed Turn Off of Lossless unlock: 
                      <br>
                      {{turnOffDateStatus}}
                      </v-row>
                    </v-container>
                  </v-card>
                </div>
                <div v-if="tab === 'status' && this.$store.state.requiredNetwork === 'Goerli'" style="width: 100%;">
                  <v-col cols="12" class="mb-3">
                    <div class="mb-2 text-center">
                      Lossless not supported on Goerli.
                    </div>
                  </v-col>
                </div>
              </div>
            </div>
          </div>
        </v-fade-transition>
      </div>
    </div>
  </div>
</template>

<script>
import SETTINGS from '@/store/settings'
import axios from 'axios';
import TxWrapper from '@/web3/tx-wrapper-2';
import TaxToken from '@/smart-contracts/minter-v2/tax-token';
import { ethers } from 'ethers';
export default {

  components: {
  },

  data: () => ({
    tab: 'admin_page', // status, admin_input, admin_page, admin_denied, admin_transfer
    loadedContractAddress: '',
    passphrase: '',
    searchToken: '',
    firstLoad: true,
    loading: true,
    losslessTransferAddress: '',
    losslessTransferPassphrase: '',
    adminToken: {
      id: '',
      name: '',
      symbol: '',
      isLosslessOn: false,
      losslessAdmin: {
        id: ''
      },
      losslessRecoveryAdmin: {
        id: ''
      },
      lossless: '',
      losslessTurnOffTimestamp: 0,
      isLosslessTurnOffProposed: false,
      losslessProposedRecoveryAdmin: {
        id: ''
      }
    },
    timelockPeriod: 604800000, // 7 days
    // timelockPeriodCheck: true,
    losslessTrackedToken: {
      name: '',
      symbol: "",
      status: '',
      id: '',
      losslessTurnOffTimestamp: 0,
    },
    activeHelpCard: 'PROPOSE_OFF',
    // activeButton: 'PROPOSE_OFF'
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    activeButton () {
      if (this.adminToken.isLosslessTurnOffProposed) {
        // now >= timsetamp to milliseconds
        if (Date.now >= this.adminToken.losslessTurnOffTimestamp * 1000) {
          return "OFF"
        } else {
          return "LOCKED";
        }
      }
      if (!this.adminToken.isLosslessOn) {
        return "ON";
      }
      return "PROPOSE_OFF"
    },
    turnOffDate () {
      return new Intl.DateTimeFormat('en-GB', {dateStyle: 'full', timeStyle: 'long'}).format(this.adminToken.losslessTurnOffTimestamp);
    },
    turnOffDateStatus () {
      return new Intl.DateTimeFormat('en-GB', {dateStyle: 'full', timeStyle: 'long'}).format(this.losslessTrackedToken.losslessTurnOffTimestamp);
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
          this.switchNetwork()
        })
        .catch(e => {})
    },
    async refresh () {
      this.loading = true;
      this.tab = 'status';
      if (this.tab === 'admin_page') {
        this.tab = 'admin_input';
      }
      try {
        this.firstLoad = false;
        this.loading = false;
      } catch (e) {
        this.firstLoad = false;
        this.loading = false;
      }
    },
    async proposeTurnOff () {
      TxWrapper.doTransaction(
        TaxToken.proposeLosslessTurnOff,
        [this.adminToken.id],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then((data) => {
        if (data.receipt.status === 1) {
          this.adminToken.isLosslessTurnOffProposed = true;
        }
        this.createLoading = false;
      })
    },
    async turnOff () {
      TxWrapper.doTransaction(
        TaxToken.executeLosslessTurnOff,
        [this.adminToken.id],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then((data) => {
        this.createLoading = false;
        if (data.receipt.status === 1) {
          this.adminToken.isLosslessTurnOffProposed = false;
          this.adminToken.isLosslessOn = false;        
        }
      })
    },
    async turnOn () {
      TxWrapper.doTransaction(
        TaxToken.executeLosslessTurnOn,
        [this.adminToken.id],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then((data) => {
        if (data.receipt.status === 1) {
          this.adminToken.isLosslessOn = true;       
        }
        this.createLoading = false;
      })
    },
    async transferRecoveryAdmin () {
      const hash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(this.losslessTransferPassphrase));
      TxWrapper.doTransaction(
        TaxToken.transferRecoveryAdminOwnership,
        [this.adminToken.id, this.losslessTransferAddress, hash],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.tab = 'status';
      })
    },
    async acceptAdminRecovery () {
      const key = ethers.utils.toUtf8Bytes(this.passphrase);
      TxWrapper.doTransaction(
        TaxToken.acceptRecoveryAdminOwnership,
        [this.adminToken.id, key],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.passphrase = '';
        this.losslessTransferAddress = '';
        this.losslessTransferPassphrase = '';
        this.loadTokenAdmin();
      })
    },
    async loadTokenStatus () {
      var token = this.searchToken.toLowerCase();

      var data = {
        query: `
          {
            taxTokens(where: {id: "${token}"}) {
              id
              name
              symbol
              isLosslessOn
              losslessAdmin {
                id
              }
              losslessRecoveryAdmin {
                id
              }
              losslessTurnOffTimestamp
              isLosslessTurnOffProposed
              losslessProposedRecoveryAdmin {
                id
              }
            }
          }
        `
      }

      var network = this.$store.state.requiredNetwork;
      var graphExplorer = SETTINGS.CHAINS[network].graphExplorerMinterV2;

      var response = await axios.post(graphExplorer, data);
      if (response.data.data.taxTokens !== null) {
        this.losslessTrackedToken = response.data.data.taxTokens[0];
        if (this.losslessTrackedToken.isLosslessOn) {
          this.losslessTrackedToken.status = 'ON';
        }
        if (!this.losslessTrackedToken.isLosslessOn) {
          this.losslessTrackedToken.status = 'OFF';
        }
        if (this.losslessTrackedToken.isLosslessTurnOffProposed) {
          this.losslessTrackedToken.status = 'PENDING';
        }
        this.losslessTrackedToken.losslessTurnOffTimestamp *= 1000;
      }
    },
    async loadTokenAdmin () {
      var token = this.searchToken.toLowerCase();

      var data = {
        query: `
          {
            taxTokens(where: {id: "${token}"}) {
              id
              name
              symbol
              isLosslessOn
              losslessAdmin {
                id
              }
              losslessRecoveryAdmin {
                id
              }
              lossless
              losslessTurnOffTimestamp
              isLosslessTurnOffProposed
              losslessProposedRecoveryAdmin {
                id
              }
            }
          }
        `
      }

      var network = this.$store.state.requiredNetwork;
      var graphExplorer = SETTINGS.CHAINS[network].graphExplorerMinterV2;

      var response = await axios.post(graphExplorer, data);
      console.log(response)
      if (response.data.data.taxTokens !== null) {
        this.adminToken = response.data.data.taxTokens[0];
        var userAddress = this.sEthers.coinbase.toLowerCase();
        if (userAddress === this.adminToken.losslessRecoveryAdmin.id) {
          this.tab = 'admin_page';
        } else if (userAddress === this.adminToken.losslessProposedRecoveryAdmin.id) {
          this.tab = 'admin_transfer';
        } else {
          this.tab = 'admin_denied';
        }
        this.losslessTrackedToken.losslessTurnOffTimestamp *= 1000;
      }
    },
  },

  created () {
    this.refresh();
  },
  mounted () {
  }
}
</script>