<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="450" content-class="br-20">
    <v-card outlined class="br-20">

      <v-row v-if="false" class="pa-4 ma-0 t-large font-weight-bold align-center">
        <v-spacer></v-spacer>
        <v-btn @click="close" icon color="text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <v-card-text class="pa-6">
        <div class="text-center">
          <div class="white--text display-1 mb-1">
            Create & Initialize Pool
          </div>
          <div class="mt-4 white--text">
            Due to Camelot's set up, we need to create the pair and initialize after token creation.
            <br>
            If this is not done, Certain taxes will not work. (Can be updated under Account)
          </div>
          <br>
          <v-btn :disabled="lpTokenCreated" :loading="createLoading" large block rounded depressed @click="createPair" color="primary" class=" br-20 gradient-button-v1">
            Create Pair
          </v-btn>
          <br>
          <v-btn :disabled="!lpTokenCreated" :loading="updateLoading" large block rounded depressed @click="updatePairAddress" color="primary" class=" br-20 gradient-button-v1">
            Update Token Pair Address
          </v-btn>
          <br>
          <!-- <br><br>
          <v-btn large block rounded outlined color="texFaint" class="mt-4" @click="close">
            Close
          </v-btn> -->
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TxWrapper from "@/web3/tx-wrapper-2";
import TaxToken from "@/smart-contracts/minter-v2/tax-token";
import UniswapABI from "@/smart-contracts/uniswap/uniswap-abis";
// import {ethers} from "ethers";

export default {
  props: {
    tokenAddress: {
      type: String
    }
  },
  data: () => ({
    createLoading: false,
    updateLoading: false,

    lpTokenCreated: false,

    lpTokenAddress: undefined,

    dialog: false,
  }),
  methods: {
    createPair () {
      this.createLoading = true;
      const WETH_ADDRESS = UniswapABI.weth9_address();
      console.log([this.tokenAddress, WETH_ADDRESS]);
      TxWrapper.doTransaction(
        TaxToken.createCamelotLPToken,
        [this.tokenAddress, WETH_ADDRESS],  
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then((data) => {
        console.log(data);
        if (data.receipt.status === 1) {
          const creationEvent = data.receipt.events[0];
          console.log(creationEvent.args[2])
          this.lpTokenAddress = creationEvent.args[2];
          this.lpTokenCreated = true;
        }
      }).finally(() => {
        this.createLoading = false;
      })
    },
    updatePairAddress () {
      TxWrapper.doTransaction(
        TaxToken.updatePairAddress,
        [this.tokenAddress, this.lpTokenAddress],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then((data) => {
        if (data.receipt.status === 1) {
          this.close();
        }
      }).finally(() => {
        this.createLoading = false;
      })
    },
    open () {
      this.dialog = true;
    },
    close () {
      this.dialog = false;
      this.$emit('on-close');
    }
  }
}
</script>

<style scoped lang="scss">
// .blocks {
//   background: url('~@/assets/img/textures/blocks.svg'), radial-gradient(104.47% 188.91% at 94% 0%, #27e25d 0%, #71b8ff 100%), #ff7171;
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-attachment: fixed;
// }
</style>