<template>
  <div class="v-card br-8 mb-2 d-flex align-center c-list" style="height: 200px;">

    <div v-if="contract_version === -1" class="textFaint--text d-flex justify-center align-center" style="flex: 1 0 0 ;">
      <v-progress-circular
      indeterminate
      size="18"
      width="1"
      style="position: absolute;top: 20px; right: 20px;"
      color="textFaint">
      </v-progress-circular>
      <div class="text-center">
        <coin-icon :url="presale.icon_url" :size="76"></coin-icon>
        <div class="title text--text font-weight-medium">
          {{ presale.s_token.name }}
        </div>
        <div v-if="!sEthers.coinbase" class="textFaint--text caption font-italic">
          Connect your wallet for faster loading times
        </div>
      </div>
    </div>

    <type-one v-else-if="contract_version < 3 && contract_version > -1" :presale="presale" :exchange="exchange"></type-one>
    <type-three v-else-if="contract_version === 3" :presale="presale" :exchange="exchange"></type-three>
    <type-four v-else-if="contract_version === 4" :presale="presale" :exchange="exchange"></type-four>
    <type-five v-else-if="contract_version === 5" :presale="presale" :exchange="exchange"></type-five>
    <type-six v-else-if="contract_version === 6" :presale="presale" :exchange="exchange"></type-six>
  </div>
</template>

<script>
import PresalePeriphery from '@/smart-contracts/presales/presale-periphery'
import TypeOne from './type_1'
import TypeThree from './type_3'
import TypeFour from './type_4'
import TypeFive from './type_5'
import TypeSix from './type_6'

export default {
  props: {
    presale: {
      type: Object,
      default: () => {
      }
    },
    exchange: null
  },

  components: {
    TypeOne,
    TypeThree,
    TypeFour,
    TypeFive,
    TypeSix
  },

  data: () => ({
    contract_version: -1
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
  },

  methods: {
    async refresh () {
      var network = this.exchange ? this.$settings.AMMS[this.exchange].chain : null
      this.contract_version = await PresalePeriphery.getContractVersion(this.presale.presale_contract, network)
    }
  },

  created () {
    this.refresh()
  }
}
</script>
