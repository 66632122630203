<template>
  <v-container style="max-width: 600px;">
    
    <v-slide-y-transition appear>
    <div :class="['mb-16', {'': $vuetify.breakpoint.xs}, {'v-card br-20 foreground': !$vuetify.breakpoint.xs}]">
      
      <!--
      <div class="title text-center mb-1">
        UNCX
      </div>
      <div class="textFaint--text text-center mb-6">
        Cross chain decentralised protocols
      </div>

      <v-card class="v-card d-flex align-center pa-4 br-20 mb-6 blocks" to="/dashboard">
        <v-icon color="white" size="40" class="mr-3">
          mdi-fire
        </v-icon>
        <div>
          <div class="title white--text">
            Whats trending?
          </div>
          <div class="caption white--text">
            ILOs and Liquidity locks across all AMMS
          </div>
        </div>
      </v-card>
      -->

      <div class="py-6 text-center">
        Select an exchange
      </div>

      <div v-for="chain of ['Mainnet', 'BSC_MAINNET', 'ArbitrumOne', 'DogeChain', 'AVAX', 'xDai', 'Matic']" :key="chain">
        <div v-for ="amm of getExchangesForChain(chain)" :key="amm" :class="['d-flex align-center c-list px-4 py-2 border-t']" @click="selectExchange(amm)">
          <img 
          :src="getExchangeIcon(amm)" 
          height="30px"
          width="30px"
          class="mr-3 br-20">
          <div class="d-flex align-center flex">
            <div class="caption">
              {{ amm }}
            </div>
            <v-spacer></v-spacer>
            <div class="caption textFaint--text">
              {{ $settings.CHAINS[$settings.AMMS[amm].chain].shortName }}
            </div>
            <img 
            :src="$settings.CHAINS[$settings.AMMS[amm].chain].icon" 
            height="18px"
            width="18px"
            class="ml-1 br-20">
          </div>
        </div>
      </div>

      <div class="py-6 text-center border-t">
        Testnets
      </div>

      <div v-for="chain of ['Goerli']" :key="chain">
        <div v-for ="amm of getExchangesForChain(chain)" :key="amm" class="d-flex align-center c-list px-4 py-2 border-t" @click="selectExchange(amm)">
          <img 
          :src="getExchangeIcon(amm)" 
          height="30px"
          width="30px"
          class="mr-3 br-20">
          <div class="d-flex align-center flex">
            <div class="caption">
              {{ amm }}
            </div>
            <v-spacer></v-spacer>
            <div class="caption textFaint--text">
              {{ $settings.CHAINS[$settings.AMMS[amm].chain].name }}
            </div>
            <img 
            :src="$settings.CHAINS[$settings.AMMS[amm].chain].icon" 
            height="18px"
            width="18px"
            class="ml-1 br-20">
          </div>
        </div>
      </div>

      <div v-if="$store.state.allowGanache" class="d-flex align-center c-list pa-4" @click="selectExchange('Uniswap V2 - Ganache')">
        <img 
        :src="getExchangeIcon('Uniswap V2 - Kovan')" 
        height="40px"
        width="40px"
        class="mr-3 greyscale">
        <div>
          <div class="title">
            Uniswap V2 - GANACHE
          </div>
          <div class="caption textFaint--text">
            Ganache
          </div>
        </div>
      </div>
      
    </div>
    </v-slide-y-transition>
    
  </v-container>
</template>

<script>
import SETTINGS from '@/store/settings'

export default {

  data: () => ({
    expandMenu: false,
    selectedChain: 'Mainnet'
  }),

  computed: {
  },

  methods: {
    getExchangesForChain (chain) {
      return Object.keys(SETTINGS.AMMS).filter(key => chain === SETTINGS.AMMS[key].chain)
    },
    getExchangeIcon (exchange) {
      return SETTINGS.AMMS[exchange].icon
    },
    selectExchange (exchange) {
      this.$store.commit('switchExchange', exchange)
      this.$root.ammLink(`/ilos`)
    }
  },

  created () {
  }
}
</script>