<template>
  <!--
    TODO:
      - move to components dir
  -->
  <v-dialog v-model="isOpen" max-width="500" content-class="br-20">
    <v-card class="br-20">
      <div>
        <v-row class="pa-4 ma-0 font-weight-medium align-center">
          <div>
            {{ title }}
          </div>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-4 ma-0 font-weight-medium align-center background">
          <div class="d-flex align-center pa-3 br-20 inputcolor my-2" style="width: 100%;">
            <c-input v-if="inputMode === 'SINGLE'" :value.sync="addressInput" placeholder="Address..." />
            <c-textarea v-if="inputMode === 'BULK'" :value.sync="bulkAddressInput" placeholder="Address List..." />
          </div>
          <v-flex class="d-flex justify-space-between">
            <v-btn @click="addAddress" v-if="inputMode === 'SINGLE'">
              <v-icon color="green">mdi-plus-circle-outline</v-icon>&nbsp;Add {{ label }}
            </v-btn>
            <v-btn @click="switchMode" v-if="inputMode === 'SINGLE' && custom !== 'lpTokens'">
              <v-icon color="green" >mdi-import</v-icon>&nbsp;Bulk Update
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn @click="removeAddress" v-if="inputMode === 'SINGLE' && custom === 'minter-v2'">
              <v-icon color="green">mdi-plus-circle-outline</v-icon>&nbsp;Remove {{ label }}
            </v-btn>
          </v-flex>
          <v-flex v-if="inputMode === 'BULK'" class="d-flex justify-space-between" >
            <v-flex>
              <v-btn @click="bulkAppend">
                <v-icon color="green">mdi-import</v-icon>&nbsp;Add
              </v-btn>
              <v-btn @click="bulkRemove">
                <v-icon color="green" style="transform: rotateY(180deg);">mdi-import</v-icon>&nbsp;Remove
              </v-btn>
              <v-btn @click="clearAll">
                <v-icon color="green">mdi-close-circle-outline</v-icon>&nbsp;Clear All
              </v-btn>
            </v-flex>
            <v-btn @click="switchMode">
              <v-icon color="green">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-row>
        <v-row v-for="address in addresses" :key="address" class="pa-4 ma-0 font-weight-medium align-center background">
          <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">
            <div class="d-flex align-self-center">
              <div>
                {{ $root.condenseAddress(address) }}
              </div>
              <copy-address :address="address" color="textFaint"></copy-address>

            </div>
            <div style="flex: 1; text-align: right;">
              <v-btn @click="removeAddress(address)">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </v-row>
        <v-row class="pa-4 ma-0 font-weight-medium align-center">
          <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">
            <div style="justify-content: flex-start; width: 48%;">
              <v-btn
                rounded block depressed large
                color="secondary"
                dark
                @click="close"
              >
                Cancel
              </v-btn>
            </div>
            <div style="justify-content: flex-end; width: 48%;">
              <v-btn
                rounded block depressed large
                color="primary"
                class="br-20 gradient-button-v1"
                @click="closeAndResolve"
              >
                Confirm
              </v-btn>
            </div>
          </div>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: 'Addresses'
    },
    label: {
      type: String,
      default: 'Addresses'
    },
    onConfirm: {
      type: Function
    },
    custom: {
      type: String,
      default: 'Normal'
    },
    addressesData: {
      type: Array,
      default () {
        return []
      }
    },
    inputData: {
      type: Array,
      default () {
        return []
      }
    },
  },
  components: {},
  data: () => ({
    addressInput: '',
    bulkAddressInput: '',
    isOpen: false,
    resolve: null,
    reject: null,
    addresses: [],
    inputMode: 'SINGLE',
  }),
  computed: {},
  // watch for changes and update addresses
  watch: {
    inputData: {
      handler (newData, oldData) {
        if (newData !== oldData) {
          this.addresses = [];
          if (this.custom === "lpTokens") {
            newData.forEach(i => {
              this.addresses.push(i);
            });
          } else {
            newData.forEach(i => {
              this.addresses.push(i.id);
            });
          }
        }
      },
      deep: true
    }
  },
  methods: {
    open () {
      this.isOpen = true;
      // // this.addresses = this.inputData;
      // this.inputData.forEach(i => {
      //   this.addresses.push(i.id);
      // });
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    close () {
      this.isOpen = false;
      this.reject();
    },
    closeAndResolve () {
      this.isOpen = false;
      this.$emit('on-confirm', this.addresses);
      this.resolve();
    },
    addAddress () {
      const re = /0x[a-fA-F0-9]{40}/;

      if (this.addressInput !== '' && !this.addresses.includes(this.addressInput) && re.test(this.addressInput)) {
        this.addresses.push(this.addressInput);
      }
      this.$emit('on-single-add', this.addressInput);
      this.addressInput = '';
    },
    switchMode () {
      if (this.inputMode === 'SINGLE') {
        this.inputMode = 'BULK';
      } else {
        this.inputMode = 'SINGLE';
      }
    },
    removeAddress (address) {
      // this.addresses = this.addresses.filter(_address => _address !== address);
      this.$emit('on-single-remove', address);
    },
    bulkRemove () {
      const userAddresses = [...new Set(this.bulkAddressInput.split('\n').filter(item => item !== ''))];
      // this.addresses = this.addresses.filter(address => !userAddresses.includes(address));
      this.$emit('on-bulk-remove', userAddresses);
      this.bulkAddressInput = '';
    },
    bulkAppend () {
      const userAddresses = [...new Set(this.bulkAddressInput.split('\n').filter(item => item !== ''))];
      const dedupedAddresses = userAddresses.filter(address => !this.addresses.includes(address));
      // this.addresses.push(...dedupedAddresses);
      this.$emit('on-bulk-add', dedupedAddresses);
      this.bulkAddressInput = '';
    },
    clearAll () {
      // this.$emit('on-bulk-remove', this.addresses);
      // this.addresses = [];
      this.bulkAddressInput = '';
    }
  },
  mounted() {
    this.addresses = [];
    if (this.custom === "lpTokens") {
      this.inputData.forEach(i => {
        this.addresses.push(i);
      });
    } else {
      this.inputData.forEach(i => {
        this.addresses.push(i.id);
      });
    }
  }
}
</script>