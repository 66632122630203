<template>
  <div class="v-card br-20">

    <div v-if="!sEthers.coinbase" class="px-4 py-8">
      <v-btn large block rounded outlined color="textFaint" @click="connectWallet" style="border: 1.5px solid;">
        <v-icon size="24" color="">mdi-ethereum</v-icon>
        <div>
          <div class="">
            Connect your wallet
          </div>
        </div>
      </v-btn>
    </div>

    <div v-else class="">
      <template v-if="enmtTokens.length > 0">
        <div class="caption pa-2 text-center">
          Your ENMT tokens
        </div>
        <div style="overflow-y: hidden;" class="unselectable py-4 background u-scrollbar d-flex mb-8">
          <div 
          v-for="token of enmtTokens" 
          :key="token.id"
          @click="onTokenSelected(token)"
          class="v-card c-list br-c mr-1 d-flex align-center pa-2" style="min-width: 180px;overflow:hidden;">
            <coin-icon :address="token.id" class="mr-1" />
            <div>
              <div class="caption">
                {{ token.symbol }}
              </div>
              <div class="caption">
                {{ $root.formatAmount(token.totalSupply, token.decimals) }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="taxTokens.length > 0">
        <div class="caption pa-2 text-center">
          Your Tax tokens
        </div>
        <div style="overflow-y: hidden;" class="unselectable py-4 background u-scrollbar d-flex mb-8">
          <div 
          v-for="token of taxTokens" 
          :key="token.id"
          @click="onTokenSelected(token)"
          class="v-card c-list br-c mr-1 d-flex align-center pa-2" style="min-width: 180px;overflow:hidden;">
            <coin-icon :address="token.id" class="mr-1" />
            <div>
              <div class="caption">
                {{ token.symbol }}
              </div>
              <div class="caption">
                {{ $root.formatAmount(token.totalSupply, token.decimals) }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SETTINGS from '@/store/settings'
import axiosQL from '@/js/axios-graphql-interceptor';

export default {
  data: () => ({
    taxTokens: [],
    enmtTokens: [],
    loading: false
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
  },
  watch: {
    sEthersWatcher () {
      this.refresh()
    },
  },
  methods: {
    onTokenSelected (token) {
      this.$emit("onTokenSelected", token)
    },
    refresh () {
      this.getTokensByOwner()
    },
    async getTokensByOwner () {
      this.loading = true
      this.fetchTokens()
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async fetchTokens () {
      var userAddress = this.sEthers.coinbase.toLowerCase();
      this.taxTokens = [];
      this.enmtTokens = [];

      var data = {
        query: `
          {
            taxTokens(where: {owner: "${userAddress}"}) {
              id
              name
              symbol
              decimals
              totalSupply
              isLosslessOn
              losslessAdmin {
                id
              }
              losslessRecoveryAdmin {
                id
              }
              lossless
              taxSettings {
                isTransactionTaxOn
                isBuyBackTaxOn
                isHolderTaxOn
                isLpTaxOn
                canBlacklist
                canMint
                canPause
                isMaxBalanceAfterBuyOn
              }
              isLocked {
                isTransactionTaxOn
                isBuyBackTaxOn
                isHolderTaxOn
                isLpTaxOn
                canBlacklist
                canMint
                canPause
                isMaxBalanceAfterBuyOn
              }
              fees {
                transactionTax {
                  buy
                  sell
                }
                buyBackTax
                holderTax
                lpTax
              }
              customTaxLength
              customTaxes {
                id
                name
                fee {
                  buy
                  sell
                }
                wallet {
                  id
                }
              }
              transactionTaxWallet {
                id
              }
              taxHelperIndex
              pairAddress
              lpTokens
              buyBackWallet {
                id
              }
              lpWallet {
                id
              }
              excluded {
                id
              }
              maxBalanceAfterBuy
              antiBotSettings {
                startBlock
                endDate
                increment
                initialMaxHold
                isActive
              }
              swapWhitelistingSettings {
                endDate
                isActive
              }
              owner {
                id
              }
              isPaused
              isTaxed
              buyBackWalletThreshold
              lpWalletThreshold
              blacklist {
    					  id
    					}
              swapWhitelist {
                id
              }
    					maxBalanceWhitelist {
    					  id
    					}
              marketInitTimestamp
            }
            enmtTokens(where: {owner: "${userAddress}"}) {
              id
              name
              symbol
              decimals
              totalSupply
            }
          }
        `
      }

      var network = this.$store.state.requiredNetwork;
      var graphExplorer = SETTINGS.CHAINS[network].graphExplorerMinterV2;

      var response = await axiosQL.post(graphExplorer, data);

      if (response.data.data.taxTokens !== null) {
        this.taxTokens = response.data.data.taxTokens;
      }
      if (response.data.data.enmtTokens !== null) {
        this.enmtTokens = response.data.data.enmtTokens;
      }
    },
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .catch(e => {})
    },
  },

  created () {
    this.refresh()
  }
}
</script>
