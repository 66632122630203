import {store} from '@/store/index';

const erc20_pager_address = {
  'Mainnet': '0x9aD929C9651bbdc432ff95A19c8FaF1285CA7e21', // NEW
  'Kovan': '0x65B1211995a7a19301A90D3EAf7A2D71Ba18A403',
  'Sepolia': '0x8cE7679225A18E666AA83C018Cf6262e3AD8d3F8',
  'Goerli': '0x1BDe81E29181d421a73D88C675c29C17154DeEE1', // NEW
  'BSC_MAINNET': '0x3913D92E6B94A03fa11f642c7f9A89B5981B731f', // NEW
  'BSC_TESTNET': '0xeDE580AB31C892dc0D2E01Fa61B63F4472C9e51F',
  'xDai': '0xCA07E89e9674e9BC5bB9CaDE6771FEc8e14e4042',
  'Matic': '0xf79525fc2a5Eb62F73A9D78C1Df79198615Dcb3E', // NEW
  'Hardhat': '0xADe6aA1bfC271FC0b0953bD7Ed8C19a7433EdB87',
  'Ganache': '0x08655a59381EA4fEbF210D609D52c0F2Ac47b8d1',
  'AVAX': '0xfd3c8275915BdaE1f49ECB56112E9c381c0996C0',
  'DogeChain': '0xba80434AFDc492F7296B5e278B375A627536c203',
  'ArbitrumOne': '0xd3ce719Df12ea6D660ddeCaE421C0E20c4741825',
  'Base': '0x1D91AaF6A092469DeAee2D5E27775c1FdfD53D2A',
}

const Self = {
  erc20_pager_address: (network = null) => {
    return erc20_pager_address[network || store.state.requiredNetwork]
  },
  erc20_pager_abi: [{"inputs":[{"internalType":"address","name":"_enmtFactory","type":"address"},{"internalType":"address","name":"_taxTokenFactory","type":"address"}],"stateMutability":"nonpayable","type":"constructor"},{"inputs":[],"name":"ENMT_FACTORY","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"TAXTOKEN_FACTORY","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"_token","type":"address"}],"name":"getERC","outputs":[{"internalType":"string","name":"","type":"string"},{"internalType":"string","name":"","type":"string"},{"internalType":"uint256","name":"","type":"uint256"},{"internalType":"uint256","name":"","type":"uint256"},{"internalType":"bool","name":"","type":"bool"},{"internalType":"bool","name":"","type":"bool"}],"stateMutability":"view","type":"function"}]
}

export default Self