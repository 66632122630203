<template>
  <div>
    <v-row class="pa-2" style="width: 100%;">
      <div v-if="showTitle" class="text-center pa-4 font-weight-medium mb-0" style="width: 100%;">
        Max Balance
      </div>
    </v-row>
    <v-row class="align-center">
      <v-col cols="6" class="d-flex align-center pa-4">
        Initial Max Balance
      </v-col>
      <v-col cols="6">
        <div class="d-flex align-center inputcolor pa-2 r-outlined">
          <c-input
            :value.sync="values.initialMaxHold"
            title="Initial Max Balance"
            type="number"
            min="0"
          />
          {{tokenSymbol}}
        </div>
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col cols="6" class="d-flex align-center pa-4">
        Max Wallet Increment
      </v-col>
      <v-col cols="6">
        <div class="d-flex align-center inputcolor pa-2 r-outlined">
          <c-input
            :value.sync="values.increment"
            title="Max Wallet Increment"
            type="number"
            min="0"
          />
          {{tokenSymbol}}
        </div>
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col cols="6" class="d-flex align-center pa-4">
        Ending Date
      </v-col>
      <v-col cols="6">
        <div class="d-flex align-center inputcolor pa-2 r-outlined">
          <c-input
            :value.sync="values.endDate"
            title="Ending Date"
            type="number"
            max="48"
            min="0"
          />
          Hours
        </div>
      </v-col>
    </v-row>
    <v-row style="justify-content: space-between; width: 50%; margin-left: 52%;">
      <div v-if="showEnabled" class="d-flex flex-row justify-space-between align-center font-weight-medium" style="padding: 8px; margin-right: 8px; width: 100%">
        On/Off
        <v-switch
          v-model="values.isActive"
          color="primary"
          class="ma-0"
          hide-details
          style="align-self: center;"
        />
      </div>
    </v-row>
  </div>
</template>
<script>

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showEnabled: {
      type: Boolean,
      default: true
    },
    showIncrement: {
      type: Boolean,
      default: true
    },
    initialValues: {
      type: Object
    },
    symbol: {
      type: String
    }
  },
  data: ({ showTitle, showEnabled, showIncrement, initialValues, symbol}) => ({
    showTitle,
    showEnabled,
    showIncrement,
    values: initialValues,
    tokenSymbol: symbol
  }),
  methods: {
    validate (values) {
      if (values.endDate < 0) {
        values.endDate = 0;
      } else if (values.endDate > 48) {
        values.endDate = 48;
      }
    },
  }
}
</script>