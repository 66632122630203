import { render, staticRenderFns } from "./locked-pairs.vue?vue&type=template&id=cc80f944&"
import script from "./locked-pairs.vue?vue&type=script&lang=js&"
export * from "./locked-pairs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports