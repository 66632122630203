<template>
  <!--
    TODO:
      - move to components dir
  -->
  <v-dialog v-model="isOpen" max-width="500" content-class="br-20">
    <v-card class="br-20">
      <div>
        <v-row class="pa-4 ma-0 font-weight-medium align-center">
          <div>
            {{ title }}
          </div>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="pa-4 ma-0 font-weight-medium align-center background">
          <div class="d-flex align-end pa-3 br-20 inputcolor my-2" style="width: 100%;">
            <c-input :value.sync="input" />
            {{symbol}}
          </div>
        </v-row>
        <v-row class="pa-4 ma-0 font-weight-medium align-center">
          <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">
            <div style="justify-content: flex-start; width: 48%;">
              <v-btn
                rounded block depressed large
                color="secondary"
                dark
                @click="close"
              >
                Cancel
              </v-btn>
            </div>
            <div style="justify-content: flex-end; width: 48%;">
              <v-btn
                rounded block depressed large
                color="primary"
                class="br-20 gradient-button-v1"
                @click="closeAndResolve"
              >
                {{title}}
              </v-btn>
            </div>
          </div>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: 'Mint'
    },
    symbol: {
      type: String
    },
    onConfirm: {
      type: Function
    }
  },
  components: {},
  data: () => ({
    input: 0,
    isOpen: false
  }),
  computed: {},
  methods: {
    open () {
      this.isOpen = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    close () {
      this.isOpen = false;
      this.input = 0;
      this.reject();
    },
    closeAndResolve () {
      this.isOpen = false;
      this.$emit('on-confirm', this.input);
      this.input = 0;
      this.resolve();
    },
  }
}
</script>