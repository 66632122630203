var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"max-width":"600px"}},[([
  'Uniswap V2',
  'Sushiswap V1',
  'Pancakeswap V2',
  'Pancakeswap V1',
  'Julswap V1',
  'Quickswap V1',
  'Honeyswap V1',
  'Uniswap V2 - Kovan'
  ].includes(_vm.$store.state.exchange))?_c('page-v1-locker',{attrs:{"address":_vm.address}}):_c('page-v2-locker',{attrs:{"address":_vm.address}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }