<template>
  <div class="pt-4">

    <div v-if="presaleInfo.presale_status === 3" class="title my-4 text-center">
      This presale has been cancelled!
    </div>

    <template v-else>
      <div class="mt-2">
        Would you like to cancel this presale and allow user withdrawals?
      </div>

      <v-btn x-large rounded block @click="cancelPresale" color="primary" class="mt-4" :disabled="presaleInfo.presale_status === 4">
        Cancel this sale
      </v-btn>
    </template>

  </div>
</template>

<script>
import PresaleContract from '@/smart-contracts/presales/v6/presale-contract'
import TxWrapper from '@/web3/tx-wrapper-2';

export default {
  props: {
    address: {
      type: String
    }
  },

  components: {
  },

  data: () => ({
    presaleInfo: {
      presale_owner: '',
      token: {

      },
      base_token: {
        symbol: ''
      },
      eth_price: '0',
      token_price: '0',
      max_spend_per_buyer: '0',
      hardcap: '0',
      softcap: '0',
      total_eth_collected: '0'
    }
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    userIsOwner () {
      return this.sEthers.coinbase === this.presaleInfo.presale_owner
    }
  },

  methods: {
    async getPresaleInfo () {
      var response = await PresaleContract.getInfo(this.address)
      this.presaleInfo = response.presale_info
    },
    async cancelPresale () {
      this.createLoading = true
      TxWrapper.doTransaction(PresaleContract.forceFailByPresaleOwner,
        [this.address], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          this.getPresaleInfo()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.createLoading = false
        })
    },
    async refresh () {
      await this.getPresaleInfo()
    }
  },

  created () {
    this.refresh()
  }
}
</script>