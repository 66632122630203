import Vue from 'vue'

// UI COMPONENTS
import ExpansionPanel from '@/components/ui/expansion-panel'
import CInput from '@/components/ui/input'
import CTextArea from '@/components/ui/textarea'
import CoinIcon from '@/components/ui/coin-icon'
import CPagination from '@/components/ui/pagination/pagination'
import CopyAddress from '@/components/ui/copy-address'
import ENMT from '@/components/ui/enmt'
import LoadToken from '@/components/ui/load-token'
import TimeTicker from '@/components/date-time/time-display-ticker'
import CandleChart from '@/components/charts/candle-chart-embed'

// UI COMPONENTS
Vue.component('expansion-panel', ExpansionPanel)
Vue.component('c-input', CInput)
Vue.component('c-textarea', CTextArea)
Vue.component('coin-icon', CoinIcon)
Vue.component('c-pagination', CPagination)
Vue.component('copy-address', CopyAddress)
Vue.component('enmt-badge', ENMT)
Vue.component('load-token', LoadToken)
Vue.component('time-ticker', TimeTicker)
Vue.component('candle-chart', CandleChart)
