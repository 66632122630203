<template>
  <div class="v-card br-20">

    <div v-if="!sEthers.coinbase" class="px-4 py-8">
      <v-btn large block rounded outlined color="textFaint" @click="connectWallet" style="border: 1.5px solid;">
        <v-icon size="24" color="">mdi-ethereum</v-icon>
        <div>
          <div class="">
            Connect your wallet
          </div>
        </div>
      </v-btn>
    </div>

    <template v-else>
      <div class="d-flex align-center title pa-4 border-b">
        Your ENMT Tokens 
        <span class="textFaint--text ml-2">({{ numTokens }})</span>
        <v-spacer></v-spacer>
        <v-btn v-if="!loading" @click="refresh" icon color="textFaint">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <div v-else style="height: 36px; width: 36px;" class="d-flex align-center justify-center">
          <v-progress-circular
          indeterminate
          size="14"
          width="2"
          color="primary">
          </v-progress-circular>
        </div>
      </div>

      <div class="">
        <template v-if="tokens.length > 0">

          <div v-if="false" class="background caption d-flex pr-4 py-2 textFaint--text">
            <v-spacer></v-spacer>
            <div>
              Total supply
            </div>
          </div>
          <tokens-row v-for="token of tokens" :key="token.address" :item="token">
          </tokens-row>

          <div v-if="pageCount > 1" class="d-flex align-center justify-center background pa-2">
            <v-btn @click="goPrevious" text :disabled="page === 0">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            {{ page + 1 }} / {{ pageCount }}
            <v-btn @click="goNext" text :disabled="!nextPageExists">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>

        </template>
        <div v-else-if="!loading" class="py-8 pa-4 text-center">
          <div>
            You have not minted any ENMT tokens yet.
          </div>
          <div class="caption textFaint--text">
            All ENMT tokens generated by your account will be shown here.
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ERC20 from '@/smart-contracts/erc20';
import MintFactoryContract from '@/smart-contracts/minter/mint-factory';
import tokensRow from './tokens-row.vue';

export default {
  components: { tokensRow },
  data: () => ({
    tokens: [],
    rowsPerPage: 3,
    page: 0,
    numTokens: 0,
    loading: false
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    nextPageExists () {
      return (this.page + 1) * this.rowsPerPage < this.numTokens
    },
    pageCount () {
      return Math.ceil(this.numTokens / this.rowsPerPage)
    }
  },
  watch: {
    sEthersWatcher () {
      this.page = 0
      this.refresh()
    },
  },
  methods: {
    goNext () {
      this.page++
      this.refresh()
    },
    goPrevious () {
      this.page--
      this.refresh()
    },
    refresh () {
      this.getTokensByOwner()
    },
    async getTokensByOwner () {
      this.loading = true
      var userAddress = this.sEthers.coinbase;
      this.numTokens = await MintFactoryContract.getTokenLengthByOwner(userAddress)
      var tokens = []
      var startIndex = this.numTokens - 1 - (this.rowsPerPage * this.page)
      var endIndex = startIndex - this.rowsPerPage
      if (endIndex < -1) {
        endIndex = -1
      }
      for (var i = startIndex; i > endIndex; i--) {
        var tokenAddress = await MintFactoryContract.getTokenByOwnerAtIndex(userAddress, i)
        var tokenHydrated = await ERC20.getERC20(tokenAddress)
        tokens.push(tokenHydrated)
      }
      this.tokens = tokens
      this.loading = false
    },
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .catch(e => {})
    },
  },

  created () {
    this.refresh()
  }
}
</script>
