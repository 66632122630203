<template>

  <div>

    <div v-if="firstLoad">
      <div class="text-center mt-6" style="height: 80vh;">
        <v-progress-circular
        indeterminate
        size="130"
        color="#aaa">
          <img 
          src="@/assets/img/UNCX_fill.svg" 
          height="120px"
          class="greyscale"
          width="120px">
        </v-progress-circular>
        <div class="pt-4 textFaint--text">
          Loading Stake Pool info
        </div>
      </div>
    </div>

    <template v-else>

      <v-expand-transition mode="out-in">
        <div v-if="showInfoTip">
          <div class="v-card pa-4 mx-2 mt-3 caption br-20 textFaint--text d-flex align-center">
            <v-icon class="mr-2" color="textFaint">mdi-information-outline</v-icon>
            <div class="flex">
              You can easily access all your farms from the 
              <router-link to="/account" class="deadlink navcolor--text">
                Account page
              </router-link>
              under the 'farms' tab
            </div>
            <v-btn icon color="textFaint" @click="showInfoTip = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </v-expand-transition>

      <div :class="[{'mt-2': !$vuetify.breakpoint.xs}]">
        
        <div class="px-4 d-flex align-center text-uppercase pt-4">
          <div>
            <div class="textFaint--text">
              Stake Pool
            </div>
            <div class="display-1 font-weight-medium">
              ORACLE
            </div>
          </div>
          <v-spacer></v-spacer>
        </div>

        <div class="d-flex justify-end align-center px-4">
          <v-btn small rounded depressed @click="expandStakerSheet = true" color="foreground">
            Stakers
          </v-btn>
          <v-btn small rounded depressed @click="expandSettings = true" color="foreground">
            Settings
          </v-btn>
          <v-btn small icon text color="text" class="foreground" @click="refresh">
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
        </div>
        
        <div v-if="!$vuetify.breakpoint.xs" :class="['mt-4 br-20', {'v-card': !$store.state.darkMode}]">
          <div class="d-flex align-center py-2">
            <coin-icon :address="address" :size="60" class="mx-2"></coin-icon>
            <v-row dense class="ma-0">
              <v-col cols="4">
                <div class="caption textFaint--text">
                  STAKERS
                </div>
                <div class="">
                  {{ pool_info.num_stakers }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="caption textFaint--text">
                  <v-menu offset-y open-on-hover transition="scale-transition" max-width="300px">
                    <template v-slot:activator="{ on, attrs }">
                      <div 
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex align-center">
                        SHARE WEIGHT
                        <v-icon
                        small
                        color="textFaint"
                        class="ml-1"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </div>
                    </template>
                    <div class="pa-3 foreground caption">
                      Share weight = SharesStaking * (TimeBoost + BurnBoost)
                    </div>
                  </v-menu>
                </div>
                <div class="">
                  {{ $root.formatAmount(pool_info.share_weight_total, pool_info.decimals) }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="caption textFaint--text">
                  CONTRACT
                </div>
                <div class="">
                  <copy-address :address="address" color="text"></copy-address>
                </div>
              </v-col>
            </v-row>
          </div>
          <div class="pt-1 midground">

          </div>
          <!-- ACCOUNT -->
          <div v-if="sEthers.coinbase" class="d-flex align-center py-2">
            <v-icon size="60" class="px-2">mdi-account-circle-outline</v-icon>
            <v-row dense class="ma-0 d-flex align-center">
              <v-col cols="4">
                <div class="caption textFaint--text">
                  YOUR SHARE
                </div>
                <div class="">
                  {{ poolSharePercent }}%
                </div>
              </v-col>
              <v-col cols="4">
                <div class="caption textFaint--text">
                  <v-menu offset-y open-on-hover transition="scale-transition" max-width="300px">
                    <template v-slot:activator="{ on, attrs }">
                      <div 
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex align-center">
                        YOUR WEIGHT
                        <v-icon
                        small
                        color="textFaint"
                        class="ml-1"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </div>
                    </template>
                    <div class="pa-3 foreground caption">
                      Your Share weight = SharesStaking * (TimeBoost + BurnBoost)
                    </div>
                  </v-menu>
                </div>
                <div class="">
                  {{ $root.formatAmount(user_info.share_weight, pool_info.decimals) }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex align-center">
                  <v-progress-linear height="36" :value="100"
                  color="lgrad-blue"
                  background-color=""
                  style="width: 36px;"
                  class="font-weight-bold br-c">
                    <v-icon size="30" color="white">mdi-flash-circle</v-icon>
                  </v-progress-linear>
                  <v-progress-linear height="36" :value="100"
                  color="lgrad-green"
                  background-color=""
                  style="width: 36px;"
                  class="font-weight-bold br-c">
                    <v-icon size="30" color="white">mdi-flash-circle</v-icon>
                  </v-progress-linear>
                  <div class="pl-2">
                    {{ totalUserBoost }}%
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="$vuetify.breakpoint.xs" class="px-1 pt-6">
          <div class="px-4 mt-3 d-flex textFaint--text">
            Total Staking
            <v-spacer></v-spacer>
            Total Stakers
          </div>
          <div class="v-card d-flex align-center pa-4 br-20">
            <!-- v-if="rpool_user_info.unharvested_rewards !== '0'" -->
            <coin-icon :address="address" :size="30" class="mr-2"></coin-icon>
            <div class="">
              <div class="text--text">
                {{ $root.formatAmount(stakePoolBalance, pool_info.decimals) }}
              </div>
            </div>
            <v-spacer></v-spacer>
            <div class="text--text">
              {{ pool_info.num_stakers }}
            </div>
            <v-icon size="30" color="textFaint" class="ml-2">mdi-account-group-outline</v-icon>
          </div>
        </div>

        <!-- USER -->
        <div v-if="$vuetify.breakpoint.xs && sEthers.coinbase" class="mt-2 px-1">
          <div class="px-4 mt-3 d-flex textFaint--text">
            Your info
          </div>
          <div class="d-flex v-card br-20 align-center px-3 py-2">
            <v-icon size="30" class="mr-2" color="textFaint">mdi-account-circle</v-icon>

            <div class="flex">
              <div class="d-flex align-center text--text">
                {{ $root.formatAmount(user_info.share_weight, pool_info.decimals) }}
                <v-spacer></v-spacer>
                <div class="d-flex textFaint--text caption align-center font-weight-bold">
                  <v-progress-linear height="24" :value="100"
                  color="lgrad-green"
                  background-color=""
                  style="width: 24px;"
                  class="font-weight-bold br-c">
                    <v-icon size="20" color="white">mdi-flash-circle</v-icon>
                  </v-progress-linear>
                  <v-progress-linear height="24" :value="100"
                  color="lgrad-blue"
                  background-color=""
                  style="width: 24px;"
                  class="font-weight-bold br-c">
                    <v-icon size="20" color="white">mdi-flash-circle</v-icon>
                  </v-progress-linear>
                </div>
              </div>
              <div class="d-flex align-center">
                <div class="caption textFaint--text">
                  {{ poolSharePercent }}% of pool
                </div>
                <v-spacer></v-spacer>
                <div v-if="false" class="ml-6 textFaint--text caption">
                  {{ $root.formatAmount(user_info.share_weight, pool_info.decimals) }} SW
                </div>
                <div class="textFaint--text caption">
                  <span class="pr-1">
                    {{ user_info.time_boost_percentage / 100 }}%
                  </span>
                  +
                  <span class="px-1">
                    {{ user_info.uncl_boost_percentage / 100 }}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- USER -->

        <v-row dense class="ma-0 mt-6">
          <v-col cols="12" md="5">
            
            <user-panel 
            :address="address" 
            :user_info="user_info" 
            :pool_info="pool_info" 
            @hard-refresh="refresh"></user-panel>

          </v-col>
          <v-col cols="12" md="7">
            
            <div :class="['v-card br-20', {'mt-10 mb-10' : $vuetify.breakpoint.xs}]">

              <reward-list :stake_pool_address="address" :user_info="user_info" :pool_info="pool_info" @on-sub-event="getUserInfo" ref="rewardList"></reward-list>
                  
            </div>
          </v-col>
        </v-row>

      </div>

    </template>

    <v-dialog v-model="expandStakerSheet" :fullscreen="$vuetify.breakpoint.xs" scrollable :content-class="!$vuetify.breakpoint.xs ? 'br-20' : ''" width="1000px">
        <v-card :class="['midground', {'br-20': !$vuetify.breakpoint.xs}]">
          <div class="d-flex border-b pa-4">
            <div class="title">
              Stakers
            </div>
            <v-spacer></v-spacer>
            <v-btn color="textFaint" icon @click="expandStakerSheet = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pa-0 foreground">
            <staker-view :stake_pool_address="address" :pool_info="pool_info"></staker-view>
          </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="expandSettings" :fullscreen="$vuetify.breakpoint.xs" scrollable :content-class="!$vuetify.breakpoint.xs ? 'br-20' : ''" width="600px">
        <v-card :class="['midground', {'br-20': !$vuetify.breakpoint.xs}]">
          <div class="d-flex align-center border-b pa-4">
            <div class="title">
              Settings
            </div>
            <v-spacer></v-spacer>
            <v-btn large color="textFaint" icon @click="expandSettings = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pa-0 foreground">
            <settings-panel v-if="expandSettings" :address="address" :pool_info="pool_info" @hard-refresh="refresh" @close-settings="onRewardPoolCreated"></settings-panel>
          </v-card-text>
        </v-card>
    </v-dialog>
    <!--
    <v-overlay
    absolute
    color="background"
    :opacity="0.9"
    :value="!sEthers.coinbase"
    >
      <v-btn
        x-large
        color="primary"
        @click="connectWallet"
      >
        Connect your wallet
      </v-btn>
    </v-overlay>
    -->

  </div>
</template>

<script>
import { ethers } from 'ethers'
import StakePoolContract from '@/smart-contracts/farms-v2/oracle/stake-pool/v2/stake-pool'
import StakerView from './staker-view'
import SettingsPanel from './settings'
import RewardList from '@/views/farms/rewards/ui/list'
import UserPanel from './user-panel'

export default {

  props: {
    address: null
  },

  components: {
    StakerView,
    SettingsPanel,
    RewardList,
    UserPanel
  },

  data: () => ({
    showInfoTip: true,
    pool_info: {
      uncl_boost_max_percentage: '0',
      boost_options: [],
      share_weight_total: '0'
    },
    user_info: {
      user_address: null,
      share_weight: '0',
      time_boost_percentage: '0',
      uncl_boost_percentage: '0'
    },

    stakePoolBalance: '0',
    expandStakerSheet: false,
    expandSettings: false,

    firstLoad: true
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    totalUserBoost () {
      var combined = ethers.BigNumber.from(this.user_info.uncl_boost_percentage).add(this.user_info.time_boost_percentage)
      return combined.toNumber() / 100
    },
    poolSharePercent () {
      if (this.pool_info.share_weight_total === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.user_info.share_weight).mul(10000).div(this.pool_info.share_weight_total)
      return percent.toNumber() / 100
      // return 0
    }
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    onRewardPoolCreated () {
      this.expandSettings = false
      try {
        this.$refs.rewardList.refresh()
      } catch (e) {}
    },
    async refresh () {
      this.pool_info = await StakePoolContract.getPoolInfo(this.address)
      this.firstLoad = false
      this.getStakePoolBalance()
      this.getUserInfo()
    },
    async getUserInfo () {
      this.user_info = await StakePoolContract.getUserInfo(this.address, this.sEthers.coinbase)
    },
    async getStakePoolBalance () {
      // this.stakePoolBalance = await ERC20BALANCE.getBalance(this.address, this.stake_token_hydrated.address)
    }
  },

  created () {
    this.refresh()
  }
}
</script>