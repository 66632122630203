<template>
  <div>
    <v-container>
      <v-row v-if="!$vuetify.breakpoint.smAndDown" justify="space-between">
        <v-col cols="3" class="d-flex flex-row justify-center">
          <v-spacer />
        </v-col>
        <v-col class="d-flex flex-row justify-center background" style="text-align: center;">
          <div class="font-weight-medium align-self-center align-center" style="font-size: smaller;">
            Enabled
          </div>
        </v-col>
        <v-col class="d-flex flex-row justify-center" style="text-align: center; overflow: hidden;">
          <div class="font-weight-medium align-self-center align-center" style="font-size: smaller;">
            Wallet Threshold ({{gasToken}})
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>The gas token threshold in the Buyback and LP wallets. When this threshold is passed, it will trigger the tax during that transaction.</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col class="d-flex flex-row justify-center background">
          <div class="font-weight-medium align-self-center" style="font-size: smaller;">
            Buy Fee
          </div>
        </v-col>
        <v-col class="d-flex flex-row justify-center" style="text-align: center;">
          <div class="font-weight-medium align-self-center" style="font-size: smaller;">
            Sell Fee
          </div>
        </v-col>
        <v-col class="d-flex flex-row justify-center background">
          <div class="font-weight-medium align-self-center" style="font-size: smaller;">
            Locked
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Locking is permanent, and cannot be undone. Once locked, settings can no longer be toggled on or off.</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
      <v-row class="border-t" v-if="!$vuetify.breakpoint.smAndDown" />
      <v-row>
        <v-col cols="12" md="3" class="pa-0 align-self-center">
          <div class="mb-2 font-weight-bold pt-4" style="text-align: left;">
            Transaction Tax
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>This is your standard tax - it will be deducted from each buy and sell and go to any specified wallet.</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" md="3" class="align-self-center">
          <div class="d-flex align-center inputcolor pa-2 r-outlined">
            <c-input
              :value.sync="values.transactionTaxAddress"
              placeholder="Address..."
            />
          </div>
        </v-col>
        <v-col :class="['d-flex flex-column', {'background justify-center': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-2">Enabled</div>
          <v-switch
            v-model="values.taxSettings.transactionTax"
            color="primary"
            class="ma-0"
            hide-details
            style="align-self: center;"
            :disabled="isLockedDisabled.transactionTax"
          />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col :class="['d-flex flex-column justify-center', {'background ': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown">Buy Fee</div>
          <div class="d-flex align-center inputcolor pa-2 r-outlined">
            <c-input
              :value.sync="values.fees.transactionTax.buy"
              placeholder="0.0"
              type="number"
              max="100"
              min="0"
            />
            %
          </div>
        </v-col>
        <v-col :class="['d-flex flex-column justify-center', {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown">Sell Fee</div>
          <div class="d-flex align-center inputcolor pa-2 r-outlined">
            <c-input
              :value.sync="values.fees.transactionTax.sell"
              placeholder="0.0"
              type="number"
              max="100"
              min="0"
            />
            %
          </div>
        </v-col>
        <v-col :class="['d-flex flex-column', {'background justify-center': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-2">Locked 
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Locking is permanent, and cannot be undone. Once locked, settings can no longer be toggled on or off.</span>
            </v-tooltip></div>
          <v-switch
            v-model="values.lockedSettings.transactionTax"
            color="red"
            class="ma-0"
            hide-details
            style="align-self: center;"
            :disabled="isLockedDisabled.transactionTax"
          />
        </v-col>
      </v-row>
      <v-row class="border-t" />
      <v-row>
        <v-col cols="12" md="3" class="pa-0 align-self-center">
          <div class="mb-2 font-weight-bold pt-4" style="text-align: left;">
            Buyback Tax
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="ma-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Buyback is an automated tax. When turned on, it will trigger automated buys (depending on a set up threshold) and remove the tokens being bought back from the token total supply. Just set the value and that's it</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" md="3" class="d-flex flex-row justify-center">
          <v-spacer />
        </v-col>
        <v-col :class="['d-flex flex-column justify-center', {'background ': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-2">Enabled</div>
          <v-switch
            v-model="values.taxSettings.buyBackTax"
            color="primary"
            class="ma-0"
            hide-details
            style="align-self: center;"
            :disabled="isLockedDisabled.buyBackTax"
          />
        </v-col>
        <v-col class="d-flex flex-column justify-center">
          <div v-if="$vuetify.breakpoint.smAndDown">Threshold</div>
          <div class="d-flex align-center inputcolor pa-2 r-outlined">
            <c-input
              :value.sync="values.buybackTaxThreshold"
              placeholder="0.0"
              type="number"
            />
          </div>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown" class="d-flex flex-row justify-center background">
          <v-spacer />
        </v-col>
        <v-col :class="['d-flex flex-column justify-center', {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown">Sell Fee</div>
          <div class="d-flex align-center inputcolor pa-2 r-outlined">
            <c-input
              :value.sync="values.fees.buyBackTax"
              placeholder="0.0"
              type="number"
              max="100"
              min="0"
            />
            %
          </div>
        </v-col>
        <v-col :class="['d-flex flex-column', {'background justify-center': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-2">Locked
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Locking is permanent, and cannot be undone. Once locked, settings can no longer be toggled on or off.</span>
            </v-tooltip>
          </div>
          <v-switch
            v-model="values.lockedSettings.buyBackTax"
            color="red"
            class="ma-0"
            hide-details
            style="align-self: center;"
            :disabled="isLockedDisabled.buyBackTax"
          />
        </v-col>
      </v-row>
      <v-row class="border-t" />
      <v-row>
        <v-col cols="12" md="3" class="pa-0 align-self-center">
          <div class="mb-2 font-weight-bold pt-4" style="text-align: left;">
            Reflection Tax
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Reflections are a special type of a tax - whenever a transaction happens, a part of it will be deducted and distributed among holders based on how many tokens they hold. It’s essentially a reward for holding! Note: Reflection activates on all transactions, including both buys and sells. Minting is now allowed when relfection is activated. Reflection is locked upon creation.</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" md="3" class="d-flex flex-row justify-center">
          <v-spacer />
        </v-col>
        <v-col :class="['d-flex flex-column', {'background justify-center': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-2">Enabled</div>
          <v-switch
            v-model="values.taxSettings.holderTax"
            color="primary"
            class="ma-0"
            hide-details
            style="align-self: center;"
            :disabled="isLockedDisabled.holderTax && isAdmin"
          />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown" class="d-flex flex-row justify-center">
          <v-spacer />
        </v-col>
        <v-col :class="['d-flex flex-column', {'background justify-center': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-1">Buy Fee</div>
          <div class="d-flex align-center inputcolor pa-2 r-outlined">
            <c-input
              :value.sync="values.fees.holderTax"
              placeholder="0.0"
              type="number"
              max="100"
              min="0"
            />
            %
          </div>
        </v-col>
        <v-col :class="['d-flex flex-column justify-center', {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-1">Sell Fee</div>
          <div class="d-flex align-center inputcolor pa-2 r-outlined">
            <c-input
              :value.sync="values.fees.holderTax"
              placeholder="0.0"
              type="number"
              max="100"
              min="0"
            />
            %
          </div>
        </v-col>
        <v-col :class="['d-flex flex-column', {'background justify-center': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-2">Locked
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Locking is permanent, and cannot be undone. Once locked, settings can no longer be toggled on or off.</span>
            </v-tooltip>
          </div>
          <v-switch
            v-model="values.lockedSettings.holderTax"
            color="red"
            class="ma-0"
            hide-details
            style="align-self: center;"
            :disabled="isLockedDisabled.holderTax"
          />
        </v-col>
      </v-row>
      <v-row class="border-t" />
      <v-row>
        <v-col cols="12" md="3" class="pa-0 align-self-center">
          <div class="mb-2 font-weight-bold pt-4" style="text-align: left;">
            LP Tax
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Liquidity Pool tax takes care of your liquidity - it automatically deducts a portion of a transaction and injects it into the liquidity pool to make sure the size of your pool is healthy.</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col class="background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" md="3" class="d-flex flex-row justify-center">
          <v-spacer />
        </v-col>
        <v-col :class="['d-flex flex-column justify-center', {'background ': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-2">Enabled</div>
          <v-switch
            v-model="values.taxSettings.lpTax"
            color="primary"
            class="ma-0"
            hide-details
            style="align-self: center;"
            :disabled="isLockedDisabled.lpTax"
          />
        </v-col>
        <v-col :class="['d-flex flex-column justify-center', {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-1">Threshold</div>
          <div class="d-flex align-center inputcolor pa-2 r-outlined">
            <c-input
              :value.sync="values.lpTaxThreshold"
              placeholder="0.0"
              type="number"
            />
          </div>
        </v-col>
        <v-col class="d-flex flex-row justify-center background" v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer />
        </v-col>
        <v-col :class="['d-flex flex-column justify-center', {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-1">LP Tax</div>
          <div class="d-flex align-center inputcolor pa-2 r-outlined">
            <c-input
              :value.sync="values.fees.lpTax"
              placeholder="0.0"
              type="number"
              max="100"
              min="0"
            />
            %
          </div>
        </v-col>
        <v-col :class="['d-flex flex-column', {'background justify-center': !$vuetify.breakpoint.smAndDown}, {'col-4': $vuetify.breakpoint.smAndDown}]">
          <div v-if="$vuetify.breakpoint.smAndDown" class="mb-2">Locked
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Locking is permanent, and cannot be undone. Once locked, settings can no longer be toggled on or off.</span>
            </v-tooltip>
          </div>
          <v-switch
            v-model="values.lockedSettings.lpTax"
            color="red"
            class="ma-0"
            hide-details
            style="align-self: center;"
            :disabled="isLockedDisabled.lpTax"
          />
        </v-col>
      </v-row>
      <v-row class="border-t" style="margin-bottom: 10px;" />
      <v-row class="mb-1">
        <div class="mb-2 font-weight-bold pt-4">
          Custom Taxes
          <v-tooltip right max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ma-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>This is the place for your Custom taxes - whether you want it to be going to Marketing, Development, Charity or anything else - the choice is yours. You can add up to 10 taxes - provided that you do not go over the 30% threshold.</span>
          </v-tooltip>
        </div>
      </v-row>
      <v-row v-for="(customTax, index) in values.customTaxes" :key="'customTax-' + {index}" class="ma-auto mt-2">
        <v-row>
          <v-col cols="6" md="3">
            <div class="d-flex align-center inputcolor pa-2 r-outlined">
              <c-input
                :value.sync="customTax.wallet"
                placeholder="Address..."
                type="string"
              />
            </div>
          </v-col>
          <!-- for some reason v-col with cols="2" is not the same as 2 cols with a spacer each -->
          <v-col class="d-flex flex-row justify-center" v-if="!$vuetify.breakpoint.smAndDown">
            <v-spacer />
          </v-col>
          <v-col class="d-flex flex-row justify-center" v-if="!$vuetify.breakpoint.smAndDown">
            <v-spacer />
          </v-col>
          <v-col :class="['d-flex flex-column justify-center', {'col-3': $vuetify.breakpoint.smAndDown}]">
            <div class="d-flex align-center inputcolor pa-2 r-outlined">
              <c-input
                :value.sync="customTax.fee.buy"
                placeholder="0"
                type="number"
                max="100"
                min="0"
              />
              %
            </div>
          </v-col>
          <v-col :class="['d-flex flex-column justify-center', {'col-3': $vuetify.breakpoint.smAndDown}]">
            <div class="d-flex align-center inputcolor pa-2 r-outlined">
              <c-input
                :value.sync="customTax.fee.sell"
                placeholder="0"
                type="number"
                max="100"
                min="0"
              />
              %
            </div>
          </v-col>
          <v-col class="d-flex flex-row justify-center" v-if="!$vuetify.breakpoint.smAndDown">
            <v-spacer />
          </v-col>
        </v-row>
        <v-row justify="space-between" style="width: 100%">
          <v-col cols="6" md="3" class="align-self-center">
            <div class="d-flex align-center inputcolor pa-2 r-outlined">
              <c-input
                :value.sync="customTax.name"
                placeholder="Name"
              />
            </div>
          </v-col>
          <v-spacer />
          <v-col cols="3" class="d-flex flex-row justify-end">
            <v-btn rounded outlined @click="removeCustomTax(index)" color="red" style="border: 1.5px solid; align-self: center;">
              Remove
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="border-t mt-4" style="margin-bottom: 10px; width: 100%" />
      </v-row>
      <v-row class="mt-7 mb-5 pl-3 justify-center" style="width: 100%;">
        <v-btn
          rounded depressed
          color="primary"
          @click="addCustomTax"
          class="align-self-center pa-2 br-20 gradient-button-v1"
        >
          Add Custom Tax
        </v-btn>
      </v-row>
    </v-container>
    <v-row cols="12" class="my-2 ma-0 justify-space-between">
      <v-card outlined class="background d-flex align-center pa-5 ma-3 br-4 flex-row justify-space-between" br="20" style="width: 100%">
        <v-col cols="4">
          <div class="d-flex flex-row justify-center pa-4" style="margin-bottom: 25px;">
            
          </div>
          <div class="d-flex flex-row justify-center pa-4">
            Mintable
            <v-tooltip right max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Allows you to mint more tokens. Note: Minting is locked when reflection is active.</span>
          </v-tooltip>
          </div>
          <div class="d-flex flex-row justify-center pa-4">
            Blacklist
            <v-tooltip right max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Allows you to block certain wallet addresses from trading the token.</span>
          </v-tooltip>
          </div>
          <div class="d-flex flex-row justify-center pa-4">
            Pause
            <v-tooltip right max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Allows you to temporarily pause trading.</span>
          </v-tooltip>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="d-flex flex-row justify-center pa-4">
            Enabled
          </div>
          <div class="d-flex flex-row justify-center pa-4">
            <div class="d-flex flex-row">
              <v-switch
                v-model="values.taxSettings.canMint"
                color="primary"
                class="ma-0 align-self-center pa-0 pl-4"
                hide-details
                :disabled="isLockedDisabled.canMint"
              />
            </div>
          </div>
          <div class="d-flex flex-row justify-center pa-4">
            <div class="d-flex flex-row">
              <v-switch
                v-model="values.taxSettings.canBlacklist"
                color="primary"
                class="ma-0 align-self-center pa-0 pl-4"
                hide-details
                :disabled="isLockedDisabled.canBlacklist"
              />
              <!-- <v-btn v-if="values.taxSettings.canBlacklist" @click="showBlacklistModal" icon color="textFaint">
                <v-icon small>mdi-pencil-outline</v-icon>
              </v-btn> -->
            </div>
          </div>
          <div class="d-flex flex-row justify-center pa-4">
            <div class="d-flex flex-row">
              <v-switch
                v-model="values.taxSettings.canPause"
                color="primary"
                class="ma-0 align-self-center pa-0 pl-4"
                hide-details
                :disabled="isLockedDisabled.canPause"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="4">
          <div class="d-flex flex-row justify-center pa-4">
            Locked
            <v-tooltip right max-width="24em">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Locking is permanent, and cannot be undone. Once locked, settings can no longer be toggled on or off.</span>
            </v-tooltip>
          </div>
          <div class="d-flex flex-row justify-center pa-4">
            <div class="d-flex flex-row">
              <v-switch
                v-model="values.lockedSettings.canMint"
                color="red"
                class="ma-0 align-self-center pa-0 pl-4"
                hide-details
                :disabled="isLockedDisabled.canMint"
              />
            </div>
          </div>
          <div class="d-flex flex-row justify-center pa-4">
            <div class="d-flex flex-row">
              <v-switch
                v-model="values.lockedSettings.canBlacklist"
                color="red"
                class="ma-0 align-self-center pa-0 pl-4"
                hide-details
                :disabled="isLockedDisabled.canBlacklist"
              />
              <!-- <v-btn v-if="values.taxSettings.canBlacklist" @click="showBlacklistModal" icon color="textFaint">
                <v-icon small>mdi-pencil-outline</v-icon>
              </v-btn> -->
            </div>
          </div>
          <div class="d-flex flex-row justify-center pa-4">
            <div class="d-flex flex-row">
              <v-switch
                v-model="values.lockedSettings.canPause"
                color="red"
                class="ma-0 align-self-center pa-0 pl-4"
                hide-details
                :disabled="isLockedDisabled.canPause"
              />
            </div>
          </div>
        </v-col>
      </v-card>
    </v-row>
    <v-row v-if="isAdmin" class="mt-0">
      <v-col cols="12">
        <v-card outlined class="background d-flex align-center pa-5 ma-3 br-4 flex-column" br="20">
          <div class="d-flex font-weight-bold align-self-center">Total Fees</div>
          <div class="d-flex flex-row">
            <v-col class="d-flex flex-column justify-center" md="4">
              <div class="font-weight-medium mb-0">
                Buy (<i>Max 30%</i>)
              </div>
              <div class="d-flex align-center inputcolor pa-2 r-outlined mt-1">
                <c-input
                  :value.sync="totalBuyFee"
                  type="number"
                  disabled="true"
                />
                %
              </div>
            </v-col>
            <v-col class="d-flex flex-column justify-center" md="4">
              <div class="font-weight-medium mb-0">
                Sell (<i>Max 30%</i>)
              </div>
              <div class="d-flex align-center inputcolor pa-2 r-outlined mt-1">
                <c-input
                  :value.sync="totalSellFee"
                  type="number"
                  disabled="true"
                />
                %
              </div>
            </v-col>
            <v-col class="d-flex flex-column justify-center" md="4">
              <div class="font-weight-medium mb-0">
                &nbsp;
              </div>
              <v-btn
                rounded depressed
                color="primary"
                @click="showFeeWhitelistDialog"
                class="align-self-center pa-4 br-20 gradient-button-v1"
              >
                Tax Whitelist
              </v-btn>
            </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!isAdmin" class="mt-0">
      <v-col cols="12">
        <v-card outlined class="background d-flex align-center pa-5 ma-3 br-4 flex-column" br="20">
          <div class="d-flex font-weight-bold align-self-center">Total Fees</div>
          <div class="d-flex flex-row">
            <v-col class="d-flex flex-column justify-center" md="6">
              <div class="font-weight-medium mb-0">
                Buy (<i>Max 30%</i>)
              </div>
              <div class="d-flex align-center inputcolor pa-2 r-outlined mt-1">
                <c-input
                  :value.sync="totalBuyFee"
                  type="number"
                  disabled="true"
                />
                %
              </div>
            </v-col>
            <v-col class="d-flex flex-column justify-center" md="6">
              <div class="font-weight-medium mb-0">
                Sell (<i>Max 30%</i>)
              </div>
              <div class="d-flex align-center inputcolor pa-2 r-outlined mt-1">
                <c-input
                  :value.sync="totalSellFee"
                  type="number"
                  disabled="true"
                />
                %
              </div>
            </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12">
        <v-card class="background d-flex align-center pa-5 ma-3 br-4 flex-column" br="20" v-if="this.$store.state.requiredNetwork === 'Goerli'">
          Lossless is not supported on Goerli.
        </v-card>
        <v-card class="background d-flex align-center pa-5 ma-3 br-4 flex-column" br="20" v-if="this.$store.state.requiredNetwork === 'ArbitrumOne'">
          Lossless is not supported on ArbitrumOne.
        </v-card>
        <v-card v-if="!isAdmin && this.$store.state.requiredNetwork !== 'Goerli' && this.$store.state.requiredNetwork !== 'ArbitrumOne'" outlined class="background d-flex align-center pa-5 ma-3 br-4 flex-column" br="20">
          <div :class="[values.losslessEnabled ? 'mb-3' : 'mb-0']" class="justify-space-between d-flex" style="width: 100%;">
            <div class="d-flex font-weight-bold align-self-center">
              Lossless Settings
              <v-tooltip top max-width="24em">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="ml-1"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>Lossless is a proprietary protocol that allows you to mitigate any damages deriving from hacks/exploits revolving around your token. For more information, visit: Hack Mitigation Protocol - Lossless (gitbook.io)</span>
              </v-tooltip>
            </div>
            <div class="d-flex">
              <v-switch
                  v-model="values.losslessEnabled"
                  color="primary"
                  class="ma-0"
                  hide-details
              />
            </div>
          </div>
          <v-container v-if="values.losslessEnabled">
            <v-row>
              <v-col cols="12" md="3" :class="['align-self-center', {'pb-0': $vuetify.breakpoint.smAndDown}]">
                <div :class="['font-weight-medium align-self-center', {'text-left': $vuetify.breakpoint.smAndDown}]">
                  Admin
                </div>
              </v-col>
              <v-col>
                <div class="d-flex align-center inputcolor pa-2 r-outlined" style="width: 100%;">
                  <c-input
                      :value.sync="values.losslessAdminAddress"
                      placeholder="Address..."
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" :class="['align-self-center', {'pb-0': $vuetify.breakpoint.smAndDown}]">
                <div :class="['font-weight-medium align-self-center', {'text-left': $vuetify.breakpoint.smAndDown}]">
                  Recovery
                </div>
              </v-col>
              <v-col>
                <div class="d-flex align-center inputcolor pa-2 r-outlined" style="width: 100%;">
                  <c-input
                      :value.sync="values.losslessRecoveryAddress"
                      placeholder="Address..."
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <!-- <address-modal
      ref="blacklistDialog"
      title="Blacklisted Addresses"
      label="Blacklisted Address"
      @on-confirm="setBlacklist"
    /> -->
    <warning-modal
      ref="notReversibleWarningDialog"
      warningText="Action is NOT reversible. Are you sure you want to continue?"
    />
    <warning-modal
      ref="lockWarningDialog"
      warningText="Turning on the lock is NOT reversible. Are you sure you want to continue?"
    />
    <warning-modal
      ref="affectsHolderWarningDialog"
      warningText="The changes you are about to make will affect every holder of this token. Do you wish to proceed?"
      alertText="Making changes will incur a fee of 0.2 ETH/BNB"
    />
    <warning-modal
      ref="losslessWarningDialog"
      warningText="Lossless functionality helps prevent you from losing funds should your wallet be defrauded."
      alertText="Are you sure you want to disable lossless functionality?"
    />
    <address-modal
      ref="feeWhitelistDialog"
      title="Whitelist Addresses"
      label="Whitelist Addresses"
      custom="minter-v2"
      :inputData="values.feeWhitelist"
      @on-single-add="addSingleFeeWhitelist"
      @on-single-remove="removeSingleFeeWhitelist"
      @on-bulk-add="bulkAddFeeWhitelist"
      @on-bulk-remove="bulkRemoveFeeWhitelist"
    />
  </div>
</template>
<script>
import AddressModal from '@/components/dialogs/address-modal';
import WarningModal from "@/components/dialogs/warning-modal";
import SETTINGS from '@/store/settings';
import TxWrapper from '@/web3/tx-wrapper-2';
import TaxToken from '@/smart-contracts/minter-v2/tax-token';

export default {
  components: {
    AddressModal,
    WarningModal,
  },
  props: {
    initialValues: {
      type: Object
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data: ({ initialValues }) => ({
    values: initialValues,
    isLockedDisabled: {
      transactionTax: false,
      holderTax: true,
      buyBackTax: false,
      lpTax: false,
      canBlacklist: false,
      canMint: false,
      canPause: false,
      maxBalanceAfterBuy: false,
      check: false
    }
  }),
  computed: {
    totalBuyFee () {
      let total = 0;

      // add transaction tax to total
      if (this.values.taxSettings.transactionTax) {
        total += Number.parseInt(this.values.fees.transactionTax.buy);
      }

      // add holder tax to total
      if (this.values.taxSettings.holderTax) {
        total += Number.parseInt(this.values.fees.holderTax);
      }

      // add custom taxes to total
      this.values.customTaxes.forEach((i) => {
        total += Number.parseInt(i.fee.buy)
      })
      return total;
    },
    totalSellFee () {
      let total = 0;

      // add transaction tax to total
      if (this.values.taxSettings.transactionTax) {
        total += Number.parseInt(this.values.fees.transactionTax.sell);
      }

      // add buyback tax to total
      if (this.values.taxSettings.buyBackTax) {
        total += Number.parseInt(this.values.fees.buyBackTax);
      }

      // add holder tax to total
      if (this.values.taxSettings.holderTax) {
        total += Number.parseInt(this.values.fees.holderTax);
      }

      // add lp tax to total
      if (this.values.taxSettings.lpTax) {
        total += Number.parseInt(this.values.fees.lpTax);
      }

      // add custom taxes to total

      this.values.customTaxes.forEach((i) => {
        total += Number.parseInt(i.fee.sell)
      })

      return total;
    },
    gasToken () {
      var network = this.$store.state.requiredNetwork;
      return SETTINGS.CHAINS[network].gasToken.symbol; 
    }
  },
  watch: {
    values: {
      handler (newValue, _) {
        this.onChange(newValue);
        console.log(this.values.customTaxes)
      },
      deep: true,
    },
  },
  methods: {
    showFeeWhitelistDialog () {
      this.feeWhitelistDialog.open();
    },
    showReflectionBlacklistModal () {
      this.reflectionBlacklistDialog.open();
    },
    setReflectionBlacklist (items) {
      self.reflectionBlacklist = items;
    },
    showBlacklistModal () {
      this.blacklistDialog.open();
    },
    setBlacklist (items) {
      self.blacklist = items;
    },
    showNotReversibleWarning () {
      this.notReversibleWarningDialog.open();
    },
    showAffectsHoldersWarning () {
      this.affectsHolderWarningDialog.open();
    },
    showLosslessWarning () {
      this.losslessWarningDialog.open();
    },
    showLockWarning () {
      this.lockWarningDialog.open();
    },
    onChange (values) {
      this.$emit('on-change', values);
    },
    addCustomTax () {
      this.values.customTaxes.push({
        name: '',
        fee: {
          buy: 0,
          sell: 0,
        },
        wallet: '',
      });
    },
    removeCustomTax (index) {
      this.values.customTaxes.splice(index, 1);
    },
    async addSingleFeeWhitelist (address) {
      TxWrapper.doTransaction(
        TaxToken.addTaxWhitelistedAddress,
        [this.values.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async removeSingleFeeWhitelist (address) {
      TxWrapper.doTransaction(
        TaxToken.removeTaxWhitelistedAddress,
        [this.values.id, address],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async bulkAddFeeWhitelist (addresses) {
      TxWrapper.doTransaction(
        TaxToken.updateTaxWhitelistBatch,
        [this.values.id, addresses, true],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
    async bulkRemoveFeeWhitelist (addresses) {
      TxWrapper.doTransaction(
        TaxToken.updateTaxWhitelistBatch,
        [this.values.id, addresses, false],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then(() => {
        this.createLoading = false;
        this.syncLists();
      })
    },
  },
  beforeMount () {
    if (this.isAdmin && !this.isLockedDisabled.check) {
      if (this.values.lockedSettings.transactionTax) {
        this.isLockedDisabled.transactionTax = true;
      }
      if (this.values.lockedSettings.buyBackTax) {
        this.isLockedDisabled.buyBackTax = true;
      }
      if (this.values.lockedSettings.lpTax) {
        this.isLockedDisabled.lpTax = true;
      }
      if (this.values.lockedSettings.canBlacklist) {
        this.isLockedDisabled.canBlacklist = true;
      }
      if (this.values.lockedSettings.canMint) {
        this.isLockedDisabled.canMint = true;
      }
      if (this.values.lockedSettings.canPause) {
        this.isLockedDisabled.canPause = true;
      }
      if (this.values.lockedSettings.maxBalanceAfterBuy) {
        this.isLockedDisabled.maxBalanceAfterBuy = true;
      }
      this.isLockedDisabled.check = true;
    } 
  },
  mounted () {
    this.blacklistDialog = this.$refs.blacklistDialog;
    this.reflectionBlacklistDialog = this.$refs.reflectionBlacklistDialog;
    this.feeWhitelistDialog = this.$refs.feeWhitelistDialog;

    this.notReversibleWarningDialog = this.$refs.notReversibleWarningDialog;
    this.affectsHolderWarningDialog = this.$refs.affectsHolderWarningDialog;
    this.losslessWarningDialog = this.$refs.losslessWarningDialog;
    this.lockWarningDialog = this.$refs.lockWarningDialog;
  },
}
</script>