<template>
  <div class="pa-6">

    <div class="text-center">

      <div class="d-flex justify-end">
        <v-btn @click="signout" text rounded color="primary" style="border: 1.5px solid;">
          SIGN OUT
        </v-btn>
      </div>

      <v-icon color="textFaint" size="80">mdi-account-outline</v-icon>

      <div class="textFaint--text caption font-italic">
        Logged in as
      </div>
      <div class="text--text font-weight-medium mb-8">
        {{ username }}
      </div>
      
      <div v-if="false" class="mb-12 textFaint--text">
        Session expires: {{ expiryFromNow }}
      </div>

      <v-btn block x-large outlined @click="$emit('change-tab', 1)">
        Set / change a password
      </v-btn>

    </div>

  </div>
</template>

<script>
import moment from 'moment'

export default {
  components: {
  },

  data: () => ({
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    username () {
      return this.$store.state.user.username
    },
    expiryFromNow () {
      var expiryDate = this.$store.state.user.jwtObject.exp
      if (moment.unix(expiryDate).isValid()) {
        return moment.unix(expiryDate).fromNow()
      }
      return ''
    },
  },

  methods: {
    async signout () {
      this.$store.commit('logout')
    }
  },

  created () {
  }
}
</script>