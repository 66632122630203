<template>
  <div class="pa-4">

    <div v-if="pool_info.creator !== sEthers.coinbase && pool_info.reward_creator !== sEthers.coinbase ">
      <div class="py-4 textFaint--text">
        Connect one of the following accounts to create a reward pool.
      </div>
      <div class="v-card pa-3 font-weight-bold d-flex align-center">
        <v-icon class="mr-3 pa-2 lgrad-green br-c" size="32" color="white">mdi-account-circle-outline</v-icon>
        <div>
          <div>
            Pool admin
          </div>
          <div class="caption font-weight-medium textFaint--text">
            Address: {{ pool_info.creator }}
          </div>
        </div>
      </div>
      <div class="pa-3 mt-1 v-card mb-10 font-weight-bold d-flex align-center">
        <v-icon class="mr-3 pa-2 lgrad-green br-c" size="32" color="white">mdi-account-circle-outline</v-icon>
        <div>
          <div>
            Reward Creator
          </div>
          <div class="caption font-weight-medium textFaint--text">
            Address: {{ pool_info.reward_creator }}
          </div>
        </div>
      </div>
    </div>

    <template v-else>
      <div class="font-weight-bold">
        Reward Token
      </div>
      <load-token @on-update="updateToken"></load-token>

      <div class="font-weight-bold mt-4">
        Amount to farm
      </div>
      <div class="pa-4 align-center flex-nowrap c-input-wrapper br-8">

        <div class="caption text-end pt-2">
          Balance: {{ $root.formatAmount(userTokenBalance, tokenHydrated.decimals) }}
        </div>

        <div class="d-flex align-center">
          <c-input :value.sync="amountHuman" placeholder="0.0" @updateWhileFocussed="onAmountChanged" class="pa-2 font-weight-bold">

          </c-input>

          <div class="font-weight-bold text-uppercase">
            {{ tokenHydrated.symbol }}
          </div>

          <v-btn v-if="true" small depressed color="primary" @click="setMax" class="ml-2">
            MAX
          </v-btn>
        </div>

      </div>
      <!-- input amount -->

      <div class="mt-6">
        <div class="font-weight-bold">
          Reward start
        </div>

        <b-date :block.sync="startBlock"></b-date>
      </div>

      <div class="mt-6">
        <div class="font-weight-bold">
          Reward end
        </div>

        <b-date :block.sync="endBlock"></b-date>
      </div>

      <div class="mt-6">
        <div class="mb-2 font-weight-bold">
          Set a bonus period?
        </div>
        <div>
          <v-btn :color="bonusEnabled ? 'primary' : ''" rounded @click="bonusEnabled = true">
            Yes
          </v-btn>
          <v-btn :color="!bonusEnabled ? 'primary' : ''" rounded class="ml-2" @click="bonusEnabled = false">
            No
          </v-btn>
        </div>

        <v-expand-transition>
          <div v-show="bonusEnabled">
            <div class="pa-3">
              <div class="v-card br-8 pa-4">

                <div class="py-3 textFaint--text">
                  Bonus periods start at the start block and end at the specified endblock (which must be less than or equal to end_block).
                  Used to encourage early staking and farming from participants.
                </div>
                <div class="font-weight-bold">
                  Bonus end
                </div>

                <b-date :block.sync="bonusEndBlock"></b-date>

                <div class="font-weight-bold mt-4">
                  Bonus multiplier e.g. 2x
                </div>
                <div class="textFaint--text caption">
                  Minimum of 2x and must be a whole integer (3x 4x 5x etc)
                </div>
                <div class="d-flex align-center inputcolor pa-2 r-outlined">
                  <c-input :value.sync="bonusPercent" placeholder="0.0" class="pa-2 font-weight-bold">
                  </c-input>

                  <div class="pr-2 title font-weight-bold">
                    X
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-expand-transition>
        
      </div>

      <template v-if ="chargeFee">
        <div class="mt-4 pa-4 br-8 midground">
          <div class="caption textFaint--text">
            REWARD POOL CREATION
          </div>
          <div class="d-flex font-weight-bold">
            Fee
            <v-spacer></v-spacer>
            {{ stake_fees.reward_fee / 10 }}%
          </div>
        </div>
      </template>
      <div v-else class="ma-4 pa-4 br-8 midground d-flex font-weight-bold">
        <img 
        v-if="true"
        src="@/assets/img/UNCX_fill.svg"
        height="40px"
        width="40px"
        class="mr-3">
        <div>
          <div class="caption textFaint--text">
            UNCX PREMIUM USER
          </div>
          <div class="primary--text">
            No Fees!
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-icon color="textFaint" size="40">mdi-emoticon-excited-outline</v-icon>
      </div>

      <div class="px-2 pb-2">
        <v-row dense class="ma-0 mt-4 br-c" style="overflow: hidden;">
          <v-col cols="6" class="pa-0">
            <v-btn class="br-0 white--text" @click="approve" color="lgrad-green" x-large block depressed :disabled="!allowanceIncreaseRequired" :loading="approvalLoading">
              Approve
            </v-btn>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn class="br-0 white--text" @click="createRewardPool" color="lgrad-green" x-large block depressed :disabled="createDisabled" :loading="createLoading">
              Create
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>

  </div>
</template>

<script>
import { ethers } from 'ethers'
import RewardPoolGeneratorContract from '@/smart-contracts/farms-v2/normal/reward-pool/v1/reward-pool-generator'
import StakeSettingsContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-settings'
import EcosystemWhitelistContract from '@/smart-contracts/ecosystem-whitelist/ecosystem-whitelist'
import TxWrapper from '@/web3/tx-wrapper-2';
import ERC20 from '@/smart-contracts/erc20'
import BDate from '@/components/ui/block-date'
import REWARDPOOLABI from '@/smart-contracts/farms-v2/normal/reward-pool/v1/abis'

export default {

  props: {
    stake_pool_address: null,
    pool_info: {
      type: Object,
      default: () => {
        return {
          share_weight_total: '0',
          min_stake_amount: '0',
          max_stake_amount: '0',
        }
      }
    },
  },

  components: {
    BDate
  },

  data: () => ({
    userTokenBalance: '0',
    amount: '0',
    amountHuman: '0',
    startBlock: '0',
    endBlock: '0',
    bonusEndBlock: '0',
    bonusPercent: '2',
    tokenHydrated: '',
    allowance: '0',

    bonusEnabled: false,

    approvalLoading: false,
    createLoading: false,

    stake_fees: {},
    chargeFee: true
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    allowanceIncreaseRequired () {
      if (ethers.BigNumber.from(this.amount).gt(this.allowance)) {
        return true
      }
      return false
    },
    createDisabled () {
      if (this.allowanceIncreaseRequired) {
        return true
      }
      return false
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
      this.checkEcosystemFee()
    }
  },

  methods: {
    async checkEcosystemFee () {
      this.chargeFee = true
      var chargeFee = await EcosystemWhitelistContract.chargeFee(this.tokenHydrated.address, this.sEthers.coinbase)
      this.chargeFee = chargeFee
    },
    onAmountChanged (_val) {
      try {
        this.amount = ethers.utils.parseUnits(_val, this.tokenHydrated.decimals).toString()
      } catch (e) {
        this.amount = '0'
      }
    },
    setMax () {
      this.amount = this.userTokenBalance
      this.amountHuman = ethers.utils.formatUnits(this.userTokenBalance, this.tokenHydrated.decimals)
    },
    updateToken (token) {
      this.tokenHydrated = token;
      this.getUserTokenBalance()
      this.getAllowance()
      this.checkEcosystemFee()
    },
    async getUserTokenBalance () {
      this.userTokenBalance = await ERC20.getBalance(this.sEthers.coinbase, this.tokenHydrated.address)
    },
    async getAllowance () {
      var allowance = await ERC20.getAllowance(this.tokenHydrated.address, this.sEthers.coinbase, REWARDPOOLABI.reward_pool_generator())
      this.allowance = allowance
    },
    async refresh () {
      this.getUserTokenBalance()
      this.getStakePoolFees()
    },
    async getStakePoolFees () {
      this.stake_fees = await StakeSettingsContract.getFees()
    },
    approve () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      this.approvalLoading = true
      TxWrapper.doTransaction(ERC20.setAllowance,
        [this.tokenHydrated.address, amount, REWARDPOOLABI.reward_pool_generator()], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          this.getAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approvalLoading = false
        })
    },
    createRewardPool () {
      this.createLoading = true
      var bonusPercent = this.bonusEnabled ? this.bonusPercent : 1
      var bonusEndBlock = this.bonusEnabled ? this.bonusEndBlock : this.startBlock
      TxWrapper.doTransaction(RewardPoolGeneratorContract.createRewardPool,
        [this.stake_pool_address, this.tokenHydrated.address, this.amount, this.startBlock, this.endBlock, bonusEndBlock, bonusPercent], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(response => {
          this.$root.$dialog.globalSuccess.open('Reward Pool Created!')
          this.$emit('pool-created')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          this.createLoading = false
        })
    }
  },

  created () {
    this.refresh()
  }
}
</script>