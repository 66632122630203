<template>
  <v-app :class="[$store.state.theme === 'theme2' ? 'darktheme' : '']">

    <!-- DESKTOP TOOLBAR -->
    <v-app-bar v-if="$vuetify.breakpoint.mdAndUp" app flat height="80" absolute class="pr-3" color="foreground">
      <v-toolbar-title class="d-flex align-center font-weight-bold" href="https//www.uncx.network">
        <img
          :src="$store.state.darkMode ? 'https://raw.githubusercontent.com/Unicrypt/media/60504bef678a5685bb569e1474e3ce910e103768/logo/uncx/uncx-horizontal-punched-for-dark-bg.svg' : 'https://raw.githubusercontent.com/Unicrypt/media/60504bef678a5685bb569e1474e3ce910e103768/logo/uncx/uncx-horizontal-punched-for-light-bg.svg'"
          width="160px" class="mr-8 pl-2">

      </v-toolbar-title>

      <v-btn rounded text to="/dashboard">
        <v-icon>mdi-view-dashboard-outline</v-icon>
      </v-btn>

      <div>
        <v-menu offset-y open-on-hover content-class="br-20 pa-0">
          <template v-slot:activator="{ attrs, on }">
            <v-btn text rounded v-bind="attrs" v-on="on" style="min-width: 74px;" @click.stop="clickBrowserButton">
              BROWSER
              <v-icon small>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list style="min-width: 250px;" class="foreground br-20 pa-0">
            <div class="px-4 py-2 caption font-italic text-center background">
              Select an exchange
            </div>
            <div class="d-flex align-center c-list px-4 py-2 border-b" @click="selectExchange('Uniswap V2')">
              <img :src="$settings.AMMS['Uniswap V2'].icon" height="30px" width="30px" class="mr-3">
              <div>
                <div class="caption">
                  Uniswap V2
                </div>
              </div>
            </div>
            <div class="d-flex align-center c-list px-4 py-2 border-b" @click="selectExchange('Pancakeswap V2')">
              <img :src="$settings.AMMS['Pancakeswap V2'].icon" height="30px" width="30px" class="mr-3">
              <div>
                <div class="caption">
                  Pancakeswap V2
                </div>
              </div>
            </div>
            <div class="d-flex align-center c-list px-4 py-2 border-b" @click="selectExchange('Camelot')">
              <img :src="$settings.AMMS['Camelot'].icon" height="30px" width="30px" class="mr-3">
              <div>
                <div class="caption">
                  Camelot
                </div>
              </div>
            </div>
            <!--
            <div class="d-flex align-center c-list px-4 py-2 border-b" @click="selectExchange('Pangolin V1')">
              <img 
              :src="$settings.AMMS['Pangolin V1'].icon"
              height="30px"
              width="30px"
              class="mr-3">
              <div>
                <div class="caption">
                  Pangolin
                </div>
              </div>
            </div>
            -->
            <div v-if="$store.state.allowGanache" class="d-flex align-center c-list pa-4 border-b"
              @click="selectExchange('Uniswap V2 - Ganache')">
              <img :src="$settings.AMMS['Uniswap V2 - Ganache'].icon" height="30px" width="30px" class="mr-3">
              <div>
                <div class="caption">
                  GANACHE V2
                </div>
                <div class="caption textFaint--text">
                  GANACHE
                </div>
              </div>
            </div>
            <div class="d-flex align-center c-list px-4 py-3 border-b" @click="$router.push('/amm')">
              <img :src="$settings.AMMS['Sushiswap V1'].icon" height="20px" width="20px" style="z-index: 2;">
              <img :src="$settings.AMMS['Quickswap V1'].icon" height="20px" width="20px" class="br-c"
                style="margin-left: -10px;z-index: 1;">
              <div>
                <div class="ml-4 textFaint--text caption">
                  ...other exchanges
                  <v-icon small>mdi-chevron-right</v-icon>
                </div>
              </div>
            </div>
          </v-list>
        </v-menu>
      </div>

      <v-btn to="/services" rounded text>
        SERVICES
      </v-btn>

      <div>
        <v-menu offset-y open-on-hover content-class="br-20 pa-0">
          <template v-slot:activator="{ attrs, on }">
            <v-btn text rounded v-bind="attrs" v-on="on" :to="`${$store.state.chainPrefix}/farms`" @click.prevent>
              FARMS
              <v-icon small>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list style="min-width: 250px;" class="foreground br-20 pa-0">
            <div class="d-flex align-center c-list px-4 py-2 border-b"
              @click="$router.push(`${$store.state.chainPrefix}/farms`)">
              <v-icon size="30px" class="mr-3">mdi-sprout</v-icon>
              <div>
                <div class="caption">
                  All Farms
                </div>
              </div>
            </div>
            <div class="d-flex align-center c-list px-4 py-2 border-b" @click="$router.push(`/uncx-staking`)">
              <img src="@/assets/img/UNCX_fill.svg" height="30px" width="30px" class="mr-3">
              <div>
                <div class="caption">
                  UNCX Staking
                </div>
              </div>
            </div>
          </v-list>
        </v-menu>
      </div>

      <v-btn text rounded target="_blank" href="https://docs.uncx.network">
        DOCS
      </v-btn>

      <div>
        <v-menu offset-y open-on-hover content-class="br-8 pa-0">
          <template v-slot:activator="{ attrs, on }">
            <v-btn text rounded v-bind="attrs" v-on="on">
              <v-icon>mdi-telegram</v-icon>
            </v-btn>
          </template>

          <v-list style="min-width: 250px;" class="foreground br-8 pa-0">
            <a href="https://twitter.com/UNCX_token" class="deadlink" target="_blank">
              <div class="d-flex align-center c-list px-4 py-3 border-b">
                <v-icon large>mdi-twitter</v-icon>
                <div class="ml-4 textFaint--text">
                  UNCX Twitter
                </div>
                <v-spacer></v-spacer>
                <v-icon small>mdi-arrow-top-right</v-icon>
              </div>
            </a>
            <a href="https://t.me/uncx_token" class="deadlink" target="_blank">
              <div class="d-flex align-center c-list px-4 py-3 border-b">
                <v-icon large>mdi-telegram</v-icon>
                <div class="ml-4 textFaint--text">
                  UNCX Telegram
                </div>
                <v-spacer></v-spacer>
                <v-icon small>mdi-arrow-top-right</v-icon>
              </div>
            </a>
            <a href="https://t.me/Unicrypt_Presales" class="deadlink" target="_blank">
              <div class="d-flex align-center c-list px-4 py-3 border-b">
                <v-icon large>mdi-telegram</v-icon>
                <div class="ml-4 textFaint--text">
                  Launchpad Chat
                </div>
                <v-spacer></v-spacer>
                <v-icon small>mdi-arrow-top-right</v-icon>
              </div>
            </a>
          </v-list>
        </v-menu>
      </div>

      <v-spacer></v-spacer>

      <main-connect-button></main-connect-button>

      <v-btn icon to="/account" class="ml-2">
        <v-icon v-if="signedIn" color="text">mdi-account-outline</v-icon>
        <v-icon v-else color="textFaint">mdi-account-question-outline</v-icon>
      </v-btn>

      <div v-if="$store.state.adminIds.includes($store.state.user.jwtObject.user_id)">
        <v-btn @click="toggleSuperUserMode" icon>
          <v-icon :color="$store.state.superUserMode ? 'primary' : ''">mdi-atom</v-icon>
        </v-btn>
      </div>

      <v-btn icon @click="changeTheme" color="textFaint">
        <v-icon small>mdi-white-balance-sunny</v-icon>
      </v-btn>

      <v-btn v-if="false" icon @click="openMenu" color="text" class="mr-2">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

    </v-app-bar>

    <!-- MOBILE TOOLBAR -->
    <v-app-bar v-else app flat color="foreground">

      <v-btn icon @click="openMenu" small color="textFaint" class="ml-1">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-menu offset-y open-on-hover content-class="br-8 pa-0">
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon v-bind="attrs" color="textFaint" v-on="on">
            <v-icon>mdi-telegram</v-icon>
          </v-btn>
        </template>

        <v-list style="min-width: 250px;" class="foreground br-8 pa-0">
          <a href="https://twitter.com/UNCX_token" class="deadlink" target="_blank">
            <div class="d-flex align-center c-list px-4 py-3 border-b">
              <v-icon large>mdi-twitter</v-icon>
              <div class="ml-4 textFaint--text">
                UNCX Twitter
              </div>
              <v-spacer></v-spacer>
              <v-icon small>mdi-arrow-top-right</v-icon>
            </div>
          </a>
          <a href="https://t.me/uncx_token" class="deadlink" target="_blank">
            <div class="d-flex align-center c-list px-4 py-3 border-b">
              <v-icon large>mdi-telegram</v-icon>
              <div class="ml-4 textFaint--text">
                UNCX Telegram
              </div>
              <v-spacer></v-spacer>
              <v-icon small>mdi-arrow-top-right</v-icon>
            </div>
          </a>
          <a href="https://t.me/Unicrypt_Presales" class="deadlink" target="_blank">
            <div class="d-flex align-center c-list px-4 py-3 border-b">
              <v-icon large>mdi-telegram</v-icon>
              <div class="ml-4 textFaint--text">
                Launchpad Chat
              </div>
              <v-spacer></v-spacer>
              <v-icon small>mdi-arrow-top-right</v-icon>
            </div>
          </a>
        </v-list>
      </v-menu>

      <div v-if="$store.state.adminIds.includes($store.state.user.jwtObject.user_id)">
        <v-btn @click="toggleSuperUserMode" icon>
          <v-icon :color="$store.state.superUserMode ? 'primary' : 'textFaint'">mdi-atom</v-icon>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <main-connect-button></main-connect-button>

      <v-btn small icon @click="changeTheme" color="textFaint">
        <v-icon v-if="$store.state.darkMode" small>mdi-white-balance-sunny</v-icon>
        <v-icon v-else small>mdi-moon-waning-crescent</v-icon>
      </v-btn>

      <v-btn icon to="/account" color="textFaint" class="midground mr-1">
        <v-icon v-if="signedIn">mdi-account-circle</v-icon>
        <v-icon v-else>mdi-account</v-icon>
      </v-btn>

    </v-app-bar>

    <unicrypt-admin-drawer></unicrypt-admin-drawer>

    <v-main :class="['hueanim', { 'ima': showBackground && !$vuetify.breakpoint.xs }]">

      <!-- LATEST NEWS -->
      <template v-if="showLatestNewsBanner">
        <a v-if="newsBannerObject.link.length > 0" :href="newsBannerObject.link" target="_blank"
          :class="['deadlink lgrad-green white--text font-weight-medium popup-border', { 'news-banner-mobile pa-4': $vuetify.breakpoint.xs }, { 'news-banner-desktop pa-6': !$vuetify.breakpoint.xs }]">
          <div class="d-flex align-center caption pb-4">
            {{ newsBannerDate }}
            <v-spacer></v-spacer>
            <v-btn icon class="ml-2" @click.prevent="showLatestNewsBanner = false">
              <v-icon color="white">mdi-close-circle</v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-top">
            {{ newsBannerObject.title }}
          </div>
          <div class="caption">
            Read more <v-icon small color="white">mdi-arrow-top-right</v-icon>
          </div>
        </a>
        <div v-else
          :class="['lgrad-blue foreground white--text pa-2 font-weight-bold popup-border', { 'news-banner-mobile': $vuetify.breakpoint.xs }, { 'news-banner-desktop': !$vuetify.breakpoint.xs }]">
          <div class="caption">
            {{ newsBannerDate }}
          </div>
          <div class="d-flex align-top">
            {{ newsBannerObject.title }}
            <v-btn small icon color="midground" class="ml-2" @click.prevent="showLatestNewsBanner = false">
              <v-icon color="text">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <!-- LATEST NEWS -->

      <!-- <div v-if="darkMode && $vuetify.breakpoint.mdAndUp" class="radial-gr"></div> -->
      <div v-if="darkMode"
        :class="[{ 'radial-grr': $vuetify.breakpoint.mdAndUp }, { 'radial-grr-mobile': $vuetify.breakpoint.smAndDown }]">
      </div>
      <!-- Keep alive Pairs? -->

      <div v-if="true" class="text-center lgrad-green black--text font-weight-medium py-1">
        <a href="https://beta.uncx.network" class="deadlink" target="_blank">
          Try our new locker UI!
          <div class="caption">
            beta.uncx.network<v-icon x-small color="black">mdi-arrow-top-right</v-icon>
          </div>
        </a>
      </div>

      <!-- <uncx-horizontal-bar></uncx-horizontal-bar> -->

      <router-view></router-view>

      <v-fab-transition>
        <v-btn v-scroll="onScroll" v-show="scrollFab" fab dark fixed bottom right
          :style="$vuetify.breakpoint.mobile ? 'bottom: 70px;' : 'bottom: 100px;'" color="lgrad-green" @click="toTop">
          <v-icon color="white">mdi-chevron-up</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-fab-transition>
        <v-btn v-show="$store.state.superUserMode" fab dark fixed top right
          :style="$vuetify.breakpoint.mobile ? 'top: 70px;' : 'top: 100px;'" color="lgrad-green"
          @click="openSuperUserDrawer">
          <v-icon color="white">mdi-atom</v-icon>
        </v-btn>
      </v-fab-transition>

    </v-main>

    <!-- DESKTOP FOOTER -->
    <v-footer v-if="$vuetify.breakpoint.mdAndUp" class="pa-4 justify-center foreground border-t">
      <v-btn icon color="textFaint" x-large href="https://twitter.com/UNCX_token" target="_blank">
        <v-icon>mdi-twitter</v-icon>
      </v-btn>
      <v-btn color="textFaint" x-large icon rounded href="https://t.me/uncx_token" target="_blank">
        <v-icon>mdi-telegram</v-icon>
      </v-btn>
      <v-btn text color="textFaint" rounded to="/terms" exact>
        Terms and Conditions
      </v-btn>
      <v-btn text color="textFaint" rounded to="/terms/privacy-policy">
        Privacy Policy
      </v-btn>
      <v-btn text color="textFaint" rounded target="_blank" href="https://www.uncx.network">
        uncx.network
      </v-btn>
      <!--
      <v-btn text rounded target="_blank" href="https://docs.uncx.network">
        Terms and conditions
      </v-btn>
      -->
    </v-footer>
    <!-- END DESKTOP FOOTER -->

    <!-- MOBILE FOOTER -->
    <v-footer v-else app fixed class="justify-center pa-0 foreground border-t">

      <v-bottom-navigation color="primary" grow class="align-center background" style="">

        <v-btn to="/dashboard">
          <span class="font-weight-medium">DASH</span>
          <v-icon>mdi-fire</v-icon>
        </v-btn>

        <v-btn to="/amm" @click.stop="clickBrowserButton">
          <span class="font-weight-medium">BROWSER</span>
          <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
        </v-btn>

        <v-btn to="/services">
          <span class="font-weight-medium">SERVICES</span>
          <v-icon>mdi-checkbox-multiple-blank-circle-outline</v-icon>
        </v-btn>

        <v-btn :to="`${$store.state.chainPrefix}/farms`">
          <span class="font-weight-medium">
            FARMS
          </span>
          <v-icon>mdi-sprout-outline</v-icon>
        </v-btn>

        <v-btn v-if="$store.state.superUserMode" :to="`${$store.state.ammPrefix}/sajfkhflkjasdlisdulansdlakds`">
          <span>Super</span>
          <v-icon>mdi-atom</v-icon>
        </v-btn>
      </v-bottom-navigation>

    </v-footer>
    <!-- END MOBILE FOOTER -->

    <main-menu ref="mainMenu"></main-menu>

    <token-page ref="tokenPage"></token-page>

    <!-- DIALOGS -->
    <web-3-error ref="web3Error"></web-3-error>
    <connect-wallet-dialog ref="connectWalletDialog"></connect-wallet-dialog>
    <weth-convertor-dialog ref="wethConvertorDialog"></weth-convertor-dialog>
    <confirm-tx-dialog ref="confirmTxInWallet"></confirm-tx-dialog>
    <confirm-tx-dialog-2 ref="confirmTxDialog2"></confirm-tx-dialog-2>
    <chain-switch-dialog ref="chainSwitchDialog"></chain-switch-dialog>
    <auto-login-dialog ref="autoLoginDialog"></auto-login-dialog>
    <account-intercept-dialog ref="accountInterceptDialog"></account-intercept-dialog>
    <testnet-dialog ref="testnetDialog"></testnet-dialog>
    <global-success-dialog ref="globalSuccessDialog"></global-success-dialog>
    <chain-switcher-dialog ref="chainSwitcherDialog"></chain-switcher-dialog>
    <arbitrage-dialog ref="arbitrageDialog"></arbitrage-dialog>
    <splash-campaign-dialog ref="splashCampaignDialog"></splash-campaign-dialog>
    <!-- DIALOGS -->

    <v-overlay :value="overlay" opacity="1" class="text-center" style="z-index: 10;">
      <v-scale-transition appear mode="out-in">
        <div>
          <img
            src="https://raw.githubusercontent.com/Unicrypt/media/60504bef678a5685bb569e1474e3ce910e103768/logo/uncx/uncx-stacked-logo-punched-for-dark-bg.svg"
            height="160px" width="320px" class="tween">
        </div>
      </v-scale-transition>
    </v-overlay>

    <!--
    <v-dialog v-model="wrongNetwork" persistent max-width="450" content-class="br-20">
      <v-card class="br-20" style="opacity: 0.9;">

        <v-card-text class="pa-0">
          <div class="text-center">
            <div class="pa-4 text-center mb-2 background font-weight-medium">
              Wrong Network
            </div>
            <div class="pa-4">
              <div class="text-center">
                <img 
                :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
                height="34px"
                width="34px"
                class="br-20">
              </div>
              <div class="caption font-italic textFaint--text">
                Switch your wallet to
              </div>
              <div class="">
                {{ $store.state.requiredNetworkDisplayName }}
              </div>
              <v-btn v-if="$store.state.requiredNetwork === 'xDai'" small text color="primary" rounded href="https://www.xdaichain.com/for-users/wallets/metamask/metamask-setup" target="_blank">
                How to switch to {{ $store.state.requiredNetworkDisplayName }}?
              </v-btn>
              <v-btn v-if="$store.state.requiredNetwork === 'BSC_MAINNET'" small text color="primary" rounded href="https://docs.binance.org/smart-chain/wallet/metamask.html" target="_blank">
                How to switch to {{ $store.state.requiredNetworkDisplayName }}?
              </v-btn>
              <v-btn v-if="$store.state.requiredNetwork === 'Matic'" small text color="primary" rounded href="https://docs.matic.network/docs/develop/metamask/config-matic" target="_blank">
                How to switch to {{ $store.state.requiredNetworkDisplayName }}?
              </v-btn>

              <div v-if="false" class="caption mt-4 textFaint--text">
                <div>
                  Your current connection:
                </div>
                {{ currentNetwork }}
              </div>
              <div class="caption my-4 textFaint--text">
                *You can browse the app on all chains by disconnecting your wallet.
              </div>
              <v-btn block large depressed class="green-button white--text" rounded @click="disconnect">
                Disconnect Wallet
              </v-btn>
            </div>
          </div>

        </v-card-text>

      </v-card>
    </v-dialog>
    -->

  </v-app>
</template>

<script>
import Web3Error from '@/views/DialogWeb3Error'
import ConfirmTxDialog from '@/web3/confirm-tx-in-wallet'
import ConfirmTxDialog2 from '@/web3/confirm-tx-in-wallet-2'
import ChainSwitchDialog from '@/web3/chain-switch-dialog'
import ConnectWalletDialog from '@/web3/connect-dialog'
import WethConvertorDialog from '@/web3/weth-convertor-dialog'
import TokenPage from '@/views/tokens/token-page'
import ConnectProvider from '@/web3/connect-provider'
import MainMenu from '@/components/ui/main-menu'
import AutoLoginDialog from '@/views/account/social-profile/auto-login-dialog'
import AccountInterceptDialog from '@/views/account/social-profile/account-action-intercept-dialog'
import TestnetDialog from '@/components/dialogs/testnet-only-dialog'
import GlobalSuccessDialog from '@/components/dialogs/global-success-dialog'
import ChainSwitcherDialog from '@/components/dialogs/chain-switcher-dialog'
import SplashCampaignDialog from '@/components/dialogs/splash-campaign'
import UnicryptAdminDrawer from '@/views/chavpanel/drawer'
// import UncxHorizontalBar from '@/views/trending/horizontal-bar/main'

import PROVIDERS from '@/web3/providers'
import { ethers } from 'ethers'
import ArbitrageDialog from '@/views/arbitrage/dialog'
import moment from 'moment'
import MainConnectButton from './web3/main-connect-button'

export default {
  name: 'App',

  components: {
    Web3Error,
    ConnectWalletDialog,
    WethConvertorDialog,
    ConfirmTxDialog,
    ConfirmTxDialog2,
    ChainSwitchDialog,
    MainMenu,
    TokenPage,
    AutoLoginDialog,
    AccountInterceptDialog,
    TestnetDialog,
    GlobalSuccessDialog,
    ChainSwitcherDialog,
    ArbitrageDialog,
    SplashCampaignDialog,
    UnicryptAdminDrawer,
    MainConnectButton,
    // UncxHorizontalBar
  },

  data: () => ({
    showBackground: false,
    scrollFab: false,
    overlay: true,
    intervalID: null,
    currentNetwork: {},
    showLatestNewsBanner: false,
    newsBannerObject: {
      title: '',
      link: '',
      date: ''
    },
  }),

  computed: {
    sEthers() {
      return this.$store.state.ethers
    },
    wrongNetwork() {
      return this.$store.state.wrongNetwork
    },
    walletNetwork() {
      return this.$store.state.ethers.network
    },
    requiredNetwork() {
      return this.$store.state.requiredNetwork
    },
    walletConnected() {
      return this.sEthers.coinbase
    },
    blockNumber() {
      return this.$store.state.blockNumber
    },
    signedIn() {
      return this.$store.state.user.username
    },
    darkMode() {
      var theme = this.$store.state.theme
      return theme === 'theme2' || theme === 'theme3'
    },
    newsBannerDate() {
      // return this.newsBannerObject.date
      return moment(this.newsBannerObject.date).format('ddd DD MMM HH:mm')
    }
  },

  watch: {
    requiredNetwork() { // on an app based change such as switching chains in the UI
      this.wrongNetworkCheck()
    },
    walletNetwork() { // on an app based change such as switching chains in the UI
      this.wrongNetworkCheck()
    }
  },

  methods: {
    async getNewsBanner() {
      this.$userAxios.get(`/admin/get-news-banner`)
        .then(response => {
          this.showLatestNewsBanner = true
          this.newsBannerObject = response.data
        })
        .catch(console.error)
    },
    selectExchange(exchange) {
      this.$store.commit('switchExchange', exchange)
      this.$root.ammLink(`/ilos`)
    },
    clickBrowserButton() {
      if (!this.$store.state.exchange || this.$settings.AMMS[this.$store.state.exchange].chain !== this.$store.state.requiredNetwork) {
        var amms = Object.keys(this.$settings.AMMS).filter(key => this.$settings.AMMS[key].chain === this.$store.state.requiredNetwork)
        this.$store.commit('switchExchange', amms[0])
      }
      var url = '/ilos'
      this.$router.replace(`${this.$store.state.ammPrefix}${url}`)
    },
    toggleSuperUserMode() {
      this.$store.commit('setSuperUserMode', !this.$store.state.superUserMode)
    },
    changeTheme() {
      var themeName = "theme1"
      if (this.$store.state.theme === "theme1") {
        themeName = "theme2"
      }
      this.$store.commit('setTheme', {
        themeName: themeName,
        vuetify: this.$vuetify
      })
    },
    async wrongNetworkCheck() {
      if (!this.sEthers.coinbase) {
        this.$store.commit('setWrongNetwork', false)
        return
      }
      var state = this.$settings.CHAINS[this.requiredNetwork].chainId !== this.walletNetwork
      this.$store.commit('setWrongNetwork', state)
    },
    openMenu() {
      this.$refs.mainMenu.open()
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.scrollFab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    openSuperUserDrawer() {
      this.$store.commit('setSuperUserDrawerState', true)
    },
    connectWallet() {
      this.$root.$dialog.connectWalletDialog.open()
        .catch(e => { })
    },
    initializeApp() {
      var theme = localStorage.getItem('theme')
      if (!['theme1', 'theme2', 'theme3'].includes(theme)) {
        theme = 'theme2'
      }
      this.$store.commit('setTheme', {
        themeName: theme,
        vuetify: this.$vuetify
      })

      var jwt = localStorage.getItem('token')
      if (jwt) {
        this.$userAxios.defaults.headers.common.Authorization = `Bearer ${jwt}`
        this.$userAxios.post(`/users/jwt-autologin`)
          .then(response => {
            this.$store.commit('login', response.data)
            // this.$refs.autoLoginDialog.open()
          })
          .catch(error => {
            // console.log(error.response.data.errors[0])
            console.log(error)
          })
      }
    },
    wssReconnect() {
      if (!this.sEthers.coinbase) {
        ConnectProvider.connectViewOnlyProvider()
        // console.log('reconnected')
      }
    },
    disconnect() {
      ConnectProvider.disconnect()
    },
    shortInterval() {
      this.$store.commit('updateInterval', 'short')
    },
    minuteInterval() {
      this.$store.commit('updateInterval', 'minute')
    },
    setIntervals() {
      setInterval(this.shortInterval, 6000)
      setInterval(this.minuteInterval, 60000)
    }
  },

  created() {
    ConnectProvider.connectViewOnlyProvider()
    this.getNewsBanner()
    this.setIntervals()
    // this.intervalID = setInterval(this.wssReconnect, 30000)

    this.$root.getPercent = (_num, _den) => {
      if (_den === '0') {
        return '0'
      }
      var percent = ethers.BigNumber.from(_num).mul(1000).div(_den)
      return percent.toNumber() / 10
    }

    this.$root.getReadableTimeSpan = (timeInSeconds) => {
      var timeInput
      var timePeriod
      if (timeInSeconds < 120) {
        timeInput = timeInSeconds
        timePeriod = 'seconds'
      } else if (timeInSeconds < 7200) {
        timeInput = timeInSeconds / 60
        timePeriod = 'minutes'
      } else if (timeInSeconds < 172800) {
        timeInput = timeInSeconds / 60 / 60
        timePeriod = 'hours'
      } else if (timeInSeconds < 5184000) {
        timeInput = timeInSeconds / 60 / 60 / 24
        timePeriod = 'days'
      } else if (timeInSeconds < 63072000) {
        timeInput = timeInSeconds / 60 / 60 / 24 / 30
        timePeriod = 'months'
      } else {
        timeInput = timeInSeconds / 60 / 60 / 24 / 365
        timePeriod = 'years'
      }
      timeInput = Math.floor(timeInput) // remove decimals
      return `${timeInput} ${timePeriod}`
    }

    this.$root.condenseAddress = (_address) => {
      if (!_address) {
        return ''
      }
      return _address.slice(0, 5) + '...' + _address.slice(_address.length - 3)
    }

    this.$root.getLegacyChainKey = (requiredNetwork) => {
      var map = {
        'Mainnet': 'eth',
        'Ganache': 'ganache',
        'BSC_MAINNET': 'bsc',
        'AVAX': 'avax',
        'Sepolia': 'sepolia'
      }
      return map[requiredNetwork]
    }

    this.$root.formatDollars = (amount) => {
      if (amount >= 1000000000000) {
        var tril = Number(amount / 1000000000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
        return tril + 'T'
      } else if (amount >= 1000000000) {
        var bil = Number(amount / 1000000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
        return bil + 'B'
      } else if (amount >= 1000000) {
        var mil = Number(amount / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
        return mil + 'M'
      } else if (amount > 1000) {
        return Math.floor(amount / 1000) + 'k'
      }
      return Number(amount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    }

    this.$root.formatAmount = (_amount, _decimals) => {
      var amount = ethers.utils.formatUnits(_amount, _decimals)
      // Huge number block
      amount = Number(amount)
      if (amount >= 1000000000000000) {
        var quad = Number(amount / 1000000000000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
        return quad + ' Q'
      } else if (amount >= 1000000000000) {
        var tril = Number(amount / 1000000000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
        return tril + ' T'
      } else if (amount >= 1000000000) {
        var bil = Number(amount / 1000000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
        return bil + ' B'
      } else if (amount >= 1000000) {
        var mil = Number(amount / 1000000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
        return mil + ' M'
      } else if (amount >= 1000) {
        var thousand = Number(amount / 1000).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })
        return thousand + ' K'
      } else if (amount > 1) {
        return Number(amount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
      }
      // decimal number block
      amount = ethers.utils.formatUnits(_amount, _decimals)
      var precision = 0
      var split = amount.split('.')
      while (precision < _decimals && precision < 18) {
        if (split[1].charAt(precision) !== '0') {
          break
        }
        precision++
      }
      precision += 2
      if (precision > _decimals) {
        precision = _decimals
      }
      return Number(amount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: precision })
    }
  },
  mounted() {
    // this.$store.commit('switchExchange', this.$store.state.exchange)
    this.$root.$dialog = {}
    this.$root.$dialog.web3Error = this.$refs.web3Error
    this.$root.$dialog.confirmTx = this.$refs.confirmTxInWallet
    this.$root.$dialog.confirmTx2 = this.$refs.confirmTxDialog2
    this.$root.$dialog.chainSwitch = this.$refs.chainSwitchDialog
    this.$root.$dialog.connectWalletDialog = this.$refs.connectWalletDialog
    this.$root.$dialog.wethConvertorDialog = this.$refs.wethConvertorDialog
    this.$root.$dialog.accountInterceptDialog = this.$refs.accountInterceptDialog
    this.$root.$dialog.globalSuccess = this.$refs.globalSuccessDialog
    this.$root.$dialog.chainSwitcher = this.$refs.chainSwitcherDialog
    this.$root.$dialog.arbitrage = this.$refs.arbitrageDialog
    this.$root.PROVIDERS = PROVIDERS
    this.$root.PROVIDERS.init()

    this.$root.globalHooks = {
      getNewsBanner: this.getNewsBanner
    }

    this.$root.tokenPage = this.$refs.tokenPage

    this.$root.ammLink = (url, push = true) => {
      this.$router.push(`${this.$store.state.ammPrefix}${url}`)
    }

    this.$root.ammLinkTwo = (url, exchange) => {
      if (this.$store.state.exchange !== exchange) {
        this.$store.commit('switchExchange', exchange)
      }
      this.$router.push(`${this.$store.state.ammPrefix}${url}`)
    }

    this.$root.chainLink = (url, network = null) => {
      var chainPrefix = network === null ? this.$store.state.chainPrefix : `/chain/${this.$settings.CHAIN_URL_NAME[network]}`
      this.$router.push(`${chainPrefix}${url}`)
    }

    this.initializeApp()
    setTimeout(() => {
      this.overlay = false
    }, 2500)
  }
}
</script>

<style scoped lang="scss">
.imaa {
  background-image: url(https://picsum.photos/1000?image=521);
  background-image: url(https://cdn.pixabay.com/photo/2016/10/30/20/22/astronaut-1784245_1280.jpg);
  // background-image: url(https://cdn.pixabay.com/photo/2016/07/28/14/40/space-1548139_1280.jpg);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.93)), url(https://cdn.pixabay.com/photo/2016/08/03/09/03/universe-1566159_1280.jpg);
  // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.93)), url(https://cdn.pixabay.com/photo/2016/10/30/20/22/astronaut-1784245_1280.jpg);
  // background-image: url(https://cdn.pixabay.com/photo/2016/04/15/04/02/water-1330252_1280.jpg);
  // background-image: url(https://cdn.pixabay.com/photo/2020/06/28/00/35/sky-5347490_1280.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.radial-gr {
  right: 0px;
  pointer-events: none;
  height: 200vh;
  mix-blend-mode: color;
  background: radial-gradient(50% 50% at 50% 50%, rgba(33, 114, 229, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  transform: translateY(-110vh);
  max-width: 100vw !important;
}

.radial-gr-mobile {
  right: 0px;
  pointer-events: none;
  height: 200vh;
  mix-blend-mode: color;
  background: radial-gradient(50% 50% at 50% 50%, rgba(33, 114, 229, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  transform: translateY(-110vh);
  max-width: 100vw !important;
}

.news-banner-mobile {
  position: fixed;
  bottom: 56px;
  left: 0px;
  right: 0px;
  z-index: 6;
  border-radius: 0px;
}

.news-banner-desktop {
  position: fixed;
  top: 100px;
  left: 0px;
  z-index: 6;
  border-radius: 0px 8px 8px 0px;
  max-width: 400px;
}

.hueanim {
  // background: url(/img/UNCX_fill.svg) no-repeat 50%;
  background-color: var(--v-background-base);
  background-size: cover;
}

/* .hueanim:after {
    content: "";
    display: block;
    position: fixed;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    opacity: .015;
    box-shadow: 0 0 35vmax 35vmax #0ff;
    -webkit-animation: hue 10s linear 0s infinite,move2 20s linear 0s infinite;
    animation: hue 30s linear 0s infinite,move2 60s linear 0s infinite;
} */
.hueanimzzz:before {
  content: "";
  display: block;
  position: fixed;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  opacity: .035;
  box-shadow: 0 0 35vmax 35vmax rgb(56, 212, 9);
  // -webkit-animation: hue 10s linear 0s infinite,move2 20s linear 0s infinite;
  // animation: hue 30s linear 0s infinite,move2 60s linear 0s infinite;
}

@-moz-keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

@-webkit-keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

@-o-keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

@keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

@-moz-keyframes move1 {
  0% {
    top: 0vh;
    left: 50vw;
  }

  25% {
    left: 0vw;
  }

  50% {
    top: 100vh;
  }

  75% {
    left: 100vw;
  }

  100% {
    top: 0vh;
    left: 50vw;
  }
}

@-webkit-keyframes move1 {
  0% {
    top: 0vh;
    left: 50vw;
  }

  25% {
    left: 0vw;
  }

  50% {
    top: 100vh;
  }

  75% {
    left: 100vw;
  }

  100% {
    top: 0vh;
    left: 50vw;
  }
}

@-o-keyframes move1 {
  0% {
    top: 0vh;
    left: 50vw;
  }

  25% {
    left: 0vw;
  }

  50% {
    top: 100vh;
  }

  75% {
    left: 100vw;
  }

  100% {
    top: 0vh;
    left: 50vw;
  }
}

@keyframes move1 {
  0% {
    top: 0vh;
    left: 50vw;
  }

  25% {
    left: 0vw;
  }

  50% {
    top: 100vh;
  }

  75% {
    left: 100vw;
  }

  100% {
    top: 0vh;
    left: 50vw;
  }
}

@-moz-keyframes move2 {
  0% {
    top: 50vh;
    left: 100vw;
  }

  25% {
    top: 100vh;
  }

  50% {
    left: 0vw;
  }

  75% {
    top: 0vh;
  }

  100% {
    top: 50vh;
    left: 100vw;
  }
}

@-webkit-keyframes move2 {
  0% {
    top: 50vh;
    left: 100vw;
  }

  25% {
    top: 100vh;
  }

  50% {
    left: 0vw;
  }

  75% {
    top: 0vh;
  }

  100% {
    top: 50vh;
    left: 100vw;
  }
}

@-o-keyframes move2 {
  0% {
    top: 50vh;
    left: 100vw;
  }

  25% {
    top: 100vh;
  }

  50% {
    left: 0vw;
  }

  75% {
    top: 0vh;
  }

  100% {
    top: 50vh;
    left: 100vw;
  }
}

@keyframes move2 {
  0% {
    top: 50vh;
    left: 100vw;
  }

  25% {
    top: 100vh;
  }

  50% {
    left: 0vw;
  }

  75% {
    top: 0vh;
  }

  100% {
    top: 50vh;
    left: 100vw;
  }
}
</style>