<template>
  <div>
    <v-slide-y-transition appear>
      <div>
        <div class="foreground border-b">
          <div class="justify-center mx-auto" style="max-width: 1000px;">

            <div v-if="false" class="d-flex border-b foreground border br-8" style="overflow: hidden;">
              <router-link 
              to="/services/select-minter"
              v-slot="{ isActive, navigate }">
                <div :class="['top-tab', {'active': isActive}]" @click="navigate">
                  <img 
                  src="@/assets/img/icons/token.svg" 
                  height="20px"
                  width="20px"
                  class="mr-2">
                  <span>Minter</span>
                </div>
              </router-link>

              <router-link 
              to="/services/launchpad"
              v-slot="{ isActive, navigate }">
                <div :class="['top-tab', {'active': isActive}]" @click="navigate">
                  <img 
                  src="@/assets/img/icons/rocket.svg" 
                  height="20px"
                  width="20px"
                  class="mr-2">
                  <span>Launchpad</span>
                </div>
              </router-link>

              <router-link 
              to="/services/lock-tokens"
              v-slot="{ isActive, navigate }">
                <div :class="['top-tab', {'active': isActive}]" @click="navigate">
                  <img 
                  src="@/assets/img/icons/padlock.svg" 
                  height="20px"
                  width="20px"
                  class="mr-2">
                  <span>Token Locker</span>
                </div>
              </router-link>
            </div>

            <v-bottom-navigation
            color="primary"
            grow
            class="align-center transparent br-c"
            style="box-shadow: none;"
            >
              <v-btn to="/services" exact>
                <span class="font-weight-medium">Services</span>
                <v-icon>mdi-format-list-bulleted-square</v-icon>
              </v-btn>

              <v-btn to="/services/select-minter">
                <span class="font-weight-medium">Minter</span>
                <v-icon>mdi-leaf</v-icon>
              </v-btn>

              <v-btn to="/services/launchpad">
                <span class="font-weight-medium">Launchpad</span>
                <v-icon>mdi-rocket</v-icon>
              </v-btn>

              <v-btn to="/services/lock-tokens">
                <span class="font-weight-medium">Lock tokens</span>
                <v-icon>mdi-lock-outline</v-icon>
              </v-btn>

            </v-bottom-navigation>

          </div>
        </div>

        <router-view :key="$route.fullPath"></router-view>
      </div>
    </v-slide-y-transition>

    <v-slide-x-transition appear>
      <template v-if="showSupportPopup">
        <div
        :class="['font-weight-medium foreground popup-border', {'news-banner-mobile': $vuetify.breakpoint.xs}, {'news-banner-desktop': !$vuetify.breakpoint.xs}]"
        > 
          <div class="d-flex align-center pa-4 border-b">
            LIVE CHAT SUPPORT
            <v-spacer></v-spacer>
            <v-btn small icon class="ml-2" @click.prevent="showSupportPopup = false">
              <v-icon small color="text">mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="text-center pt-4">
            <v-icon size="80" color="background">mdi-telegram</v-icon>
          </div>
          <div class="pr-2 pa-4 text-center caption">
            Need help with our services? <br />
            We're here!
          </div>
          <div class="pa-2">
            <v-btn block color="primary" class="br-c" href="https://t.me/uncx_token" target="_blank">
              CHAT <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-slide-x-transition>
    <!--
    <template v-else>
      <a
      href="https://t.me/uncx_token" target="_blank"
      :class="['lgrad-blue deadlink white--text pa-2 pr-4 font-weight-bold', {'news-banner-mobile': $vuetify.breakpoint.xs}, {'news-banner-desktop': !$vuetify.breakpoint.xs}]"
      > 
        <div class="d-flex align-center caption font-weight-bold white--text">
          <v-icon size="26" color="white" class="mr-1">mdi-telegram</v-icon>
          CHAT
        </div>
      </a>
    </template>
    -->
  </div>
</template>

<script>
export default {
  props: {
  },

  data: () => ({
    showSupportPopup: true
  }),
}
</script>

<style scoped lang="scss">
.news-banner-mobile {
  overflow: hidden;
  opacity: 1; 
  position: fixed; 
  bottom: 70px; 
  left: 10px;
  z-index: 5;
  border-radius: 20px 20px 20px 20px;
  max-width: 90%;
}
.news-banner-desktop {
  overflow: hidden;
  opacity: 1; 
  position: fixed; 
  bottom: 100px; 
  left: 10px;
  z-index: 5;
  border-radius: 16px;
  max-width: 300px;
}
</style>