import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import themes from '@/plugins/themes.js'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: themes.theme2.light
    }
  }
})
