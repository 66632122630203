<template>
    <v-container class="d-flex justify-center flex-wrap mt-10">
        <!-- <div class="pa-1 br-20 gradient-border-v2" style="width: 40%;">
            <div :class="[{'v-card br-20': !$vuetify.breakpoint.xs}, {'v-card': $vuetify.breakpoint.xs}]">
                <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;}" class="title">
                    ENMT v1
                </div>
            </div>
        </div>
        <v-spacer style="width: 2%; flex-grow: 0 !important;">
        </v-spacer>
        <div class="pa-1 br-20 gradient-border-v2" style="width: 40%;">
            <div :class="[{'v-card br-20': !$vuetify.breakpoint.xs}, {'v-card': $vuetify.breakpoint.xs}]">
                <div style="text-align: center; margin-top: 40px; margin-bottom: 40px;}" class="title">
                    Tax Token
                </div>
            </div>
        </div> -->
        <div class="pa-1 br-20 gradient-border-v2 d-flex" style="width: 40%; min-height: fit-content;"  :style="`min-width: ${$vuetify.breakpoint.xs ? '350px': '40%'};`" :class="[{'pa-0 foreground mobile-page mb-4': $vuetify.breakpoint.xs}]">
            <div class="ml-20 mr-20 v-card br-20" style="min-height: fit-content; padding: 10%;">
                <div class="title text-center primary--text mb-10" style="font-size: 2em !important;">
                    ENMT v1
                </div>
                <div class="" style="margin-bottom: 130px;">
                  <b>ERC-20 Non-Mintable Token</b>
                  <br>
                  <br>
                  Minting an ENMT is the simplest way for you to have your own token in a matter of minutes. At a click of a button, you are able to mint a new, unique token ready to be launched using our ILO dApp. 
                  <br>
                  <br>
                  Because ENMT only covers basic functionality of a crypto token, there is no need for you to apply for an audit - all token addresses created using this tool can be instantly queried with our token factory, ensuring that you’re getting exactly what you want - a simple token you can start using right away.
                  <br>
                  <br>
                  Additionally, every ENMT token created using this factory will receive a special badge when displayed in the UNCX presales browser!
                </div>
                <div class="br-20 pa-4 mb-10">
                    <div class="font-weight-medium text-center mb-2">
                      ENMT Token specs
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      No mint function
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      No owner / admin functions
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      Verified and authenticated code of the token contract itself
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      Fully ERC20 compliant
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      Fully decentralised
                    </div>
                  </div>
                  <div class="text-center"> 
                    <router-link 
                    to="/services/minter"
                    v-slot="{ navigate }">
                        <v-btn
                            rounded depressed
                                color="primary"
                                @click="navigate"
                                class="align-self-center pa-0 br-20 gradient-button-v1"
                                style="width: 30%;"
                            >
                                Select
                        </v-btn>
                    </router-link>
                  </div>    
            </div>
        </div>
        <v-spacer class="d-flex" style="flex-grow: 0 !important; height: 50px;" :style="`width: ${$vuetify.breakpoint.xs ? '100%': '3%'}`">
        </v-spacer>
        <div v-if="true" class="pa-1 br-20 gradient-border-v2 d-flex" style="width: 40%; min-height: fit-content; "  :style="`min-width: ${$vuetify.breakpoint.xs ? '350px': '40%'};`" :class="[{'pa-0 foreground mobile-page mb-4': $vuetify.breakpoint.xs}]">
            <div class="ml-20 mr-20 v-card br-20" style="min-height: fit-content;  padding: 10%;">
                <div class="title text-center primary--text mb-10" style="font-size: 2em !important;">
                    Tax Token
                </div>
                <div class="mb-8"> 
                    <b>Fully Customizable Token Solution</b>
                    <br>
                    <br>
                    For our more demanding customers, we have created the ultimate token creation solution - our brand new, Tax Token minter! Whether you would like to create a token with regular taxes, LP ones, Buybacks or even Reflections - we got you covered!
                    <br>
                    <br>
                    The motto of our new minter is ‘Full control’ - so you can decide which taxes to implement (including labelling custom ones, like a Marketing tax), as well as whether you want your token to be Mintable, Pausable or Blacklistable - so you can tailor it specifically to meet your needs.
                    <br>
                    <br>
                    Additionally, we have developed the world’s most innovative AntiBot solution - which not only allows you to block off bots from sniping initial blocks after market initialization, but also allows you to whitelist specific wallets for trading in the first hours after launch - meaning that nobody will be able to use their bots to buy in and destroy your chart!
                </div>
                <div class="br-20 pa-4 mb-10">
                    <div class="font-weight-medium text-center mb-2">
                      Tax Token specs
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      Fully adjustable taxation (up to 30%)
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      Ability to lock functions without renouncing the contract
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      Mint/Pause/Blacklist functions available
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      UNCX AntiBot included
                    </div>
                    <div class="d-flex align-start">
                      <v-icon size="20" color="" class="mr-1">mdi-check-circle-outline</v-icon>
                      Fully ERC-20 compliant
                    </div>
                  </div>
                  <div class="text-center"> 
                    <router-link 
                      to="/services/minterv2"
                      v-slot="{ navigate }"
                    >
                      <v-btn
                        rounded
                        depressed
                        color="primary"
                        @click="navigate"
                        class="align-self-center pa-0 br-20 gradient-button-v1"
                        style="width: 30%;"
                      >
                        Select
                      </v-btn>
                    </router-link>
                  </div>
            </div>
        </div>
    </v-container>
</template>

<script>
// import GeneratedTokens from '@/views/minter/generated-tokens';
// import VersionDialog from "@/views/minter/version-dialog";
// import TokenCreate from '@/views/minter-v2/token-create';

export default {
  components: {
    // GeneratedToken/s,
    // VersionDialog,
    // TokenCreate
  },

  data: () => ({
    minterVersion: 'ENMT'
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    }
  },

  methods: {
    connectWallet () {
      this.$root.$dialog.connectWalletDialog.open()
        .then(() => {
        })
        .catch(e => {})
    },
    setMinterVersion (version) {
      console.log(version);
      this.minterVersion = version;
      console.log(self.minterVersion);
    },
    showMinterVersionDialog () {
      this.$refs.minterVersionDialog.open();
    }
  },
  created () {
  }
}
</script>