<template>
<div class="midground pa-4 br-0">

  <div class="d-flex align-center">
    <span class="font-weight-bold textFaint--text">
      Stake Fee
    </span>
    <v-btn v-if="!isGasToken" color="primary" class="ml-1" small rounded text @click.native="$root.tokenPage.open(fee_token.address, $store.state.requiredNetwork)" style="min-width: 10px;">
      Token info
    </v-btn>
    <v-spacer></v-spacer>
    <span class="mr-1 font-weight-bold">
      {{ $root.formatAmount(fee_amount, isGasToken ? 18 : fee_token.decimals) }}
      {{ isGasToken ? $store.state.nativeGasTokenSymbol : fee_token.symbol }}
    </span>
  </div>
  <div class="d-flex caption textFaint--text">
    <span>
      Your Balance
    </span>
    <v-spacer></v-spacer>
    <span class="mr-1">
      {{ $root.formatAmount(fee_token_balance, isGasToken ? 18 : fee_token.decimals) }}
    </span>
    {{ isGasToken ? $store.state.nativeGasTokenSymbol : fee_token.symbol }}
  </div>
  <div class="caption textFaint--text">
    Fee applied on both stake and withdraw methods
  </div>

  <div class="d-flex align-center">
    <v-btn v-if="!isGasToken && allowanceIncreaseStakeModFeeRequired" @click="approveStakeFeeToken" small color="primary">
      approve {{ fee_token.symbol }}
    </v-btn>
  </div>
</div>
</template>

<script>
import { ethers } from 'ethers'
import ERC20BALANCE from '@/smart-contracts/erc20'
import TxWrapper from '@/web3/tx-wrapper-2';

export default {

  props: {
    pool_address: null,
    fee_amount: {
      type: String,
      default: '0'
    },
    fee_token: {
      decimals: 18,
      address: '0'
    }
  },

  components: {
  },

  data: () => ({
    allowance: '0',
    fee_token_balance: '0',
    approvalLoading: false
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
    isGasToken () {
      return this.fee_token.address === ethers.constants.AddressZero
    },
    allowanceIncreaseStakeModFeeRequired () {
      if (ethers.BigNumber.from(this.fee_amount).gt(this.allowance)) {
        return true
      }
      return false
    },
  },

  watch: {
    sEthersWatcher () {
      this.refresh()
    }
  },

  methods: {
    refresh () {
      this.getStakeModFeeInfo()
      this.getStakeModFeeAllowance()
    },
    async getStakeModFeeAllowance () {
      if (this.isGasToken) {
        return
      }
      this.allowance = await ERC20BALANCE.getAllowance(this.fee_token.address, this.sEthers.coinbase, this.pool_address)
    },
    async getStakeModFeeInfo () {
      if (this.isGasToken) {
        this.fee_token_balance = (await this.sEthers.provider.getBalance(this.sEthers.coinbase)).toString()
      } else {
        this.fee_token_balance = (await ERC20BALANCE.getBalance(this.sEthers.coinbase, this.fee_token.address)).toString()
      }
    },
    approveStakeFeeToken () {
      var amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      this.approvalLoading = true
      TxWrapper.doTransaction(ERC20BALANCE.setAllowance,
        [this.fee_token.address, amount, this.pool_address], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          this.getStakeModFeeAllowance()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          this.approvalLoading = false
        })
    },
  },

  created () {
    this.refresh()
  }
}
</script>