<template>
  <div style="display: inline-block;position: relative;">
    <div class="d-flex align-center">
      <v-menu
      offset-y
      content-class="br-20"
      transition="fade-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            small
            text
            rounded :color="color" class="pa-2"
            v-bind="attrs"
            v-on="on"
          >
            <slot>
              {{ $root.condenseAddress(address) }}
              <v-icon x-small class="ml-2">mdi-content-copy</v-icon>
            </slot>
          </v-btn>
        </template>

        <v-list style="min-width: 200px;" class="background br-20 ma-0 pa-0">
          <v-list-item dense @click="copyTokenAddress">
            <v-list-item-title>
              Copy Address
            </v-list-item-title>
          </v-list-item>
          <v-list-item dense @click="copyTokenAddressLowercase">
            <v-list-item-title>
              Copy Address (in lowercase)
            </v-list-item-title>
          </v-list-item>
          <v-list-item dense :href="`${$settings.CHAINS[cNetwork].explorerURL}/address/${address}`" target="_blank">
            <v-list-item-title>
              View on {{ $settings.CHAINS[cNetwork].explorerName }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-scroll-x-transition>
        <v-card v-if="textCopied" class="px-2 primary br-20" style="position: fixed;z-index: 1;">
          <div class="white--text caption font-weight-bold">
            <v-icon small color="white">mdi-check-circle-outline</v-icon>
            Copied
          </div>
        </v-card>
      </v-scroll-x-transition>
      <div ref="copyContainer" class="t-small">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    network: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    textCopied: false,
  }),

  computed: {
    cNetwork () {
      return this.network || this.$store.state.requiredNetwork
    }
  },
  
  methods: {
    copyTokenAddress: function () {
      var container = this.$refs.copyContainer
      this.$copyText(this.address, container).then(() => {
        this.textCopied = true
        setTimeout(() => {
          this.textCopied = false
        }, 1000)
      }, function (e) {
        alert('Can not copy')
      })
    },
    copyTokenAddressLowercase: function () {
      var container = this.$refs.copyContainer
      this.$copyText(this.address.toLowerCase(), container).then(() => {
        this.textCopied = true
        setTimeout(() => {
          this.textCopied = false
        }, 1000)
      }, function (e) {
        alert('Can not copy')
      })
    },
  }
}
</script>