<template>
  <v-container style="max-width: 1200px;" :class="['pb-10 pa-0', {'pa-0 mobile-page': $vuetify.breakpoint.xs}]">

    <template v-if="contract_version === 1">
      <pool-v1 :address="address"></pool-v1>
    </template>
    <template v-if="contract_version === 2">
      <pool-v2 :address="address"></pool-v2>
    </template>

  </v-container>
</template>

<script>
import ContractVersionContract from '@/smart-contracts/contract-version/contract-version'
import PoolV1 from './ui/v1/pool'
import PoolV2 from './ui/v2/pool'

export default {

  props: {
    address: null
  },

  components: {
    PoolV1,
    PoolV2
  },

  data: () => ({
    contract_version: -1
  }),

  computed: {
  },

  methods: {
    async getContractVersion () {
      this.contract_version = await ContractVersionContract.getContractVersion(this.address)
      this.firstLoad = false
    }
  },

  created () {
    this.getContractVersion()
  }
}
</script>