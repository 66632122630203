<template>
  <div class="pa-1 br-20 gradient-border-v2">
    <v-card class="br-20">
      <div class="d-flex align-center title pa-4 border-b justify-center" style="min-height: 69px;">
        {{ view !== 'ANTIBOT' ? 'Create Token' : 'AntiBot Features' }}
      </div>
      <div :class="[{'pa-4': $vuetify.breakpoint.xs}, {'pa-6': !$vuetify.breakpoint.xs}]">
        <token-presets 
          v-if="view === 'PRESETS'"
          @on-change="handleTaxFormChange"
          @on-preset-change="handlePresetChange"
          :initial-values="tokenTaxValues"
          :current-preset="preset"

        />
        <token-form
          v-if="view === 'CREATE'"
          @on-change="handleTokenFormChange"
          :initial-values="tokenFormValues"
        />
        <token-antibot
          v-if="view === 'ANTIBOT'"
          @on-change="handleTokenAntibotChange"
          :initial-values="tokenAntiBotValues"
          :symbol="tokenFormValues.symbol"
        />
        <token-tax-form
          v-if="view === 'TAX'"
          @on-change="handleTaxFormChange"
          :initial-values="tokenTaxValues"
        />
        <div v-if="view === 'TAX'">
          <div class="text-center">
            Fees:
          </div>
          <div class="d-flex">
            Total supply:
            <v-spacer />
            {{ fees.ts_fee / 100 }}%
          </div>
          <div class="d-flex">
            Flat fee:
            <v-spacer />
            {{ $root.formatAmount(fees.flat_fee, $settings.CHAINS[$store.state.requiredNetwork].wrappedGasToken.decimals)  }}
            {{ $settings.CHAINS[$store.state.requiredNetwork].gasToken.symbol }}
          </div>
        </div>
        <div class="pa-2">
        <div v-for="error of errors" :key="error" class="orange--text">
          {{ error }}
        </div>
        </div>
  <!--      <div-->
  <!--        v-if="view === 'TAX'"-->
  <!--        style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;"-->
  <!--      >-->
  <!--        <v-btn large block rounded outlined @click="handleBack" color="primary" style="border: 1.5px solid;">-->
  <!--          Next-->
  <!--        </v-btn>-->
  <!--      </div>-->
  <!--        v-if="view !== 'TAX'"-->

        <div
          style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;"
        >
          <div style="justify-content: flex-start; width: 48%;">
            <v-btn large block rounded outlined @click="handleBack" color="textFaint" style="border: 1.5px solid;">
              {{view === 'CREATE' ? 'Cancel' : 'Back'}}
            </v-btn>
          </div>
          <div style="justify-content: flex-start; width: 48%;">
            <v-btn :loading="createLoading" large block rounded depressed @click="handleNext" color="primary" class=" br-20 gradient-button-v1">
              {{['PRESETS', 'CREATE', 'ANTIBOT'].includes(view) ? 'Next' : 'Create'}}
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
    <success-modal ref="successDialog" />
    <camelot-modal ref="camelotDialog" :tokenAddress="tokenAddress" @on-close="showSuccessDialog" />
  </div>
</template>
<script>
import { ethers } from 'ethers';
import TokenForm from "@/views/minter-v2/token-form";
import TokenPresets from "@/views/minter-v2/presets";
import TokenTaxForm from "@/views/minter-v2/token-tax-form";
import TxWrapper from '@/web3/tx-wrapper-2';
import TokenFeesContract from '@/smart-contracts/minter-v2/token-fees';
import MintGenerator from '@/smart-contracts/minter-v2/mint-generator01';
import TokenAntibot from "@/views/minter-v2/token-antibot";
import SuccessModal from "@/components/dialogs/tax-token-success-dialog";
import CamelotModal from "@/components/dialogs/tax-token-camelot-dialog";
import SETTINGS from '@/store/settings'

export default {

  components: {
    TokenAntibot,
    TokenForm,
    TokenPresets,
    TokenTaxForm,
    SuccessModal,
    CamelotModal
  },
  data: () => ({
    view: 'CREATE',
    preset: '',
    tokenFormValues: {
      name: '',
      symbol: '',
      supply: 0,
      decimals: 18,
      creator: ''
    },
    tokenTaxValues: {
      transactionTaxAddress: '',

      buybackTaxThreshold: 0,
      lpTaxThreshold: 0,

      losslessEnabled: false,
      losslessAdminAddress: '',
      losslessRecoveryAddress: '',
      customTaxes: [],
      taxSettings: {
        transactionTax: false,
        buyBackTax: false,
        holderTax: false,
        lpTax: false,
        canBlacklist: false,
        canMint: false,
        canPause: false,
        maxBalanceAfterBuy: false
      },
      lockedSettings: {
        transactionTax: false,
        buyBackTax: false,
        holderTax: true,
        lpTax: false,
        // TODO : need to account for locking these settings
        canBlacklist: false,
        canMint: false,
        canPause: false,
        maxBalanceAfterBuy: false
      },
      fees: {
        transactionTax: {
          buy: 0,
          sell: 0
        },
        buyBackTax: 0,
        holderTax: 0,
        lpTax: 0
      },
    },
    tokenAntiBotValues: {
      antiBotSettings: {
        startBlock: 0,
        endDate: 0,
        increment: 0,
        initialMaxHold: 0,
        isActive: false
      },
      swapWhitelistingSettings: {
        endDate: 0,
        isActive: false
      },
      maxBalanceAfterBuyActive: false,
      maxBalanceAfterBuy: 0

      // antibotMaxBalanceEnabled: false,
      // antibotMaxBalance: null,
      // antibotIncrementEnabled: false,
      // antibotIncrement: null,
      // antibotSwapWhitelistEnabled: false,
      // antibotSwapWhitelist: []
    },
    fees: {
      flat_fee: '0',
      ts_fee: '0'
    },
    tokenAddress: '',
    createLoading: false,
    errors: [],
  }),
  computed: {
    sEthers () {
      return this.$store.state.ethers;
    },
    pendingTx () {
      return this.$store.state.pendingTx.pending;
    },
    wallet () {
      return this.sEthers.coinbase
    },
    currentNetwork () {
      return this.$store.state.requiredNetwork
    },
    taxHelperIndex () {
      var exchange = this.$store.state.exchange;
      return this.$settings.AMMS[exchange].taxTokenTaxHelperIndex;
    }
  },
  watch: {
    currentNetwork () {
      this.getFees()
    },
    wallet: {
      handler (newData, oldData) {
        if (this.tokenFormValues.creator === '') {
          this.tokenFormValues.creator = this.walletConnected();
        }
        if (this.tokenTaxValues.losslessAdminAddress === '') {
          this.tokenTaxValues.losslessAdminAddress = this.walletConnected();
        }
        if (this.tokenTaxValues.losslessRecoveryAddress === '') {
          this.tokenTaxValues.losslessRecoveryAddress = this.walletConnected();
        }
      }
    }
  },
  methods: {
    setView (view) {
      // only allow navigating to views that actually exist
      if (['CREATE', 'ANTIBOT', 'PRESETS', 'TAX'].includes(view)) {
        this.view = view;
      }
    },
    handleBack () {
      if (this.view === 'TAX') {
        this.setView('PRESETS');
      } else if (this.view === 'ANTIBOT') {
        this.setView('CREATE');
      } else if (this.view === 'PRESETS') {
        this.setView('ANTIBOT')
      }
    },
    handleNext () {
      if (this.view === 'ANTIBOT') {
        this.setView('PRESETS');
      } else if (this.view === 'CREATE') {
        this.setView('ANTIBOT');
      } else if (this.view === 'PRESETS') {
        this.setView('TAX');
      } else if (this.view === 'TAX') {
        this.createToken();
      }
    },
    async getFees () {
      this.fees = await TokenFeesContract.getFees();
    },
    formIsValid () {
      this.errors = [];

      // check Token Name Length
      if (this.tokenFormValues.name.length < 1) {
        this.errors.push("Name must have at least 1 character");
      }

      // Check Token symbol length, force uppercase
      this.tokenFormValues.symbol = this.tokenFormValues.symbol.toUpperCase();
      if (this.tokenFormValues.symbol.length < 1) {
        this.errors.push("Symbol must have at least 1 character");
      }

      // Total Supply
      if (
        this.tokenFormValues.supply.length < 1 ||
        this.tokenFormValues.supply > 2 ** 256
      ) {
        this.errors.push("Supply out of range");
      }

      // Decimals must be between 0 and 36
      if (
        this.tokenFormValues.decimals.length < 1 ||
        parseInt(this.tokenFormValues.decimals) < 0 ||
        parseInt(this.tokenFormValues.decimals) > 18
      ) {
        this.errors.push("Decimals must be between or equal to 0 and 18");
      }

      // Making sure taxes, if activated, have values
      // Not sure if we need to validate, we can set everything to zero if not filled out
      // if (
      //   this.tokenTaxValues.taxSettings &&
      //   this.tokenTaxValues.fees. ||
      //   parseInt(this.tokenFormValues.decimals) > 18
      // ) {
      //   this.errors.push("Decimals must be between or equal to 0 and 18");
      // }

      // Check for max taxes
      let taxTotalBuy = 0;
      if (this.tokenTaxValues.taxSettings.transactionTax) {
        taxTotalBuy += Number.parseInt(this.tokenTaxValues.fees.transactionTax.buy);
      }
      if (this.tokenTaxValues.taxSettings.holderTax) {
        taxTotalBuy += Number.parseInt(this.tokenTaxValues.fees.holderTax);
      }
      this.tokenTaxValues.customTaxes.forEach((i) => {
        if (!ethers.utils.isAddress(i.wallet)) {
          this.errors.push(`Custom Tax Wallet must be an address`);
        }
        taxTotalBuy += Number.parseInt(i.fee.buy);
      })
      let taxTotalSell = 0;
      if (this.tokenTaxValues.taxSettings.transactionTax) {
        taxTotalSell += Number.parseInt(this.tokenTaxValues.fees.transactionTax.sell);
      }
      if (this.tokenTaxValues.taxSettings.buyBackTax) {
        taxTotalSell += Number.parseInt(this.tokenTaxValues.fees.buyBackTax);
      }
      if (this.tokenTaxValues.taxSettings.lpTax) {
        taxTotalSell += Number.parseInt(this.tokenTaxValues.fees.lpTax);
      }
      if (this.tokenTaxValues.taxSettings.holderTax) {
        taxTotalSell += Number.parseInt(this.tokenTaxValues.fees.holderTax);
      }
      this.tokenTaxValues.customTaxes.forEach((i) => {
        taxTotalSell += Number.parseInt(i.fee.sell);
      })
      console.log("TOTALS: ")
      console.log(taxTotalBuy)
      console.log(taxTotalSell)

      if (
        taxTotalBuy > 30
      ) {
        this.errors.push("Buy tax total must be 30% or less");
      }
      if (
        taxTotalSell > 30
      ) {
        this.errors.push("Sell tax total must be 30% or less");
      }

      // Check addresses
      if (!ethers.utils.isAddress(this.tokenFormValues.creator)) {
        this.errors.push("Creator must be an address");
      }

      if (this.tokenTaxValues.taxSettings.transactionTax && !ethers.utils.isAddress(this.tokenTaxValues.transactionTaxAddress)) {
        this.errors.push("Transaction Tax Address must be an address");
      }

      if (this.tokenTaxValues.taxSettings.canMint && this.tokenTaxValues.taxSettings.holderTax) {
        this.errors.push("Cannot have both reflection and mint activated")
      }

      return this.errors.length === 0;
    },
    async createToken () {
      if (!this.formIsValid()) {
        return;
      }
      const network = this.$store.state.requiredNetwork
      const gasTokenDecimals = SETTINGS.CHAINS[network].gasToken.decimals

      this.createLoading = true;
      const totalSupply = ethers.utils.parseUnits(this.tokenFormValues.supply, this.tokenFormValues.decimals);
      this.tokenTaxValues.taxSettings.maxBalanceAfterBuy = this.tokenAntiBotValues.maxBalanceAfterBuyActive;
      const _tokenFees = {
        transactionTax: {
          buy: this.tokenTaxValues.fees.transactionTax.buy * 100,
          sell: this.tokenTaxValues.fees.transactionTax.sell * 100,
        },
        buyBackTax: this.tokenTaxValues.fees.buyBackTax * 100,
        holderTax: this.tokenTaxValues.fees.holderTax * 100,
        lpTax: this.tokenTaxValues.fees.lpTax * 100
      }
      const _customTaxes = JSON.parse(JSON.stringify(this.tokenTaxValues.customTaxes));
      _customTaxes.forEach((i) => {
        i.fee.buy = Number.parseInt(i.fee.buy) * 100;
        i.fee.sell = Number.parseInt(i.fee.sell) * 100;
        i.withdrawAsGas = false;
      })
      const _antiBotSettings = {
        startBlock: this.tokenAntiBotValues.antiBotSettings.startBlock,
        endDate: this.tokenAntiBotValues.antiBotSettings.endDate,
        increment: ethers.utils.parseUnits(String(this.tokenAntiBotValues.antiBotSettings.increment), this.tokenFormValues.decimals),
        initialMaxHold: ethers.utils.parseUnits(String(this.tokenAntiBotValues.antiBotSettings.initialMaxHold), this.tokenFormValues.decimals),
        isActive: this.tokenAntiBotValues.antiBotSettings.isActive
      }

      const constructorParams = {
        name_: this.tokenFormValues.name,
        symbol_: this.tokenFormValues.symbol,
        decimals_: this.tokenFormValues.decimals, 
        creator_: this.tokenFormValues.creator,
        tTotal_: totalSupply,
        _maxTax: 3000,
        _settings: this.tokenTaxValues.taxSettings,
        _lockedSettings: this.tokenTaxValues.lockedSettings,
        _fees: _tokenFees,
        _transactionTaxWallet: this.tokenTaxValues.transactionTaxAddress || this.walletConnected(),
        _customTaxes: _customTaxes,
        _taxHelperIndex: this.taxHelperIndex,
        lpWalletThreshold: ethers.utils.parseUnits(String(this.tokenTaxValues.lpTaxThreshold), gasTokenDecimals),
        buyBackWalletThreshold: ethers.utils.parseUnits(String(this.tokenTaxValues.buybackTaxThreshold), gasTokenDecimals),
        isLossless_: this.tokenTaxValues.losslessEnabled,
        admin_: this.tokenTaxValues.losslessAdminAddress
          ? this.tokenTaxValues.losslessAdminAddress
          : this.walletConnected(),
        recoveryAdmin_: this.tokenTaxValues.losslessRecoveryAddress
          ? this.tokenTaxValues.losslessRecoveryAddress
          : this.walletConnected(),

        _antiBotSettings: _antiBotSettings,
        _maxBalanceAfterBuy: ethers.utils.parseUnits(String(this.tokenAntiBotValues.maxBalanceAfterBuy), this.tokenFormValues.decimals),
        _swapWhitelistingSettings: this.tokenAntiBotValues.swapWhitelistingSettings
      }

      console.log(constructorParams);

      TxWrapper.doTransaction(
        MintGenerator.createToken,
        [constructorParams, this.fees.flat_fee],
        this.$store.state.requiredNetwork,
        this.$root.$dialog.chainSwitch,
        this.$root.$dialog.confirmTx2
      ).catch(e => {
        this.$root.$dialog.web3Error.open(e.message);
      }).then((data) => {
        console.log(data);
        if (data.receipt.status === 1) {
          const eventIndex = data.receipt.events.length;
          const creationEvent = data.receipt.events[eventIndex - 1];
          const decodedArray = ethers.utils.defaultAbiCoder.decode(["address", "address"], creationEvent.data);
          this.tokenAddress = decodedArray[1];

          this.lpTokenCreated = true;
        
          if (this.$store.state.exchange === 'Camelot') {
            this.showCamelotDialog();
          } else {
            this.showSuccessDialog();
          }
        }
      }).finally(() => {
        this.createLoading = false;
      })
    },
    handleTokenFormChange (values) {
      this.tokenFormValues = values;
    },
    handlePresetChange (value) {
      this.preset = value;
    },
    handleTaxFormChange (values) {
      this.tokenTaxValues = values;
    },
    handleTokenAntibotChange (values) {
      this.tokenAntiBotValues = values;
    },
    walletConnected () {
      return this.sEthers.coinbase;
    },
    showSuccessDialog () {
      this.successDialog.open();
    },
    showCamelotDialog () {
      this.camelotDialog.open();
    },
  },
  mounted () {
    this.successDialog = this.$refs.successDialog;
    this.camelotDialog = this.$refs.camelotDialog;
    this.tokenFormValues.creator = this.walletConnected();
    this.tokenTaxValues.losslessAdminAddress = this.walletConnected();
    this.tokenTaxValues.losslessRecoveryAddress = this.walletConnected();
    this.getFees();
  }
}

</script>