<template>
  <div>
    <div class="mb-2 font-weight-bold" style="text-align: start;">
      Name
    </div>
    <div class="d-flex align-center inputcolor pa-2 r-outlined mb-4">
      <c-input
        :value.sync="values.name"
        type="text"
        placeholder="My Token"
      />
    </div>
    <div class="mb-2 font-weight-bold" style="text-align: start;">
      Symbol
    </div>
    <div class="d-flex align-center inputcolor pa-2 r-outlined mb-4">
      <c-input
        :value.sync="values.symbol"
        type="text"
        placeholder="MTKN"
      />
    </div>
    <div class="mb-2 font-weight-bold" style="text-align: start;">
      Initial Supply
    </div>
    <div class="d-flex align-center inputcolor pa-2 r-outlined mb-4">
      <c-input
        :value.sync="values.supply"
        type="number"
        placeholder="21000000000"
      />
    </div>
    <div class="mb-2 font-weight-bold" style="text-align: start;">
      Decimals
    </div>
    <div class="d-flex align-center inputcolor pa-2 r-outlined mb-4">
      <c-input
        :value.sync="values.decimals"
        type="number"
        max="18"
      />
    </div>
    <div class="mb-2 font-weight-bold" style="text-align: start;">
      Creator
      <v-tooltip right max-width="24em">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
                class="ml-2"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Only change if you want to have a different wallet as the owner.</span>
          </v-tooltip>
    </div>
    <div class="d-flex align-center inputcolor pa-2 r-outlined mb-4">
      <c-input
        :value.sync="values.creator"
        type="strinng"
        placeholder="0x..."
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    initialValues: {
      type: Object
    }
  },
  data: ({ initialValues }) => ({
    values: initialValues
  }),
  methods: {
    onChange (values) {
      this.$emit('on-change', values);
    }
  },
  watch: {
    values: {
      handler (newValue, _) {
        this.onChange(newValue);
      },
      deep: true
    }
  }
}
</script>