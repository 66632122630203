<template>
  <v-container style="max-width: 600px;">

    <div class="text-center flex pa-2">
      <v-btn large rounded outlined @click="$root.$dialog.chainSwitcher.open()" class="foreground title">
        <img 
        :src="$settings.CHAINS[$store.state.requiredNetwork].icon" 
        height="24px"
        width="24px"
        class="mr-3 br-20">
        {{ $settings.CHAINS[$store.state.requiredNetwork].name }}
        <v-icon small color="">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <v-slide-x-transition appear>
      <div>

        <div class="v-card br-20 px-4 pt-8 pb-4">

          <div class="text-center">
            <img 
            src="@/assets/img/icons/rocket.svg" 
            height="60px"
            width="60px"
            class="">
          </div>

          <div class="title text-center textFaint--text">
            Launchpad
          </div>

          <div class="text-center mb-2">
            Launch your token on which exchange?
          </div>

          <div v-for="amm of amms" :key="amm" class="v-card d-flex align-center title c-list pa-4 br-20 mb-2" @click="selectExchange(amm)">
            <img 
            :src="$settings.AMMS[amm].icon" 
            height="40px"
            width="40px"
            class="mr-3 br-20">
            <div>
              <div class="title">
                {{ amm }}
              </div>
              <div class="caption textFaint--text">
                {{ $settings.CHAIN_DISPLAY_NAME[$store.state.requiredNetwork] }}
              </div>
            </div>
          </div>

        </div>

      </div>
    </v-slide-x-transition>
    
  </v-container>
</template>

<script>

export default {

  data: () => ({
    amms: [] // ['uniswap', 'sushiswap']
  }),

  computed: {
    requiredNetwork () {
      return this.$store.state.requiredNetwork
    },
  },

  watch: {
    requiredNetwork () {
      this.refresh()
    }
  },

  methods: {
    selectExchange (exchange) {
      this.$store.commit('switchExchange', exchange)
      this.$router.push(`/services/launchpad/create`)
    },
    refresh () {
      this.amms = Object.keys(this.$settings.AMMS).filter(key => this.$settings.AMMS[key].chain === this.$store.state.requiredNetwork)
    }
  },

  created () {
    this.refresh()
  }
}
</script>