<template>
  <tr v-if="!$vuetify.breakpoint.xs">
    <td class="caption">
      <copy-address :address="staker_info.user_address" color="text"></copy-address>
    </td>
    <td>
      <!-- {{ tokenHydrated.symbol }} -->
    </td>
    <td>
      <div class="d-flex align-center">
        <v-progress-linear height="30" :value="100"
        color="lgrad-green"
        background-color=""
        style="width: 30px;"
        class="font-weight-bold br-c">
          <v-icon size="24" color="white">mdi-flash-circle</v-icon>
        </v-progress-linear>
        <span class="pl-1">
          {{ staker_info.time_boost_percentage / 100 }}%
        </span>
      </div>
    </td>
    <td>
      <div class="d-flex align-center">
        <v-progress-linear height="30" :value="100"
        color="lgrad-blue"
        background-color=""
        style="width: 30px;"
        class="font-weight-bold br-c">
          <v-icon size="24" color="white">mdi-flash-circle</v-icon>
        </v-progress-linear>
        <span class="pl-1">
          {{ staker_info.uncl_boost_percentage / 100 }}%
        </span>
      </div>
    </td>
    <td class="">
      {{ $root.formatAmount(staker_info.share_weight, pool_info.decimals) }}
    </td>
    <td v-if="false">
      {{ staker_info.subscriptions_length }}
    </td>
    <td>
    </td>
    <td v-if="false">
      {{ staker_info }}
    </td>
  </tr>
  <tr v-else>
    <td class="caption">
      <div class="d-flex align-center">
        <v-icon size="50">mdi-account-circle</v-icon>
        <div>
          <copy-address :address="staker_info.user_address" color="text"></copy-address>

          <div class="d-flex align-center">
            <v-progress-linear height="24" :value="100"
            color="lgrad-green"
            background-color=""
            style="width: 24px;"
            class="font-weight-bold br-c">
              <v-icon size="20" color="white">mdi-flash-circle</v-icon>
            </v-progress-linear>
            <span class="primary--text px-1">
              {{ staker_info.time_boost_percentage / 100 }}%
            </span>
            <v-progress-linear height="24" :value="100"
            color="lgrad-blue"
            background-color=""
            style="width: 24px;"
            class="font-weight-bold br-c">
              <v-icon size="20" color="white">mdi-flash-circle</v-icon>
            </v-progress-linear>
            <span class="light-blue--text pl-1">
              {{ staker_info.uncl_boost_percentage / 100 }}%
            </span>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div>
      </div>
      <div class="caption textFaint--text">
        {{ $root.formatAmount(staker_info.share_weight, pool_info.decimals) }}
      </div>
    </td>
  </tr>
</template>

<script>
// import moment from 'moment'
// import { ethers } from 'ethers'

export default {

  props: {
    staker_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    pool_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },

  components: {
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },

  created () {
  }
}
</script>