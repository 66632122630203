<template>
  <div :class="['cursor-pointer d-flex align-center']" style="flex: 1 0 0;">

    <div v-if="redCard && !presale.hot" class="red" style="position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px;opacity: 0.15;">
    </div>

    <div style="flex: 1 0 0;">
      <div v-if="firstLoad" class="textFaint--text d-flex justify-center align-center">
        <v-progress-circular
        indeterminate
        size="18"
        width="1"
        style="position: absolute;top: 20px; right: 20px;"
        color="textFaint">
        </v-progress-circular>
        <div class="text-center">
          <coin-icon :url="presale.icon_url" :size="76" :class="presale.hot ? 'primary-pulse': ''"></coin-icon>
          <div class="title text--text font-weight-medium">
            {{ presale.s_token.name }}
          </div>
          <div v-if="!sEthers.coinbase" class="textFaint--text caption font-italic">
            Connect your wallet for faster loading times
          </div>
        </div>
      </div>

      <div v-else style="overflow: hidden;">

        <!-- NEW -->
        <v-carousel v-model="carousel" :continuous="false" hide-delimiters show-arrows-on-hover height="200px;" class="carousel-override">
          
          <v-carousel-item class="fill-height py-3 px-4">
            <div class="d-flex align-center" style="height: 100%;">
              <div class="flex">
                <!-- PROFIT AND LOSS -->
                <div v-if="presaleStage === 5" class="d-flex align-center mb-1">
                  <div class="br-c midground">
                    <chart-links :token_address="presale.s_token.address" :pair_address="finalizedPairAddress" :exchange="cExchange"></chart-links>
                    <v-btn v-if="price_diff > 0 || $store.state.showAllStatsMode" @click.stop="getPriceDiff" text rounded small class="align-center">
                      <span :class="[price_diff >= 0 ? 'primary--text' : 'red--text', 'caption font-weight-medium']">{{ price_diff.toFixed(0) }}%</span>
                      <v-icon color="textFaint" small style="margin: 1px;" :class="['br-c', refreshPriceLoading ? 'primary-pulse' : '']">{{ price_diff_positive ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                    </v-btn>
                  </div>
                  <div v-if="price_diff >= 100" :class="['d-flex caption align-center primary--text px-2 br-c font-weight-bold ml-1']">
                    {{ ((price_diff / 100) + 1).toFixed(1) }}X
                    <v-icon small color="primary" class="ml-1">mdi-rocket</v-icon>
                  </div>
                </div>
                <!-- PROFIT AND LOSS -->

                <div class="d-flex align-center">
                  <coin-icon :url="presale.icon_url" :size="$vuetify.breakpoint.xs ? 56 : 66" class="mr-3"></coin-icon>
                  <div style="flex:1;">

                    <div class="d-flex align-center">
                      <v-btn text @click.stop="openTokenPage" rounded class="title pa-0" style="min-width: 10px;">
                        <v-icon v-if="presale.hot" size="24" color="primary" class="mx-1 br-c">mdi-rocket</v-icon>
                        <span :class="['text-truncate', {'red--text': tokenomicsIsRed}, {'primary--text': presale.hot}]" :style="$vuetify.breakpoint.xs ? 'max-width: 12ch;' : 'max-width: 20ch;'">
                          {{ presale_info.token.name }}
                        </span>
                        <v-icon small :color="tokenomicsIsRed ? 'red' : 'textFaint'">mdi-chevron-up</v-icon>
                      </v-btn>

                      <div class="d-flex align-start ml-2" @click.stop="carousel++">
                        <!--
                        <v-icon v-if="presale.twitter_url" color="textFaint" small>mdi-twitter</v-icon>
                        <v-icon v-else color="red" small>mdi-twitter</v-icon>
                        <v-icon v-if="presale.telegram_url" color="textFaint" small>mdi-telegram</v-icon>
                        <v-icon v-else color="red" small>mdi-telegram</v-icon>
                        <v-icon v-if="presale.website_url" color="textFaint" small>mdi-web</v-icon>
                        <v-icon v-else color="red" small>mdi-web</v-icon>

                        <v-icon v-if="!presale.twitter_url" color="red" small>mdi-twitter</v-icon>
                        <v-icon v-if="!presale.telegram_url" color="red" small>mdi-telegram</v-icon>
                        <v-icon v-if="!presale.website_url" color="red" small>mdi-web</v-icon>
                        -->
                      </div>

                      <v-spacer></v-spacer>

                      <v-btn 
                      v-if="$store.state.superUserMode && $store.state.user.jwtObject.user_id === '21'" 
                      small color="textFaint" text 
                      :href="`${$settings.ETHERSCAN_URL[cNetwork]}/address/${presale_info.presale_contract}`" 
                      target="_blank"
                      @click.stop>
                        {{ $store.state.explorer_name }}
                        <v-icon x-small>mdi-arrow-top-right</v-icon>
                      </v-btn>

                      <div v-if="locksLoading" class="caption textFaint--text">
                        <v-progress-circular
                        indeterminate
                        size="30"
                        width="1"
                        color="textFaint">
                          {{ lockLoadProgress }}
                        </v-progress-circular>
                      </div>

                      <div v-else style="width: 30px;">
                        <donut-chart :chartdata="chartData" :options="chartOptions" :red="tokenomicsIsRed" ref="donutChart1" />
                      </div>

                    </div>

                    <div v-if="presale_info.presale_status === 0" :class="`caption font-weight-regular ${textColor}--text`">
                      <template v-if="!firstLoad">
                        Starts {{ dateStartBlockHuman }} your time
                      </template>
                      <span v-else class="font-weight-medium textFaint--text">
                        ...loading
                      </span>
                    </div>

                    <!-- BADGES -->
                    <div class="d-flex align-center flex-wrap">

                      <template v-if="tokenHydrated.address">

                        <div class="d-flex align-center font-weight-medium pr-2 br-8" style="position: relative; overflow: hidden;">
                          <v-icon size="16" color="text" class="mr-1">mdi-lock</v-icon>
                          <span class="text--text">
                            {{ presale_info.liquidity_percent / 10 }}%
                          </span>
                        </div>

                        <div v-if="presale.hot" class="caption d-flex align-center font-weight-medium lgrad-green white--text px-2 br-20">
                          <v-icon v-if="false" size="16" color="white" class="mx-1">mdi-rocket</v-icon>
                          INCUBATED
                        </div>

                        <!--
                        <div v-if="(tokenomicsScore < 70 || tokenomicsIsRed) && !presale.hot" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-chart-pie</v-icon>
                          <span>
                            {{ tokenomicsScore }}%
                          </span>
                        </div>

                        <div v-else class="caption d-flex align-center font-weight-medium lgrad-blue white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-chart-pie</v-icon>
                          <span>
                            {{ tokenomicsScore }}%
                          </span>
                        </div>
                        -->

                        <div v-if="tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pa-1 br-c">
                          <v-icon size="16" color="white">mdi-leaf</v-icon>
                          <!-- <span>
                            ENMT
                          </span> -->
                        </div>
                        <v-tooltip v-if="tokenHydrated.isTaxToken" right max-width="24em">
                          <template v-slot:activator="{ on, attrs }">
                            <span 
                            v-bind="attrs"
                            v-on="on"
                            class="caption br-20 font-weight-medium lgrad-blue white--text px-2" 
                            style="">
                              <v-icon color="white" small>mdi-security</v-icon>
                              Tax
                            </span>
                          </template>
                          <span>This tax token was securely minted with the UNCX Token Minter</span>
                        </v-tooltip>

                        <div v-if="presale_info.vesting_implemented" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pa-1 br-c">
                          <v-icon size="16" color="white">mdi-lock-outline</v-icon>
                        </div>

                        <div v-if="presale.audit_rating === 2" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                          <span>
                            Flagged
                          </span>
                        </div>

                        <div v-else-if="presale.audit_rating === 1" class="caption d-flex align-center font-weight-medium orange white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                          Audit
                        </div>

                        <div v-else-if="presale.audit_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text pa-1 br-c">
                          <v-icon size="16" color="white">mdi-file-document-outline</v-icon>
                          <!-- <span>
                            Audit
                          </span> -->
                        </div>

                        <!--
                        <div v-else-if="!tokenHydrated.isENMT" class="caption d-flex align-center font-weight-medium red white--text pr-2 br-20">
                          <v-icon size="16" color="white" class="mx-1">mdi-file-document-outline</v-icon>
                          <span>
                            Audit
                          </span>
                        </div>
                        -->
                      </template>

                      <div v-if="presale.kyc_rating === 0" class="caption d-flex align-center font-weight-medium lgrad-blue white--text br-c pa-1">
                        <v-icon size="16" color="white">mdi-magnify</v-icon>
                        <!-- <span>
                          KYC
                        </span> -->
                      </div>
                      
                      <div v-else-if="presale.kyc_rating === 1" class="caption d-flex align-center white--text font-weight-medium lgrad-red pr-2 br-20">
                        <v-icon size="16" color="white" class="mx-1">mdi-close-circle-outline</v-icon>
                        <span>
                          KYC
                        </span>
                      </div>
                      
                      <v-btn 
                      v-if="$store.state.superUserMode && $store.state.adminIds.includes($store.state.user.jwtObject.user_id)"
                      @click.stop="togglePresaleHot"
                      x-small rounded
                      color="text">
                        <v-icon small color="background">mdi-fire</v-icon>
                      </v-btn>

                      <v-btn 
                      v-if="$store.state.superUserMode && $store.state.adminIds.includes($store.state.user.jwtObject.user_id)"
                      @click.stop="togglePresaleVisibility"
                      x-small rounded
                      color="text">
                        <v-icon small color="background">mdi-eye</v-icon>
                      </v-btn>

                    </div>
                    <!-- BADGES -->

                  </div>
                </div>

                <!-- Presale Status -->
                <div class="d-flex mt-1">
                  <div class="caption font-weight-medium textFaint--text" style="position: relative; overflow: hidden;">
                    <span v-if="presaleStage === 0">
                      Duration {{ presaleDurationHuman }}
                    </span>
                    <span v-else-if="presaleStage === 1 || presaleStage === 2">
                      Ends {{ timeToEndBlock }}
                    </span>
                    <span v-else>
                      Presale ended
                    </span>
                  </div>

                  <v-spacer></v-spacer>

                  <!-- PRESALE STAGE -->
                  <template>
                    <div class="caption font-weight-medium px-2 textFaint--text" style="position: relative; overflow: hidden;">
                      <span>{{ presaleStageString }}</span>
                      &nbsp;
                      <span v-if="presaleStage === 0" class="text-uppercasee">{{ timeToStartBlock }}</span>
                    </div>
                  </template>
                  <!-- PRESALE STAGE -->
                </div>
                <!-- Presale Status -->

                <!-- PROGRESS -->
                <v-progress-linear height="22" :value="presaleProgress" 
                :color="tokenomicsIsRed ? 'red' : presale.hot ? 'lgrad-green' : 'lgrad-green'"
                :class="['caption font-weight-bold br-20', {'text--text': presaleProgress > 50}, {'text--text': presaleProgress <= 50}]"
                background-color="background">
                  <div class="d-flex flex px-3 align-center">
                    <div class="">
                      {{ totalBaseCollectedHuman }}
                    </div>
                    <div :class="`px-1`">
                      /
                    </div>
                    <div :class="`d-flex align-center`">
                      {{ hardcapHuman }} {{ baseTokenSymbol }}
                    </div>
                    <v-spacer></v-spacer>
                    <span v-if="$store.state.superUserMode" class="mr-2 textFaint--text">v5</span>
                    <div class="">
                      {{ userMaxLimitHuman }} {{ baseTokenSymbol }}
                    </div>
                  </div>
                </v-progress-linear>

                <v-row dense class="ma-0 pa-0 align-center">
                  <v-col cols="6" class="d-flex align-center pa-0">
                    <div v-if="unclReservationsUpcoming" class="background font-weight-medium br-c d-flex flex align-center px-2 caption" style="height: 22px;">

                      <img 
                      v-if="false"
                      src="@/assets/img/UNCL.svg" 
                      height="20px"
                      width="20px">

                      <span class="font-weight-medium text--text">{{ presale_info.uncl_max_participants }} spots</span>
                      &nbsp;<v-icon small color="text">mdi-clock-outline</v-icon>&nbsp;
                      <span class="text--text">{{ timeToUNCLReservations }}</span>
                    </div>
                    <v-progress-linear v-else height="22" :value="unclProgress" 
                    :color="tokenomicsIsRed ? 'red' : presale.hot ? 'lgrad-green' : 'lgrad-blue'" :class="['br-20 caption font-weight-bold', {'text--text': unclProgress > 50}, {'text--text': unclProgress <= 50}]"
                    background-color="background">
                      <span class="">{{ presale_info.uncl_participants }}</span>/{{ presale_info.uncl_max_participants }}
                    </v-progress-linear>
                  </v-col>
                  <v-col cols="6" class="d-flex caption align-center">
                    <v-icon size="12" class="mr-1" color="textFaint">mdi-file-document-outline</v-icon>
                    <div class="caption textFaint--text">
                      {{ presale_info.whitelist_assigned }}/{{ presale_info.whitelist_max_participants }}
                    </div>
                    <v-spacer></v-spacer>
                    <div :class="`d-flex align-center font-weight-medium ${textColor}--text mr-3`">
                      <DisqusCount shortname='unicrypt-network' :identifier="netIdentifier" />
                      <v-icon size="12" color="text" class="ml-1">mdi-comment</v-icon>
                    </div>
                    <div :class="`d-flex align-center font-weight-medium ${textColor}--text`">
                      {{ presale_info.num_buyers }}
                      <v-icon size="12" color="text" class="ml-1">mdi-account</v-icon>
                    </div>
                  </v-col>
                </v-row>
                <!-- PROGRESS -->

              </div>
            </div>
          </v-carousel-item>

          <v-carousel-item class="fill-height">
            <div class="text-center d-flex align-center justify-center border-b pa-2">
              <coin-icon :url="presale.icon_url" :size="30" class="mr-3"></coin-icon>
              {{ presale_info.token.name }} Social links
            </div>
            <div class="d-flex align-start textFaint--text px-4" style="word-break: break-all;">
              <a v-if="presale.twitter_url" @click.stop :href="presale.twitter_url" target="_blank" class="deadlink d-flex flex-column justify-center text-center pa-2 hoverlink" style="flex: 1 0 0;">
                <v-icon size="50">mdi-twitter</v-icon>
                <div class="caption font-italic">
                  {{ presale.twitter_url }}
                </div>
              </a>
              <a v-if="presale.telegram_url" @click.stop :href="presale.telegram_url" target="_blank" class="deadlink d-flex flex-column justify-center text-center pa-2 hoverlink" style="flex: 1 0 0;">
                <v-icon size="50">mdi-telegram</v-icon>
                <div class="caption textFaint--text font-italic">
                  {{ presale.telegram_url }}
                </div>
              </a>
              <a v-if="presale.website_url" @click.stop :href="presale.website_url" target="_blank" class="deadlink d-flex flex-column justify-center text-center pa-2 hoverlink" style="flex: 1 0 0;">
                <v-icon size="50">mdi-web</v-icon>
                <div class="caption textFaint--text font-italic">
                  {{ presale.website_url }}
                </div>
              </a>
            </div>
          </v-carousel-item>

          <v-carousel-item>
            <div>
              <div class="text-center d-flex align-center justify-center border-b pa-2">
                <coin-icon :url="presale.icon_url" :size="30" class="mr-3"></coin-icon>
                {{ presale_info.token.name }} {{ $settings.CHAINS[cNetwork].explorerName }} Contracts
              </div>
              <div class="caption px-3">
                <div class="d-flex align-center mt-2 px-4">
                  <div>
                    Presale contract
                  </div>
                  <div class="textFaint--text ml-2">
                    ({{ $root.condenseAddress(presale_info.presale_contract) }})
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn 
                  color="text" text small
                  :href="`${$settings.ETHERSCAN_URL[cNetwork]}/address/${presale_info.presale_contract}`" 
                  target="_blank"
                  @click.stop>
                    {{ $settings.CHAINS[cNetwork].explorerName }}
                    <v-icon x-small>mdi-arrow-top-right</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex align-center mt-1 px-4">
                  <div>
                    Creator
                  </div>
                  <div class="textFaint--text ml-2">
                    ({{ $root.condenseAddress(presale_info.presale_owner) }})
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn 
                  color="text" text small
                  :href="`${$settings.ETHERSCAN_URL[cNetwork]}/address/${presale_info.presale_owner}`" 
                  target="_blank"
                  @click.stop>
                    {{ $settings.CHAINS[cNetwork].explorerName }}
                    <v-icon x-small>mdi-arrow-top-right</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex align-center pt-1 px-4">
                  <div>
                    {{ presale_info.token.symbol }} Token
                  </div>
                  <div class="textFaint--text ml-2">
                    ({{ $root.condenseAddress(presale_info.token.address) }})
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn 
                  color="text" text small
                  :href="`${$settings.ETHERSCAN_URL[cNetwork]}/address/${presale_info.token.address}`" 
                  target="_blank"
                  @click.stop>
                    {{ $settings.CHAINS[cNetwork].explorerName }}
                    <v-icon x-small>mdi-arrow-top-right</v-icon>
                  </v-btn>
                </div>
                
              </div>
            </div>
          </v-carousel-item>

          <v-carousel-item v-if="false">
            <div style="width: 80px;">
              <donut-chart :chartdata="chartData" :options="chartOptions" ref="donutChart" />
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { ethers } from 'ethers'
import PresaleContract from '@/smart-contracts/presales/v6/presale-contract'
import { DisqusCount } from 'vue-disqus'
import SETTINGS from '@/store/settings'
import ERC20 from '@/smart-contracts/erc20/pager'
import ERC20BALANCE from '@/smart-contracts/erc20'
import V2PAIR from '@/smart-contracts/uniswap/v2-pair'
import V2FACTORY from '@/smart-contracts/uniswap/uniswap-factory-contract'
import VestingPager from '@/smart-contracts/token-locker/pager-contract'
import VESTABI from '@/smart-contracts/token-locker/token-locker-abis'
import DonutChart from '@/components/charts/donut6burn'
import ChartLinks from '@/components/linkers/charts'

export default {
  props: {
    presale: {
      type: Object,
      default: () => {
      }
    },
    exchange: null
  },

  data: () => ({
    carousel: 0,
    firstLoad: true,
    refreshPriceLoading: false,
    finalizedPairAddress: null,
    lastPriceUpdateBlock: 0,
    lastSync: 0,
    tokenHydrated: {},
    presale_info: {},
    tokenomicsScore: 0,
    current_price: 0,
    price_diff: 0,
    price_diff_positive: true,
    chartData: {
      labels: ['Presale', 'Liquidity', 'Fees', 'Locked', 'Burnt', 'Unlocked'],
      datasets: [{
        data: [],
        backgroundColor: [
          'rgb(20, 240, 132)',
          'rgb(54, 162, 235)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
          'rgba(0, 0, 0, 0.1)',
        ],
        borderWidth: 1,
        // borderColor: 'rgba(0,0,0,0.6)'
      }]

    },
    chartOptions: {
      cutoutPercentage: 70,
      legend: {
        display: false,
        position: 'right'
      },
      events: [] // an empty events array prevents hover events showing tooltips
    },
    lockLoadProgress: '',
    locksLoading: true
  }),

  components: {
    DisqusCount,
    DonutChart,
    ChartLinks
  },

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    cExchange () {
      return this.exchange || this.$store.state.exchange
    },
    cNetwork () {
      return this.$settings.AMMS[this.cExchange].chain
    },
    redCard () {
      return false
      /* if (this.presale.kyc_rating !== 0) {
        return true
      }
      return false */
    },
    tokenomicsIsRed () {
      if (this.presale.audit_rating === 2) {
        return true
      }
      return false
      // return !(this.tokenHydrated.isENMT || this.presale.audit_rating === 0 || this.presale.audit_rating === 1)
    },
    textColor () {
      // return this.presale.hot ? 'white' : 'text'
      return 'text'
    },
    netIdentifier () {
      var identifier = `token_${this.presale.s_token.address}`
      return `${this.cNetwork}_${identifier}`
    },
    blockNumber () {
      if (this.cNetwork === 'ArbitrumOne') {
        return this.$store.state.blockNumz.Mainnet
      }
      return this.$store.state.blockNumz[this.cNetwork]
    },
    blocksPerSecond () {
      if (this.cNetwork === 'ArbitrumOne') {
        return this.$settings.CHAINS.Mainnet.blockTime
      }
      return this.$settings.CHAINS[this.cNetwork].blockTime
    },
    dateStartBlock () {
      var numBlocks = this.presale_info.start_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateStartBlockHuman () {
      return this.dateStartBlock.format('ddd D MMM HH:mm')
    },
    timeToStartBlock () {
      return this.dateStartBlock.fromNow()
    },
    dateEndBlock () {
      var numBlocks = this.presale_info.end_block - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date
    },
    dateEndBlockHuman () {
      return this.dateEndBlock.format('ddd D MMM HH:mm')
    },
    timeToEndBlock () {
      return this.dateEndBlock.fromNow()
    },
    presaleBlockDuration () {
      var blockLength = Number(this.presale_info.end_block) - Number(this.presale_info.start_block)
      return blockLength
    },
    unclReservationsUpcoming () {
      if (Number(this.blockNumber) < Number(this.presale_info.round0_offset)) {
        return true
      }
      return false
    },
    timeToUNCLReservations () {
      var numBlocks = Number(this.presale_info.round0_offset) - this.blockNumber
      var seconds = numBlocks * this.blocksPerSecond
      var date = moment().add(seconds, 'seconds')
      return date.fromNow()
    },
    presaleDurationHuman () {
      var duration = this.presaleBlockDuration * this.blocksPerSecond
      var mdur = moment.duration(duration * 1000)
      if (mdur.days() > 0) {
        return mdur.days() + ' days'
      }
      if (mdur.hours() > 0) {
        return mdur.hours() + ' hours'
      }
      if (mdur.minutes() > 0) {
        return mdur.minutes() + ' minutes'
      }
      return mdur.seconds() + ' seconds'
    },
    softcapHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.softcap, this.presale.b_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    hardcapHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.hardcap, this.presale.b_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    tokenPriceHuman () {
      var amount = ethers.utils.formatUnits(this.presale_info.token_price, this.presale.s_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    listingPriceHuman () {
      var amount = ethers.utils.formatUnits(this.presale_info.listing_rate, this.presale.s_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 8})
    },
    baseTokenSymbol () {
      return this.presale.b_token.address === this.$settings.WRAPPED_GAS_TOKEN[this.cNetwork] ? this.$settings.CHAINS[this.cNetwork].gasToken.symbol : this.presale.b_token.symbol.toUpperCase()
    },
    lockPeriodHuman () {
      var endOfLock = moment().unix() + Number(this.presale_info.lock_period)
      if (endOfLock > 9999999999) {
        var diff = Number(this.presale_info.lock_period) - moment().unix()
        return moment.duration(diff * 1000).years() + ' Years'
      }
      var duration = moment.duration(this.presale_info.lock_period * 1000)
      if (duration.years() >= 1) {
        return duration.years() + ' Years'
      }
      if (duration.months() >= 1) {
        return duration.months() + ' Months'
      }
      return duration.days() + ' Days'
    },
    userMaxLimitHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.max_spend_per_buyer, this.presale.b_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    unclProgress () {
      if (this.firstLoad) {
        return 0
      }
      if (this.presale_info.uncl_max_participants === '0') {
        return 0
      }
      var percent = ethers.BigNumber.from(this.presale_info.uncl_participants).mul(1000).div(this.presale_info.uncl_max_participants)
      return percent.toNumber() / 10
    },
    presaleProgress () {
      if (this.firstLoad) {
        return 0
      }
      var percent = ethers.BigNumber.from(this.presale_info.total_base_collected).mul(1000).div(this.presale_info.hardcap)
      return percent.toNumber() / 10
      // return 0
    },
    totalBaseCollectedHuman () {
      if (this.firstLoad) {
        return 0
      }
      var amount = ethers.utils.formatUnits(this.presale_info.total_base_collected, this.presale.b_token.decimals)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    presaleStage () {
      if (this.firstLoad) {
        return 0
      }
      if (this.presale_info.lp_generation_complete) {
        return 5 // finalized
      }
      if (this.presale_info.force_failed) {
        return 4 // failed
      }
      if (Number(this.blockNumber) > Number(this.presale_info.end_block) && ethers.BigNumber.from(this.presale_info.total_base_collected).lt(this.presale_info.softcap)) {
        return 4 // failed
      }
      if (ethers.BigNumber.from(this.presale_info.total_base_collected).gte(this.presale_info.hardcap)) {
        return 3 // success
      }
      if (Number(this.blockNumber) > Number(this.presale_info.end_block) && ethers.BigNumber.from(this.presale_info.total_base_collected).gte(this.presale_info.softcap)) {
        return 3 // success
      }
      if (Number(this.blockNumber) >= Number(this.presale_info.start_block) && Number(this.blockNumber) <= Number(this.presale_info.end_block)) {
        if (Number(this.blockNumber) < (Number(this.presale_info.start_block) + Number(this.presale_info.round1_length))) {
          return 1 // round 1
        }
        return 2 // round 2
      }

      return 0 // awaiting start
    },
    presaleStageString () {
      var arr = ['Starts', 'LIVE: Round 1', 'LIVE: Round 2', 'Success', 'Failed', 'Markets initialized']
      return arr[this.presaleStage]
    },
    presaleStageColor () {
      var arr = ['blue', 'primary', 'primary', 'primary', 'pink', 'purple']
      return arr[this.presaleStage]
    },
    auditColor () {
      if (this.presale.audit_rating === null) {
        return 'orange'
      }
      var arr = ['green', 'orange', 'red']
      return arr[this.presale.audit_rating]
    },
    actualLiquidityPercent () {
      return (Number(this.presale_info.liquidity_percent) - (Number(this.presale_info.fee_base))) / 10
    }
  },

  watch: {
    blockNumber () {
      var now = Math.round(new Date() / 1000)
      var elapsed = now - this.lastSync
      // console.log('elapsed: ' + elapsed)
      if (elapsed >= 8) {
        this.lastSync = now
        this.refresh()
      } else {
        // console.log('skipping')
      }
      if (this.lastPriceUpdateBlock < this.blockNumber - 5) {
        this.getPriceDiff()
        this.lastPriceUpdateBlock = this.blockNumber
      }
    }
  },

  methods: {
    logSomething () {
      console.log(this.$root.PROVIDERS)
    }, 
    togglePresaleHot () {
      var data = {
        presale_contract: this.presale.presale_contract,
      }
      this.$axios.post(`/presales/toggle-hot`, data)
        .then(response => {
          this.$root.$dialog.globalSuccess.open('Incubation toggled, refresh to see changes')
        })
        .catch(error => {
          this.$root.$dialog.web3Error.open(error.response.data.errors[0])
        })
    },
    togglePresaleVisibility () {
      var data = {
        presale_contract: this.presale.presale_contract,
      }
      this.$axios.post(`/presales/toggle-visibility`, data)
        .then(response => {
          this.$root.$dialog.globalSuccess.open('Visibility toggled, refresh to see changes')
        })
        .catch(error => {
          this.$root.$dialog.web3Error.open(error.response.data.errors[0])
        })
    },
    async refresh () {
      var network = this.exchange ? SETTINGS.AMMS[this.exchange].chain : null
      var response = await PresaleContract.getInfo(this.presale.presale_contract, network)
      this.presale_info = response.presale_info
    },
    openTokenPage () {
      this.$root.tokenPage.open(this.presale.s_token.address, this.cNetwork, this.cExchange)
    },
    async hydrateToken () {
      this.tokenHydrated = await ERC20.getERC20(this.presale.s_token.address, this.cNetwork)
    },
    async getPriceDiff () {
      if (this.presaleStage !== 5) {
        return
      }
      this.refreshPriceLoading = true
      this.finalizedPairAddress = await V2FACTORY.getPair(this.presale.s_token.address, this.presale.b_token.address, this.cNetwork, this.cExchange)
      var reserves = await V2PAIR.getReserves(this.finalizedPairAddress, this.cNetwork)
      var token0 = await V2PAIR.token0(this.finalizedPairAddress, this.cNetwork)

      var priceToken0
      if (token0 === this.presale.s_token.address) {
        priceToken0 = ethers.BigNumber.from(ethers.BigNumber.from(10).pow(this.presale.b_token.decimals)).mul(reserves.reserve0).div(reserves.reserve1)
      } else {
        priceToken0 = ethers.BigNumber.from(ethers.BigNumber.from(10).pow(this.presale.b_token.decimals)).mul(reserves.reserve1).div(reserves.reserve0)
      }
      this.current_price = priceToken0

      var priceDifference = ethers.BigNumber.from(this.presale_info.token_price).sub(this.current_price)
      priceDifference = priceDifference.mul(10000).div(this.current_price).toString()
      var newPrice = Number(priceDifference / 100)
      this.price_diff_positive = newPrice >= this.price_diff 
      this.price_diff = newPrice
      this.refreshPriceLoading = false
    },
    async getTokenomics () {
      try {
        this.locksLoading = true
        var totalSupply = this.presale_info.token.totalSupply

        var listingRatePercent = ethers.BigNumber.from(this.presale_info.listing_rate).mul(1000).div(this.presale_info.token_price).toString()
        var unicryptFee = ethers.BigNumber.from(this.presale_info.amount).mul(this.presale_info.fee_token).div(1000).toString()
        var liquidity = ethers.BigNumber.from(this.presale_info.amount).sub(unicryptFee).mul(this.presale_info.liquidity_percent).mul(listingRatePercent).div(1000000).toString()

        // tokenLocks
        var numLocks = await VestingPager.getTokenLocksLength(this.presale_info.token.address, this.cNetwork)
        numLocks = Number(numLocks)
        var rows = []
        var page = 0;
        while (rows.length < numLocks) {
          this.lockLoadProgress = `${page + 1}/${Math.ceil(numLocks / VESTABI.MAX_GETTER_LENGTH)}`
          var lock_ids = await VestingPager.getTokenLocks(this.presale_info.token.address, page * VESTABI.MAX_GETTER_LENGTH, VESTABI.MAX_GETTER_LENGTH, this.cNetwork)
          var locks = await VestingPager.getLocks(lock_ids, this.cNetwork)
          rows.push(...locks)
          page++
        }
        this.locksLoading = false
        // total locked
        var now = moment(this.dateStartBlock).add(6, 'days').unix()
        var unlockedLocks = []
        var lockedLocks = []
        var partialLocks = []
        rows.forEach(item => {
          if (item.start_emission !== 0) { // Linear locks
            if (item.start_emission > now && item.condition === ethers.constants.AddressZero) {
              lockedLocks.push(item)
            } else if (item.end_emission > now && item.condition === ethers.constants.AddressZero) {
              partialLocks.push(item)
            } else {
              unlockedLocks.push(item)
            }
          } else { // Cliff locks
            if (item.end_emission > now && item.condition === ethers.constants.AddressZero) {
              lockedLocks.push(item)
            } else {
              unlockedLocks.push(item)
            }
          }
        })
        var amountLocked = JSON.parse(JSON.stringify(lockedLocks)).reduce((a, item) => {
          return a.add(item.tokens_deposited).sub(item.tokens_withdrawn)
        }, ethers.BigNumber.from(0))
        var amountLockedPartially = JSON.parse(JSON.stringify(partialLocks)).reduce((a, item) => {
          var timeclamp = now
          if (timeclamp > item.end_emission) {
            timeclamp = item.end_emission;
          }
          if (timeclamp < item.start_emission) {
            timeclamp = item.start_emission
          }
          var remainingTime = item.end_emission - timeclamp
          var fullperiod = item.end_emission - item.start_emission
          var tokens = ethers.BigNumber.from(item.tokens_deposited).sub(item.tokens_withdrawn)
          var locked = tokens.mul(remainingTime).div(fullperiod)
          return a.add(locked)
        }, ethers.BigNumber.from(0))
        amountLocked = amountLocked.add(amountLockedPartially)

        // chart DATA
        var burnt = await ERC20BALANCE.getBalance('0x000000000000000000000000000000000000dEaD', this.presale_info.token.address, this.cNetwork)
        var remain = ethers.BigNumber.from(totalSupply).sub(this.presale_info.amount).sub(liquidity).sub(unicryptFee).sub(amountLocked).sub(burnt)
        var unsold = ethers.utils.formatUnits(remain, this.presale_info.token.decimals)

        var amount = ethers.utils.formatUnits(this.presale_info.amount, this.presale_info.token.decimals)
        unicryptFee = ethers.utils.formatUnits(unicryptFee, this.presale_info.token.decimals)
        liquidity = ethers.utils.formatUnits(liquidity, this.presale_info.token.decimals)
        amountLocked = ethers.utils.formatUnits(amountLocked, this.presale_info.token.decimals)
        burnt = ethers.utils.formatUnits(burnt, this.presale_info.token.decimals)

        var data = [amount, liquidity, unicryptFee, amountLocked, burnt, unsold]
        this.chartData.datasets[0].data = data
        try {
          this.$refs.donutChart.update()
        } catch (e) {}
        try {
          this.$refs.donutChart1.update()
        } catch (e) {}

        // percent of total
        var percent = ethers.BigNumber.from(totalSupply).sub(remain).mul(100).div(totalSupply)
        this.tokenomicsScore = Math.min(percent.toNumber(), 100)
      } catch (e) {
        console.log(e)
        // incase on testnets and token vesting is not connected
        // this prevents errors in the browser console that make no sense
      }
    },
  },

  created () {
    this.hydrateToken()
    this.refresh()
      .then(() => {})
      .catch(e => { console.log(e) })
      .then(() => {
        setTimeout(() => {
          this.firstLoad = false
          this.getTokenomics()
          this.getPriceDiff()
        }, 0)
      })
  }
}
</script>

<style scoped lang="scss">
.carousel-override {
  &.v-carousel .v-window-item {
    display: flex;
    align-items: center;
  }
  .v-image {
    color: var(--v-text-base);
    height: 100%;
  }
}
.hoverlink {
  :hover {
    color: var(--v-primary-base)!important;
    &.v-icon {
      color: var(--v-primary-base)!important;
    }
  }
}
</style>