<template>
  <div class="d-flex px-4 py-4">
    <div v-for="presale of presales" :key="presale.presale_contract" class="d-flex flex align-start">
      <div class="outline flex" style="height: 2px;margin-top:30px;">

      </div>
      <presale-row :db_info="presale" :exchange="exchange"></presale-row>
      <div class="outline flex" style="height: 2px;margin-top:30px;">

      </div>
    </div>
  </div>
</template>

<script>
import PresaleRow from './timeline_row'
import axios from 'axios'

export default {

  props: {
    exchange: null,
    sorting: {
      type: String,
      default: 'uncl_participants'
    },
    hide_flagged: {
      type: Boolean,
      default: true
    },
  },

  components: {
    PresaleRow
  },

  data: () => ({
    presales: []
  }),

  watch: {
    sorting () {
      this.getPresales()
    },
    hide_flagged () {
      this.getPresales()
    }
  },

  methods: {
    async getPresales () {
      var page = 0
      var filters = {
        // sort: 'profit',
        sort: this.sorting === 'uncl_participants' ? 'uncl_participants' : 'start_block',
        sortAscending: false,
        search: '',
        hide_flagged: this.hide_flagged,
        show_hidden: false
      }
      var response = await axios.post(`${this.$settings.AMMS[this.exchange].server}/presales/search`, {filters: filters, page: page, stage: 0, rows_per_page: 10})
      var presales = response.data.rows
      presales.sort((a, b) => a.start_block - b.start_block)
      presales = presales.slice(0, this.$vuetify.breakpoint.xs ? 3 : 6)
      // presales = presales.filter(item => item.profit > 0)
      this.presales = presales
    },
  },

  created () {
    this.getPresales()
  }

}
</script>
