<template>
  <v-dialog v-model="dialog" persistent max-width="450" content-class="br-20">
    <v-card class="br-20">
      <v-row class="pa-4 ma-0 font-weight-bold align-center">
        <v-spacer></v-spacer>
        
        <v-btn large @click="close" icon color="textFaint">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <v-card-text class="px-8">
        <div class="text-center">

          <img 
          src="@/assets/img/green_leaf.svg" 
          height="100px"
          width="100px">
          <v-progress-linear
          indeterminate
          striped
          height="7"
          color="primary"
          style="width: 50%;"
          class="br-c mx-auto"
          >

          </v-progress-linear>
          <div class="mt-3 primary--text font-weight-medium">
            Confirm this transaction in your wallet
          </div>
          <div class="mt-6 textFaint--text font-weight-bold caption">
            Using a mobile wallet?
          </div>
          <div class="caption textFaint--text">
            Sometimes it helps to close the mobile app and restart it if the transaction is not shown in the wallet.
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  methods: {
    open () {
      this.dialog = true
    },
    close () {
      this.dialog = false
    }
  }
}
</script>