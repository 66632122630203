<template>
  <div>

    <div class="c-list pa-4" @click="expandInfo = !expandInfo">

      <div class="d-flex align-center">
        <!--
        <coin-icon :address="item.token0.address" :url="item.token0.icon_url" :exchange="cExchange" :size="22" style="z-index: 1;"></coin-icon>
        <coin-icon :address="item.token1.address" :url="item.token1.icon_url" :exchange="cExchange" :size="22" style="margin-left: -6px;" class="mr-2"></coin-icon>
        -->

        <div @click.stop="openTokenPage(token0.address)" class="title d-flex align-center">
          <coin-icon :address="token0.address" :url="token0.icon_url" :network="cNetwork" :size="32" style="border: 2px solid var(--v-foreground-base);z-index: 1;"></coin-icon>
          <span class="br-c" style="margin-left: -6px;height: 28px;">
            <coin-icon :address="token1.address" :url="token1.icon_url" :network="cNetwork"  :size="28"></coin-icon>
          </span>

          <span class="text-truncate hover-primary pl-2" :style="$vuetify.breakpoint.xs ? 'max-width: 8ch;' : 'max-width: 20ch;'">{{ token0Symbol }}</span>
          <!-- <v-icon x-small color="textFaint">mdi-chevron-up</v-icon> -->
        </div>
        <div @click.stop="openTokenPage(token1.address)" class="textFaint--text d-flex align-center">
          <span class="caption">&nbsp;/&nbsp;</span>
          <span class="text-truncate hover-primary" :style="$vuetify.breakpoint.xs ? 'max-width: 8ch;' : 'max-width: 20ch;'">{{ token1Symbol }}</span>
          <!-- <v-icon x-small color="textFaint">mdi-chevron-up</v-icon> -->
        </div>

        <v-spacer></v-spacer>

        <div v-if="item.locked_usd" class="">
          <div class="font-weight-bold d-flex align-center justify-end">
            <template v-if="item.fishy">
              ?
            </template>
            <span v-else :class="['font-weight-medium', {'text--text': dollarValueLive > 100000}]">
              <span v-if="loadReserve" class="textFaint--text">
                ...
              </span>
              <span v-else-if="dollarValueLive > 0" class="primary--text">
                ${{ dollarValueLiveHuman }}
              </span>
            </span>

            <!-- <v-progress-circular
            :value="dollarValueLive / 1000"
            :rotate="-90"
            size="26"
            width="1.5"
            class="ml-1"
            color="text"> -->
              <v-icon small :color="dollarValueLive > 100000 ? 'primary' : 'primary'" class="ml-1">mdi-lock</v-icon>
            <!-- </v-progress-circular> -->

            <!-- <v-icon small class="ml-1" :color="item.locked_usd > 100000 ? 'primary' : ''">mdi-lock</v-icon> -->
          </div>
        </div>
        <div v-else class="text-end textFaint--text">
          <div class="caption font-weight-medium">
            0% locked
            <v-icon small color="textFaint">mdi-alert-outline</v-icon>
          </div>
        </div>

      </div>

      <div class="d-flex align-center">
        <div class="caption textFaint--text font-weight-medium">
          Liquidity: 

          <span v-if="reserveUSDHuman !== 0" class="text--text">
            ${{ reserveUSDHuman }}
          </span>
          <span v-else class="text--text">
            ({{ $root.formatAmount(pairInverted ? reserves.reserve1 : reserves.reserve0, token0.decimals) }}
            <span class="textFaint--text">
              /
              {{ $root.formatAmount(pairInverted ? reserves.reserve0 : reserves.reserve1, token1.decimals) }})
            </span>
          </span>

          <!-- <span v-if="loadReserve">...</span>
          <span v-else-if="!loadReserveFailed || true" :class="[{'text--text': item.locked_usd && liveStats.reserveUSD > 100000}, {'pink--text': !item.locked_usd && liveStats.reserveUSD > 100000}]">${{ reserveUSDHuman }}</span>
          <span v-else>?</span> -->
        </div>

        <v-btn v-if="cExchange === 'Pancakeswap V1' && $store.state.user.username" small color="primary" text rounded @click.stop="$root.$dialog.arbitrage.open(item.token0.address, item.token1.address, cNetwork)">ARB</v-btn>

        <v-spacer></v-spacer>

        <div v-if="item.locked_usd && dateFromNow" class="caption textFaint--text text-end font-weight-medium">
          <span v-if="percentLocked > 0" class="textFaint--text caption">
            {{ percentLocked }}% -
          </span>
          next {{ dateFromNow }} 
        </div>
        <div v-else class="caption textFaint--text font-weight-medium">
          <!-- on UNCX -->
        </div>
        <v-icon small color="textFaint" :class="[{'rotate-180': expandInfo}]">mdi-chevron-down</v-icon>
      </div>
    </div>

    <v-expand-transition>
      <div v-if="expandInfo" class="midground">

        <div class="d-flex align-center pt-2">

          <div class="flex" style="height: 2px;">
          </div>
          <coin-icon :address="item.token0.address" :url="item.token0.icon_url" :network="cNetwork" :size="46"></coin-icon>

          <div class="outline flex" style="height: 2px;">
          </div>

          <v-btn v-if="!refreshReservesLoading" @click.stop="getReserves" icon size="40" color="textFaint">
            <v-icon size="40">mdi-water-outline</v-icon>
          </v-btn>
          <div v-else style="height: 36px; width: 36px;" class="text-center">
            <v-progress-circular
            indeterminate
            size="30"
            width="2"
            color="primary">
            </v-progress-circular>
          </div>

          <div class="outline flex" style="height: 2px;">
          </div>

          <coin-icon :address="item.token1.address" :url="item.token1.icon_url" :network="cNetwork" :size="46"></coin-icon>
          <div class="flex" style="height: 2px;">
          </div>
        </div>

        <div class="d-flex align-center font-weight-bold">

          <div class="flex text-center" style="flex: 110;">
            <div class="caption textFaint--text pt-1">
              {{ item.token0.symbol }}
            </div>
            {{ $root.formatAmount(reserves.reserve0, item.token0.decimals) }}
          </div>
          <div>
            <v-btn small rounded outlined color="primary" @click="$root.ammLinkTwo(`/pair/${item.address}`, cExchange)">
              VIEW LOCK
            </v-btn>
          </div>
          <div class="flex text-center" style="flex: 110;">
            <div class="caption textFaint--text pt-1">
              {{ item.token1.symbol }}
            </div>
            {{ $root.formatAmount(reserves.reserve1, item.token1.decimals) }}
          </div>
        </div>

        <div style="height: 400px;">
          <candle-chart :address="item.address" :network="cNetwork"></candle-chart>
        </div>

        <!-- <iframe v-if="$settings.CHAINS[this.cNetwork].dexscreenerChainName" width="100%" height="400px" :src="`https://dexscreener.com/${$settings.CHAINS[this.cNetwork].dexscreenerChainName}/${item.address}?embed=1&trades=0&info=0`" title="Dexscreener" style="border:none;"></iframe> -->

        <div class="mt-4 font-weight-bold px-3">
          Pair
        </div>

        <div class="d-flex align-center px-3 border-t">
          <div class="textFaint--text">
            Links
          </div>
          <v-spacer></v-spacer>
          <v-btn color="textFaint" rounded text :href="`${$settings.AMMS[this.cExchange].ammPairLink}${item.address}`" target="_blank">
            <img 
            :src="$settings.AMMS[this.cExchange].icon" 
            height="20px"
            width="20px"
            class="mr-2 br-20">
            <v-icon x-small>mdi-arrow-top-right</v-icon>
          </v-btn>
          <v-btn v-if="dextLink" text color="textFaint" rounded :href="dextLink" target="_blank">
            <img 
            src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xfB7B4564402E5500dB5bB6d63Ae671302777C75a/logo.png" 
            height="20px"
            width="20px"
            class="mr-1">
            <v-icon x-small>mdi-arrow-top-right</v-icon>
          </v-btn>
        </div>
        <div class="d-flex align-center px-3 border-t">
          <div class="textFaint--text">
            Contract
          </div>
          <v-spacer></v-spacer>
          <copy-address :address="item.address" color="textFaint"></copy-address>
        </div>
        <div class="d-flex align-center px-3 border-t textFaint--text">
          <div class="textFaint--text">
            {{ cExchange }} index
          </div>
          <v-spacer></v-spacer>
          {{ item.uniswap_index }}
        </div>

        <div class="d-flex align-center px-3 border-t">
          <div class="text--text font-weight-bold">
            {{ item.token0.symbol }}
          </div>
          <v-spacer></v-spacer>
          <chart-strip-button :network="cNetwork" :exchange="cExchange" :token_address="item.token0.address"></chart-strip-button>
        </div>
        <div class="d-flex align-center px-3 border-t">
          <div class="text--text font-weight-bold">
            {{ item.token1.symbol }}
          </div>
          <v-spacer></v-spacer>
          <chart-strip-button :network="cNetwork" :exchange="cExchange" :token_address="item.token1.address"></chart-strip-button>
        </div>

      </div>
    </v-expand-transition>

  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { ethers } from 'ethers'
import ERC20 from '@/smart-contracts/erc20'
import SETTINGS from '@/store/settings'
import V2PAIR from '@/smart-contracts/uniswap/v2-pair'
import ChartStripButton from '@/components/linkers/chart-button-strip'

export default {
  props: {
    item: {},
    index: {
      type: Number,
      default: null
    },
    exchange: null
  },

  components: {
    ChartStripButton
  },

  data: () => ({
    liveStats: {
      reserveUSD: '0',
      totalSupply: '0'
    },
    totalSupply: '0',
    loadReserve: true,
    loadReserveFailed: false,
    refreshReservesLoading: false,
    reserves: {
      reserve0: '0',
      reserve1: '0'
    },
    expandInfo: false
  }),

  computed: {
    dextLink () {
      if (this.cExchange === 'Uniswap V2') {
        return `https://www.dextools.io/app/uniswap/pair-explorer/${this.item.address}`
      } else if (this.cExchange === 'Pancakeswap V2' || this.cExchange === 'Pancakeswap V1') {
        return `https://www.dextools.io/app/pancakeswap/pair-explorer/${this.item.address}`
      }
      return null
    },
    cNetwork () {
      return this.$settings.AMMS[this.cExchange].chain
    },
    cExchange () {
      return this.exchange || this.$store.state.exchange
    },
    // swap token0 and token1
    pairInverted () {
      if (this.item.token0.address === this.$settings.CHAINS[this.cNetwork].wrappedGasToken.address) {
        return true
      }
      return false
    },
    token0 () {
      return this.pairInverted ? this.item.token1 : this.item.token0
    },
    token1 () {
      return this.pairInverted ? this.item.token0 : this.item.token1
    },
    token0Symbol () {
      if (this.token0.address === this.$settings.CHAINS[this.cNetwork].wrappedGasToken.address) {
        return this.$settings.CHAINS[this.cNetwork].gasToken.symbol
      }
      return this.token0.symbol
    },
    token1Symbol () {
      if (this.token1.address === this.$settings.CHAINS[this.cNetwork].wrappedGasToken.address) {
        return this.$settings.CHAINS[this.cNetwork].gasToken.symbol
      }
      return this.token1.symbol
    },
    percentLocked () {
      if (this.totalSupply === '0') {
        return '0'
      }
      var percent = ethers.BigNumber.from(this.item.locked_univ2).mul(1000).div(this.totalSupply)
      return percent.toNumber() / 10
    },
    dollarValueLive () {
      if (this.loadReserveFailed) {
        return this.item.locked_usd
      }
      if (this.totalSupply === '0') {
        return '0'
      }
      var percent = ethers.BigNumber.from(this.item.locked_univ2).mul(10000000000).div(this.totalSupply)
      var amount = this.liveStats.reserveUSD * percent / 10000000000
      amount = parseInt(amount)
      return amount
    },
    dollarValueLiveHuman () {
      var amount = this.dollarValueLive
      if (amount >= 1000000) {
        var deci = Number(amount / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return deci + 'M'
      } else if (amount > 1000) {
        return Math.floor(amount / 1000) + 'k'
      }
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    // this is from the database, does not rely on uniswap api
    dollarValueDatabase () {
      var amount = parseInt(this.item.locked_usd)
      // amount = '1230000' // for testing
      if (amount >= 1000000) {
        var deci = Number(amount / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return deci + 'M'
      } else if (amount > 1000) {
        return Math.floor(amount / 1000) + 'k'
      }
      return amount
      // return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    dateFromNow () {
      if (moment.unix(this.item.next_unlock).isValid() && this.item.next_unlock !== '0') {
        return moment.unix(this.item.next_unlock).fromNow()
      }
      return null
    },
    reserveUSDHuman () {
      var amount = parseInt(this.liveStats.reserveUSD)
      // amount = '1230000000' // for testing
      if (amount >= 1000000000) {
        var bil = Number(amount / 1000000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return bil + 'B'
      } else if (amount >= 1000000) {
        var mil = Number(amount / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return mil + 'M'
      } else if (amount > 1000) {
        return Math.floor(amount / 1000) + 'k'
      }
      return amount
      // return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    reserve0Human () {
      var amount = ethers.utils.formatUnits(this.reserves.reserve0, this.item.token0.decimals)
      amount = Number(amount)
      if (amount >= 1000000000000) {
        var tril = Number(amount / 1000000000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return tril + ' T'
      } else if (amount >= 1000000000) {
        var bil = Number(amount / 1000000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return bil + ' B'
      } else if (amount >= 1000000) {
        var mil = Number(amount / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return mil + ' M'
      } else if (amount > 1000) {
        return Math.floor(amount / 1000) + 'k'
      }
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
    reserve1Human () {
      var amount = ethers.utils.formatUnits(this.reserves.reserve1, this.item.token1.decimals)
      amount = Number(amount)
      if (amount >= 1000000000) {
        var bil = Number(amount / 1000000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return bil + 'B'
      } else if (amount >= 1000000) {
        var mil = Number(amount / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        return mil + 'M'
      } else if (amount > 1000) {
        return Math.floor(amount / 1000) + 'k'
      }
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
    },
  },

  watch: {
    'item.address' (nv) {
      // this.getLiveStats()
    }
  },

  methods: {
    openTokenPage (address) {
      this.$root.tokenPage.open(address, this.cNetwork, this.cExchange)
    },
    async getBlockchainSupply () { // get total supply from the blockchain if the the graph api is down
      var token = await ERC20.getERC20(this.item.address, this.cNetwork)
      this.totalSupply = token.totalSupply
    },
    async getReserves () {
      this.refreshReservesLoading = true
      this.reserves = await V2PAIR.getReserves(this.item.address, this.cNetwork)
      this.refreshReservesLoading = false
    },
    async getLiveStats () {
      // this.loadReserve = true
      // this.loadReserveFailed = false
      var data = {
        query: `
        {
          pair(id: "${this.item.address.toLowerCase()}") {
            reserveUSD,
            totalSupply
          }
        }
        `
      }

      var graphExplorer = SETTINGS.AMMS[this.cExchange].graphExplorer

      var response = await axios.post(graphExplorer, data)
      if (response.data.data.pair !== null) {
        this.liveStats = response.data.data.pair
        this.totalSupply = ethers.utils.parseUnits(this.liveStats.totalSupply, 18).toString()
      } else {
        await this.getBlockchainSupply()
      }
    },
  },

  created () {
    this.getReserves()
    if (['Pancakeswap V1', 'Pancakeswap V2', 'Safemoonswap V1'].includes(this.cExchange)) {
      this.getBlockchainSupply()
      this.getLiveStats()
      this.loadReserveFailed = true
      this.loadReserve = false
    } else {
      this.getLiveStats()
        .catch(error => {
          console.log(error)
          this.loadReserveFailed = true
        })
        .then(() => {
          this.loadReserve = false
        })
    }
  }
}
</script>