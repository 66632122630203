<template>
  <v-container style="max-width: 1200px;" :class="[{'pa-0 mobile-page': $vuetify.breakpoint.xs}]">
    <farm-list></farm-list>
    <test-factory v-if="false"></test-factory>
  </v-container>
</template>

<script>
import TestFactory from './TestFactory'
import FarmList from './list-page/FarmList'

export default {

  components: {
    TestFactory,
    FarmList
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },

  created () {
  }
}
</script>