<template>
  <div class="pa-4 v-card midground br-8">
    <div>
      Transactions
    </div>
    <div class="pt-4">
      <div class="mt-4 textFaint--text font-weight-bold">
        Transactio hash
      </div>
      <div class="mt-2 py-2 px-4 br-8 inputcolor mb-4">
        <div class="d-flex align-center py-2">
          <c-input :value.sync="txn_hash" placeholder="0x...">
          </c-input>
        </div>
      </div>
      <div class="py-2 text-end">
        <v-btn color="lgrad-green" class="white--text" @click="fetchTxn">
          Fetch
        </v-btn>
      </div>

      <div>
        <tx-row v-for="item of items" :item="item" :key="item.tx_hash + item.chain_id" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TxRow from './row'

export default {
  components: {
    TxRow
  },

  data: () => ({
    txn_hash: '',
    items: []
  }),

  computed: {
  },

  watch: {
    txn_hash () {
      this.items = []
    }
  },

  methods: {
    async fetchTxn () {
      axios.get(
        `${this.$root.$servers.TXNS}/tx/view/${this.txn_hash}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.user.jwtToken}`,
          },
        }
      )
        .then(response => {
          this.items = response.data.rows
        })
    }
  },

  created () {
  }
}
</script>