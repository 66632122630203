<template>
  <div>
    <div class="text-center title pb-4">
      ORACLE settings
    </div>
    
    <div class="mt-4">
      Oracle address, this is the wallet which can push state changes to the chain
    </div>
    <div class="px-3 br-8 inputcolor mb-4">
      <div class="d-flex align-center py-2">
        <template v-if="!stakePoolAddressIsValid">
          <v-icon small color="textFaint" class="mr-1">mdi-alert-outline</v-icon>
        </template>
        <template v-else>
          <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
        </template>
        <c-input :value.sync="settings.oracle_address" placeholder="Oracle address..">
        </c-input>
      </div>
    </div>

    <div>
      <v-btn color="primary" @click="setOracle">
        Set oracle
      </v-btn>
    </div>

    <v-row dense class="ma-0">
      <v-col cols="6">
        <div class="mt-4">
          Source Stake pool
        </div>
        <div class="px-3 br-8 inputcolor mb-4">
          <div class="d-flex align-center py-2">
            <template v-if="!stakePoolAddressIsValid">
              <v-icon small color="textFaint" class="mr-1">mdi-alert-outline</v-icon>
            </template>
            <template v-else>
              <v-icon small color="primary" class="mr-1">mdi-check-circle-outline</v-icon>
            </template>
            <c-input :value.sync="source_stake_pool.address" placeholder="Stake pool address..">
            </c-input>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="mt-4">
          Target chain
        </div>
        <chain-select :value.sync="source_stake_pool.chain"></chain-select>
        <v-fade-transition appear mode="out-in">
          <v-progress-circular
          v-if="loadingPoolInfo"
          indeterminate
          size="36"
          width="2"
          color="textFaint">
            <img 
            src="@/assets/img/UNCX_fill.svg" 
            height="120px"
            class="greyscale"
            width="28px">
          </v-progress-circular>
          <v-btn v-else @click="fetchStakePoolInfo" icon color="textFaint">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-fade-transition>
      </v-col>
    </v-row>

    <v-expansion-panels multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Oracle Pool Info
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ pool_info }}
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Source Pool Info
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ source_pool.info }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="v-card mt-4 br-20">
      <div class="pa-4 d-flex align-center">
        Source pool
        <v-spacer></v-spacer>
        <v-fade-transition appear mode="out-in">
          <v-progress-circular
          v-if="loadingStakersSourcePool"
          indeterminate
          size="36"
          width="2"
          color="textFaint">
            <img 
            src="@/assets/img/UNCX_fill.svg" 
            height="120px"
            class="greyscale"
            width="28px">
          </v-progress-circular>
          <v-btn v-else @click="fetchSourcePoolStakers" icon color="textFaint">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-fade-transition>
      </div>
      <table class="c-table" style="width: 100%">
        <tr>
          <th>
            user
          </th>
          <th>
            share_weight
          </th>
        </tr>
        <tr v-for="(staker, user_address) of source_pool.stakers" :key="staker.user_address" class="caption">
          <td>
            {{ $root.condenseAddress(user_address) }}
          </td>
          <td>
            {{ staker.share_weight }}
          </td>
          <td>
            <v-menu
            offset-x
            open-on-hover
            content-class="br-8 foreground pa-4 caption"
            max-width="400px"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  icon
                  v-bind="attrs"
                  color="textFaint"
                  v-on="on"
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>

              <div>
                {{ staker }}
              </div>
            </v-menu>
          </td>
        </tr>
      </table>
    </div>

    <div class="v-card mt-4 br-20">
      <div class="pa-4 d-flex align-center">
        Oracle pool
        <v-spacer></v-spacer>
        <v-fade-transition appear mode="out-in">
          <v-progress-circular
          v-if="loadingStakersOraclePool"
          indeterminate
          size="36"
          width="2"
          color="textFaint">
            <img 
            src="@/assets/img/UNCX_fill.svg" 
            height="120px"
            class="greyscale"
            width="28px">
          </v-progress-circular>
          <v-btn v-else @click="fetchOraclePoolStakers" icon color="textFaint">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-fade-transition>
      </div>
      <table class="c-table" style="width: 100%">
        <tr>
          <th>
            user
          </th>
          <th>
            share_weight
          </th>
        </tr>
        <tr v-for="(staker, user_address) of oracle_pool.stakers" :key="staker.user_address" class="caption">
          <td>
            {{ $root.condenseAddress(user_address) }}
          </td>
          <td>
            {{ staker.share_weight }}
          </td>
          <td>
            <v-menu
            offset-x
            open-on-hover
            content-class="br-8 foreground pa-4 caption"
            max-width="400px"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  icon
                  v-bind="attrs"
                  color="textFaint"
                  v-on="on"
                >
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>

              <div>
                {{ staker }}
              </div>
            </v-menu>
          </td>
        </tr>
      </table>

    </div>

    <v-card class="mt-2 br-20">
      <div class="pa-2 d-flex align-center">
        <v-btn @click="compileDiffList" rounded color="navcolor" class="white--text">
          Compile diff list
        </v-btn>
        <v-spacer></v-spacer>
        <span>
          {{ diff_list.length }} changes
        </span>
        <v-btn @click="sliceDiffList" rounded color="navcolor" class="white--text ml-2" small>
          Slice
        </v-btn>
        <v-btn @click="oracleUpdateUsers" rounded color="navcolor" class="white--text ml-2" small>
          Push
        </v-btn>
      </div>
      <table class="c-table" style="width: 100%">
        <tr>
          <th>
            user
          </th>
          <th>
            share_weight
          </th>
          <th>
            time
          </th>
          <th>
            uncl
          </th>
        </tr>
        <tr v-for="row of diff_list" :key="row[0]" :class="[{'red-overlay': row[1] === 0}]">
          <td>
            {{ $root.condenseAddress(row[0]) }}
          </td>
          <td>
            {{ row[1] }}
          </td>
          <td>
            {{ row[2] }}
          </td>
          <td>
            {{ row[3] }}
          </td>
        </tr>
      </table>
    </v-card>

  </div>
</template>

<script>
import { ethers } from 'ethers'
import ChainSelect from '@/components/ui/chain-select'
import StakePoolContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool'
import OraclePoolContract from '@/smart-contracts/farms-v2/oracle/stake-pool/v2/stake-pool'
import StakePoolPaginatorContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool-paginator'
import OraclePoolPaginatorContract from '@/smart-contracts/farms-v2/oracle/stake-pool/v2/stake-pool-paginator'
import TxWrapper from '@/web3/tx-wrapper-2';

export default {

  props: {
    address: null,
    pool_info: {
      type: Object,
      default: () => {
        return {
          share_weight_total: '0',
          min_stake_amount: '0',
          max_stake_amount: '0',
        }
      }
    },
  },

  components: {
    ChainSelect
  },

  data: () => ({
    source_stake_pool: {
      address: '0x655264AD30ccFFE69c2b76A9045575f9D333EE6b',
      chain: 'Kovan'
    },
    source_pool: {
      info: {},
      num_stakers: 0,
      stakers: []
    },
    oracle_pool: {
      num_stakers: 0,
      stakers: []
    },

    settings: {
      oracle_address: ''
    },

    diff_list: [],

    loadingPoolInfo: false,
    loadingStakersSourcePool: false,
    loadingStakersOraclePool: false,
  }),

  watch: {
    'source_stake_pool.address' () {
      this.fetchStakePoolInfo()
    },
    'source_stake_pool.chain' () {
      this.fetchStakePoolInfo()
    }
  },

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    stakePoolAddressIsValid () {
      try {
        ethers.utils.getAddress(this.source_stake_pool.address)
        return true
      } catch (e) {
        return false
      }
    }
  },

  methods: {
    oracleUpdateUsers () {
      // var sliced = this.diff_list.slice(0, 10)
      // var sliced = [['0x41a79b68aF29860F1BF59cB7AECe9c0ceDAF1c19', 0, 0, 0]]
      var sliced = this.diff_list
      TxWrapper.doTransaction(OraclePoolContract.oracleUpdateUsers,
        [this.address, sliced], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(response => {
          this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // end
        })
    },
    async refresh () {

    },
    setOracle () {
      TxWrapper.doTransaction(OraclePoolContract.setOracle,
        [this.address, this.settings.oracle_address], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(response => {
          // this.$emit('hard-refresh')
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.data ? e.data.message : e.message) })
        .then(() => {
          // end
        })
    },
    sliceDiffList () {
      this.diff_list = this.diff_list.slice(0, 20)
    },
    async fetchStakePoolInfo () {
      this.loadingPoolInfo = true
      this.source_pool.info = {}
      this.source_pool.num_stakers = 0
      this.source_pool.stakers = []
      try {
        this.source_pool.info = await StakePoolContract.getPoolInfo(this.source_stake_pool.address, this.source_stake_pool.chain)
      } catch (e) {}
      this.loadingPoolInfo = false
    },
    async fetchSourcePoolStakers () {
      this.loadingStakersSourcePool = true
      try {
        var num_stakers = await StakePoolPaginatorContract.getNumStakers(this.source_stake_pool.address, this.source_stake_pool.chain)
        var stakers = await StakePoolPaginatorContract.getStakers(this.source_stake_pool.address, 0, num_stakers, this.source_stake_pool.chain)
      } catch (e) {}
      var stakerMap = {}
      for (var staker of stakers) {
        stakerMap[staker.user_address] = staker
      }
      this.source_pool.num_stakers = num_stakers
      this.source_pool.stakers = stakerMap
      this.loadingStakersSourcePool = false
    },
    async fetchOraclePoolStakers () {
      this.loadingStakersOraclePool = true
      try {
        var num_stakers = await OraclePoolPaginatorContract.getNumStakers(this.address, this.$store.state.requiredNetwork)
        var stakers = await OraclePoolPaginatorContract.getStakers(this.address, 0, num_stakers, this.$store.state.requiredNetwork)
      } catch (e) {}
      var stakerMap = {}
      for (var staker of stakers) {
        stakerMap[staker.user_address] = staker
      }
      this.oracle_pool.num_stakers = num_stakers
      this.oracle_pool.stakers = stakerMap
      this.loadingStakersOraclePool = false
    },
    async compileDiffList () {
      var diffMap = {}
      var removeList = []
      // scan for removes
      for (var staker1 of Object.values(this.oracle_pool.stakers)) {
        if (!Object.hasOwnProperty.call(this.source_pool.stakers, staker1.user_address)) {
          removeList.push(staker1.user_address)
        }
      }
      // iterate over values
      for (var staker2 of Object.values(this.source_pool.stakers)) {
        if (removeList.includes(staker2.user_address)) {
          continue
        }

        // TODO check if share_weights are equal
        if (staker2.share_weight !== (this.oracle_pool.stakers[staker2.user_address] || {}).share_weight) {
          diffMap[staker2.user_address] = [
            staker2.user_address, staker2.share_weight, staker2.time_boost_percentage, staker2.uncl_boost_percentage
          ]
        }
      }
      for (var user_address of removeList) {
        diffMap[user_address] = [
          user_address, 0, 0, 0
        ]
      }
      this.diff_list = Object.values(diffMap)
      // this.source_pool.stakers
    }
  },

  created () {
    this.settings.oracle_address = this.pool_info.oracle_address
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
.red-overlay {
  position: relative;
}
.red-overlay::after {
  content: "";
  position: absolute;
  background: red;
  opacity: 0.1;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}
</style>