<template>
  <v-card :class="['br-20 mb-16', {'pa-4': $vuetify.breakpoint.xs}, {'pa-6': !$vuetify.breakpoint.xs}]">
    
    <div class="d-flex align-center">
      <v-btn icon :to="`${$store.state.ammPrefix}/pairs`" color="textFaint">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn :to="`${$store.state.ammPrefix}/locker?address=${address}`" rounded outlined color="white" class="lgrad-blue">
        <v-icon small class="mr-2">mdi-lock</v-icon>
        Lock Liquidity
      </v-btn>

    </div>

    <trade-test v-if="false" :pair="pair.address">

    </trade-test>

    <div class="text-center caption textFaint--text mb-3">
      {{ $store.state.exchange }} pair: <copy-address :address="pair.address" color="textFaint"></copy-address>
    </div>

    <price-ratio-component 
    :token0="pair.token0"
    :token1="pair.token1"
    :reserve0="reserves.reserve0" 
    :reserve1="reserves.reserve1">
    </price-ratio-component>

    <div class="title text--text text-center mb-1">
      <div class="caption textFaint--text">
        Locked Liquidity
      </div>
      <div :class="['font-weight-bold', percentOfTotalLPSLocked < 10 ? 'text--text' : 'text--text']">
        {{ percentOfTotalLPSLocked }}%
      </div>
    </div>

    <div class="d-flex align-center">
      <v-progress-circular
      :value="percentOfTotalLPSLocked"
      :rotate="-90"
      size="60"
      width="3"
      color="text"
      background-color="background">
        <coin-icon :address="pair.token0.address" :url="pair.token0.icon_url" :size="46"></coin-icon>
      </v-progress-circular>

      <div class="outline flex" style="height: 2px;">

      </div>

      <v-progress-circular
      :value="percentOfTotalLPSLocked"
      :rotate="-90"
      size="75"
      width="3"
      :color="percentOfTotalLPSLocked < 10 ? 'text' : 'text'"
      background-color="background">
        <v-icon size="50" :color="percentOfTotalLPSLocked < 10 ? 'text' : 'text'">mdi-lock</v-icon>
      </v-progress-circular>

      <div class="outline flex" style="height: 2px;">

      </div>

      <v-progress-circular
      :value="percentOfTotalLPSLocked"
      :rotate="-90"
      size="60"
      width="3"
      color="text"
      background-color="background">
        <coin-icon :address="pair.token1.address" :url="pair.token1.icon_url" :size="46"></coin-icon>
      </v-progress-circular>
    </div>

    <div class="d-flex align-center">
      <div style="flex: 1 1 0;" class="">
        <v-btn text rounded @click.stop="$root.tokenPage.open(pair.token0.address)" class="title pa-0 pr-2" style="min-width: 10px;">
          <span class="text-truncate" :style="$vuetify.breakpoint.xs ? 'max-width: 11ch;' : 'max-width: 20ch;'">
            {{ pair.token0.symbol }}
          </span>
          <v-icon x-small color="textFaint">mdi-chevron-up</v-icon>
        </v-btn>
      </div>
      <div style="flex: 1 1 0;" class="text-end">
        <v-btn text rounded @click.stop="$root.tokenPage.open(pair.token1.address)" class="title pa-0 pl-2" style="min-width: 10px;">
          <v-icon x-small color="textFaint">mdi-chevron-up</v-icon>
          <span class="text-truncate" :style="$vuetify.breakpoint.xs ? 'max-width:11ch;' : 'max-width: 20ch;'">
            {{ pair.token1.symbol }}
          </span>
        </v-btn>
      </div>
    </div>

    <!-- LOCKED LIQUIDITY -->
    <div v-if="items.length > 0" class="d-flex align-center primary--text font-weight-medium">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs"
            v-on="on" style="flex: 1 1 0;" class="d-flex align-center cursor-help">
            <v-icon size="18" color="primary">mdi-lock-outline</v-icon>
            <coin-icon :address="pair.token0.address" :url="pair.token0.icon_url" :size="18" class="mr-1"></coin-icon>
            {{ $root.formatAmount(LP0Locked, pair.token0.decimals) }}
          </div>
        </template>
        <span>Tokens represented by LP lock, the 50%/50% ratio between assets can vary over time</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs"
            v-on="on" style="flex: 1 1 0;" class="justify-end d-flex align-center cursor-help">
            {{ $root.formatAmount(LP1Locked, pair.token1.decimals) }}
            <coin-icon :address="pair.token1.address" :url="pair.token1.icon_url" :size="18" class="ml-1"></coin-icon>
            <v-icon size="18" color="primary">mdi-lock-outline</v-icon>
          </div>
        </template>
        <span>Tokens represented by LP lock, the 50%/50% ratio between assets can vary over time</span>
      </v-tooltip>
    </div>

    <!-- LIQUIDITY -->
    <div class="d-flex">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs"
            v-on="on" style="flex: 1 1 0;" class="d-flex align-center cursor-help">
            <v-icon size="18">mdi-water-outline</v-icon>
            <coin-icon :address="pair.token0.address" :url="pair.token0.icon_url" :size="18" class="mr-1"></coin-icon>
            {{ $root.formatAmount(reserves.reserve0, pair.token0.decimals) }}
            <span class="ml-1 font-weight-medium">({{ $root.getPercent(reserves.reserve0, pair.token0.total_supply) }}%)</span>
          </div>
        </template>
        <span>Total tokens in pool, percentage of total supply in pool</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs"
            v-on="on" style="flex: 1 1 0;" class="justify-end d-flex align-center cursor-help">
            <span class="mr-1 font-weight-medium">({{ $root.getPercent(reserves.reserve1, pair.token1.total_supply) }}%)</span>
            {{ $root.formatAmount(reserves.reserve1, pair.token1.decimals) }}
            <coin-icon :address="pair.token1.address" :url="pair.token1.icon_url" :size="18" class="ml-1"></coin-icon>
            <v-icon size="18">mdi-water-outline</v-icon>
          </div>
        </template>
        <span>Total tokens in pool, percentage of total supply in pool</span>
      </v-tooltip>
    </div>

    <div class="d-flex">
      <chart-strip-button :network="$store.state.requiredNetwork" :exchange="$store.state.exchange" :token_address="pair.token0.address"></chart-strip-button>
      <v-spacer></v-spacer>
      <chart-strip-button :network="$store.state.requiredNetwork" :exchange="$store.state.exchange" :token_address="pair.token1.address"></chart-strip-button>
    </div>

    <!-- TOTAL SUPPLY -->
    <div v-if="false" class="d-flex">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs"
            v-on="on" style="flex: 1 1 0;" class="d-flex align-center cursor-help">
            <v-icon size="18">mdi-google-circles-extended</v-icon>
            <coin-icon :address="pair.token0.address" :url="pair.token0.icon_url" :size="18" class="mr-1"></coin-icon>
            {{ $root.formatAmount(pair.token0.total_supply, pair.token0.decimals) }}
          </div>
        </template>
        <span>Total supply</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs"
            v-on="on" style="flex: 1 1 0;" class="justify-end d-flex align-center cursor-help">
            {{ $root.formatAmount(pair.token1.total_supply, pair.token1.decimals) }}
            <coin-icon :address="pair.token1.address" :url="pair.token1.icon_url" :size="18" class="ml-1"></coin-icon>
            <v-icon size="18">mdi-google-circles-extended</v-icon>
          </div>
        </template>
        <span>Total supply</span>
      </v-tooltip>
    </div>

    <!-- DEXT AND REFRESH -->
    <div class="mt-4 text-center border-t border-b">
      <span>
        <v-btn v-if="!refreshReservesLoading" @click.stop="getReserves" icon color="textFaint">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <span v-else>
          <v-progress-circular
          indeterminate
          size="20"
          width="2"
          class="mx-2"
          color="primary">
          </v-progress-circular>
        </span>
      </span>

      <v-btn text color="textFaint" rounded :href="`${$store.state.etherscan_url}/address/${pair.address}`" target="_blank">
        {{ $store.state.explorer_name }}
        <v-icon x-small>mdi-arrow-top-right</v-icon>
      </v-btn>
      <v-btn text color="textFaint" rounded :href="`${$store.state.exchangePairLink}${pair.address}`" target="_blank">
        {{ $store.state.exchange }}
        <v-icon x-small>mdi-arrow-top-right</v-icon>
      </v-btn>
      <v-btn v-if="dextLink" text color="textFaint" rounded :href="dextLink" target="_blank">
        <img 
        src="https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xfB7B4564402E5500dB5bB6d63Ae671302777C75a/logo.png" 
        height="18px"
        width="18px"
        class="mr-1">
        Dext
        <v-icon x-small>mdi-arrow-top-right</v-icon>
      </v-btn>
      <div v-if="false" class="">
        Uniswap index: {{ pair.uniswap_index }}
      </div>
    </div>

    <div :class="['']">

      <div v-if="loadingPOL1 && POL1Length > 1" class="text-center mt-4">
        <v-progress-circular
        :rotate="-90"
        :value="POL1Progress"
        size="60"
        color="primary">
          <v-icon>mdi-lock</v-icon>
        </v-progress-circular>
        <div>
          {{ POL1SyncIndex }} / {{ POL1Length }}
        </div>
      </div>

      <div v-if="loadingLockedLiquidity" class="text-center textFaint--text mt-4">
        <div>
          Loading...
        </div>
      </div>

      <template v-else>
        
        <!-- LOCKED -->
        <div>
          <div class="font-weight-medium px-4 pt-3 br-8">
            <div>
              <div class="text-center">
                <div>
                  <!-- DOLLAR VALUES -->
                  <div v-if="!uniswapAPIIsDown">
                    <span class="primary--text">
                      ${{ lockedValueHuman }}
                    </span>
                    <span class="textFaint--text ml-1">
                      / ${{ reserveValueHuman }}
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="d-flex mt-1 caption textFaint--text">
          Total LP tokens
          <v-spacer></v-spacer>
          {{ totalSupplyHuman }}
        </div>
        <div class="d-flex caption textFaint--text">
          Total locked LP
          <v-spacer></v-spacer>
          <span class="text--text">
            {{ totalLockedHuman }}
          </span>
        </div>

        <div v-if="uniswapAPIIsDown" class="caption">
          <div class="pink--text">
            {{ $store.state.exchange }} price API is down, dollar value not determinable
          </div>
        </div>

        <!-- NOT LOCKED -->
        <div v-if="items.length === 0" class="my-2 d-flex white--text align-center font-weight-medium lgrad-red pa-4 br-8">
          <div>
            <div class="d-flex align-center mb-2">
              <img 
              src="@/assets/img/flags/exclamation.svg" 
              height="26px"
              class="mr-2"
              width="26px">
              No Locked Liquidity on UNCX
            </div>
            <div class="caption font-weight-bold">
              There is no locked liquidity for this pair on the UNCX platform.
            </div>
          </div>
        </div>

        <template v-if="items.length > 0">
          <div class="mt-8">
            <div class="t-large">
              Liquidity Locks
            </div>

            <div class="textFaint--text caption mb-8">
              Please be aware only the univ2 tokens are locked. Not the actual dollar value. This changes as people trade. More liquidity tokens are also minted as people add liquidity to the pool.
            </div>
          </div>

          <div class="d-flex mt-4 textFaint--text font-italic">
            <div>
              Value
            </div>
            <v-spacer></v-spacer>
            <div class="mr-9">
              Unlock date
            </div>
          </div>

          <div>
            <unlock-row v-for="(item, index) of items" :item="item" :key="index" :reserveUSD="reserveUSD" :totalSupply="totalSupply"></unlock-row>
          </div>
        </template>

      </template>

      <v2-card v-if="false" :address="address"></v2-card>

    </div>

  </v-card>
</template>

<script>
import TradeTest from '@/views/pair/TradeTest'
import _ from 'lodash'
import POL1Contract from '@/smart-contracts/pol1/locker-contract'
import POL2Contract from '@/smart-contracts/lock-uni-v2/univ2-contract'
import ERC20 from '@/smart-contracts/erc20'
import UnlockRow from './unlock-row'
import { ethers } from 'ethers'
import V2Card from './v2-card'
import moment from 'moment'
import axios from 'axios'
import V2PAIR from '@/smart-contracts/uniswap/v2-pair'
import PriceRatioComponent from './price-ratio-component.vue'
import ChartStripButton from '@/components/linkers/chart-button-strip'

export default {
  components: {
    UnlockRow,
    V2Card,
    TradeTest,
    PriceRatioComponent,
    ChartStripButton
  },

  props: {
    address: {
      type: String
    }
  },

  data: () => ({
    pair: {
      token0: {
        total_supply: '0',
        decimals: '0',
      },
      token1: {
        total_supply: '0',
        decimals: '0',
      }
    },
    totalLockedTokens: '0', // with unlock epoch above now
    items: [],
    totalSupply: '0',
    reserveUSD: '0',
    loadingLockedLiquidity: true,
    loadingPOL1: true,
    POL1Length: 0,
    POL1SyncIndex: 0,
    uniswapAPIIsDown: false,

    refreshReservesLoading: false,
    reserves: {
      reserve0: '0',
      reserve1: '0'
    },
  }),

  computed: {
    dextLink () {
      if (this.$store.state.exchange === 'Uniswap V2') {
        return `https://www.dextools.io/app/uniswap/pair-explorer/${this.pair.address}`
      } else if (this.$store.state.exchange === 'Pancakeswap V2' || this.$store.state.exchange === 'Pancakeswap V1') {
        return `https://www.dextools.io/app/pancakeswap/pair-explorer/${this.pair.address}`
      }
      return null
    },
    sEthers () {
      return this.$store.state.ethers
    },
    totalSupplyHuman () {
      var amount = ethers.utils.formatUnits(this.totalSupply, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    totalLockedHuman () {
      var amount = ethers.utils.formatUnits(this.totalLockedTokens, 18)
      return Number(amount).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 4})
    },
    percentOfTotalLPSLocked () {
      if (this.totalSupply === '0') {
        return '0'
      }
      var percent = ethers.BigNumber.from(this.totalLockedTokens).mul(1000).div(this.totalSupply)
      return percent.toNumber() / 10
    },
    LP0Locked () {
      if (this.totalSupply === '0') {
        return '0'
      }
      var locked = ethers.BigNumber.from(this.totalLockedTokens).mul(this.reserves.reserve0).div(this.totalSupply)
      return locked
    },
    LP1Locked () {
      if (this.totalSupply === '0') {
        return '0'
      }
      var locked = ethers.BigNumber.from(this.totalLockedTokens).mul(this.reserves.reserve1).div(this.totalSupply)
      return locked
    },
    reserveValueHuman () {
      var value = this.reserveUSD
      return Number(value).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
    },
    lockedValueHuman () {
      if (this.totalSupply === '0') {
        return '0'
      }
      var percent = ethers.BigNumber.from(this.totalLockedTokens).mul(10000000000).div(this.totalSupply)
      var value = this.reserveUSD * percent / 10000000000
      return Number(value).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
    },
    POL1Progress () {
      if (this.POL1Length === 0) {
        return 0
      }
      var progress = (this.POL1SyncIndex / this.POL1Length) * 100
      return progress
    },
    token0Symbol () {
      if (this.pair.token0.address === '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2') {
        return 'ETH'
      }
      return this.pair.token0.symbol
    },
    token1Symbol () {
      if (this.pair.token1.address === '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2') {
        return 'ETH'
      }
      return this.pair.token1.symbol
    },
  },

  methods: {
    loadAPI: _.debounce(function () {
      return new Promise((resolve, reject) => {
        this.$axios.get(`/uniswap/pair/${this.address}?network=${this.$store.state.ethers.network}`)
          .then(response => {
            this.pair = response.data
          })
          .then(() => resolve())
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    }, 500, {leading: true}),

    async loadLocks () {
      if (this.$store.state.exchange === 'Uniswap V2') {
        await this.loadLocksUniswap()
      } else {
        await this.loadLocksOnlyPOL2()
      }
    },

    async loadLocksOnlyPOL2 () {
      this.loadingPOL1 = true
      // var totalLockedTokens = await POL1Contract.getLockedLiquidity(this.address)

      var len2 = await POL2Contract.getNumLocksForToken(this.address)
      len2 = Number(len2)
      this.POL1Length = len2

      var arr = []
      this.POL1SyncIndex = 0
      for (var i2 = 0; i2 < len2; i2++) {
        this.POL1SyncIndex++
        var struct2 = await POL2Contract.getLockForTokenAtIndex(this.address, i2)
        if (struct2.unlock_date < moment().unix()) {
          continue // only show new locks, comment this to show all
        }
        struct2.v2 = true
        arr.push(struct2)
      }

      arr = arr.sort((a, b) => a.epoch - b.epoch)
      this.items = arr
      this.totalLockedTokens = arr.reduce((a, b) => {
        return ethers.BigNumber.from(a).add(b.amount)
      }, '0')

      this.loadingPOL1 = false

      this.getReserveUSD()
    },

    async loadLocksUniswap () {
      this.loadingPOL1 = true
      // var totalLockedTokens = await POL1Contract.getLockedLiquidity(this.address)

      var len1 = await POL1Contract.getTokenReleaseLength(this.address)
      var len2 = await POL2Contract.getNumLocksForToken(this.address)
      len1 = Number(len1)
      len2 = Number(len2)
      this.POL1Length = len1 + len2

      var arr = []
      this.POL1SyncIndex = 0
      for (var i = 0; i < len1; i++) {
        var struct = await POL1Contract.getTokenReleaseAtIndex(this.address, i)
        this.POL1SyncIndex++
        // console.log(struct)
        if (struct.epoch < moment().unix()) {
          continue // only show new locks, comment this to show all
        }
        arr.push({
          unlock_date: struct.epoch,
          amount: struct.amount
        })
      }

      for (var i2 = 0; i2 < len2; i2++) {
        this.POL1SyncIndex++
        var struct2 = await POL2Contract.getLockForTokenAtIndex(this.address, i2)
        console.log(struct2)
        if (struct2.unlock_date < moment().unix()) {
          continue // only show new locks, comment this to show all
        }
        struct2.v2 = true
        arr.push(struct2)
      }

      arr = arr.sort((a, b) => a.epoch - b.epoch)
      this.items = arr
      this.totalLockedTokens = arr.reduce((a, b) => {
        return ethers.BigNumber.from(a).add(b.amount)
      }, '0')

      this.loadingPOL1 = false

      this.getReserveUSD()
    },

    async getUniswapTotalSupply () {
      var token = await ERC20.getERC20(this.address)
      this.totalSupply = token.totalSupply
    },

    getReserveUSD () {
      var data = {
        query: `
        {
          pair(id: "${this.address.toLowerCase()}") {
            reserveUSD
          }
        }
        `
      }
      
      axios.post(this.$store.state.graphExplorer, data)
        .then(response => {
          this.reserveUSD = response.data.data.pair.reserveUSD
        })
        .catch(error => {
          console.log(error)
          this.uniswapAPIIsDown = true
        })
    },

    async getReserves () {
      this.refreshReservesLoading = true
      this.reserves = await V2PAIR.getReserves(this.address)
      this.refreshReservesLoading = false
    },

    async doFirstLoad () {
      await this.getUniswapTotalSupply() // this needs to be called before loadLocks
      this.loadLocks()
        .then(() => {})
        .catch(e => {
          console.log(e)
        })
        .then(() => {
          this.loadingLockedLiquidity = false
        })
    }
  },

  created () {
    this.loadAPI()
    this.getReserves()
    this.doFirstLoad()
  }
}
</script>