<template>
  <v-container style="max-width: 600px;">

    <v-card class="mb-2 br-20">

      <div v-if="false" class="mt-5 mb-5 d-flex align-center white pa-3 r-outlined ml-2 br-20 mr-2 background">
        <c-input :value.sync="apiKey" placeholder="Key">
        </c-input>
      </div>

      <v-btn x-large text :color="tab === 0 ? 'light-blue' : ''" @click="tab = 0">
        CHAV
      </v-btn>
      <v-btn x-large text :color="tab === 1 ? 'light-blue' : ''" @click="tab = 1">
        MARK
      </v-btn>
    </v-card>

    <template v-if="tab === 0">
      <latest-news></latest-news>
      <promoted-content class="mt-2"></promoted-content>
    </template>

    <template v-if="tab === 1">
      <cron-card></cron-card>
      <set-pair-fishy></set-pair-fishy>
      <set-token-fishy class="mt-3"></set-token-fishy>
      <liquidity-fees-card class="mt-3"></liquidity-fees-card>
    </template>

  </v-container>
</template>

<script>
import CronCard from './cron'
import SetPairFishy from './set-pair-fishy'
import SetTokenFishy from './set-token-fishy'
import LiquidityFeesCard from './liquidity-fees'
import LatestNews from './chav/latest-news'
import PromotedContent from './chav/promoted-content'
// import WithdrawPage from './withdraw/WithdrawPage'

export default {
  name: 'CronTab',
  components: {
    CronCard,
    SetPairFishy,
    SetTokenFishy,
    LiquidityFeesCard,
    LatestNews,
    PromotedContent
    // WithdrawPage
  },

  data: () => ({
    apiKey: '',
    tab: 0
  }),

  methods: {
  }
}
</script>