import { ethers } from "ethers"
import { store } from '@/store/index';
import Endpoints from './endpoints'

const Self = {
  'Mainnet': new ethers.providers.JsonRpcProvider(Endpoints.Mainnet),
  'Goerli': new ethers.providers.JsonRpcProvider(Endpoints.Goerli),
  'Sepolia': new ethers.providers.JsonRpcProvider(Endpoints.Sepolia),
  'BSC_MAINNET': new ethers.providers.JsonRpcProvider(Endpoints.BSC_MAINNET),
  'BSC_TESTNET': new ethers.providers.JsonRpcProvider(Endpoints.BSC_TESTNET),
  'xDai': new ethers.providers.JsonRpcProvider(Endpoints.xDai),
  'Matic': new ethers.providers.JsonRpcProvider(Endpoints.Matic),
  'AVAX': new ethers.providers.JsonRpcProvider(Endpoints.AVAX),
  'DogeChain': new ethers.providers.JsonRpcProvider(Endpoints.DogeChain),
  'ArbitrumOne': new ethers.providers.JsonRpcProvider(Endpoints.ArbitrumOne),
  'Base': new ethers.providers.JsonRpcProvider(Endpoints.Base),
  // 'Ganache': Handled at the bottom of this script

  intervals: {
    'Mainnet': null,
    'Goerli': null,
    'Sepolia': null,
    'BSC_MAINNET': null,
    'xDai': null,
    'Matic': null,
    'AVAX': null,
    'DogeChain': null,
    'ArbitrumOne': null,
    'Base': null,
  },
  init() {
    Self.Mainnet.on('block', (blockNumber) => {
      store.commit('setBlockNum', {
        blockNumber: blockNumber,
        network: 'Mainnet'
      })
    })

    Self.intervals.Goerli = setInterval(() => {
      Self.getBlockNumberByInterval('Goerli')
    }, 10000)

    Self.intervals.Sepolia = setInterval(() => {
      Self.getBlockNumberByInterval('Sepolia')
    }, 10000)

    Self.intervals.BSC_MAINNET = setInterval(() => {
      Self.getBlockNumberByInterval('BSC_MAINNET')
    }, 10000)

    // Self.intervals.xDai = setInterval(() => {
    //   Self.getBlockNumberByInterval('xDai')
    // }, 10000)

    Self.intervals.Matic = setInterval(() => {
      Self.getBlockNumberByInterval('Matic')
    }, 10000)

    Self.intervals.AVAX = setInterval(() => {
      Self.getBlockNumberByInterval('AVAX')
    }, 10000)

    // Self.intervals.DogeChain = setInterval(() => {
    //   Self.getBlockNumberByInterval('DogeChain')
    // }, 10000)

    Self.intervals.ArbitrumOne = setInterval(() => {
      Self.getBlockNumberByInterval('ArbitrumOne')
    }, 10000)

    if (store.state.allowGanache) {
      Self.Ganache.on('block', (blockNumber) => {
        store.commit('setBlockNum', {
          blockNumber: blockNumber,
          network: 'Ganache'
        })
      })
    }

  },

  async getBlockNumberByInterval(_network) {
    var blockNumber = await Self[_network].getBlockNumber()
    store.commit('setBlockNum', {
      blockNumber: blockNumber,
      network: _network
    })
  }
}

if (store.state.allowGanache) {
  Self.Ganache = new ethers.providers.JsonRpcProvider("http://127.0.0.1:8545")
}

export default Self