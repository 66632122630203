<template>
  <div>

    <div class="textFaint--text d-flex align-center pa-2">
      {{ num_stakers }} active staker{{ num_stakers === '1' ? '' : 's' }}
      <v-spacer></v-spacer>
      <v-btn @click="refresh" text rounded color="textFaint">
        <span class="font-weight-medium mr-1">Refresh</span>
        <v-icon small>mdi-refresh</v-icon>
      </v-btn>
    </div>
          
    <v-scroll-y-transition mode="out-in">
      <div v-if="loading" class="text-center pa-8">
        <v-progress-circular
        indeterminate
        size="120"
        width="2"
        color="#aaa">
          <img 
          src="@/assets/img/UNCX_fill.svg" 
          height="110px"
          class="greyscale"
          width="110px">
        </v-progress-circular>
      </div>
      <div v-else>
        
        <template v-if="num_stakers > 0">

          <div style="overflow: auto;">
            <table class="c-table" style="width: 100%">
              <tr v-if="!$vuetify.breakpoint.xs" class="caption textFaint--text">
                <th>
                  User
                </th>
                <th>
                  {{ tokenHydrated.symbol }} staking
                </th>
                <th>
                  Time Boost
                </th>
                <th>
                  {{ uncl_token_hydrated.symbol }} Boost
                </th>
                <th>
                  Stake Weight
                </th>
                <th v-if="false">
                  Subs
                </th>
                <th>
                  Withdrawl date
                </th>
              </tr>
              <tr v-else class="caption textFaint--text">
                <th>
                  <div>
                    User
                  </div>
                  <div class="caption">
                    Boost info
                  </div>
                </th>
                <th>
                  <div>
                    {{ tokenHydrated.symbol }} staking
                  </div>
                  <div class="caption">
                    Share weight
                  </div>
                </th>
              </tr>
              <staker-row v-for="staker of paginatedRows" :key="staker.user_address" :staker_info="staker" :tokenHydrated="tokenHydrated" :share_decimals="tokenHydrated.decimals" :pool_info="pool_info">
              </staker-row>

            </table>
            <!-- Pagination -->
            <div v-if="pageCount > 1" class="d-flex align-center justify-center background pa-2">
              <v-btn @click="goPrevious" text :disabled="pagination.page === 0">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              {{ pagination.page + 1 }} / {{ pageCount }}
              <v-btn @click="goNext" text :disabled="pagination.page >= pageCount - 1">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- Pagination -->
          </div>
        </template>
        <div v-else class="pa-8">
          No one is staking in this stake pool.
        </div>

      </div>
    </v-scroll-y-transition>

  </div>
</template>

<script>
import StakePoolPaginatorContract from '@/smart-contracts/farms-v2/normal/stake-pool/v1/stake-pool-paginator'
import StakerRow from './staker-view-row'
import ERC20 from '@/smart-contracts/erc20/pager'

export default {

  props: {
    tokenHydrated: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    stake_pool_address: null,
    pool_info: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },

  components: {
    StakerRow
  },

  data: () => ({
    num_stakers: 0,
    stakers: [],
    uncl_token_hydrated: {
    },
    pagination: {
      page: 0,
      rowsPerPage: 10
    },
    loading: false
  }),

  computed: {
    pageCount () {
      var count = Math.ceil(this.stakers.length / this.pagination.rowsPerPage)
      return count === 0 ? 1 : count
    },
    paginatedRows () {
      var offset = this.pagination.page * this.pagination.rowsPerPage
      return this.stakers.slice(offset, offset + this.pagination.rowsPerPage)
    },
  },

  methods: {
    // async refresh () {
    //   this.loading = true
    //   this.num_stakers = await StakePoolPaginatorContract.getNumStakers(this.stake_pool_address)
    //   this.stakers = await StakePoolPaginatorContract.getStakers(this.stake_pool_address, 0, this.num_stakers)
    //   this.loading = false
    // },
    goNext () {
      this.pagination.page++
      // this.refresh()
    },
    goPrevious () {
      this.pagination.page--
      // this.refresh()
    },
    async refresh () {
      this.loading = true
      this.num_stakers = Number(await StakePoolPaginatorContract.getNumStakers(this.stake_pool_address))
      var stakers = []
      var batchGet = 500
      while (stakers.length < this.num_stakers) {
        console.log(stakers.length)
        var upperTarget = stakers.length + batchGet > this.num_stakers ? this.num_stakers : stakers.length + batchGet
        var batch = await StakePoolPaginatorContract.getStakers(this.stake_pool_address, stakers.length, upperTarget)
        stakers.push(...batch)
        this.loading = false
      }

      this.stakers = stakers
    },
    async getUnclTokenInfo () {
      this.uncl_token_hydrated = await ERC20.getERC20(this.pool_info.uncl_token_address)
    }
  },

  created () {
    this.getUnclTokenInfo()
    this.refresh()
  }
}
</script>