import { store } from '@/store/index'
import { ethers } from "ethers"
import WalletLink from 'walletlink'
import StaticConnectorVars from './static'
import Endpoints from './endpoints'
// import StaticSettings from '@/store/settings'

// import Web3 modal stuff
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'
import { configureChains, createConfig } from '@wagmi/core'
import { arbitrum, mainnet, polygon, bsc, goerli, sepolia, avalanche } from '@wagmi/core/chains'
import { getAccount, getNetwork, watchAccount, watchNetwork, getWalletClient, disconnect as disconnectWalletConnect } from '@wagmi/core'
import { getEthersSigner } from './wagmi-ethers-signer'

// Initialize WalletLink
const walletLink = new WalletLink({
  appName: "UNCX",
  appLogoUrl: "https://i.imgur.com/WCwbSiX.png",
  darkMode: false
})

// Web3 modal stuff
const chains = [arbitrum, avalanche, mainnet, polygon, bsc, goerli, sepolia]
const projectId = '000c81983a1a36c44373290aeef9704c'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)
const web3modal = new Web3Modal({ projectId }, ethereumClient)

const Self = {
  async initalizeWalletConnect () {
    const account = getAccount()
    Self.onWalletConnectAccountChange(account)


    // watchers
    const unwatchAccount = watchAccount((account) => {
      Self.onWalletConnectAccountChange(account)
    })

    const unwatchNetwork = watchNetwork((network) => {
      // if (network?.chain?.id) {
      //   Self.onWalletConnectNetworkChange(network.chain.id)
      // }
      const account = getAccount()
      Self.onWalletConnectAccountChange(account)
    })
  },
  async connectWalletConnect() {
    web3modal.openModal()
  },
  async onWalletConnectAccountChange (account) {
    // On connect
    if (account.isConnected) {
      const { chain } = getNetwork()
      const signer = await getEthersSigner(chain.id)

      store.commit('updateEthers', {
        coinbase: account.address,
        providerName: StaticConnectorVars.WALLETCONNECT,
        signer: signer
      })

      // set the chain and provider
      Self.onWalletConnectNetworkChange(chain.id)
    }
  },
  async onWalletConnectNetworkChange (chainId) {
    store.commit('updateWalletNetworkWalletConnect', chainId)
  },
  async getCoinbase(provider) {
    var accounts = await provider.send('eth_requestAccounts')
    // var accounts = await provider.eth.getAccounts()
    return accounts[0]
  },
  async connectViewOnlyProvider() {
    // var homesteadProvider = ethers.getDefaultProvider('ropsten')
    // var homesteadProvider = new ethers.providers.JsonRpcProvider("https://ropsten.infura.io/v3/3cd774e14cf34ff78167908f8377051c");
    // var homesteadProvider = ethers.getDefaultProvider('homestead')
    var requiredNetwork = store.state.requiredNetwork
    var provider = new ethers.providers.JsonRpcProvider(Endpoints[requiredNetwork])

    provider.getBlockNumber()
      .then(blockNumber => {
        store.commit('setBlockNumber', blockNumber)
      })

    store.commit('updateEthers', {
      provider: provider
    })
  },
  async connectToMetamask() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    var coinbase = await this.getCoinbase(provider)
    var checksum_address = ethers.utils.getAddress(coinbase)
    store.commit('updateEthers', {
      coinbase: checksum_address,
      providerName: StaticConnectorVars.METAMASK,
      signer: signer,
    })
    store.commit('updateWalletNetwork', provider)
    this.removeMetamaskListeners()
    this.addMetamaskListeners()
  },
  // coinbase
  async connectToWalletLink() {
    var wlprovider = walletLink.makeWeb3Provider(Endpoints.Mainnet, 1)
    const provider = new ethers.providers.Web3Provider(wlprovider)
    const signer = provider.getSigner()
    var coinbase = await this.getCoinbase(provider)
    var checksum_address = ethers.utils.getAddress(coinbase)
    store.commit('updateEthers', {
      coinbase: checksum_address,
      providerName: StaticConnectorVars.WALLETLINK,
      // provider: provider,
      signer: signer,
      walletLinkProvider: wlprovider
      // network: StaticMap.networkHexToInt[window.ethereum.chainId]
    })
    store.commit('updateWalletNetwork', provider)
    this.removeMetamaskListeners()
  },
  /* async connectWalletConnectLegacy () {
    var requiredNetwork = store.state.requiredNetwork
    var settings
    if (requiredNetwork === 'Mainnet') {
      settings = {
        rpc: {1: Endpoints.Mainnet}
      }
    } else if (requiredNetwork === 'Ganache') {
      settings = {
        rpc: {1: "https://bsc-dataseed.binance.org/"}
      }
    } else if (requiredNetwork === 'xDai') {
      settings = {
        rpc: {100: Endpoints.xDai}
      }
    } else if (requiredNetwork === 'BSC_MAINNET') {
      settings = {
        rpc: {56: "https://bsc-dataseed.binance.org/"}
      }
    } else if (requiredNetwork === 'Matic') {
      settings = {
        rpc: {137: Endpoints.Matic}
      }
    }

    const walletConnectProvider = new WalletConnectProvider(settings)
    await walletConnectProvider.enable();
    var connector = new ethers.providers.Web3Provider(walletConnectProvider)
    const signer = connector.getSigner()

    var checksum_address = ethers.utils.getAddress(walletConnectProvider.wc.accounts[0])
    store.commit('updateEthers', {
      coinbase: checksum_address,
      providerName: StaticConnectorVars.WALLETCONNECT,
      // provider: connector,
      signer: signer,
      walletConnectProvider: walletConnectProvider
    })
    store.commit('updateWalletNetwork', connector)

    // Check if connection is already established
    if (!walletConnectProvider.connected) {
      // create new session
      walletConnectProvider.createSession();
    }

    // Subscribe to connection events
    connector.on("connect", (error, payload) => {
      if (error) {
        throw error;
      }

      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0];
      console.log(accounts)
      console.log(chainId)
    })

    walletConnectProvider.on("session_update", (error, payload) => {
      if (error) {
        throw error;
      }

      // Get updated accounts and chainId
      const { accounts, chainId } = payload.params[0]
      console.log(accounts)
      console.log(chainId)
    })

    walletConnectProvider.on("disconnect", (error, payload) => {
      if (error) {
        throw error;
      }

      // Delete connector
    })
  }, */

  removeMetamaskListeners() {
    window.ethereum.removeAllListeners('accountsChanged')
    window.ethereum.removeAllListeners('chainChanged')
  },
  addMetamaskListeners() {
    window.ethereum.on('accountsChanged', accounts => {
      var checksum_address = ethers.utils.getAddress(accounts[0])
      store.commit('updateEthers', {
        coinbase: checksum_address
      })
    })
    window.ethereum.on('chainChanged', () => {
      this.connectToMetamask()
    })
  },
  disconnect() {
    if (store.state.ethers.providerName === StaticConnectorVars.WALLETCONNECT) {
      // store.state.ethers.walletConnectProvider.disconnect()
      disconnectWalletConnect()
    }
    if (store.state.ethers.providerName === StaticConnectorVars.WALLETLINK) {
      store.state.ethers.walletLinkProvider.disconnect()
    }
    store.commit('updateEthers', {
      coinbase: null,
      coinbase_condensed: null,
      providerName: null,
      signer: null,
      network: null,
      walletConnectProvider: null,
      walletLinkProvider: null
    })

    this.connectViewOnlyProvider()
  },
}

Self.initalizeWalletConnect()

export default Self