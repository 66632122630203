<template>
  <div class="v-card pa-2">

    <div class="text-center mb-3">
      Admin functions
    </div>

    <div>
      Set UNCL guaranteed allocation amount
    </div>
    <div>
      <div class="d-flex align-center border br-20">
        <c-input :value.sync="unclAmountHuman" placeholder="0.0" @updateWhileFocussed="onHumanAmountChange" class="pa-2 font-weight-bold">

        </c-input>

        <div class="font-weight-bold">
          UNCL
        </div>
      </div>
    </div>
    <v-btn rounded block depressed color="blue" @click="setUNCLAmount" class="ml-2">
      SET UNCL allocation
    </v-btn>

    <v-btn @click="forceFailByUnicrypt" x-large color="red" block class="mt-8">
      Force Fail
    </v-btn>
  </div>
</template>

<script>
import { ethers } from 'ethers'
import PresaleContract from '@/smart-contracts/presales/v3/presale-contract'
import TxWrapper from '@/web3/tx-wrapper-2';

export default {
  props: {
    address: {
      type: String
    }
  },

  components: {
  },

  data: () => ({
    unclAmount: '2000000000000000000',
    unclAmountHuman: '2'
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    },
  },

  watch: {
  },

  methods: {
    onHumanAmountChange (val) {
      try {
        this.unclAmount = ethers.utils.parseUnits(val, 18)
      } catch (e) {
        this.unclAmount = '0'
      }
    },
    async setUNCLAmount () {
      TxWrapper.doTransaction(PresaleContract.setUNCLAmount,
        [this.address, this.unclAmount], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          // this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          // this.lockLoading = false
        })
    },
    async forceFailByUnicrypt () {
      TxWrapper.doTransaction(PresaleContract.forceFailByUnicrypt,
        [this.address], this.$store.state.requiredNetwork, this.$root.$dialog.chainSwitch, this.$root.$dialog.confirmTx2)
        .then(() => { 
          // this.refresh()
        })
        .catch(e => { this.$root.$dialog.web3Error.open(e.message) })
        .then(() => {
          // this.lockLoading = false
        })
    },
  },

  created () {
  }

}
</script>