<template>
  <v-card class="pa-4">
    <div>
      Promoted content
    </div>
    <div class="d-flex py-3">
      <v-btn color="light-blue" class="white--text" @click="addCard">
        Add
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="saveRequired" color="primary" class="white--text" @click="saveContent">
        Save
      </v-btn>
    </div>

    <div>
      <v-btn color="light-blue" class="white--text" @click="shiftStart">
        Shift start
      </v-btn>
      <v-btn color="light-blue" class="white--text" @click="shiftEnd">
        Shift end
      </v-btn>
    </div>

    <v-carousel 
    v-model="currentPage"
    :show-arrows="false" 
    hide-delimiter-background
    :hide-delimiters="cards.length < 2">
      <v-carousel-item v-for="(card, index) of cards" :key="index">
        <div v-if="edit === index" class="v-card br-8 white">
          <!-- Edit bar -->
          <div class="text-end px-3 pt-3">
            <v-btn @click="edit = null" icon small color="text" class="background">
              <v-icon small>mdi-pen</v-icon>
            </v-btn>
            <v-btn @click="deleteCard(index)" icon small color="text" class="background ml-2">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </div>
          <!-- Edit bar -->
          <v-row class="px-5 m-0">
            <v-col>
              <v-text-field
                v-model="cards[edit].icon_url"
                label="Icon Image"
                hide-details
                class="caption"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="cards[edit].image_url"
                label="Banner Image"
                hide-details
                class="caption"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-5 m-0">
            <v-col>
              <v-text-field
                v-model="cards[edit].subtitle"
                label="Subtitle"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="cards[edit].title"
                label="Title"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="textFaint--text mt-4 pb-3 px-5">
            <v-textarea
              counter
              label="Description"
              v-model="cards[edit].description"
              rows="3"
            ></v-textarea>
          </div>
          <v-row class="px-5 m-0">
            <v-col>
              <v-text-field
                v-model="cards[edit].twitter_url"
                label="Twitter"
                hide-details
                class="caption"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="cards[edit].website_url"
                label="Website"
                hide-details
                class="caption"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-5 m-0 mb-2">
            <v-col>
              <v-text-field
                v-model="cards[edit].button_text"
                label="Button Text"
                hide-details
                class="caption"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="cards[edit].button_url"
                label="Button URL"
                hide-details
                class="caption"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div v-else class="v-card overflow-hidden br-8">
          <div style="position: relative;">
            <v-img
            height="140px"
            max-width="100%"
            position="center center"
            :src="card.image_url"
            >
            </v-img>
            <coin-icon 
            v-if="card.icon_url"
            :url="card.icon_url" 
            :size="80"
            class="foreground pa-05"
            style="position: absolute; left: 2px; bottom: -30px;"
            ></coin-icon>
            <!-- Edit bar -->
            <div style="position: absolute; right: 2px; top: 10px;">
              <v-btn @click="edit = index" icon small color="text" class="background">
                <v-icon small>mdi-pen</v-icon>
              </v-btn>
              <v-btn @click="deleteCard(index)" icon small color="text" class="background ml-2">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
            <!-- Edit bar -->
          </div>
          <div class="text-center pt-4 pb-1">
            <div v-if="card.subtitle" class="text-center">
              <span class="background--text subtitle-1 text py-1 px-3 br-20">
                {{ card.subtitle }}
              </span>
            </div>
            <div v-if="card.title" class="title mt-2">
              {{ card.title }}
            </div>
          </div>
          <div v-if="card.description" class="textFaint--text pb-3 px-3" style="white-space: pre-line;">
            {{ card.description }}
          </div>
          <div class="d-flex border-t align-center justify-center py-3 pr-4" style="margin-left: -4px;">
            <v-btn v-if="card.button_text && card.button_url.slice(0, 1) === '/'" text color="text" :to="card.button_url">
              <span class="text-uppercase">{{ card.button_text }}</span>
            </v-btn>
            <v-btn v-else-if="card.button_text" text color="text" :href="card.button_url">
              <span class="text-uppercase">{{ card.button_text }}</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="card.twitter_url" icon color="text" :href="card.twitter_url" target="_blank">
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn v-if="card.website_url" icon color="text" class="ml-2" :href="card.website_url" target="_blank">
              <v-icon>mdi-web</v-icon>
            </v-btn>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
// import moment from 'moment'

export default {
  data: () => ({
    cards: [
      {
        title: 'UNCX',
        subtitle: 'New liquidity lock',
        description: 'Voxnet is a metaverse game that does x and y and stuff Voxnet is a metaverse game that does x and y and stuff Voxnet is a metaverse game that does x and y and stuff...Voxnet is a metaverse game that does x and y and stuff...',
        icon_url: 'https://pbs.twimg.com/profile_images/1484449970371665920/I651va_e_400x400.jpg',
        image_url: 'https://pbs.twimg.com/profile_banners/1275709288787345408/1610369699/1080x360',
        button_text: 'View Presale',
        button_url: '/amm/pancake-v2/ilo/0x9627Ccb587b2C5bdDc150FAA61eF032bD62058C0',
        twitter_url: 'https://twitter.com/UNCX_token',
        website_url: 'https://www.uncx.network'
      }
    ],
    edit: null,
    currentPage: 0,

    lastSave: ''
  }),

  computed: {
    saveRequired () {
      return JSON.stringify(this.cards) !== this.lastSave
    }
  },

  methods: {
    addCard () {
      this.cards.push({
        title: '',
        subtitle: '',
        description: '',
        icon_url: '',
        image_url: '',
        button_text: '',
        button_url: '',
        twitter_url: '',
        website_url: ''
      })
    },
    deleteCard (index) {
      this.cards.splice(index, 1)
      this.edit = null
    },
    shiftStart () {
      var element = this.cards[this.currentPage]
      this.cards.splice(this.currentPage, 1)
      this.cards.unshift(element)
    },
    shiftEnd () {
      var element = this.cards[this.currentPage]
      this.cards.splice(this.currentPage, 1)
      this.cards.push(element)
    },
    async getPromotedContent () {
      this.$userAxios.get(`/admin/get-promoted-content`)
        .then(response => {
          this.cards = response.data
          this.lastSave = JSON.stringify(response.data)
        })
        .catch(console.error)
    },
    async saveContent () {
      this.$userAxios.post(`/admin/set-promoted-content`, this.cards)
        .then(response => {
          this.getPromotedContent()
        })
        .catch(error => {
          console.log(error.response.data.errors[0])
        })
    },
  },

  created () {
    this.getPromotedContent()
  }
}
</script>