<template>
  <div class="pa-2 d-flex align-center caption">
    <img 
    :src="$settings.CHAINS[networkKey].icon" 
    height="30px"
    width="30px"
    class="br-20">

    <div class="pl-2">
      <div class="d-flex align-center">
        ip:
        <c-input :value="item.ip_address" placeholder="ip address" class="pa-2 foreground br-c">
        </c-input>
      </div>
      <div>
        sender: {{ item.sender}}
      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    item: {}
  },

  data: () => ({
  }),

  computed: {
    networkKey () {
      return Object.keys(this.$settings.CHAINS).filter(chainKey => this.$settings.CHAINS[chainKey].chainId === this.item.chain_id)[0]
    }
  },

  methods: {
  },

  created () {
  }
}
</script>