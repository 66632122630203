/*
This file throws errors when using GraphQL Endpoints since 
GraphQL returns status: 200 for errors
*/

import axios from 'axios'

const http = axios.create()
http.interceptors.response.use(function (response) {
  if (response.data.errors) {
    console.log(response.date.errors)
    throw new axios.Cancel('Graph Ql Errors Detected');
  } else {
    return response;
  }
}, function (error) {
  return Promise.reject(error);
});

export default http