<template>
  <div class="">

    <div v-if="firstLoad" class="text-center">
      <v-progress-circular
      indeterminate
      size="50"
      color="#aaa">
        <img 
        src="@/assets/img/UNCX_fill.svg" 
        height="40px"
        width="40px"
        class="greyscale">
      </v-progress-circular>
    </div>

    <template v-else>

      <div v-if="items.length === 0" class="textFaint--text px-4 py-2 mt-1">
        No audits for this token on UNCX yet.
      </div>

      <div v-for="audit of items" :key="audit.id" class="pa-4 mt-1" style="position: relative; overflow: hidden;">

        <div :class="getAuditColor(audit.rating)" style="position: absolute; left: 0px; top: 0px; bottom: 0px; right: 0px;opacity: 0.2;">
        </div>

        <div class="d-flex align-center">
          <div v-if="audit.rating === 2" class="d-flex align-center font-weight-medium pr-2 br-8" style="position: relative; overflow: hidden;">
            <img 
            src="@/assets/img/flags/close.svg" 
            height="26px"
            class="mr-2"
            width="26px">
            <span>
              Flagged
            </span>
          </div>

          <div v-if="audit.rating === 1" class="d-flex align-center font-weight-medium pr-2 br-8" style="position: relative; overflow: hidden;">
            <img 
            src="@/assets/img/flags/exclamation.svg" 
            height="26px"
            class="mr-2"
            width="26px">
            <span>
              Minor warning
            </span>
          </div>

          <div v-if="audit.rating === 0" class="d-flex align-center font-weight-medium pr-2 br-8" style="position: relative; overflow: hidden;">
            <img 
            src="@/assets/img/flags/verified.svg" 
            height="26px"
            class="mr-2"
            width="26px">
            <span>
              Audited
            </span>
          </div>

          <div class="ml-1 font-weight-medium textFaint--text">
            by <span class="text--text">{{ audit.username }}</span>
          </div>

        </div>

        <div v-if="audit.audit_text" class="font-weight-medium caption mt-4" style="white-space: pre-wrap;"
        >{{ audit.audit_text }}
        </div>

        <v-btn :href="audit.audit_link" target="_blank" rounded text v-if="audit.audit_link" class="mt-4" :color="getAuditColor(audit.rating)">
          View audit
          <v-icon x-small class="ml-1">mdi-arrow-top-right</v-icon>
        </v-btn>

      </div>

    </template>

  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    address: {
      type: String
    }
  },

  data: () => ({
    firstLoad: true,
    items: []
  }),

  computed: {
  },

  methods: {
    getAuditColor (rating) {
      if (rating === 2) {
        return 'red'
      } else if (rating === 1) {
        return 'orange'
      }
      return 'primary'
    },
    normalLoad: _.debounce(function () {
      this.APIFetch()
        .then(audits => {
          this.items = audits
          // this.nextPageExists = rows.length === 2
          // var endOfPage = (this.page + 1) * this.rowsPerPage
          // this.nextPageExists = endOfPage < response.data.count
        })
        .catch(error => {
          console.log(error)
        })
        .then(() => {
          this.loading = false
          this.showSearchLoader = false
          this.firstLoad = false
        })
    }, 300, {leading: true}),

    async APIFetch () {
      this.loading = true
      var response = await this.$axios.post(`/audits/${this.address}`)
      var audits = response.data.rows
      var user_ids = audits.map(row => row.user_id)
      var usernames = await this.$userAxios.post(`/users/get-usernames`, {user_ids: user_ids})
      usernames = usernames.data
      audits.map(audit => {
        audit.username = usernames[audit.user_id]
      })
      return audits
    },
  },

  created () {
    this.normalLoad()
  }
}
</script>