<template>
  <span @click.stop="showRelative = !showRelative" class="d-inline-flex align-center unselectable">
    <template v-if="showRelative">
      {{ fromNow }}
    </template>
    <template v-else>
      {{ timeLocal }}
    </template>
    <v-icon x-small color="textFaint" class="pl-1">mdi-information-outline</v-icon>
  </span>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    epoch: null
  },

  data: () => ({
    showRelative: true,
  }),

  computed: {
    shortInterval () {
      return this.$store.state.intervals.short
    },
    timeLocal () {
      return moment.unix(this.epoch).format('D MMM H:mm')
    },
    timeUTC () {
      return moment.unix(this.epoch).utc().format('D MMM H:mm') + ' UTC'
    },
    fromNow () {
      return moment.unix(this.epoch).fromNow()
    },
  },

  watch: {
    shortInterval () {
      // this.showRelative = !this.showRelative
    }
  },

  methods: {
  }

}
</script>