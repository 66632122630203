const Self = {
  CHAIN_DISPLAY_NAME: {
    'Mainnet': 'Mainnet',
    'Goerli': 'Goerli',
    'Kovan': 'Kovan',
    'Sepolia': 'Sepolia',
    'Ganache': 'Ganache',
    'BSC_MAINNET': 'Binance Smart Chain',
    'BSC_TESTNET': 'Binance Smart Chain - Testnet',
    'xDai': 'xDai',
    'ArbitrumOne': 'Arbitrum One',
    'Matic': 'Polygon',
    'Hardhat': 'Hardhat',
    'Base': 'Base',
  },
  CHAIN_URL_NAME: {
    'Mainnet': 'mainnet',
    'Kovan': 'kovan',
    'Sepolia': 'sepolia',
    'Ganache': 'ganache',
    'BSC_MAINNET': 'bsc',
    'BSC_TESTNET': 'bsc-testnet',
    'xDai': 'xdai',
    'ArbitrumOne': 'arb1',
    'Matic': 'matic',
    'Hardhat': 'hardhat',
    'AVAX': 'avax',
    'DogeChain': 'dogechain',
    'Base': 'base',
  },

  // this must be the same as the back end anti bot settings
  ANTI_BOT_WAIT: {
    'Ganache': 3,
    'Kovan': 4,
    'BSC_MAINNET': 3,
    'Mainnet': 2,
  },

  UNCX_ADDRESS: {
    'Mainnet': '0xaDB2437e6F65682B85F814fBc12FeC0508A7B1D0',
    'BSC_MAINNET': '0x09a6c44c3947B69E2B45F4D51b67E6a39ACfB506',
    'xDai': '0x0116e28B43A358162B96f70B4De14C98A4465f25',
    'AVAX': '0x3b9e3b5c616A1A038fDc190758Bbe9BAB6C7A857',
  },
  UNCL_ADDRESS: {
    'Mainnet': '0x2f4eb47A1b1F4488C71fc10e39a4aa56AF33Dd49',
    'BSC_MAINNET': '0x0E8D5504bF54D9E44260f8d153EcD5412130CaBb',
    'xDai': '0x703120F2f2011a0D03A03a531Ac0e84e81F15989',
    'AVAX': '0x7D86F1eafF29F076576b2Ff09CE3bcC7533fD2C5',
  },

  ETHERSCAN_URL: {
    'Mainnet': 'https://etherscan.io',
    'Goerli': 'https://goerli.etherscan.io',
    'Kovan': 'https://kovan.etherscan.io',
    'Sepolia': 'https://sepolia.etherscan.io/',
    'Ganache': 'https://etherscan.io',
    'BSC_MAINNET': 'https://bscscan.com',
    'BSC_TESTNET': 'https://testnet.bscscan.com',
    'xDai': 'https://blockscout.com/poa/xdai',
    'Matic': 'https://explorer-mainnet.maticvigil.com',
    'ArbitrumOne': 'https://arbiscan.io',
    'Base': 'https://basescan.org',
  },

  WRAPPED_GAS_TOKEN: { // e.g. WETH on ETHEREUM
    'Mainnet': '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    'Kovan': '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    'Sepolia': '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
    'Ganache': '0x62F53f13Ed85E8e184DfffcaF54408b13E7BFab5',
    'BSC_MAINNET': '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    'BSC_TESTNET': '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    'xDai': '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
    'Matic': '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    'Base': '0x4200000000000000000000000000000000000006',
  },

  CHAINS: {
    'Mainnet': {
      chainId: 1,
      name: 'Ethereum',
      shortName: 'eth',
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
      gasToken: {
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        symbol: 'WETH',
        name: 'Wrapped Ether',
        decimals: 18
      },
      explorerName: 'Etherscan',
      explorerURL: 'https://etherscan.io',
      dexscreenerChainName: 'ethereum',
      blockTime: 12,
      confirmations: 2,
      graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_ETH
    },
    'ArbitrumOne': {
      chainId: 42161,
      name: 'ArbitrumOne',
      shortName: 'arb1',
      icon: 'https://cryptologos.cc/logos/arbitrum-arb-logo.png',
      gasToken: {
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
        symbol: 'WETH',
        name: 'Wrapped Ether',
        decimals: 18
      },
      explorerName: 'Arbiscan',
      explorerURL: 'https://arbiscan.io',
      // dexscreenerChainName: 'ethereum',
      blockTime: 2,
      confirmations: 2,
      graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_ARBITRUMONE
      // graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_ETH
    },
    'Base': {
      chainId: 8453,
      name: 'Base',
      shortName: 'base',
      icon: 'https://dd.dexscreener.com/ds-data/chains/base.png',
      gasToken: {
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0x4200000000000000000000000000000000000006',
        symbol: 'WETH',
        name: 'Wrapped Ether',
        decimals: 18
      },
      explorerName: 'Basescan',
      explorerURL: 'https://basescan.org',
      // dexscreenerChainName: 'ethereum',
      blockTime: 2,
      confirmations: 2,
      // graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_ARBITRUMONE
      // graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_ETH
    },
    'AVAX': {
      chainId: 43114,
      name: 'Avalanche',
      shortName: 'avax',
      icon: 'https://cryptologos.cc/logos/avalanche-avax-logo.png',
      gasToken: {
        symbol: 'AVAX',
        name: 'AVAX',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        symbol: 'WAVAX',
        name: 'Wrapped AVAX',
        decimals: 18
      },
      explorerName: 'Snowtrace',
      explorerURL: 'https://snowtrace.io',
      dexscreenerChainName: 'avalanche',
      blockTime: 2.2,
      confirmations: 5
    },
    'Goerli': {
      chainId: 5,
      name: 'Goerli',
      shortName: 'goerli',
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
      gasToken: {
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
        symbol: 'WETH',
        name: 'Wrapped Ether',
        decimals: 18
      },
      explorerName: 'Etherscan',
      explorerURL: 'https://goerli.etherscan.io',
      blockTime: 15,
      confirmations: 1,
      graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_GOERLI
    },
    'Sepolia': {
      chainId: 11155111,
      name: 'Sepolia',
      shortName: 'sepolia',
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
      gasToken: {
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
        symbol: 'WETH',
        name: 'Wrapped Ether',
        decimals: 18
      },
      explorerName: 'Etherscan',
      explorerURL: 'https://sepolia.etherscan.io/',
      blockTime: 12,
      confirmations: 1,
      // graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_GOERLI
    },
    'Ganache': {
      chainId: 1337,
      name: 'Ganache',
      shortName: 'ganache',
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
      gasToken: {
        symbol: 'ETH',
        name: 'Ethereum',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
        symbol: 'WETH',
        name: 'Wrapped Ether',
        decimals: 18
      },
      explorerName: 'Etherscan',
      explorerURL: 'https://kovan.etherscan.io',
      blockTime: 4,
      confirmations: 1
    },
    'BSC_MAINNET': {
      chainId: 56,
      name: 'Binance Smart Chain',
      shortName: 'bsc',
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png',
      gasToken: {
        symbol: 'BNB',
        name: 'BNB',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        symbol: 'WBNB',
        name: 'Wrapped BNB',
        decimals: 18
      },
      explorerName: 'BscScan',
      explorerURL: 'https://bscscan.com',
      dexscreenerChainName: 'bsc',
      blockTime: 3,
      confirmations: 2,
      graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_BSC
    },
    'BSC_TESTNET': {
      chainId: 97,
      name: 'Binance Smart Chain - Testnet',
      shortName: 'bsc-testnet',
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png',
      gasToken: {
        symbol: 'BNB',
        name: 'BNB',
        decimals: 18
      },
      wrappedGasToken: {
        address: '',
        symbol: 'WBNB',
        name: 'Wrapped BNB',
        decimals: 18
      },
      explorerName: 'BscScan',
      explorerURL: 'https://testnet.bscscan.com',
      blockTime: 3,
      confirmations: 2,
      graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_BSC_TESTNET
    },
    'xDai': {
      chainId: 100,
      name: 'xDai',
      shortName: 'xdai',
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0Ae055097C6d159879521C384F1D2123D1f195e6/logo.png',
      gasToken: {
        symbol: 'xDai',
        name: 'xDai',
        decimals: 18
      },
      wrappedGasToken: {
        address: '',
        symbol: 'WxDai',
        name: 'Wrapped xDai',
        decimals: 18
      },
      explorerName: 'blockscout',
      explorerURL: 'https://blockscout.com/poa/xdai',
      blockTime: 5,
      confirmations: 2
    },
    'Matic': {
      chainId: 137,
      name: 'Polygon',
      shortName: 'polygon',
      icon: 'https://res.cloudinary.com/sushi-cdn/image/fetch/w_64,f_auto,q_auto,fl_sanitize/https://raw.githubusercontent.com/sushiswap/logos/main/token/polygon.jpg',
      gasToken: {
        symbol: 'Matic',
        name: 'Matic',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        symbol: 'WMATIC',
        name: 'Wrapped Matic',
        decimals: 18
      },
      explorerName: 'Explorer',
      explorerURL: 'https://polygonscan.com',
      dexscreenerChainName: 'polygon',
      blockTime: 2.2,
      confirmations: 2,
      graphExplorerMinterV2: process.env.VUE_APP_MINTER_V2_GRAPH_POLYGON
    },
    'DogeChain': {
      chainId: 2000,
      name: 'DogeChain',
      shortName: 'dogechain',
      icon: 'https://docs.dogechain.dog/img/logo.png',
      gasToken: {
        symbol: 'wDOGE',
        name: 'wDOGE',
        decimals: 18
      },
      wrappedGasToken: {
        address: '0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101',
        symbol: 'WWDOGE',
        name: 'Wrapped WDOGE',
        decimals: 18
      },
      explorerName: 'DogeChain explorer',
      explorerURL: 'https://explorer.dogechain.dog',
      dexscreenerChainName: 'dogechain',
      blockTime: 2,
      confirmations: 1
    },
  },

  // AMMS key must never be changed as it will break contract interaction
  // shortname must never be changed as it will break URL links. 
  // Display name can be changed without affecting the app in any way
  AMMS: {
    'Uniswap V2': {
      chain: 'Mainnet',
      displayname: 'Uniswap V2',
      shortname: 'uni-v2',
      liqLockerVersion: 1,
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984/logo.png',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
      ammTokenLink: 'https://v2.info.uniswap.org/token/',
      ammPairLink: 'https://v2.uniswap.info/pair/',
      server: 'https://api-univ2-accounts.unicrypt.network/api/v1',
      // server: 'http://localhost/api/v1'
      taxTokenTaxHelperIndex: 0
    },
    'Uniswap V2 - Base': {
      chain: 'Base',
      displayname: 'Uniswap V2 - Base',
      shortname: 'uni-v2-base',
      liqLockerVersion: 1,
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984/logo.png',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
      ammTokenLink: 'https://v2.info.uniswap.org/token/',
      ammPairLink: 'https://v2.uniswap.info/pair/',
      server: 'https://api-univ2-accounts.unicrypt.network/api/v1',
      // server: 'http://localhost/api/v1'
      taxTokenTaxHelperIndex: 0
    },
    'Camelot': {
      chain: 'ArbitrumOne',
      displayname: 'Camelot',
      shortname: 'camelot',
      liqLockerVersion: 2,
      icon: 'https://pbs.twimg.com/profile_images/1574894755078610944/vj5DIMhP_400x400.jpg',
      // banner: 'https://i.imgur.com/9RLOkou.gif',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/camelotlabs/camelot-amm',
      ammTokenLink: 'https://info.camelot.exchange/token/',
      ammPairLink: 'https://info.camelot.exchange/pair/',
      server: 'https://api-camelot.unicrypt.network/api/v1',
      // server: 'http://localhost/api/v1'
      taxTokenTaxHelperIndex: 1
    },
    'Sushiswap - Arbitrum': {
      chain: 'ArbitrumOne',
      displayname: 'Sushiswap',
      shortname: 'sushi-arb1',
      liqLockerVersion: 2,
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B3595068778DD592e39A122f4f5a5cF09C90fE2/logo.png',
      // banner: 'https://i.imgur.com/9RLOkou.gif',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-exchange',
      ammTokenLink: 'https://app.sushi.com/analytics/tokens/',
      ammPairLink: 'https://app.sushi.com/analytics/pools/',
      server: 'https://api-sushi-arb.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'YodeSwap': {
      chain: 'DogeChain',
      displayname: 'YodeSwap',
      shortname: 'yodeswap',
      liqLockerVersion: 2,
      icon: 'https://i.imgur.com/JVE3egl.png',
      // banner: 'https://i.imgur.com/9RLOkou.gif',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
      ammTokenLink: 'https://yodeswap.dog/exchange/swap?outputCurrency=',
      ammPairLink: 'https://dexscreener.com/dogechain/',
      server: 'https://api-yodeswap.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'Sushiswap V1': {
      chain: 'Mainnet',
      displayname: 'Sushiswap',
      shortname: 'sushi-v1',
      liqLockerVersion: 1,
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B3595068778DD592e39A122f4f5a5cF09C90fE2/logo.png',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/sushiswap/exchange',
      ammTokenLink: 'https://sushiswap.vision/token/',
      ammPairLink: 'https://sushiswap.vision/pair/',
      server: 'https://api-sushi-mainnet.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'Pancakeswap V2': {
      chain: 'BSC_MAINNET',
      displayname: 'Pancakeswap V2',
      shortname: 'pancake-v2',
      liqLockerVersion: 1,
      icon: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.svg?v=010',
      // graphExplorer: 'https://api.bscgraph.org/subgraphs/id/QmUDNRjYZ7XbgTvfVnXHj6LcTNacDD9GPXHWLjdTKi6om6',
      graphExplorer: 'https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2',
      // ammTokenLink: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=',
      ammTokenLink: 'https://pancakeswap.info/token/',
      ammPairLink: 'https://pancakeswap.info/pair/',
      server: 'https://api-pcakev2.unicrypt.network/api/v1',
      // server: 'http://localhost/api/v1'
      taxTokenTaxHelperIndex: 0
    },
    'BabyDogeSwap V1': {
      chain: 'BSC_MAINNET',
      displayname: 'BabyDogeSwap V1',
      shortname: 'babydoge-bsc-v1',
      liqLockerVersion: 2,
      icon: 'https://cdn.shopify.com/s/files/1/0388/4833/1916/files/baby_doge_200.png',
      // banner: 'https://i.imgur.com/apdoWvP.png',
      // bannerLink: 'https://biswap.org/incentive_program?utm_source=uni&utm_medium=gip&utm_campaign=col_gipuni_jh',
      graphExplorer: 'https://graph-bsc-mainnet.babydoge.com/subgraphs/name/babydoge/exchange',
      // ammTokenLink: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=',
      ammTokenLink: 'https://babydogeswap.com/info/token/',
      ammPairLink: 'https://babydogeswap.com/info/pool/',
      server: 'https://api-babydoge-bsc.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'Biswap V1': {
      chain: 'BSC_MAINNET',
      displayname: 'Biswap V1',
      shortname: 'biswap-bsc-v1',
      liqLockerVersion: 2,
      icon: 'https://static.biswap.org/bs/coins/bsw.svg',
      banner: 'https://i.imgur.com/apdoWvP.png',
      bannerLink: 'https://biswap.org/incentive_program?utm_source=uni&utm_medium=gip&utm_campaign=col_gipuni_jh',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/biswapcom/exchange5',
      // ammTokenLink: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=',
      ammTokenLink: 'https://biswap.org/analytics/token/',
      ammPairLink: 'https://biswap.org/analytics/pool/',
      server: 'https://api-biswap-v1-bsc.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    /* 'Sushiswap (Polygon)': {
      chain: 'Matic',
      displayname: 'Sushiswap',
      shortname: 'sushi-poly-v1',
      liqLockerVersion: 2,
      icon: 'https://res.cloudinary.com/sushi-cdn/image/fetch/f_auto,c_limit,w_32,q_auto/https://app.sushi.com/images/logo.svg',
      // banner: 'https://i.imgur.com/apdoWvP.png',
      // bannerLink: 'https://biswap.org/incentive_program?utm_source=uni&utm_medium=gip&utm_campaign=col_gipuni_jh',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/sushiswap/matic-exchange',
      // ammTokenLink: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=',
      ammTokenLink: 'https://app.sushi.com/analytics/tokens/',
      ammPairLink: 'https://app.sushi.com/analytics/pools/',
      // server: 'https://api-biswap-v1-bsc.unicrypt.network/api/v1'
      server: 'http://localhost/api/v1'
    }, */
    /* 'Pangolin V1': {
      chain: 'AVAX',
      displayname: 'Pangolin V1',
      shortname: 'pangolin-v1',
      liqLockerVersion: 2,
      icon: 'https://app.pangolin.exchange/static/media/icon.adcff230.svg',
      // graphExplorer: 'https://api.bscgraph.org/subgraphs/id/QmUDNRjYZ7XbgTvfVnXHj6LcTNacDD9GPXHWLjdTKi6om6',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/dasconnor/pangolin-dex',
      // ammTokenLink: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=',
      ammTokenLink: 'https://info.pangolin.exchange/#/token/',
      ammPairLink: 'https://info.pangolin.exchange/#/pair/',
      server: 'https://api-pangolinv1.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    }, */
    'Pancakeswap V1': {
      chain: 'BSC_MAINNET',
      displayname: 'Pancakeswap V1',
      shortname: 'pancake-v1',
      liqLockerVersion: 1,
      icon: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.svg?v=010',
      graphExplorer: 'https://api.bscgraph.org/subgraphs/id/QmUDNRjYZ7XbgTvfVnXHj6LcTNacDD9GPXHWLjdTKi6om6',
      ammTokenLink: 'https://v1exchange.pancakeswap.finance/#/swap?inputCurrency=',
      ammPairLink: 'https://pancakeswap.info/pair/',
      server: 'https://pcake.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'Safemoonswap V1': {
      chain: 'BSC_MAINNET',
      displayname: 'Safemoonswap V1',
      shortname: 'safemoon-bsc-v1',
      liqLockerVersion: 2,
      icon: 'https://safemoon-dashboard.s3.ap-southeast-1.amazonaws.com/16875743995901652278757419logo.png',
      // graphExplorer: 'https://api.bscgraph.org/subgraphs/id/QmUDNRjYZ7XbgTvfVnXHj6LcTNacDD9GPXHWLjdTKi6om6',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/officialsafemoon/safemoon-swap-bsc',
      // ammTokenLink: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=',
      ammTokenLink: 'https://swap.safemoon.net/#/swap/',
      ammPairLink: 'https://swap.safemoon.net/#/swap/',
      server: 'https://api-safemoon-v1-bsc.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'Julswap V1': {
      chain: 'BSC_MAINNET',
      displayname: 'Julswap',
      shortname: 'julswap-v1',
      liqLockerVersion: 1,
      icon: 'https://dex.julswap.com/static/media/logo.1a47995b.svg',
      graphExplorer: 'https://subgraph.swapliquidity.org/subgraphs/name/swapliquidity/subgraph',
      ammTokenLink: 'https://julswap.org/token/',
      ammPairLink: 'https://julswap.org/pair/',
      server: 'https://juld.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'Trader Joe V1': {
      chain: 'AVAX',
      displayname: 'Trader Joe V1',
      shortname: 'traderjoe-v1',
      liqLockerVersion: 2,
      icon: 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd/logo.png',
      // graphExplorer: 'https://api.bscgraph.org/subgraphs/id/QmUDNRjYZ7XbgTvfVnXHj6LcTNacDD9GPXHWLjdTKi6om6',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/exchange',
      // ammTokenLink: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=',
      ammTokenLink: 'https://analytics.traderjoexyz.com/tokens/',
      ammPairLink: 'https://analytics.traderjoexyz.com/pairs/',
      server: 'https://api-traderjoev1.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'Quickswap V1': {
      chain: 'Matic',
      displayname: 'Quickswap',
      shortname: 'quickswap-v1',
      liqLockerVersion: 1,
      icon: 'https://i.imgur.com/8G7QIrR.png',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/sameepsi/quickswap06',
      ammTokenLink: 'https://info.quickswap.exchange/#/token/',
      ammPairLink: 'https://info.quickswap.exchange/#/pair/',
      server: 'https://quickswap.unicrypt.network/api/v1',
      // server: 'http://localhost/api/v1'
      taxTokenTaxHelperIndex: 0
    },
    'Honeyswap V1': {
      chain: 'xDai',
      displayname: 'Honeyswap',
      shortname: 'honey-v1',
      liqLockerVersion: 1,
      icon: 'https://honeyswap.1hive.eth.limo/static/media/logo_white.svg',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/1hive/uniswap-v2',
      ammTokenLink: 'https://info.honeyswap.org/token/',
      ammPairLink: 'https://info.honeyswap.org/pair/',
      server: 'https://honey.unicrypt.network/api/v1'
      // server: 'http://localhost/api/v1'
    },
    'Pancakeswap V2 - BSC TESTNET': {
      chain: 'BSC_TESTNET',
      displayname: 'Pancakeswap V2',
      shortname: 'pancake-v2',
      liqLockerVersion: 1,
      icon: 'https://cryptologos.cc/logos/pancakeswap-cake-logo.svg?v=010',
      // graphExplorer: 'https://api.bscgraph.org/subgraphs/id/QmUDNRjYZ7XbgTvfVnXHj6LcTNacDD9GPXHWLjdTKi6om6',
      graphExplorer: 'https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2',
      // ammTokenLink: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=',
      ammTokenLink: 'https://pancakeswap.info/token/',
      ammPairLink: 'https://pancakeswap.info/pair/',
      server: 'https://api-pcakev2.unicrypt.network/api/v1',
      // server: 'http://localhost/api/v1'
      taxTokenTaxHelperIndex: 0
    },
    'Uniswap V2 - Ganache': {
      chain: 'Ganache',
      displayname: 'Uniswap V2 - Ganache',
      shortname: 'uniswap-v2-ganache',
      liqLockerVersion: 1,
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984/logo.png',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
      ammTokenLink: 'https://info.uniswap.org/token/',
      ammPairLink: 'https://uniswap.info/pair/',
      server: 'http://localhost/api/v1' // Always localhost
    },
    'Uniswap V2 - Goerli': {
      chain: 'Goerli',
      displayname: 'Uniswap V2 - Goerli',
      shortname: 'univ2-v2-goerli',
      liqLockerVersion: 2,
      icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984/logo.png',
      graphExplorer: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
      ammTokenLink: 'https://v2.info.uniswap.org/token/',
      ammPairLink: 'https://v2.uniswap.info/pair/',
      server: 'https://api-goerli.unicrypt.network/api/v1',
      // server: 'http://localhost/api/v1'
      taxTokenTaxHelperIndex: 0
    },
  },
}

module.exports = Self