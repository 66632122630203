<template>
  <v-row class="align-center" style="width: 103%;">
    <div v-if="showTitle" class="text-center pa-4 font-weight-medium mb-0" style="width: 100%;">
      Swap Whitelisting
    </div>
    <v-col cols="6">
      <div class="d-flex align-center pa-2">
        Ending Date
      </div>
      <div v-if="showWhitelistEnabled" class="d-flex align-center pa-2">
        Whitelisted Addresses
      </div>
    </v-col>
    <v-col cols="6">
      <div class="d-flex align-center inputcolor pa-2 r-outlined">
        <c-input
          :value.sync="values.endDate"
          title="Ending Date"
          type="number"
          max="48"
          min="0"
          @change="validate(values)"
        />
        Hours
      </div>
      <div v-if="showWhitelistEnabled" class="d-flex align-center pa-2">
        <v-btn
          rounded block deprsessed large
          color="primary"
          class="br-20 gradient-button-v1"
          @click="showWhitelistDialog"
        >
          Whitelist
        </v-btn>
      </div>
      <div v-if="showEnabled" class="d-flex flex-row justify-space-between align-center font-weight-medium" style="padding: 8px; margin-right: 8px;">
        On/Off
        <v-switch
          v-model="values.isActive"
          color="primary"
          class="ma-0"
          hide-details
          style="align-self: center;"
        />
      </div>
    </v-col>
  </v-row>
</template>
<script>

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showEnabled: {
      type: Boolean,
      default: true
    },
    showWhitelistEnabled: {
      type: Boolean,
      default: false
    },
    initialValues: {
      type: Object
    }
  },
  data: ({ showTitle, showEnabled, showWhitelistEnabled, initialValues }) => ({
    showTitle,
    showEnabled,
    showWhitelistEnabled: showWhitelistEnabled,
    values: initialValues
  }),
  methods: {
    validate (values) {
      if (values.endDate < 0) {
        values.endDate = 0;
      } else if (values.endDate > 48) {
        values.endDate = 48;
      }
    },
    onChange (values) {
      this.validate(values);
      this.$emit('on-change', values);
    },
    showWhitelistDialog () {
      this.$emit('open-whitelist');
    }
  },
  watch: {
    values: {
      handler (newValue, _) {
        this.onChange(newValue);
      },
      deep: true
    }
  }
}
</script>