<template>

  <div style="overflow: unset;" class="v-card br-8 c-list imghover">
    <div :style="`position: relative;overflow: hidden;`">
      <div :style="`background-image: url(${item.meta.media.banner ? item.meta.media.banner : $root.$icons.farm_image});background-repeat: no-repeat;background-size: cover;background-position: top;min-height: 120px;`" class="img-zoom-on-hover">
      </div>
      <div :style="`position: absolute; top: 30px; left: ${item.meta.lp_meta ? '120px' : '90px'};`" class="text-appear">
        <div class="display-1 font-weight-bold">
          <template v-if="!item.meta.lp_meta">
            STAKE
          </template>
          <template v-else>
            FARM
          </template>
        </div>
        <div>
          {{ item.stoken_symbol }}
          <span class="textFaint--text">
            -
          </span>
          <span class="primary--text font-weight-medium">
            {{ totalAPYFormatted }}% APR
          </span>
        </div>
      </div>
    </div>
    <div v-if="rewardList.length <= 2 && rewardList.length > 0" class="br-c caption font-weight-medium d-flex align-center" style="position: absolute; top: 110px; right: 10px;">
      <div class="d-flex">
        <div v-for="reward_pool of rewardList" :key="reward_pool.reward_pool_address" class="mr-1 px-2 foreground br-c d-flex align-center hover-primary" @click.stop="$root.tokenPage.open(reward_pool.address, $store.state.requiredNetwork)">
          <coin-icon :address="reward_pool.address" :size="12" :network="network"></coin-icon>
          <span class="ml-1 text-truncate" style="max-width: 6ch;">
            {{ reward_pool.symbol }}
          </span>
          <span v-if="reward_pool.apy === 0" class="ml-1 text--text">
            0%
          </span>
          <span v-else-if="reward_pool.apy < 10" class="ml-1 text--text">
            {{ (reward_pool.apy).toFixed(2) }}%
          </span>
          <span v-else class="ml-1 text--text">
            {{ (reward_pool.apy).toFixed(0) }}%
          </span>
        </div>
      </div>
    </div>
    <div v-else-if="rewardList.length === 0" class="textFaint--text br-c foreground px-2 caption d-flex align-center" style="position: absolute; top: 110px; right: 10px;">
      <span>
        No rewards
      </span>
    </div>

    <div v-if="rewardList.length > 2" class="d-flex align-center" style="position: absolute; top: 110px; right: 10px;">
      <div v-for="reward_pool of rewardList.slice(0, 1)" :key="reward_pool.reward_pool_address" class="caption px-2 foreground br-c d-flex align-center hover-primary" @click.stop="$root.tokenPage.open(reward_pool.address, $store.state.requiredNetwork)">
        <coin-icon :address="reward_pool.address" :size="12" :network="network"></coin-icon>
        <span class="ml-1">
          {{ reward_pool.symbol }}
        </span>
        <span class="ml-1 text--text">
          {{ (reward_pool.apy).toFixed(2) }}%
        </span>
      </div>
      <span>
        <v-menu
        offset-y
        open-on-hover
        transition="slide-x-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="text background--text caption px-2 br-c"
              v-bind="attrs"
              v-on="on"
              @click.stop
              x-small
            > 
              <span class="font-weight-bold">
                +{{ rewardList.length - 1 }}
                <v-icon small color="background">mdi-chevron-down</v-icon>
              </span>
            </v-btn>
          </template>

          <v-list style="min-width: 200px;" class="background pa-0">
            <v-list-item v-for="reward_pool of rewardList" :key="reward_pool.reward_pool_address" class="border-b px-2 foreground d-flex align-center">
              <coin-icon :address="reward_pool.address" :size="18" :network="network"></coin-icon>
              <span class="ml-1 font-weight-bold">
                {{ reward_pool.symbol }}
              </span>
              <v-spacer></v-spacer>
              <span class="font-weight-bold primary--text">
                {{ (reward_pool.apy).toFixed(2) }}%
              </span>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </div>

    <div class="ml-4 icon-zoom" style="margin-top: -30px;position: absolute;">
      <template v-if="!item.meta.lp_meta">
        <div class="pa-05 white br-c" style="width: 50px; height: 50px;">
          <coin-icon :address="item.stoken_address" :url="item.meta.media.icon" :size="46" :network="network"></coin-icon>
        </div>
      </template>
      <div v-else class="d-flex align-center">
        <div class="pa-05 white br-c" style="width: 50px; height: 50px;">
          <coin-icon :address="item.meta.lp_meta.token0.address" :size="46" :network="network"></coin-icon>
        </div>
        <div class="pa-05 white br-c" style="width: 40px; height: 40px;margin-left: -12px;">
          <coin-icon :address="item.meta.lp_meta.token1.address" :size="36" :network="network"></coin-icon>
        </div>
      </div>
    </div>

    <div v-if="false">
      {{ item }}
    </div>

    <div class="background" style="height:1.5px;">

    </div>

    <div class="px-4 pb-4">
      
      <!-- TOKEN SYMBOL -->
      <div class="d-flex align-baseline mt-6">
        <div class="title font-weight-bold hover-primary" @click.stop="$root.tokenPage.open(item.stoken_address, $store.state.requiredNetwork)">
          <template v-if="!item.meta.lp_meta">
            {{ item.stoken_symbol }}
          </template>
          <template v-else>
            {{ item.meta.lp_meta.token0.symbol }}
            -
            {{ item.meta.lp_meta.token1.symbol }}
          </template>
        </div>
        <v-spacer></v-spacer>
        <span v-if="false">
          <div class="textFaint--text font-weight-medium caption">
            {{ Number(item.stoken_balance).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
            {{ item.stoken_symbol }}
          </div>
        </span>

        <span v-if="false" class="textFaint--text caption">
          {{ item.meta.pricing.stoken }}
        </span>
      </div>

      <div class="d-flex textFaint--text caption align-center">
        <span class="pr-2">
        {{ $root.formatAmount(item.meta.min_stake_amount, item.meta.staking_token.decimals) }}
        </span>
        <v-icon x-small color="textFaint">mdi-arrow-left-right</v-icon>
        <span class="pl-2">
          <template v-if="maxStakeAmountIsMaxUint">
            <v-icon small color="textFaint">mdi-infinity</v-icon>
          </template>
          <div v-else style="max-width: 9ch;" class="text-truncate">
            {{ $root.formatAmount(item.meta.max_stake_amount, item.meta.staking_token.decimals) }}
          </div>
        </span>

        <v-spacer></v-spacer>

        <template v-if="item.meta.min_staking_period !== 0">
          <v-icon x-small color="textFaint" class="mr-1">mdi-lock</v-icon>
          <span>
            {{ $root.getReadableTimeSpan(item.meta.min_staking_period) }}
          </span>
        </template>

        <v-icon x-small color="textFaint" class="ml-2 mr-1">mdi-account-circle</v-icon>
        x&nbsp;
        <span>
          {{ item.num_stakers }}
        </span>
      </div>

      <div v-if="false" class="caption d-flex align-center">
        <div class="textFaint--text">
          {{ Number(item.stoken_balance).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
          {{ item.stoken_symbol }}
        </div>
        <v-spacer></v-spacer>
        <div class="textFaint--text">
          x
          {{ item.num_stakers }}
        </div>
      </div>

    </div>

    <v-row class="ma-0 px-2 midground" dense>
      <v-col cols="6" class="pa-0">
        <div class="pa-2 br-8">
          <div class="textFaint--text caption font-weight-medium">
            TVL
          </div>
          <div class="textFaint--text title font-weight-bold">
            ${{ $root.formatDollars(item.tvl) }}
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="pa-0">
        <div class="pa-2 br-8 text-end">
          <div :class="['caption font-weight-medium', {'textFaint--text': totalAPY > 0}, {'textFaint--text': totalAPY === 0}]">
            APR
          </div>
          <div v-if="totalAPY > 0" class="title textFaint--text font-weight-bold">
            {{ totalAPYFormatted }}%
          </div>
          <div v-else class="title textFaint--text font-weight-bold">
            0%
          </div>
        </div>
      </v-col>
    </v-row>

  </div>
  <!--
  <div @click="$root.chainLink(`/farm/${item.spool_address}`)"
  class="pa-4 c-list d-flex align-center">
    <coin-icon :address="item.stoken_address" :size="30" class="mr-2"></coin-icon>
    {{ item.stoken_symbol }}
    <v-spacer></v-spacer>
    <span class="textFaint caption px-3 br-c background--text d-flex align-center font-weight-medium">
      {{ item.num_stakers }}
      <v-icon small color="background" class="ml-1">mdi-account-group</v-icon>
    </span>
    <span class="textFaint caption px-3 br-c background--text d-flex align-center font-weight-medium">
      id: {{ item.id }}
    </span>
  </div>
  -->
  <!--
  <tr @click="$root.chainLink(`/farm/${item.spool_address}`)" class="align-center c-list">
    <td>
      <div class="d-flex align-center">
        <coin-icon :address="item.stoken_address" :size="30" class="mr-2"></coin-icon>
        <div>
          <div>
            {{ item.stoken_symbol }}
          </div>
          <div class="caption textFaint--text">
            {{ Number(item.stoken_balance).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}) }}
          </div>
        </div>
      </div>
    </td>
    <td class="">
      <div class="d-flex align-center font-weight-medium textFaint--text">
        {{ item.num_stakers }}
        <v-icon small class="ml-2" color="textFaint">mdi-account-group</v-icon>
      </div>
    </td>
    <td v-if="advancedMode" class="caption">
      {{ item.num_reward_pools }}
    </td>
    <td v-if="advancedMode" class="textFaint--text caption">
      {{ item.id }}
    </td>
  </tr>
  -->
</template>

<script>
import { ethers } from 'ethers'

export default {

  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    advancedMode: {
      type: Boolean,
      default: false
    },
    network: null
  },

  data: () => ({
  }),

  computed: {
    maxStakeAmountIsMaxUint () {
      return ethers.constants.MaxUint256.toString() === this.item.meta.max_stake_amount
    },
    rewardList () {
      var rewards = JSON.parse(JSON.stringify(this.item.meta.rewards))
      rewards = rewards.sort((a, b) => {
        return b.apy - a.apy
      })
      return rewards
    },
    totalAPY () {
      var apy = this.rewardList.reduce((a, item) => {
        return a + item.apy
      }, 0)
      return apy
    },
    totalAPYFormatted () {
      if (this.totalAPY > 10) {
        return this.totalAPY.toFixed(0)
      }
      return this.totalAPY.toFixed(2)
    }
  },

  methods: {
    /* async getRewards () {
      this.loadingRewards = true
      var userAddress = ethers.constants.AddressZero
      var num_reward_pools = await StakePoolPaginatorContract.getNumRewardPools(this.item.spool_address)
      var reward_pools = await StakePoolPaginatorContract.getRewardPools(this.item.spool_address, userAddress, 0, num_reward_pools)
      for (var reward_pool of reward_pools) {
        var pool = await RewardPoolContract.getInfo(reward_pool.pool_address)
        this.reward_pools.push(pool)
      }
      this.loadingRewards = false
    } */
  },

  created () {
  }
}
</script>

<style scoped lang="scss">
.imghover {
  & .img-zoom-on-hover {
    transition: all 0.5s;
    opacity: 0.95;
  }
  & .icon-zoom {
    transition: all 0.5s;
  }
  & .text-appear {
    transition: all 0.5s;
    transform: scale(0.8);
    opacity: 0;
  }
  & .text-disappear {
    transition: all 0.5s;
    opacity: 0;
  }
  &:hover {
    & .img-zoom-on-hover {
      transform: scale(1.2);
      opacity: 0.01;
    }
    & .icon-zoom {
      transform: scale(1.3) translate(0, -42px);
      opacity: 1;
      // animation: 0.8s cubic-bezier(0.83, 0, 0.17, 1) 0s coin-flip
    }
    & .text-appear {
      transform: scale(1);
      opacity: 1;
    }
    & .text-disappear {
      opacity: 1;
    }
  }
}
</style>