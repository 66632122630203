<template>
  <v-container style="max-width: 600px;">
    <div v-if="!firstLoad" class="text-center textFaint--text caption font-weight-medium">
      Presale contract version: {{ contract_version }}
    </div>
    <v-card :class="['br-20 mb-16', {'pa-2': $vuetify.breakpoint.xs}, {'pa-4': !$vuetify.breakpoint.xs}]">
      
      <v-icon v-if="true" size="100" style="color: var(--v-background-base)!important; position: absolute; right: -0px; top: -0px;">mdi-cog</v-icon>

      <div class="d-flex align-center mb-4">
        <v-btn icon :to="`${$store.state.ammPrefix}/ilo/${this.address}`" color="text">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>

      <div v-if="!firstLoad && presaleNotFound" class="pa-8 v-card mx-auto" style="max-width: 600px;">
        <div class="mb-4">
          <v-btn icon :to="`${$store.state.ammPrefix}/ilos`" color="text">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        No presale for this address is registered on UniCrypt.
        <div class="mt-2 textFaint--text">
          {{ address }}
        </div>
      </div>
      <template v-else-if="!firstLoad">
        <type-one v-if="contract_version < 3 && contract_version > -1" :address="address"></type-one>
        <type-three v-else-if="contract_version === 3" :address="address"></type-three>
        <type-four v-else-if="contract_version === 4" :address="address"></type-four>
        <type-four v-else-if="contract_version === 5" :address="address"></type-four>
        <type-six v-else-if="contract_version === 6" :address="address"></type-six>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import PresaleFactoryContract from '@/smart-contracts/presales/presale-factory'
import PresalePeriphery from '@/smart-contracts/presales/presale-periphery'
import TypeOne from './type_1'
import TypeThree from './v3/type_3'
import TypeFour from './v4/type_4'
import TypeSix from './v6/type_6'

export default {
  props: {
    address: {
      type: String
    }
  },

  components: {
    TypeOne,
    TypeThree,
    TypeFour,
    TypeSix
  },

  data: () => ({
    contract_version: -1,
    firstLoad: true,
    presaleNotFound: false,
  }),

  methods: {
    async getContractVersion () {
      this.contract_version = await PresalePeriphery.getContractVersion(this.address)
      this.firstLoad = false
    }
  },

  created () {
    PresaleFactoryContract.presaleIsRegistered(this.address)
      .then(isTrue => {
        if (isTrue) {
          this.getContractVersion()
        } else {
          this.presaleNotFound = true
          this.firstLoad = false
        }
      })
      .catch(e => {
        console.log(e)
      })
  }
}
</script>
