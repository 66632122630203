<template>
  <div class="pa-4 foreground br-8">
    <div>Chain servers</div>

    <chain-select :value.sync="selected_chain"></chain-select>
    <div>
      {{ $root.$servers.FARMS[chainShortName].server }}
    </div>
    <v-btn @click="test"> One min cron </v-btn>
  </div>
</template>

<script>
import axios from "axios";
import ChainSelect from '@/components/ui/chain-select'

export default {

  components: {
    ChainSelect
  },

  data: () => ({
    selected_chain: "Mainnet",
  }),

  computed: {
    chainShortName () {
      return this.$settings.CHAINS[this.selected_chain].shortName
    }
  },

  methods: {
    async test () {
      var response = await axios.post(
        `${this.$root.$servers.FARMS[this.chainShortName].server}/cron/one-min`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.user.jwtToken}`,
          },
        }
      );
      console.log(response)
    },
  },

  created () {},
};
</script>