<template>
  <v-container style="max-width: 600px;" :class="[{'pa-0 foreground mobile-page': $vuetify.breakpoint.xs}]">
    
    <div class="text-center pt-2 pb-2">
      <chain-select :chains="allowedChains" :value.sync="selected_chain" @on-update="switchServer"></chain-select>
    </div>

    <v-slide-y-transition appear>
      <div>
        <oracle-gen v-if="$store.state.superUserMode" class="mb-4"></oracle-gen>
        <farm-gen></farm-gen>
      </div>
    </v-slide-y-transition>
    
  </v-container>
</template>

<script>
import FarmGen from './farmgen-01'
import OracleGen from './oraclegen-01'
import ChainSelect from '@/components/ui/chain-select'

export default {

  components: {
    FarmGen,
    OracleGen,
    ChainSelect
  },

  data: () => ({
    selected_chain: 'Mainnet',
    allowedChains: []
  }),

  computed: {
  },

  methods: {
    switchServer (chain) {
      this.$store.commit('switchChain', chain)
    }
  },

  created () {
    this.selected_chain = this.$store.state.requiredNetwork
    this.allowedChains = ['Mainnet', 'BSC_MAINNET', 'AVAX']
    if (this.$store.state.allowGanache) {
      this.allowedChains.push('Ganache')
    }
  }
}
</script>