import {store} from '@/store/index'
import SETTINGS from '@/store/settings'
import SERVERS from '@/store/servers'
import axios from "axios"

const Self = {
  doTransaction: async (contractCall, contractArguments, requiredNetwork, chainSwitchDialog, loadingDialog, wait = 1) => {
    if (SETTINGS.CHAINS[requiredNetwork].chainId !== store.state.ethers.network) {
      // open chain switcher dialog
      await chainSwitchDialog.open(requiredNetwork)
        .then(resolved => {
          throw new Error('NETWORK_CHANGED')
          // return Self.contractCall(contractCall, contractArguments, requiredNetwork, loadingDialog, wait)
        })
        .catch(e => {
          if (e && e.message === 'NETWORK_CHANGED') {
            throw new Error('Network changed successfully, you can now retry the last action')
          } else {
            throw new Error('User cancelled transaction')
          }
        })
    } else {
      var vWait = SETTINGS.CHAINS[requiredNetwork].confirmations
      vWait = vWait > wait ? vWait : wait
      return Self.contractCall(contractCall, contractArguments, requiredNetwork, loadingDialog, vWait)
    }
  },

  contractCall: async (contractCall, contractArguments, requiredNetwork, loadingDialog, wait = 1) => {
    var txHash
    try {
      if (loadingDialog) {
        loadingDialog.open(requiredNetwork)
      }
      var txn = await contractCall(...contractArguments)
      txHash = txn.hash
      if (loadingDialog) {
        loadingDialog.close()
      }
      store.commit('addPendingTx', { txhash: txHash, requiredNetwork: requiredNetwork })
      var receipt = await txn.wait(wait)
      // console.log(receipt)
      var chainId = SETTINGS.CHAINS[requiredNetwork].chainId
      axios.post(`${SERVERS.TXNS}/tx/new`, {chainId: chainId, txHash: receipt.transactionHash, from: receipt.from})
        .then(() => {})
        .catch(e => { console.log('TXN') })
      store.commit('completePendingTx', { txhash: receipt.transactionHash, requiredNetwork: requiredNetwork })
      return {
        txn: txn,
        receipt: receipt
      }
    } catch (error) {
      if (loadingDialog) {
        loadingDialog.close()
      }
      console.log(error)
      store.commit('failPendingTx', { txhash: txHash, requiredNetwork: requiredNetwork })
      throw error
    }
  }
}

export default Self