import {store} from '@/store/index';
import { ethers } from "ethers";
import PRESALEABI from '@/smart-contracts/presales/presale-abis'
import ERC20 from '@/smart-contracts/erc20'
import PROVIDERS from '@/web3/providers'

const Self = {
  getInfo: async (_address, network) => {
    var provider = store.state.ethers.provider
    if (network) {
      provider = PROVIDERS[network]
    }
    const Presale = new ethers.Contract(_address, PRESALEABI.presale0304_abi, provider)
    var contract_version = -1
    var response = {}
    var res
    var sale_token, base_token
    try {
      res = await Presale.getInfo()
      contract_version = res[0]
    } catch (e) {
      contract_version = 0
    }

    // this catches everything (version 0, 1, 2?) before the new getInfo spec
    if (contract_version === 0) {
      const Presale01 = new ethers.Contract(_address, PRESALEABI.presale01_abi, provider)
      res = await Presale01.PRESALE_INFO()

      sale_token = {}
      try {
        sale_token = await ERC20.getERC20(res[1])
      } catch (e) {}
      base_token = {}
      try {
        base_token = await ERC20.getERC20(res[2])
      } catch (e) {}
      response = {
        contract_version: contract_version,
        presale_info: {
          presale_owner: res[0],
          token: sale_token,
          base_token: base_token,
          token_price: res[3].toString(),
          max_spend_per_buyer: res[4].toString(),
          amount: res[5].toString(),
          hardcap: res[6].toString(),
          softcap: res[7].toString(),
          liquidity_percent: res[8].toString(),
          listing_rate: res[9].toString(),
          start_block: res[10].toString(),
          end_block: res[11].toString(),
          lock_period: res[12].toString(),
          presale_in_eth: Boolean(res[13]),
          presale_contract: _address
        }
      }
    }

    if (contract_version === 3) {
      sale_token = {}
      try {
        sale_token = await ERC20.getERC20(res[1][0])
      } catch (e) {}
      base_token = {}
      try {
        base_token = await ERC20.getERC20(res[1][1])
      } catch (e) {}

      response = {
        contract_version: contract_version,
        presale_info: {
          presale_contract: _address,
          token: sale_token,
          base_token: base_token,
          token_price: res[1][2].toString(),
          max_spend_per_buyer: res[1][3].toString(),
          amount: res[1][4].toString(),
          hardcap: res[1][5].toString(),
          softcap: res[1][6].toString(),
          liquidity_percent: res[1][7].toString(),
          listing_rate: res[1][8].toString(),
          start_block: res[1][9].toString(),
          end_block: res[1][10].toString(),
          lock_period: res[1][11].toString(),
          presale_owner: res[2][0],
          presale_in_eth: Boolean(res[2][1]),
          country_code: res[2][2],
          uncl_max_participants: res[2][3].toString(),
          uncl_participants: res[2][4].toString(),

          fee_base: res[3][0].toString(),
          fee_token: res[3][1].toString(),
          fee_refferal: res[3][2].toString(),

          whitelist_only: Boolean(res[4][0]),
          lp_generation_complete: Boolean(res[4][1]),
          force_failed: Boolean(res[4][2]),
          total_base_collected: res[4][3].toString(),
          total_tokens_sold: res[4][4].toString(),
          total_tokens_withdrawn: res[4][5].toString(),
          total_base_withdrawn: res[4][6].toString(),
          round1_length: res[4][7].toString(),
          num_buyers: res[4][8].toString(),
        }
      }
    }

    return response
  },
  getContractVersion: async (_presaleAddress, _network) => {
    var provider = store.state.ethers.provider
    if (_network) {
      provider = PROVIDERS[_network]
    }
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.contract_version_abi, provider)
    var res = await Presale.CONTRACT_VERSION()
    return res.toNumber()
  },
  ownerWithdrawTokens: (_presaleAddress) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.ownerWithdrawTokens()
    return res
  },
  userDepositETH: (_presaleAddress, _ethAmount) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.userDeposit(0, {value: _ethAmount})
    return res
  },
  userDepositToken: (_presaleAddress, _tokenAmount) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.userDeposit(_tokenAmount)
    return res
  },
  userWithdrawTokens: (_presaleAddress) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.userWithdrawTokens()
    return res
  },
  userWithdrawBaseTokens: (_presaleAddress) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.userWithdrawBaseTokens()
    return res
  },
  addLiquidity: (_presaleAddress) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.addLiquidity()
    return res
  },
  forceFailByUnicrypt: (_presaleAddress) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.forceFailByUnicrypt()
    return res
  },
  presaleInfo: async (_address, network) => {
    var provider = store.state.ethers.provider
    if (network) {
      provider = PROVIDERS[network]
    }
    const Presale = new ethers.Contract(_address, PRESALEABI.presale01_abi, provider)
    var res = await Presale.PRESALE_INFO()

    var sale_token = {}
    try {
      sale_token = await ERC20.getERC20(res[1])
    } catch {}
    var base_token = {}
    try {
      base_token = await ERC20.getERC20(res[2])
    } catch {}
    return {
      presale_owner: res[0],
      token: sale_token,
      base_token: base_token,
      token_price: res[3].toString(),
      max_spend_per_buyer: res[4].toString(),
      amount: res[5].toString(),
      hardcap: res[6].toString(),
      softcap: res[7].toString(),
      liquidity_percent: res[8].toString(),
      listing_rate: res[9].toString(),
      start_block: res[10].toString(),
      end_block: res[11].toString(),
      lock_period: res[12].toString(),
      presale_in_eth: Boolean(res[13]),
      presale_contract: _address
    }
  },
  presaleSettings: async (_address, network) => {
    var provider = store.state.ethers.provider
    if (network) {
      provider = PROVIDERS[network]
    }
    const Presale = new ethers.Contract(_address, PRESALEABI.presale01_abi, provider)
    var res = await Presale.STATUS()
    return {
      whitelist_only: Boolean(res[0]),
      lp_generation_complete: Boolean(res[1]),
      force_failed: Boolean(res[2]),
      total_base_collected: res[3].toString(),
      total_tokens_sold: res[4].toString(),
      total_tokens_withdrawn: res[5].toString(),
      total_base_withdrawn: res[6].toString(),
      round1_length: res[7].toString(),
      num_buyers: res[8].toString()
    }
  },
  presaleFeeInfo: async (_address, network) => {
    var provider = store.state.ethers.provider
    if (network) {
      provider = PROVIDERS[network]
    }
    const Presale = new ethers.Contract(_address, PRESALEABI.presale01_abi, provider)
    var res = await Presale.PRESALE_FEE_INFO()
    return {
      base_fee: res[0].toString(),
      token_fee: res[1].toString(),
    }
  },
  presaleStatus: async (_address) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_address, PRESALEABI.presale01_abi, sEthers.provider)
    var res = await Presale.presaleStatus()
    return res.toString()
  },
  userInfo: async (_presaleAddress, _user, network) => {
    var provider = store.state.ethers.provider
    if (network) {
      provider = PROVIDERS[network]
    }
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, provider)
    var res = await Presale.BUYERS(_user)
    return {
      eth_deposited: res[0].toString(),
      tokens_owed: res[1].toString()
    }
  },
  getUserWhitelistStatus: async (_presaleAddress, _address, network) => {
    var provider = store.state.ethers.provider
    if (network) {
      provider = PROVIDERS[network]
    }
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, provider)
    var address = await Presale.getUserWhitelistStatus(_address)
    return address
  },
  // ADMIN FUNCTIONS
  updateMaxSpendLimit: (_presaleAddress, _maxSpend) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.updateMaxSpendLimit(_maxSpend)
    return res
  },
  updateBlocks: (_presaleAddress, _startBlock, _endBlock) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.updateBlocks(_startBlock, _endBlock)
    return res
  },
  setWhitelistFlag: (_presaleAddress, _flag) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.setWhitelistFlag(_flag)
    return res
  },
  editWhitelist: (_presaleAddress, _whitelist, _add) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.signer)
    var res = Presale.editWhitelist(_whitelist, _add)
    return res
  },
  whitelistLength: async (_presaleAddress) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.provider)
    var length = await Presale.getWhitelistedUsersLength()
    return length.toString()
  },
  getWhitelistedUserAtIndex: async (_presaleAddress, _index) => {
    var sEthers = store.state.ethers
    const Presale = new ethers.Contract(_presaleAddress, PRESALEABI.presale01_abi, sEthers.provider)
    var address = await Presale.getWhitelistedUserAtIndex(_index)
    return address
  }
}

export default Self