<template>
    
  <div class="d-flex align-center pa-4 border-b">
    <coin-icon :address="item.address" :size="60" class="mr-3"></coin-icon>
      <div class="flex">
        <div>
          <v-btn text @click.native="$root.tokenPage.open(item.address, $store.state.requiredNetwork)" class="title pa-0" style="min-width: 10px;">
            <span :class="['text-truncate']" :style="$vuetify.breakpoint.xs ? 'max-width: 14ch;' : 'max-width: 20ch;'">
              {{ item.symbol }}
            </span>
            <v-icon small>mdi-chevron-up</v-icon>
          </v-btn>

        </div>
        <div class="font-weight-medium d-flex textFaint--text mb-2">
          Your Balance: 
          <v-spacer></v-spacer>
          {{ $root.formatAmount(balance, item.decimals) }}
        </div>
        <div>
          <copy-address :address="item.address" color="textFaint" class="border br-20"></copy-address>
          <v-btn small rounded outlined color="textFaint" :to="`/services/lock-tokens?token=${item.address}`">
            Lock tokens
          </v-btn>
        </div>
      </div>
      <!--
      <div v-if="false" class="">
          {{ $root.formatAmount(item.totalSupply, item.decimals) }}
      </div>
      -->
  </div>
   
</template>

<script>
import ERC20 from '@/smart-contracts/erc20';

export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  data: () => ({
    balance: '0'
  }),

  computed: {
    sEthers () {
      return this.$store.state.ethers
    },
    sEthersWatcher () {
      return this.sEthers.watcher
    }
  },

  watch: {
    sEthersWatcher () {
      this.getBalance()
    }
  },

  methods: {
    async getBalance () {
      var balance = await ERC20.getBalance(this.sEthers.coinbase, this.item.address)
      this.balance = balance
    }
  },

  created () {
    this.getBalance()
  }
}
</script>
